import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LinkModel } from '../models/cockpit/link.model';
import { ReportModel } from '../models/cockpit/report.model';
import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { SearchResultModel } from '../models/common/search-result.model';
import { UrlModel } from '../models/common/url.model';

import { Logger } from './Logger.provider';

@Injectable()
export class ReportService {
  /**
   *
   */
  constructor(private http: HttpClient, private logger: Logger) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'no-cache': 'true'
    })
  };

  getPresignedUrlUpload(equipmentId: string, fileToSend): Observable<UrlModel> {
    // TODO change type retrieve after test api
    return this.http
      .post<UrlModel>(
        ApiEndpointsEnum.EQUIPMENTS_REPORTS_ADD.replace('{0}', equipmentId),
        { filename: fileToSend.fileNames },
        this.httpOptions
      )
      .pipe(
        catchError(error => {
          this.logger.error(error);
          return [];
        })
      );
  }

  getEquipmentReports(
    equipmentId: string,
    page: number,
    pageSize: number
  ): Observable<SearchResultModel<ReportModel[]>> {
    return this.http
      .get<SearchResultModel<ReportModel[]>>(
        `${ApiEndpointsEnum.EQUIPMENTS_REPORTS_ADD.replace(
          '{0}',
          equipmentId
        )}?page=${page}&page_size=${pageSize}`,
        this.httpOptions
      )
      .pipe(
        catchError(error => {
          this.logger.error(error);
          return [];
        })
      );
  }

  getUrlLink(
    equipmentId: string,
    page: number,
    pageSize: number
  ): Observable<SearchResultModel<LinkModel[]>> {
    return this.http
      .get<SearchResultModel<LinkModel[]>>(
        `${ApiEndpointsEnum.EQUIPMENTS_REPORTS_LINK_ADD.replace(
          '{0}',
          equipmentId
        )}?page=${page}&page_size=${pageSize}`,
        this.httpOptions
      )
      .pipe(
        catchError(error => {
          this.logger.error(error);
          return [];
        })
      );
  }

  getPresignedUrlForDownload(
    equipmentId: string,
    reportId: string
  ): Observable<UrlModel> {
    return this.http
      .get<UrlModel>(
        `${ApiEndpointsEnum.EQUIPMENTS_REPORTS_ID.replace(
          '{0}',
          equipmentId
        ).replace('{1}', reportId)}`,
        this.httpOptions
      )
      .pipe(
        catchError(error => {
          this.logger.error(error);
          return [];
        })
      );
  }

  deleteLinkById(equipmentId: string, urlId: string): Observable<any> {
    return this.http.delete(
      ApiEndpointsEnum.EQUIPMENTS_REPORTS_LINK_ID.replace(
        '{0}',
        equipmentId
      ).replace('{1}', urlId)
    );
  }

  deleteReportById(equipmentId: string, reportId: string): Observable<any> {
    return this.http.delete(
      ApiEndpointsEnum.EQUIPMENTS_REPORTS_ID.replace(
        '{0}',
        equipmentId
      ).replace('{1}', reportId)
    );
  }

  uploadFileWithPresignedUrl(
    uri: string,
    file: File,
    type: string
  ): Observable<any> {
    const customOptions = {
      headers: new HttpHeaders({
        'no-cache': 'true',
        'no-auth': 'true',
        'Content-Type': 'application/octet-stream'
      })
    };
    return this.http.put(uri, file, customOptions).pipe(
      catchError(error => {
        this.logger.error(error);
        return [];
      })
    );
  }

  postUrlLink(equipmentId: string, newLink: LinkModel): Observable<any> {
    return this.http
      .post<any>(
        ApiEndpointsEnum.EQUIPMENTS_REPORTS_LINK_ADD.replace(
          '{0}',
          equipmentId
        ),
        newLink,
        this.httpOptions
      )
      .pipe(
        catchError(error => {
          this.logger.error(error);
          return [];
        })
      );
  }
}
