import * as tslib_1 from "tslib";
import { MaximoDataService } from '../dataServices/maximo-data-service.provider';
import { TableDataSource } from './table-data-source.provider';
var CatchesDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(CatchesDataSourceService, _super);
    /**
     *
     */
    function CatchesDataSourceService(maximoDataService) {
        var _this = _super.call(this) || this;
        _this.maximoDataService = maximoDataService;
        return _this;
    }
    CatchesDataSourceService.prototype.getData = function (sort, filter, query, page) {
        return this.maximoDataService.getCatches(this.equipmentId, page, this.pageSize, sort, filter);
    };
    return CatchesDataSourceService;
}(TableDataSource));
export { CatchesDataSourceService };
