import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { LogEventModel } from '../models/common/log-event.model';

@Injectable()
export class Logger {
  constructor(private http: HttpClient) {}

  private user: string;

  setUser(email: string) {
    this.user = email;
  }

  /**
   * log a info message
   * @param message message to add to log
   * @param obj optional object list to log
   */
  async info(message: string, ...obj: any) {
    const event: LogEventModel = {
      args: obj,
      level: 'info',
      message: message,
      stackTrace: '',
      user: this.user
    };
    this.http.post(`${ApiEndpointsEnum.LOG}/info`, event).subscribe();

    // tslint:disable-next-line: no-console
    if (!environment.production) {
      console.info(message, event);
    }
  }

  /**
   * log a warn message
   * @param message message to add to log
   * @param obj optional object list to log
   */
  async warn(message: string, ...obj: any) {
    const event: LogEventModel = {
      args: obj,
      level: 'warning',
      message: message,
      stackTrace: '',
      user: this.user
    };
    this.http.post(`${ApiEndpointsEnum.LOG}/warning`, event).subscribe();
    if (!environment.production) {
      console.warn(message, event);
    }
  }

  /**
   * log a error message
   * @param message message to add to log
   * @param obj optional object list to log
   */
  async error(message: string, ...obj: any) {
    const event: LogEventModel = {
      args: obj,
      level: 'error',
      message: message,
      stackTrace: '',
      user: this.user
    };
    this.http.post(`${ApiEndpointsEnum.LOG}/error`, event).subscribe();
    if (!environment.production) {
      console.error(message, event);
    }
  }
}
