import * as moment from 'moment';

import EquipmentStatus from '../models/common/enums/equipment.status.enum';
import { ScopeItemModel } from '../models/common/scope-item.model';
import { EquipmentModel } from '../models/equipment/equipement.model';

import { ClassStructureViewModel } from './class-structure.viewmodel';
import { MaximoViewModel } from './maximo.viewmodel';
import { PlantViewModel } from './plant.viewmodel';

export class EquipmentViewModel {
  pid: string;
  name: string;
  maximo: MaximoViewModel;
  cluster: ScopeItemModel;
  country: ScopeItemModel;
  plant: PlantViewModel;
  isFavorite: boolean;
  productionUnit: ScopeItemModel;
  alProcessTag?: string;
  manufacturer?: string;
  installDate?: any;
  serialNum?: any;
  assetTag?: any;
  status: EquipmentStatus;
  class?: ClassStructureViewModel;
  locationDescription?: string;
  main: boolean;
  lastTarDate: moment.Moment;
  nextTarPmDate: moment.Moment;
  nextTarDeferredDate: moment.Moment;

  static fromModel(model: EquipmentModel): EquipmentViewModel {
    const equipment: EquipmentViewModel = {
      main: model.main,
      country: model.country,
      cluster: model.cluster,
      plant: {
        clusterName: model.cluster.name,
        id: model.plant.id,
        country: model.country.name, // TODO: to define
        name: model.plant.name, // TODO: to ask from api
        status: EquipmentStatus.Good // TODO: to define by a service or a separate component
      },
      name: model.description,
      alProcessTag: model.alProcessTag,
      manufacturer: model.manufacturer,
      installDate: model.installDate,
      serialNum: model.serialNum,
      assetTag: model.assetTag,
      pid: model.id,
      maximo: {
        numero: model.assetNum,
        url: model.assetLink
      },
      isFavorite: false,
      productionUnit: model.productionUnit,
      status: EquipmentViewModel.statusFromModel(model.status),
      class: model.classStructure,
      locationDescription: model.locationDescription,
      lastTarDate: model.lastTarDate,
      nextTarDeferredDate: model.nextTarDeferredDate,
      nextTarPmDate: model.nextTarPmDate
    };
    equipment.class.expertiseDomain = equipment.class.expertiseDomain ? equipment.class.expertiseDomain : model.expertiseDomain;
    return equipment;
  }

  static statusFromModel(status: string): EquipmentStatus {
    switch (status.toLocaleLowerCase()) {
      case 'active':
      case 'operating': {
        return EquipmentStatus.VeryGood;
      }
      case 'decommissionin': {
        return EquipmentStatus.Warning;
      }
      default: {
        return EquipmentStatus.Danger;
      }
    }
  }
}
