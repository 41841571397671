import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { SearchResultModel } from '../models/common/search-result.model';
import { CompanyBaseModel } from '../models/company/company.base.model';

import { Logger } from './Logger.provider';

@Injectable()
export class CompanyService {
  constructor(private http: HttpClient, private logger: Logger) {}
  loadCompanies(): Observable<CompanyBaseModel[]> {
    return this.http
      .get<SearchResultModel<CompanyBaseModel[]>>(ApiEndpointsEnum.COMPANY)
      .pipe(
        map((result: SearchResultModel<CompanyBaseModel[]>) => result.items)
      );
  }

  getCompanyById(companyId: string): Observable<CompanyBaseModel> {
    return this.http.get<CompanyBaseModel>(
      `${ApiEndpointsEnum.COMPANY}/${companyId}`
    );
  }
  getCompanyByName(companyName: string): Observable<CompanyBaseModel> {
    return this.http
      .get<SearchResultModel<CompanyBaseModel>>(
        `${ApiEndpointsEnum.COMPANY}?name=${companyName}`
      )
      .pipe(
        map((result: SearchResultModel<CompanyBaseModel>) => result.items[0])
      );
  }
  createCompany(company: CompanyBaseModel) {
    return this.http.post(ApiEndpointsEnum.COMPANY, company).pipe(
      catchError(error => {
        this.handleError(error, 'createCompany');
        return throwError(error);
      })
    );
  }
  handleError(error: HttpErrorResponse, action: string) {
    this.logger.error('CompanyProvider', action, error);
  }
}
