import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { ScadaDataService } from '../dataServices/scada-data-service.provider';
import { TableDataSource } from './table-data-source.provider';
var ScadaDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(ScadaDataSourceService, _super);
    function ScadaDataSourceService(scadaDataService) {
        var _this = _super.call(this) || this;
        _this.scadaDataService = scadaDataService;
        return _this;
    }
    ScadaDataSourceService.prototype.getData = function (sort, filter, query, page) {
        return this.scadaDataService
            .getScadaForEquipment(this.equipmentId, page, this.pageSize)
            .pipe(map(function (scadaData) {
            return {
                totalSize: scadaData.totalSize,
                items: scadaData.items.map(function (scada) {
                    return {
                        id: scada.pid,
                        timeserie: scada.parameterTimeseries,
                        unit: scada.unit,
                        name: scada.parameterName,
                        pid: scada.pid,
                        helper: scada.helper
                    };
                })
            };
        }));
    };
    return ScadaDataSourceService;
}(TableDataSource));
export { ScadaDataSourceService };
