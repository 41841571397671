import { List } from 'lodash';

import { EquipmentColumnModel } from './equipment-column.model';
import { ParameterRow } from './parameter-row.model';

export class ImportTableModel {
  equipments: EquipmentColumnModel[] = [];
  parameters: ParameterRow[] = [];

  convertToSheet(): List<string[]> {
    const rows = [];
    rows.push([
      'Analyses',
      'Units',
      ...this.equipments.map((eq, index) => `Sample ${index + 1}`)
    ]);
    rows.push(['Site ID', '', ...this.equipments.map(eq => eq.siteId)]);
    rows.push(['Asset ID', '', ...this.equipments.map(eq => eq.assetId)]);
    this.parameters.map(parameter =>
      rows.push([parameter.parameter.name, parameter.parameter.defaultUnits])
    );

    return rows;
  }
}
