import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { ParameterUnitModel } from '../models/common/parameter-unit.model';

@Injectable()
export class ParameterUnitService {
  /**
   *
   */
  constructor(private http: HttpClient) {}

  private httpOptions = {
    headers: new HttpHeaders({
      'no-cache': 'true'
    })
  };

  getUnitsByParameter(parameterId: string): Observable<ParameterUnitModel[]> {
    return this.http.get<ParameterUnitModel[]>(
      ApiEndpointsEnum.PARAMETER_UNITS.replace('{0}', parameterId),
      this.httpOptions
    );
  }

  addUnit(parameterId: string, unit: ParameterUnitModel): Observable<Object> {
    return this.http.post(
      ApiEndpointsEnum.PARAMETER_UNITS.replace('{0}', parameterId),
      unit,
      this.httpOptions
    );
  }

  updateUnit(
    parameterId: string,
    unit: ParameterUnitModel
  ): Observable<Object> {
    return this.http.patch(
      `${ApiEndpointsEnum.PARAMETER_UNITS.replace('{0}', parameterId)}/${
        unit.id
      }`,
      {
        formulaCoef: unit.formulaCoef,
        formulaOffset: unit.formulaOffset,
        name: unit.name
      },
      this.httpOptions
    );
  }

  delete(parameterId: string, unit: ParameterUnitModel): Observable<Object> {
    return this.http.delete(
      `${ApiEndpointsEnum.PARAMETER_UNITS.replace('{0}', parameterId)}/${
        unit.id
      }`
    );
  }
}
