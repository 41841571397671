<div fxLayout="column" fxFlexAlign="start stretch">
  <mat-progress-bar
    *ngIf="(graphService.loading$ | async); else menuDeletionPoint"
    class="loading"
    mode="indeterminate"
  ></mat-progress-bar>
  <ng-template #menuDeletionPoint>
    <ahc-menu-graph-point-deletion
      *ngxPermissionsOnly="[permissions.ADMIN, permissions.INTERNAL_KEY]"
      fxLayout="row-reverse"
    ></ahc-menu-graph-point-deletion
  ></ng-template>
  <div id="{{ containerId.toString() }}" fxFill></div>
</div>
