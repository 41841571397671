import { BehaviorSubject } from 'rxjs';
var BaseGraphParameterService = /** @class */ (function () {
    function BaseGraphParameterService() {
        this.points$ = new BehaviorSubject({
            name: '',
            values: []
        });
        this.loading$ = new BehaviorSubject(false);
        this.deletedPoint$ = new BehaviorSubject(null);
    }
    BaseGraphParameterService.prototype.loadData = function (parameterId, parameter) {
        // Looking for args to be [EquipmentViewModel] as args 
        // should be an array and EquipmentViewModel is passed in from caller.
        //
        var _this = this;
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        var clusterName = '';
        if (args && args.length > 0) {
            var equipmentViewModel = args[0];
            if (equipmentViewModel.cluster) {
                clusterName = equipmentViewModel.cluster.name;
            }
        }
        this.loading$.next(true);
        this.points$.next({
            name: '',
            values: []
        });
        this.getValues(parameterId, parameter, clusterName).subscribe(function (values) {
            _this.points$.next(values);
            _this.loading$.next(false);
        });
    };
    return BaseGraphParameterService;
}());
export { BaseGraphParameterService };
