<div class="catches" fxLayout="column" fxLayoutAlign="start stretch">
  <span class="label">{{
    'cockpit.predictive.catches.label' | translate
  }}</span>
  <div *ngIf="(tableDataSource.loading$ | async)" fxFlexAlign="stretch">
    <mat-progress-bar class="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-table
    fxFill
    [dataSource]="data"
    class="mat-elevation-z1 cockpit-table"
    fxFlexAlign="center"
  >
    <!-- column assetNum -->
    <ng-container matColumnDef="assetNum">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.assetNumber' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter['assetNum'] }"
          (search)="filter('assetNum', $event)"
          (used)="showFilter['assetNum'] = $event"
          (sort)="sort('assetNum', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.assetNum }} </mat-cell>
    </ng-container>
    <!-- column description -->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.description' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter['description'] }"
          (search)="filter('description', $event)"
          (used)="showFilter['description'] = $event"
          (sort)="sort('description', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.description }} </mat-cell>
    </ng-container>
    <!-- column priority -->
    <ng-container matColumnDef="displayPriority">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.priority' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter['displayPriority'] }"
          (search)="filter('displayPriority', $event)"
          (used)="showFilter['displayPriority'] = $event"
          (sort)="sort('displayPriority', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.displayPriority }}
      </mat-cell>
    </ng-container>
    <!-- column status -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.status' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter['status'] }"
          (search)="filter('status', $event)"
          (used)="showFilter['status'] = $event"
          (sort)="sort('status', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.status }} </mat-cell>
    </ng-container>
    <!-- column date -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.date' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter['date'] }"
          (search)="filter('date', $event)"
          (used)="showFilter['date'] = $event"
          (sort)="sort('date', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.date }} </mat-cell>
    </ng-container>
    <!-- column feedback -->
    <ng-container matColumnDef="feedback">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.feedback' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter['feedback'] }"
          (search)="filter('feedback', $event)"
          (used)="showFilter['feedback'] = $event"
          (sort)="sort('feedback', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.feedback }} </mat-cell>
    </ng-container>
    <!-- column report -->
    <ng-container matColumnDef="report">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.report' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter['report'] }"
          (search)="filter('report', $event)"
          (used)="showFilter['report'] = $event"
          (sort)="sort('report', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a *ngIf="element.report" href="{{ element.report }}" target="_blank">
          <mat-icon class="report">insert_drive_file</mat-icon></a
        >
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    fxFlexAlign="stretch"
    [pageSizeOptions]="[5]"
    showFirstLastButtons
  ></mat-paginator>
</div>
