/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./parameter-graph.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i3 from "@angular/material/progress-bar";
import * as i4 from "@angular/platform-browser/animations";
import * as i5 from "./menu-graph-point-deletion/menu-graph-point-deletion.component.ngfactory";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "./menu-graph-point-deletion/menu-graph-point-deletion.component";
import * as i9 from "../../../../../shared-module/src/lib/providers/parameter-graph-parameter.provider";
import * as i10 from "ngx-permissions";
import * as i11 from "@angular/common";
import * as i12 from "./parameter-graph.component";
import * as i13 from "@ngx-translate/core";
var styles_ParameterGraphComponent = [i0.styles];
var RenderType_ParameterGraphComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ParameterGraphComponent, data: {} });
export { RenderType_ParameterGraphComponent as RenderType_ParameterGraphComponent };
function View_ParameterGraphComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "loading mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i2.View_MatProgressBar_0, i2.RenderType_MatProgressBar)), i1.ɵdid(1, 4374528, null, 0, i3.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i4.ANIMATION_MODULE_TYPE], [2, i3.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "indeterminate"; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 1).mode === "indeterminate") || (i1.ɵnov(_v, 1).mode === "query")) ? null : i1.ɵnov(_v, 1).value); var currVal_1 = i1.ɵnov(_v, 1).mode; var currVal_2 = i1.ɵnov(_v, 1)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ParameterGraphComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ahc-menu-graph-point-deletion", [["fxLayout", "row-reverse"]], null, null, null, i5.View_MenuGraphPointDeletionComponent_0, i5.RenderType_MenuGraphPointDeletionComponent)), i1.ɵdid(1, 671744, null, 0, i6.DefaultLayoutDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutStyleBuilder], i7.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 114688, null, 0, i8.MenuGraphPointDeletionComponent, [i9.ParameterGraphParameterService], null, null)], function (_ck, _v) { var currVal_0 = "row-reverse"; _ck(_v, 1, 0, currVal_0); _ck(_v, 2, 0); }, null); }
function View_ParameterGraphComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_ParameterGraphComponent_3)), i1.ɵdid(1, 737280, null, 0, i10.NgxPermissionsDirective, [i10.NgxPermissionsService, i10.NgxPermissionsConfigurationService, i10.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(2, 2), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.permissions.ADMIN, _co.permissions.INTERNAL_KEY); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ParameterGraphComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["fxFlexAlign", "start stretch"], ["fxLayout", "column"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i6.DefaultLayoutDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutStyleBuilder], i7.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i6.DefaultFlexAlignDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.FlexAlignStyleBuilder], i7.MediaMarshaller], { fxFlexAlign: [0, "fxFlexAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_ParameterGraphComponent_1)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i11.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["menuDeletionPoint", 2]], null, 0, null, View_ParameterGraphComponent_2)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "div", [["fxFill", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(8, 671744, null, 0, i6.FlexFillDirective, [i1.ElementRef, i7.StyleUtils, i6.FlexFillStyleBuilder, i7.MediaMarshaller], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start stretch"; _ck(_v, 2, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.graphService.loading$)); var currVal_3 = i1.ɵnov(_v, 6); _ck(_v, 4, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = i1.ɵinlineInterpolate(1, "", _co.containerId.toString(), ""); _ck(_v, 7, 0, currVal_4); }); }
export function View_ParameterGraphComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ahc-parameter-graph", [], null, null, null, View_ParameterGraphComponent_0, RenderType_ParameterGraphComponent)), i1.ɵdid(1, 4440064, null, 0, i12.ParameterGraphComponent, [i13.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParameterGraphComponentNgFactory = i1.ɵccf("ahc-parameter-graph", i12.ParameterGraphComponent, View_ParameterGraphComponent_Host_0, { equipmentId: "equipmentId", parameter: "parameter", parameterId: "parameterId", graphService: "graphService", module: "module" }, {}, []);
export { ParameterGraphComponentNgFactory as ParameterGraphComponentNgFactory };
