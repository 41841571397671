import { Component, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import { UrlModel } from 'libs/shared-module/src/lib/models/common/url.model';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';
import { combineLatest } from 'rxjs';

import { ReportDataQualityIssueComponent } from '../report-data-quality-issue/report-data-quality-issue.component';

interface TabRouteModel {
  route: string;
  label: string;
}

@Component({
  selector: 'ahc-cockpit-tab-selection',
  templateUrl: './cockpit-tab-selection.component.html',
  styleUrls: ['./cockpit-tab-selection.component.scss']
})
export class CockpitTabSelectionComponent implements OnInit {
  activeLink = '';
  loading = false;
  permissions = Permissions;
  tabs: Map<ExpertiseDomain, TabRouteModel[]> = new Map([
    [
      ExpertiseDomain.WATER,
      [
        {
          route: 'water',
          label: 'cockpit.nav.water'
        },
        {
          route: 'service-report',
          label: 'cockpit.nav.service_report'
        }
      ]
    ],
    [
      ExpertiseDomain.ELECTRICAL,
      [
        {
          route: 'oil',
          label: 'cockpit.nav.oil_analysis'
        },
        {
          route: 'vibration',
          label: 'cockpit.nav.vibration_analysis'
        }
      ]
    ],
    [
      ExpertiseDomain.MECHANICAL,
      [
        {
          route: 'oil',
          label: 'cockpit.nav.oil_analysis'
        },
        {
          route: 'vibration',
          label: 'cockpit.nav.vibration_analysis'
        }
      ]
    ]
  ]);

  activeTab = '';
  userIsInternal = false;
  equipment: EquipmentViewModel;
  constructor(
    private snackbar: SnackbarService,
    private translator: TranslateService,
    private connectedUser: ConnectedUserService,
    private cockpitService: CockpitService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    combineLatest([
      this.connectedUser.onUser(),
      this.cockpitService.onEquipment()
    ]).subscribe(values => {
      this.userIsInternal = values[0] ? values[0].isInternal : false;
      this.equipment = values[1];
      if (this.equipment) {
        if (!this.userIsInternal) {
          this.activeLink = this.tabs.get(
            this.equipment.class.expertiseDomain
          )[0].route;
          this.router.navigate([
            'cockpit',
            this.equipment.pid,
            `${this.tabs.get(this.equipment.class.expertiseDomain)[0].route}`
          ]);
        }
      }
    });
  }
  exportEquipment() {
    this.loading = true;
    this.snackbar.open(
      this.translator.instant('cockpit.nav.export_equipment.waitingMessage'),
      ''
    );
    this.cockpitService
      .getPresignedUrlForDownloadExcel(this.equipment.pid)
      .subscribe(
        (presignedUrl: UrlModel) => {
          if (presignedUrl) {
            const a = document.createElement('a');
            a.setAttribute('href', presignedUrl.url);
            a.click();
          } else {
            this.snackbar.open(
              this.translator.instant(
                'cockpit.nav.export_equipment.errorMessage'
              ),
              ''
            );
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.snackbar.open(
            this.translator.instant(
              'cockpit.nav.export_equipment.errorMessage'
            ),
            ''
          );
        }
      );
  }
  getCurrentTabs(): TabRouteModel[] {
    if (this.equipment.class) {
      const nav = this.tabs.get(this.equipment.class.expertiseDomain);
      this.activeTab = nav && nav.length > 0 ? nav[0].route : 'home';
      return nav;
    }
    return [];
  }

  public openReportDataQualityIssue() {
    this.dialog.open(ReportDataQualityIssueComponent, { width: '50%' });
  }
}
