import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material';

import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import LevelEnum from 'libs/shared-module/src/lib/models/common/level.enum';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
import { UserViewModel } from 'libs/shared-module/src/lib/viewmodels/user.viewmodel';

@Component({
  selector: 'ahc-user-information',
  templateUrl: './user-information.component.html',
  styleUrls: ['./user-information.component.scss']
})
export class UserInformationComponent implements OnInit {
  @ViewChild('infoPanel', { static: false }) panel: MatExpansionPanel;
  @Input()
  user: UserViewModel;

  connectedUser: UserViewModel;
  permissions = Permissions;
  constructor(
    private userService: UsersService,
    connectedUserService: ConnectedUserService
  ) {
    connectedUserService.onUser().subscribe(u => {
      this.connectedUser = u;
    });
  }

  ngOnInit() {}

  togglePanel() {
    this.panel.toggle();
  }

  delete() {
    this.userService.delete(this.user.id).subscribe(done => {
      this.userService.refresh();
    });
  }

  canDelete(): boolean {
    return (
      (this.user.company &&
        this.connectedUser.company &&
        this.connectedUser.company.id === this.user.company.id &&
        LevelEnum.EXTERNAL_KEY.toString() === this.connectedUser.level) ||
      [LevelEnum.ADMIN.toString(), LevelEnum.EXTERNAL_KEY.toString()].indexOf(
        this.connectedUser.level.toString()
      ) > -1
    );
  }
}
