<div fxLayout="column" fxLayoutAlign="center center" fxFlex="100%">
  <div *ngIf="(tableDataSource.loading$ | async)" fxFlexAlign="stretch">
    <mat-progress-bar class="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-table
    fxFill
    [dataSource]="data"
    class="mat-elevation-z1 cockpit-table timeline"
    fxFlexAlign="center"
  >
    <ng-container
      *ngFor="let column of displayedColumns"
      matColumnDef="{{ column }}"
    >
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ column }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter[column] }"
          (search)="filter(column, $event)"
          (used)="showFilter[column] = $event"
          (sort)="sort(column, $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element[column] }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    fxFlexAlign="stretch"
    [pageSizeOptions]="[5]"
    showFirstLastButtons
  ></mat-paginator>
</div>
