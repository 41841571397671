import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ParameterTableModel } from '../../models/cockpit/parameter-table.model';
import { Point } from '../../models/common/point.model';

@Injectable()
export class OilDataService {
  getOilPrameterDetail(
    equipmentId: string,
    parameter: string,
    page: number,
    pageSize: number
  ): Observable<Point[]> {
    return of([]);
  }

  getOilForEquipment(
    equipmentId: string,
    page: number,
    pageSize: number
  ): Observable<ParameterTableModel[]> {
    return of([]);
  }
}
