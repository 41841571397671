import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import ApiEndpointsEnum from '../../models/common/enums/api_endpoints.enum';
import { ScopeItemModel } from '../../models/common/scope-item.model';

@Injectable()
export class PlantDataService {
  constructor(private http: HttpClient) {}
  getPlantById(pid: string): Observable<ScopeItemModel> {
    return this.http.get<ScopeItemModel>(`${ApiEndpointsEnum.PLANT}/${pid}`);
  }
}
