import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import CompanyEnum from 'libs/shared-module/src/lib/models/common/company.enum';
import LanguageEnum from 'libs/shared-module/src/lib/models/common/enums/language.enum';
import LevelEnum from 'libs/shared-module/src/lib/models/common/level.enum';
import { ScopeModel } from 'libs/shared-module/src/lib/models/scope/scope.model';
import { NewUserBaseModel } from 'libs/shared-module/src/lib/models/user/new-user-base.model';
import { CompanyService } from 'libs/shared-module/src/lib/providers/company.provider';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
var CreateNewUserComponent = /** @class */ (function () {
    function CreateNewUserComponent(connectedUser, scopeParameterService, userService, snackbarService, translate, companyService, dialogRef, data) {
        this.connectedUser = connectedUser;
        this.scopeParameterService = scopeParameterService;
        this.userService = userService;
        this.snackbarService = snackbarService;
        this.translate = translate;
        this.companyService = companyService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.newUser = new NewUserBaseModel();
        this.clusters = [];
        this.countries = [];
        this.plants = [];
        this.productionUnits = [];
        this.expertiseDomains = [];
        this.levels = [LevelEnum.ADMIN, LevelEnum.INTERNAL, LevelEnum.INTERNAL_KEY];
        this.languages = LanguageEnum;
        this.favorites = {
            clusters: [],
            countries: [],
            expertiseDomains: [],
            plants: []
        };
        this.profileForm = new FormGroup({
            companyPid: new FormControl(this.newUser.companyPid),
            lastname: new FormControl(this.newUser.lastname, [Validators.required]),
            firstname: new FormControl(this.newUser.firstname, [Validators.required]),
            email: new FormControl(this.newUser.email),
            level: new FormControl(this.newUser.level),
            language: new FormControl(this.newUser.language, [Validators.required]),
            scopes: new FormControl(new ScopeModel())
        });
        dialogRef.disableClose = true;
        this.username = data;
    }
    CreateNewUserComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var clustersSource, countriesSource, plantsSource, productionUnitsSource, expertiseDomainsSource;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.profileForm.controls['email'].setValue(this.username);
                this.connectedUser.onUser().subscribe(function (user) {
                    if (user.level === LevelEnum.INTERNAL) {
                        _this.levels = [LevelEnum.INTERNAL];
                    }
                });
                if (this.username.toLowerCase().includes('alizent.com')) {
                    this.companyname = CompanyEnum.ALIZENT;
                }
                if (this.username.toLowerCase().includes('airliquide.com')) {
                    this.companyname = CompanyEnum.AIRLIQUIDE;
                }
                if (this.companyname !== undefined) {
                    this.companyService
                        .getCompanyByName(this.companyname)
                        .subscribe(function (company) {
                        _this.profileForm.controls['companyPid'].setValue(company.id);
                    });
                }
                this.scopeParameterService.loadClusters().subscribe(function (clusters) {
                    _this.clusters = clusters;
                });
                clustersSource = this.scopeParameterService.loadClusters();
                countriesSource = this.scopeParameterService.loadCountries();
                plantsSource = this.scopeParameterService.loadPlants();
                productionUnitsSource = this.scopeParameterService.loadProductionUnits();
                expertiseDomainsSource = this.scopeParameterService.loadExpertiseDomains();
                forkJoin([
                    clustersSource,
                    countriesSource,
                    plantsSource,
                    productionUnitsSource,
                    expertiseDomainsSource
                ]).subscribe(function (results) {
                    _this.clusters = results[0];
                    _this.countries = results[1];
                    _this.plants = results[2];
                    _this.productionUnits = results[3];
                    _this.expertiseDomains = results[4];
                });
                return [2 /*return*/];
            });
        });
    };
    CreateNewUserComponent.prototype.setClusters = function (values) {
        this.favorites.clusters = values.map(function (value) { return value.id; });
    };
    CreateNewUserComponent.prototype.setCountries = function (values) {
        this.favorites.countries = values.map(function (value) { return value.id; });
    };
    CreateNewUserComponent.prototype.setPlants = function (values) {
        this.favorites.plants = values.map(function (value) { return value.id; });
    };
    CreateNewUserComponent.prototype.setExpertiseDomain = function (values) {
        this.favorites.expertiseDomains = values.map(function (value) { return value.id; });
    };
    CreateNewUserComponent.prototype.sendInvite = function () {
        var _this = this;
        if (this.profileForm.valid) {
            this.newUser = this.profileForm.value;
            this.userService
                .create(this.newUser)
                .pipe(catchError(function (error) {
                switch (error.status) {
                    case 409: {
                        _this.handleError(_this.translate.instant('dashboard.users.create_error_conflict'));
                        break;
                    }
                    case 400: {
                        _this.handleError(_this.translate.instant('dashboard.users.create_error_bad_request'));
                        break;
                    }
                    default: {
                        _this.handleError(_this.translate.instant('dashboard.users.create_error'));
                        break;
                    }
                }
                return of(null);
            }))
                .subscribe(function (response) {
                if (response) {
                    try {
                        console.info('Creating new internal user' +
                            ', user: " + this.newUser.email' +
                            ', userService.updateFavorites(), ' +
                            ', clusters: ' + _this.favorites.clusters.length +
                            ', countries: ' + _this.favorites.countries.length +
                            ', expertiseDomains: ' + _this.favorites.expertiseDomains.length +
                            ', plants: ' + _this.favorites.plants.length);
                    }
                    catch (error) {
                    }
                    _this.userService
                        .updateFavorites(response.userPid, {
                        favorites: {
                            clusters: _this.favorites.clusters,
                            countries: _this.favorites.countries,
                            expertiseDomains: _this.favorites.expertiseDomains.map(function (item) { return item; }),
                            plants: _this.favorites.plants,
                            productionUnits: []
                        }
                    })
                        .pipe(catchError(function (error) {
                        _this.translate.instant('dashboard.users.create_error');
                        return of(null);
                    }))
                        .subscribe(function (done) {
                        if (done) {
                            _this.snackbarService.open(_this.translate.instant('dashboard.users.created'), _this.translate.instant('common.success'));
                            _this.closeModal();
                        }
                    });
                }
            });
        }
    };
    CreateNewUserComponent.prototype.closeModal = function () {
        this.dialogRef.close();
    };
    CreateNewUserComponent.prototype.handleError = function (message) {
        this.snackbarService.open(message, null);
    };
    return CreateNewUserComponent;
}());
export { CreateNewUserComponent };
