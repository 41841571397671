import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-cockpit-header',
  templateUrl: './cockpit-header.component.html',
  styleUrls: ['./cockpit-header.component.scss']
})
export class CockpitHeaderComponent implements OnInit {
  equipment: EquipmentViewModel;
  permissions = Permissions;

  constructor(private cockpitProvider: CockpitService, private router: Router) {
    this.cockpitProvider
      .onEquipment()
      .subscribe(equipment => (this.equipment = equipment));
  }

  ngOnInit() {}

  goToPreviousPage() {
    this.router.navigate(['main/search']);
  }
}
