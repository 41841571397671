import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';

import SortEnum from 'libs/shared-module/src/lib/models/common/enums/sort.enum';
import { UnitSystemModel } from 'libs/shared-module/src/lib/models/profile/unit-system.model';
import { UnitSystemDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/unit-system-data-source.provider';

@Component({
  selector: 'ahc-profile-unit-system-preference',
  templateUrl: './profile-unit-system-preference.component.html',
  styleUrls: ['./profile-unit-system-preference.component.scss']
})
export class ProfileUnitSystemPreferenceComponent implements OnInit {
  showFilter: boolean[] = [];
  totalPages: number;
  displayedColumns: string[] = ['name', 'Expertise domain', 'Prefered unit'];
  data: UnitSystemModel[] = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(public dataSource: UnitSystemDataSourceService) {}

  ngOnInit() {
    this.dataSource.init(5);
    this.dataSource.data$.subscribe(result => (this.data = result.items));
  }

  filter(column: string, query: string) {
    if (query) {
      this.dataSource.addFilter(column, query);
    } else {
      this.dataSource.removeFilter(column);
    }
  }

  sort(column: string, direction: SortEnum) {
    if (direction === SortEnum.none) {
      this.dataSource.removeSort(column);
    } else {
      this.dataSource.addSort(column, direction);
    }
  }
}
