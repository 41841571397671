import { SharedModule } from '@ahc/shared-module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import {
  MatPaginatorModule,
  MatProgressBarModule,
  MatTableModule
} from '@angular/material';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';

import { UiCommonModule } from '../../common/uicommon.module';

import { ProfileFavoriteEquipmentComponent } from './profile-favorite-equipment/profile-favorite-equipment.component';
import { ProfileHomeComponent } from './profile-home/profile-home.component';
import { ProfileInformationComponent } from './profile-information/profile-information.component';
import { ProfileUnitSystemPreferenceComponent } from './profile-unit-system-preference/profile-unit-system-preference.component';

@NgModule({
  declarations: [
    ProfileHomeComponent,
    ProfileFavoriteEquipmentComponent,
    ProfileInformationComponent,
    ProfileUnitSystemPreferenceComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    FlexLayoutModule,
    CommonModule,
    SharedModule,
    TranslateModule,
    RouterModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    UiCommonModule,
    MatTableModule,
    MatPaginatorModule,
    NgxPermissionsModule,
    MatProgressBarModule
  ],
  exports: [ProfileHomeComponent, ProfileInformationComponent]
})
export class ProfileModule {}
