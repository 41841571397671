import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Spread } from '@grapecity/spread-sheets';
import { TranslateService } from '@ngx-translate/core';
import DisplayZone from 'libs/shared-module/src/lib/models/common/enums/display-zone.enum';
import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { ParameterModel } from 'libs/shared-module/src/lib/models/common/parameter.model';
import { ScopeItemModel } from 'libs/shared-module/src/lib/models/common/scope-item.model';
import { SearchEquipmenFilterEnum } from 'libs/shared-module/src/lib/models/common/search-equipment-filter.enum';
import { SearchResultModel } from 'libs/shared-module/src/lib/models/common/search-result.model';
import { EquipmentModel } from 'libs/shared-module/src/lib/models/equipment/equipement.model';
import { CommentModel } from 'libs/shared-module/src/lib/models/feedback/feedback.model';
import { DisciplineTypeOfAnalysis } from 'libs/shared-module/src/lib/models/import/disciplineTypeOfAnalysis.model';
import { ImportMainFilter } from 'libs/shared-module/src/lib/models/import/main-filter.model';
import { ParameterImportValueModel } from 'libs/shared-module/src/lib/models/import/parameter-import-value.model';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { ImportParametersValueService } from 'libs/shared-module/src/lib/providers/import-parameters-value.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SearchEquipmentService } from 'libs/shared-module/src/lib/providers/search-equipment.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { ThresholdService } from 'libs/shared-module/src/lib/providers/threshold.provider';
import { UserViewModel } from 'libs/shared-module/src/lib/viewmodels/user.viewmodel';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

import { environment } from '../../../../../../apps/ahc/ahc-front/src/environments/environment';

import {
  CellModel,
  CellStatus,
  CoordinateModel,
  EquipmentColumnModel,
  ImportTableModel,
  ParameterRow
} from './models';

@Component({
  selector: 'ahc-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  tabs = Tabs;
  sheetName = 'import sheet';
  columnWidth = 200;
  isLoading = false;

  plants: ScopeItemModel[] = [];
  filteredPlants: Observable<ScopeItemModel[]> = new Observable();

  mainFilter: ImportMainFilter = {
    discipline: new DisciplineTypeOfAnalysis(
      ExpertiseDomain.ELECTRICAL,
      Tabs.OIL_ANALYSIS,
      this.translate
    ),
    plantId: ''
  };
  errorCells: CoordinateModel[] = [];
  dataSource: ImportTableModel = new ImportTableModel();

  disciplines: DisciplineTypeOfAnalysis[] = [
    new DisciplineTypeOfAnalysis(
      ExpertiseDomain.ELECTRICAL,
      Tabs.OIL_ANALYSIS,
      this.translate
    ),
    new DisciplineTypeOfAnalysis(
      ExpertiseDomain.ELECTRICAL,
      Tabs.VIBRATION,
      this.translate
    ),
    new DisciplineTypeOfAnalysis(
      ExpertiseDomain.MECHANICAL,
      Tabs.OIL_ANALYSIS,
      this.translate
    ),
    new DisciplineTypeOfAnalysis(
      ExpertiseDomain.MECHANICAL,
      Tabs.VIBRATION,
      this.translate
    ),
    new DisciplineTypeOfAnalysis(
      ExpertiseDomain.WATER,
      Tabs.WATER,
      this.translate
    ),
    new DisciplineTypeOfAnalysis(
      ExpertiseDomain.WATER,
      Tabs.SERVICE_REPORT,
      this.translate
    )
  ];

  filterForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private scopeProvider: ScopeParameterService,
    private importService: ImportParametersValueService,
    private equipmentSearch: SearchEquipmentService,
    private translate: TranslateService,
    private thresholdService: ThresholdService,
    private snackbarService: SnackbarService,
    connectedUserService: ConnectedUserService
  ) {
    if (window.location.hostname !== 'localhost') {
      Spread.Sheets.LicenseKey = environment.spreadJsLicense;
    }
    connectedUserService.onUser().subscribe(u => (this.connectedUser = u));
  }
  private connectedUser: UserViewModel;
  private backColor = 'lightgrey';
  private errorBackColor = '#F54B4B';
  private warningBackColor = '#FFB200';

  private spread: Spread.Sheets.Workbook;
  private sheet: Spread.Sheets.Worksheet;

  private row_assetId = 2;
  private row_date = 3;

  ngOnInit() {
    this.filterForm = this.formBuilder.group({
      plant: new FormControl('', Validators.required),
      discipline: new FormControl(this.disciplines[0], Validators.required)
    });

    // init for autocomplete filter values
    this.filteredPlants = this.filterForm.controls['plant'].valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.plants.slice()))
    );

    this.filterForm.valueChanges.subscribe(values => {
      this.mainFilter.plantId = values.plant.id;
      this.mainFilter.discipline = values.discipline;
    });

    this.scopeProvider.loadPlants().subscribe(data => {
      this.plants = data;
      this.mainFilter.plantId =
        this.plants.length > 0 ? this.plants[0].id : null;
    });

    const sheetCulture = new Spread.Common.CultureInfo();
    // set default date format to use
    sheetCulture.DateTimeFormat.shortDatePattern = 'dd/MM/yyyy';
    Spread.Common.CultureManager.addCultureInfo('ahc-culture', sheetCulture);
    // update culture
    Spread.Common.CultureManager.culture('ahc-culture');

    this.spread = new Spread.Sheets.Workbook(
      document.getElementById('importTable')
    );

    this.spread.options.newTabVisible = false;
    this.spread.options.showVerticalScrollbar = true;
    this.spread.options.showHorizontalScrollbar = true;
    this.spread.options.highlightInvalidData = true;
    this.spread.options.allowContextMenu = false;

    this.sheet = this.spread.getActiveSheet();

    // lock header row and parameters column
    this.sheet.bind(Spread.Sheets.Events.EditStarting, function(s, args) {
      if (
        args.col === 0 ||
        args.col === 1 ||
        args.row === 0 ||
        args.row === 1
      ) {
        args.cancel = true;
      }
    });

    this.sheet.bind(Spread.Sheets.Events.ClipboardPasting, function(s, args) {
      if (args.cellRange.col < this.row_assetId) {
        args.cancel = true;
      }
    });

    this.sheet.options.allowCellOverflow = false;

    this.dataSource.equipments = [];
    this.cellUpdated();
  }

  initFromFilter() {
    this.isLoading = true;
    this.importService
      .getParameters(this.mainFilter)
      .subscribe(async (parameters: ParameterModel[]) => {
        if (parameters) {
          this.dataSource.equipments = [];
          this.dataSource.parameters = parameters
            .sort((a, b) =>
              !a.displayPriority
                ? 1
                : a.displayPriority > b.displayPriority
                ? 1
                : -1
            )
            .map((param, index) => {
              const row: ParameterRow = {
                row: index,
                parameter: param
              };
              return row;
            });
        }

        const thresholds = await this.loadThresholds();
        thresholds.items.forEach(threshold => {
          const param = this.dataSource.parameters.find(
            parameter => parameter.parameter.id === threshold.parameterPid
          );
          if (param) {
            param.parameter.threshold = threshold;
          }
        });

        this.build();
        this.pushEquipment(2);
        this.isLoading = false;
      });
  }

  loadThresholds() {
    return this.thresholdService
      .batchThreshold({
        parameterListPid: this.dataSource.parameters.map(p => p.parameter.id)
      })
      .toPromise();
  }

  build() {
    this.spread.suspendPaint();
    let currentRow = 0;
    this.sheet.autoGenerateColumns = true;
    this.sheet.deleteColumns(0, this.sheet.getColumnCount());
    // Parameters column
    this.sheet.addColumns(0, 1);
    // units column
    this.sheet.addColumns(1, 1);

    this.sheet
      .getCell(currentRow, 0)
      .text(
        this.translate.instant(
          'dashboard.import.labels.parameters_characteristic'
        )
      );
    this.sheet.getCell(currentRow, 0).backColor(this.backColor);

    currentRow++;
    this.sheet
      .getCell(currentRow, 0)
      .text(this.translate.instant('dashboard.import.labels.process_tag'));
    this.sheet.getCell(currentRow, 0).backColor(this.backColor);
    // this.sheet.getCell(currentRow, 2).backColor(this.backColor);

    currentRow++;
    this.sheet
      .getCell(currentRow, 0)
      .text(this.translate.instant('dashboard.import.labels.asset_number'));
    this.sheet.getCell(currentRow, 0).backColor(this.backColor);

    currentRow++;
    this.sheet
      .getCell(currentRow, 0)
      .text(this.translate.instant('dashboard.import.labels.date'));
    this.sheet.getCell(currentRow, 1).text('DD/MM/YY');
    this.sheet.getCell(currentRow, 0).backColor(this.backColor);
    currentRow++;

    // units column
    this.sheet
      .getCell(0, 1)
      .text(this.translate.instant('dashboard.import.labels.units'));
    this.sheet.getCell(0, 1).backColor(this.backColor);
    this.sheet.getCell(1, 1).backColor(this.backColor);
    this.sheet.getCell(2, 1).backColor(this.backColor);
    this.sheet.getCell(3, 1).backColor(this.backColor);
    this.sheet.getCell(this.row_date, 1).backColor(this.backColor);

    this.dataSource.parameters.forEach((row, index) => {
      this.sheet.getCell(currentRow + index, 0).text(row.parameter.name);
      this.sheet.comments.add(
        currentRow + index,
        0,
        ` ${this.translate.instant('dashboard.admin.alarm.veryHigh')}: ${
          row.parameter.threshold ? row.parameter.threshold.veryHigh : '-'
        }\n ${this.translate.instant('dashboard.admin.alarm.veryLow')}: ${
          row.parameter.threshold ? row.parameter.threshold.veryLow : '-'
        }`
      );
      this.sheet.getCell(currentRow + index, 0).backColor(this.backColor);
      this.sheet.getCell(currentRow + index, 1).text(row.parameter.unit);
      this.sheet.getCell(currentRow + index, 1).backColor(this.backColor);
      row.row = currentRow + index;
      this.sheet.autoFitRow(currentRow + index);
    });
    this.sheet.autoFitColumn(0);
    this.sheet.autoFitColumn(1);
    this.buildSheetActions();
    this.sheet.resumePaint();
  }

  import() {
    this.isLoading = true;
    let hasError = false;
    this.dataSource.equipments.forEach((eq: EquipmentColumnModel) => {
      // clear existing data
      eq.values = [];

      if (!eq.assetId) {
        hasError = true;
        this.pushError(this.row_assetId, eq.col);
      }
      if (!eq.date) {
        hasError = true;
        this.pushError(this.row_date, eq.col);
      }

      this.dataSource.parameters.forEach(param => {
        if (param.parameter.unit && this.sheet.getValue(param.row, eq.col)) {
          const unit = param.parameter.unit.toLocaleLowerCase();
          hasError = !this.parameterDateIsValid(unit, param, eq);
          if (hasError) {
            this.pushError(param.row, eq.col);
          }
        }
      });

      if (!hasError) {
        this.dataSource.parameters.forEach(param => {
          const value = this.sheet.getValue(param.row, eq.col);
          if (value === null || value === undefined) {
            return;
          }
          eq.values.push({
            coord: {
              col: eq.col,
              row: param.row
            },
            status: CellStatus.valid,
            value: value
          });
        });
      }
    });
    if (!hasError) {
      this.doImport();
    } else {
      this.isLoading = false;
    }
  }

  displayFn(p?: ScopeItemModel): string | undefined {
    return p ? p.name : undefined;
  }

  private doImport() {
    const data: ParameterImportValueModel[] = [];
    this.dataSource.equipments.forEach((eq: EquipmentColumnModel) => {
      data.push(
        ...eq.values.map((val: CellModel) => {
          const values: Map<string, string> = new Map();
          const parameter = this.dataSource.parameters.find(
            paramRow => paramRow.row === val.coord.row
          ).parameter;
          if (
            parameter.displayZone === DisplayZone.COMMENT_BOX &&
            parameter.name.toLocaleLowerCase().indexOf('comments') > -1
          ) {
            const comment: CommentModel = {
              author:
                this.connectedUser.firstname +
                ' ' +
                this.connectedUser.lastname,
              comment: val.value
            };
            values[eq.date.format('YYYY-MM-DD')] = JSON.stringify(comment);
          } else {
            values[eq.date.format('YYYY-MM-DD')] = val.value;
          }

          const dataToInsert: ParameterImportValueModel = {
            equipmentId: eq.id,
            parameter: parameter,
            values: values
          };
          return dataToInsert;
        })
      );
    });
    this.importService
      .import(data)
      .pipe(
        catchError(err => {
          this.snackbarService.open(
            this.translate.instant('dashboard.import.fail_import'),
            null
          );
          return of(false);
        })
      )
      .subscribe(done => {
        this.isLoading = false;
        if (done) {
          this.snackbarService.open(
            this.translate.instant('dashboard.import.success_import'),
            null
          );
        }
      });
  }

  private cellUpdated() {
    // if we delete the content of a cell
    this.spread.bind(Spread.Sheets.Events.LeaveCell, (e, args) => {
      const value = this.sheet.getValue(args.row, args.col);
      if (!value && args.row > this.row_date && args.col > 1) {
        this.popError(args.row, args.col);
      }
    });

    this.spread.bind(Spread.Sheets.Events.ClipboardPasted, (e, args) => {
      // row of asset ID and only one cell
      if (
        args.cellRange.row === this.row_assetId &&
        args.cellRange.col > 1 &&
        args.cellRange.colCount === 1 &&
        args.cellRange.rowCount === 1
      ) {
        this.addEquipment(args.pasteData.text, args.cellRange.col);
      }
      const rowsData: string[] = args.pasteData.text.split('\n');
      rowsData.forEach((rowData, rowIndex) => {
        const cellsData = rowData.split('\t');
        cellsData.forEach((value, colIndex) => {
          this.checkCellValue(
            args.cellRange.row + rowIndex,
            args.cellRange.col + colIndex,
            value
          );
        });
      });
    });

    this.spread.bind(Spread.Sheets.Events.ValueChanged, (e, args) => {
      const row = args.row;
      const col = args.col;
      const value = args.newValue;
      this.checkCellValue(row, col, value);
    });
  }

  private checkCellValue(row, col, value) {
    const equipment = this.dataSource.equipments.find(eq => eq.col === col);
    if (!equipment) {
      return;
    }

    // asset ID updated
    if (row === this.row_assetId && col > 1) {
      this.popError(this.row_assetId, col);
      this.addEquipment(value, col);
      return;
    }
    // date cell
    if (row === this.row_date) {
      this.popError(this.row_date, col);
      const date = moment(this.sheet.getValue(this.row_date, col));

      if (date.isValid() && date.year() > 1970 && date.year() < 2100) {
        equipment.date = date;
        this.popError(row, col);
      } else {
        this.pushError(row, col);
      }
      return;
    }

    if (row > this.row_date) {
      const parameterRow = this.dataSource.parameters.find(
        item => item.row === row
      );

      if (
        parameterRow.parameter.displayZone === DisplayZone.NON_TRENDABLE ||
        parameterRow.parameter.displayZone === DisplayZone.COMMENT_BOX
      ) {
        if (
          parameterRow.parameter.name.toLocaleLowerCase().indexOf('health') >
            -1 ||
          parameterRow.parameter.name.toLocaleLowerCase().indexOf('indicator') >
            -1
        ) {
          const indicator = parseFloat(value);
          if (!indicator || indicator < 1 || indicator > 5) {
            this.pushError(row, col);
          } else {
            this.popError(row, col);
          }
        }
        if (value) {
          if (
            this.parameterDateIsValid(
              parameterRow.parameter.unit,
              parameterRow,
              equipment
            )
          ) {
            this.popError(row, col);
          } else {
            this.pushError(row, col);
          }
        }
        return;
      }

      if (isNaN(Number(value))) {
        this.pushError(row, col);
      } else {
        const numValue = parseFloat(value);
        if (parameterRow) {
          if (parameterRow.parameter.threshold) {
            const ll = parseFloat(parameterRow.parameter.threshold.veryLow);
            const hh = parseFloat(parameterRow.parameter.threshold.veryHigh);
            if (!isNaN(ll) && numValue <= ll) {
              this.pushWarning(row, col);
            } else if (!isNaN(hh) && numValue >= hh) {
              this.pushWarning(row, col);
            } else {
              this.popError(row, col);
            }
          } else {
          }
        }
      }
      return;
    }
  }

  private addEquipment(value: any, col: any) {
    this.isLoading = true;
    const equipment = this.dataSource.equipments.find(eq => eq.col === col);
    if (equipment) {
      this.searchEquipmentByAssetID(value).subscribe((eq: EquipmentModel) => {
        if (eq) {
          this.popError(this.row_assetId, col);
          equipment.assetId = value;
          equipment.id = eq.id;
          equipment.processTag = eq.alProcessTag;
          this.sheet.setValue(1, col, equipment.processTag);
        } else {
          this.pushError(this.row_assetId, col);
        }
        this.isLoading = false;
      });
    }
  }

  private searchEquipmentByAssetID(value: string): Observable<EquipmentModel> {
    return this.equipmentSearch
      .doRequest(
        Object.assign({
          assetId: value,
          plantId: this.mainFilter.plantId,
          page: 1,
          pageSize: 1,
          filter: SearchEquipmenFilterEnum.ALL
        })
      )
      .pipe(
        map((result: SearchResultModel<EquipmentModel[]>) => {
          const equipment = _.first(result.items);
          if (
            !equipment ||
            equipment.expertiseDomain !==
              this.filterForm.controls['discipline'].value.discipline
          ) {
            return null;
          }
          return equipment;
        })
      );
  }

  private buildSheetActions() {
    this.sheet.addColumns(this.sheet.getColumnCount() + 1, 1);

    const addEquipmentAction = new Spread.Sheets.CellTypes.Button();
    addEquipmentAction.text(
      `  ${this.translate.instant('dashboard.import.add_equipment')}  `
    );

    this.sheet
      .getCell(0, this.sheet.getColumnCount() - 1)
      .cellType(addEquipmentAction);
    addEquipmentAction.marginTop(10);
    addEquipmentAction.marginBottom(10);

    const removeEquipmentAction = new Spread.Sheets.CellTypes.Button();
    removeEquipmentAction.text(
      `  ${this.translate.instant('dashboard.import.remove_equipment')}  `
    );
    this.sheet
      .getCell(1, this.sheet.getColumnCount() - 1)
      .cellType(removeEquipmentAction);
    removeEquipmentAction.marginTop(10);
    removeEquipmentAction.marginBottom(10);

    this.sheet.autoFitColumn(this.sheet.getColumnCount() - 1);

    this.sheet.autoFitRow(0);
    this.sheet.autoFitRow(1);
    this.spread.bind(Spread.Sheets.Events.ButtonClicked, (e, args) => {
      const sheet = args.sheet,
        row = args.row,
        col = args.col;
      const cellType = sheet.getCellType(row, col);
      if (cellType instanceof Spread.Sheets.CellTypes.Button) {
        if (col === sheet.getColumnCount() - 1) {
          if (row === 0 && this.mainFilter.plantId) {
            this.pushEquipment(col);
          }
          if (row === 1) {
            this.popEquipment(col - 1);
          }
        }
      }
    });
  }
  private popEquipment(col: number) {
    if (this.dataSource.equipments.length > 0) {
      this.popError(this.row_assetId, col);
      // clear errors for this equipment
      this.dataSource.parameters.map(param => {
        this.popError(param.row, col);
      });
      this.sheet.deleteColumns(col, 1);
    }
    this.dataSource.equipments.pop();
  }

  private pushEquipment(col: any) {
    this.dataSource.equipments.push({
      id: null,
      assetId: null,
      col: col,
      date: null,
      processTag: '',
      siteId: this.plants.find(p => p.id === this.mainFilter.plantId).id,
      values: []
    });
    this.sheet.addColumns(col, 1);
    this.sheet.setValue(
      0,
      col,
      `${this.translate.instant('dashboard.import.sample')} ${col - 1}`
    );
    this.sheet.getCell(0, col).backColor(this.backColor);
    this.sheet
      .getCell(this.row_date, col)
      .formatter(new Spread.Formatter.GeneralFormatter('dd MMM YYYY', 'fr-fr'));

    this.sheet.getCell(1, col).backColor(this.backColor);
    this.sheet.setColumnWidth(col, 160);
  }

  private popError(row: any, col: any) {
    this.errorCells = this.errorCells.filter(
      cell => cell.col !== col || cell.row !== row
    );
    this.sheet.getCell(row, col).backColor('');
  }

  private pushError(row: number, col: number) {
    this.sheet.getCell(row, col).backColor(this.errorBackColor);
    this.errorCells.push({ col: col, row: row });
  }

  private pushWarning(row: number, col: number) {
    this.sheet.getCell(row, col).backColor(this.warningBackColor);
  }

  private _filter(name: string): ScopeItemModel[] {
    const filterValue = name.toLowerCase();

    return this.plants.filter(
      option => option.name.toLowerCase().indexOf(filterValue) > -1
    );
  }

  private parameterDateIsValid(
    unit: string,
    param: ParameterRow,
    eq: EquipmentColumnModel
  ) {
    const dateFormatFound = this.parameterIsDateFormat(unit);
    if (dateFormatFound) {
      try {
        return !isNaN(Date.parse(this.sheet.getValue(param.row, eq.col)));
      } catch (error) {
        return false;
      }
    }
    return true;
  }

  private parameterIsDateFormat(unit: string) {
    let dateFormatFound = false;
    ['jj/', 'dd/', 'mm/', '/yy', '/aa'].forEach(item =>
      unit.toLocaleLowerCase().indexOf(item) > -1
        ? (dateFormatFound = true)
        : (dateFormatFound = dateFormatFound || false)
    );
    return dateFormatFound;
  }
}
