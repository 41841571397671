import { Component, OnInit } from '@angular/core';
import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';

@Component({
  selector: 'ahc-profile-home',
  templateUrl: './profile-home.component.html',
  styleUrls: ['./profile-home.component.scss']
})
export class ProfileHomeComponent implements OnInit {
  permissions = Permissions;
  constructor() { }

  ngOnInit() {
  }

}
