import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import DisplayZone from 'libs/shared-module/src/lib/models/common/enums/display-zone.enum';
import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { ParameterModel } from 'libs/shared-module/src/lib/models/common/parameter.model';
import { SearchResultModel } from 'libs/shared-module/src/lib/models/common/search-result.model';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ParameterTableDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/parameter-table-data-source.provider';
import { ParameterService } from 'libs/shared-module/src/lib/providers/parameter.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-insert-parameter-value',
  templateUrl: './insert-parameter-value.component.html',
  styleUrls: ['./insert-parameter-value.component.scss'],
  providers: [ParameterService]
})
export class InsertParameterValueComponent implements OnInit {
  @Input()
  expertiseDomain: ExpertiseDomain;
  @Input()
  tab: Tabs;
  parameters: ParameterModel[] = [];
  filteredParameters: Observable<ParameterModel[]>;
  parameterForm: FormGroup;
  parametersCtrl = new FormControl('', Validators.required);
  parameterValueCtrl = new FormControl('', Validators.required);
  parameterDateCtrl = new FormControl(moment().toNow(), Validators.required);

  @ViewChild(MatExpansionPanel, { static: false }) formPanel: MatExpansionPanel;

  constructor(
    private parameterService: ParameterService,
    private cockpitService: CockpitService,
    private dataSource: ParameterTableDataSourceService
  ) {
    this.cockpitService
      .onEquipment()
      .subscribe((eq: EquipmentViewModel) => (this.equipment = eq));
    this.parameterForm = new FormGroup({
      parameter: this.parametersCtrl,
      value: this.parameterValueCtrl,
      date: this.parameterDateCtrl
    });
  }

  private equipment: EquipmentViewModel;

  ngOnInit() {
    this.parameterService
      .getParameters(
        this.expertiseDomain,
        1,
        1000,
        null,
        new Map([['display_zone', DisplayZone.TREND_BOX]])
      )
      .subscribe((params: SearchResultModel<ParameterModel[]>) => {
        this.parameters = params.items.filter(
          (p: ParameterModel) => p.tab === this.tab
        );
      });

    this.filteredParameters = this.parametersCtrl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(name => (name ? this._filter(name) : this.parameters.slice()))
    );
  }

  displayFn(parameter?: ParameterModel): string | undefined {
    return parameter ? parameter.name : undefined;
  }

  insertData() {
    if (!this.parameterForm.valid) {
      return;
    }
    const values = new Map<Date, number>();
    values[
      moment(this.parameterDateCtrl.value).format('YYYY-MM-DD')
    ] = parseFloat(this.parameterValueCtrl.value);

    this.parameterService
      .addParameterData(this.parametersCtrl.value, this.equipment.pid, values)
      .subscribe(done => {
        this.formPanel.close();
        this.dataSource.loadData();
      });
  }

  private _filter(name: string): ParameterModel[] {
    const filterValue = name.toLowerCase();

    return this.parameters.filter(
      option => option.name.toLowerCase().indexOf(filterValue) === 0
    );
  }
}
