import { CollectionViewer } from '@angular/cdk/collections';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { CatchesModel } from '../../models/cockpit/catches.model';
import { IRondeModel } from '../../models/cockpit/ironde.model';
import { LinkModel } from '../../models/cockpit/link.model';
import { LireModel } from '../../models/cockpit/lire.model';
import { MaximoModel } from '../../models/cockpit/maximo.model';
import { ParameterTableModel } from '../../models/cockpit/parameter-table.model';
import { ReportModel } from '../../models/cockpit/report.model';
import { ScadaModel } from '../../models/cockpit/scada.model';
import { TimelineModel } from '../../models/cockpit/timeline.model';
import SortEnum from '../../models/common/enums/sort.enum';
import { ParameterModel } from '../../models/common/parameter.model';
import { TableDataSourceResponseModel } from '../../models/common/table-data-source-response.model';
import { UnitSystemModel } from '../../models/profile/unit-system.model';

@Injectable()
export abstract class TableDataSource<
  T extends
    | TimelineModel
    | MaximoModel
    | IRondeModel
    | CatchesModel
    | LireModel
    | ParameterTableModel
    | ParameterModel
    | UnitSystemModel
    | ScadaModel
    | ReportModel
    | LinkModel
> implements OnDestroy {
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public data$: BehaviorSubject<TableDataSourceResponseModel<T>>;
  public pageSize = 5;

  constructor() {
    const emptyResult: TableDataSourceResponseModel<T> = {
      items: [],
      totalSize: 0
    };
    this.data$ = new BehaviorSubject(emptyResult);
  }

  private ngUnsubscribe: Subject<void> = new Subject();

  protected sort: Map<string, SortEnum> = new Map();
  protected filter: Map<string, string> = new Map();
  protected currentPage = 1;
  protected equipmentId = '';

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return of();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.data$.complete();
    this.loading$.complete();
  }

  init(
    pageSize = 5,
    equipmentId?: string,
    sort?: Map<string, SortEnum>,
    filter?: Map<string, string>
  ) {
    if (equipmentId === null || equipmentId === undefined) {
      return;
    }
    this.equipmentId = equipmentId;
    this.pageSize = pageSize;
    if (filter) {
      this.filter = filter;
    }
    if (sort) {
      this.sort = sort;
    }

    this.loadData();
  }

  page(index: number, size: number) {
    this.currentPage = index;
    this.pageSize = size;
    this.loadData();
  }

  addSort(column: string, way: SortEnum) {
    this.sort.set(column, way);

    this.loadData();
  }

  removeSort(column: string) {
    this.sort.delete(column);
    this.loadData();
  }

  addFilter(column: string, query: string) {
    this.filter.set(column, query);
    this.loadData();
  }

  removeFilter(column: string) {
    this.filter.delete(column);
    this.loadData();
  }

  abstract getData(
    sort: Map<string, SortEnum>,
    filter: Map<string, string>,
    query: string,
    page: number,
    ...args: string[]
  ): Observable<TableDataSourceResponseModel<T>>;

  loadData() {
    this.loading$.next(true);
    const query: Observable<TableDataSourceResponseModel<T>> = this.getData(
      this.sort,
      this.filter,
      '',
      this.currentPage
    );
    query
      .pipe(
        takeUntil(this.ngUnsubscribe),
        catchError(err => {
          this.loading$.next(false);
          return of(null);
        })
      )
      .subscribe((result: TableDataSourceResponseModel<T>) => {
        if (result) {
          this.data$.next({
            items: result.items,
            totalSize: result.totalSize
          });
        }
        this.loading$.next(false);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
