import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import SortEnum from '../../models/common/enums/sort.enum';
import { TableDataSourceResponseModel } from '../../models/common/table-data-source-response.model';
import { UnitSystemModel } from '../../models/profile/unit-system.model';
import { UnitSystemDataService } from '../dataServices/unit-system-data-service.provider';

import { TableDataSource } from './table-data-source.provider';

@Injectable()
export class UnitSystemDataSourceService extends TableDataSource<
  UnitSystemModel
> {
  constructor(private unitDataService: UnitSystemDataService) {
    super();
  }

  getData(
    sort: Map<string, SortEnum>,
    filter: Map<string, string>,
    query: string,
    page: number
  ): Observable<TableDataSourceResponseModel<UnitSystemModel>> {
    return this.unitDataService.getUnits();
  }
}
