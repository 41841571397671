<div
  fxLayout="column"
  fxLayoutAlign="start"
  class="water"
  fxFlex
  fxLayoutGap="10"
>
  <div *ngIf="(dataSource.loading$ | async)" fxFlexAlign="stretch">
    <mat-progress-bar
      class="loading"
      class="loading"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>
  <ahc-feedback class="feedback" [tab]="'kpis'"></ahc-feedback>
  <ng-container *ngIf="equipment && equipment.pid">
    <ahc-parameters-table
      [pageSize]="1000"
      [dataSource]="dataSource"
      [equipment]="equipment"
      [graphService]="graphService"
      fxFlexAlign="stretch"
    ></ahc-parameters-table>
    <ahc-insert-parameter-value
      [expertiseDomain]="equipment.class.expertiseDomain"
      [tab]="tab"
      fxFlexAlign="stretch"
    ></ahc-insert-parameter-value>
  </ng-container>
</div>
