import { ErrorHandler, Injectable } from '@angular/core';

import { Logger } from './Logger.provider';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private logger: Logger) {}

  handleError(error) {
    this.logger.error(
      `Exception: ${error.message ? error.message : error.toString()}`,
      error,
      error.stack
    );

    throw error;
  }
}
