import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { AuthService } from 'libs/shared-module/src/lib/providers/auth.provider';

import { Router } from '@angular/router';
import { Logger } from 'libs/shared-module/src/lib/providers/Logger.provider';

export const passwordConfirmationValidator: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  return password && confirmPassword && password.value !== confirmPassword.value
    ? { notEqual: true }
    : null;
};

@Component({
  selector: 'ahc-login-set-first-new-password',
  templateUrl: './login-set-first-new-password.component.html',
  styleUrls: ['./login-set-first-new-password.component.scss']
})
export class LoginSetFirstNewPasswordComponent implements OnInit {
  setFG: FormGroup;
  user: CognitoUser;
  constructor(
    private logger: Logger,
    fb: FormBuilder,
    private auth: AuthService,
    private router: Router
  ) {
    this.setFG = fb.group(
      {
        password: new FormControl('', [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
          )
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          Validators.pattern(
            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}'
          )
        ])
      },
      {
        validators: passwordConfirmationValidator
      }
    );
  }

  ngOnInit() {
    this.auth.onUser().subscribe(userAws => (this.user = userAws));
  }
  get password() {
    return this.setFG.get('password');
  }
  isFormValid(): boolean {
    return (
      !this.setFG.errors &&
      this.setFG.controls['password'].valid &&
      this.setFG.controls['password'].dirty &&
      this.setFG.controls['confirmPassword'].valid &&
      this.setFG.controls['confirmPassword'].dirty
    );
  }

  setPassword() {
    if (this.isFormValid()) {
      const userAttributes = null;

      Auth.completeNewPassword(this.user, this.password.value, userAttributes)
        .then(() => {
          this.router.navigateByUrl('/login');
        })
        .catch(err => {
          this.logger.error(
            'Auto complete new password for user failed',
            this.user,
            err
          );
        });
    }
  }
}
