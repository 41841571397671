import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import LevelEnum from 'libs/shared-module/src/lib/models/common/level.enum';
import { ScopeItemModel } from 'libs/shared-module/src/lib/models/common/scope-item.model';
import { NewUserBaseModel } from 'libs/shared-module/src/lib/models/user/new-user-base.model';
import { ClusterDataService } from 'libs/shared-module/src/lib/providers/dataServices/cluster-data-service.provider';
import { CountryDataService } from 'libs/shared-module/src/lib/providers/dataServices/country-data-service.provider';
import { PlantDataService } from 'libs/shared-module/src/lib/providers/dataServices/plant-data-service.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
import { UserViewModel } from 'libs/shared-module/src/lib/viewmodels/user.viewmodel';

import { AutoCompleteValueItem } from '../../../common/auto-complete/auto-complete-value-item.model';

interface Scope {
  clusters: ScopeItemModel[];
  countries: ScopeItemModel[];
  plants: ScopeItemModel[];
  expertiseDomains: ScopeItemModel[];
}

@Component({
  selector: 'ahc-user-information-panel',
  templateUrl: './user-information-panel.component.html',
  styleUrls: ['./user-information-panel.component.scss']
})
export class UserInformationPanelComponent implements OnInit {
  @Output() togglePanelEvent: EventEmitter<any> = new EventEmitter();
  @Input() user: UserViewModel;
  loading = false;

  permissions = Permissions;

  initialScope: Scope = {
    clusters: [],
    countries: [],
    expertiseDomains: [],
    plants: []
  };

  userScope: Scope = {
    clusters: [],
    countries: [],
    expertiseDomains: [],
    plants: []
  };

  userFavorites: Scope = {
    clusters: [],
    countries: [],
    expertiseDomains: [],
    plants: []
  };

  constructor(
    private scopeParameterService: ScopeParameterService,
    private clusterDataService: ClusterDataService,
    private countryDataService: CountryDataService,
    private plantDataService: PlantDataService,
    private userService: UsersService,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.getData();
    if (this.user.scopes) {
      this.getSelectedClusters();
      this.getSelectedCountries();
      this.getSelectedPlants();
      this.getSelectedExpertiseDomains();
    }
  }
  getData() {
    const clustersSource = this.scopeParameterService.loadClusters();
    const countriesSource = this.scopeParameterService.loadCountries();
    const plantsSource = this.scopeParameterService.loadPlants();
    const expertiseDomainsSource = this.scopeParameterService.loadExpertiseDomains();

    forkJoin([
      clustersSource,
      countriesSource,
      plantsSource,
      expertiseDomainsSource
    ]).subscribe(results => {
      this.initialScope.clusters = results[0];
      this.initialScope.countries = results[1];
      this.initialScope.plants = results[2];
      this.initialScope.expertiseDomains = results[3];
      this.userFavorites.clusters = this.user.favorite.clusters
        ? this.initialScope.clusters.filter(
            c => this.user.favorite.clusters.indexOf(c.id) > -1
          )
        : [];
      this.userFavorites.countries = this.user.favorite.countries
        ? this.initialScope.countries.filter(
            c => this.user.favorite.countries.indexOf(c.id) > -1
          )
        : [];
      this.userFavorites.expertiseDomains = this.user.favorite.expertiseDomains
        ? this.initialScope.expertiseDomains.filter(
            c =>
              this.user.favorite.expertiseDomains.indexOf(
                c.id as ExpertiseDomain
              ) > -1
          )
        : [];
      this.userFavorites.plants = this.user.favorite.plants
        ? this.initialScope.plants.filter(
            c => this.user.favorite.plants.indexOf(c.id) > -1
          )
        : [];
    });
  }
  setClusters(values: AutoCompleteValueItem[]) {
    this.user.scopes.clusters = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setCountries(values: AutoCompleteValueItem[]) {
    this.user.scopes.countries = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setPlants(values: AutoCompleteValueItem[]) {
    this.user.scopes.plants = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setExpertiseDomain(values: AutoCompleteValueItem[]) {
    this.user.scopes.expertiseDomains = values.map(
      (value: AutoCompleteValueItem) => value.id as ExpertiseDomain
    );
  }

  setFavoriteClusters(values: AutoCompleteValueItem[]) {
    this.user.favorite.clusters = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  getSelectedClusters() {
    if (this.user.scopes.clusters) {
      const clusters = this.user.scopes.clusters;
      clusters.forEach(clusterId => {
        this.clusterDataService.getClusterById(clusterId).subscribe(data => {
          this.userScope.clusters.push(data);
        });
      });
      {
      }
    }
  }

  getSelectedCountries() {
    if (this.user.scopes.countries) {
      const countries = this.user.scopes.countries;
      countries.forEach(countryId => {
        this.countryDataService.getCountryById(countryId).subscribe(data => {
          this.userScope.countries.push(data);
        });
      });
    }
  }
  getSelectedPlants() {
    if (this.user.scopes.plants) {
      const plants = this.user.scopes.plants;
      plants.forEach(plantId => {
        this.plantDataService.getPlantById(plantId).subscribe(data => {
          this.userScope.plants.push(data);
        });
      });
    }
  }

  getSelectedExpertiseDomains() {
    if (this.user.scopes && this.user.scopes.expertiseDomains) {
      this.userScope.expertiseDomains = this.user.scopes.expertiseDomains.map(
        (domain: ExpertiseDomain) => {
          const scopeItem: ScopeItemModel = {
            id: domain,
            name: this.translate.instant(`common.${ domain }`),
            displayName: this.translate.instant(`common.${ domain }`)
          };
          return scopeItem;
        }
      );
    }
  }

  updateUser() {
    this.loading = true;
    let request: Observable<object>;
    if (this.user.isInternal) {
      request = this.userService.updateFavorites(this.user.id, {
        favorites: {
          clusters: this.userFavorites.clusters.map(cluster => cluster.id),
          countries: this.userFavorites.countries.map(item => item.id),
          expertiseDomains: this.userFavorites.expertiseDomains.map(
            item => item.id as ExpertiseDomain
          ),
          plants: this.userFavorites.plants.map(item => item.id),
          productionUnits: []
        }
      });
    } else {
      const updatedUser = new NewUserBaseModel();
      updatedUser.scopes = this.user.scopes;
      request = this.userService.updateScopes(this.user.id, updatedUser);
    }

    request
      .pipe(
        catchError(error => {
          this.handleError(
            this.translate.instant('dashboard.users.update_error')
          );
          return of(null);
        })
      )
      .subscribe(response => {
        if (response) {
          this.snackbarService.open(
            this.translate.instant('dashboard.users.updated'),
            this.translate.instant('common.success')
          );
          this.togglePanelEvent.emit();
        } else {
          this.handleError(
            this.translate.instant('dashboard.users.update_error')
          );
        }
        this.loading = false;
      });
  }

  isUserInternal() {
    return (
      this.user.level === LevelEnum.INTERNAL ||
      this.user.level === LevelEnum.INTERNAL_KEY ||
      this.user.level === LevelEnum.ADMIN
    );
  }
  isUserExternal() {
    return (
      this.user.level === LevelEnum.EXTERNAL ||
      this.user.level === LevelEnum.EXTERNAL_KEY
    );
  }
  handleError(message: string) {
    this.snackbarService.open(message, null);
  }
}
