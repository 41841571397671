import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { ScadaModel } from '../../models/cockpit/scada.model';
import ApiEndpointsEnum from '../../models/common/enums/api_endpoints.enum';
import { ScadaPoint } from '../../models/common/scada-point.model';
import { TableDataSourceResponseModel } from '../../models/common/table-data-source-response.model';

@Injectable()
export class ScadaDataService {
  category: 'home' | 'operating' = 'operating';
  constructor(private http: HttpClient) {}

  getScadaParameterDetail(
    pid: string,
    page: number,
    pageSize: number,
    clusterName: string
  ): Observable<ScadaPoint[]> {

    let path = ApiEndpointsEnum.SCADA_HISTORY.replace('{0}', pid);

    if (clusterName) {
      path += ('&cluster_name=' + clusterName);
    }

    return this.http
      .get<ScadaPoint[]>(path)
      .pipe(
        retry(3),
        map((result: any) => result.items));
  }

  getScadaForEquipment(
    equipmentId: string,
    page: number,
    pageSize: number
  ): Observable<TableDataSourceResponseModel<ScadaModel>> {
    return this.http
      .get<ScadaResponseModel[]>(
        `${ApiEndpointsEnum.SCADA.replace('{0}', equipmentId)}?category=${
          this.category
        }`
      )
      .pipe(
        retry(3),
        catchError(err => {
          return of([]);
        }),
        map((data: ScadaResponseModel[]) => {
          const mappedData: ScadaModel[] = data.map((d: ScadaResponseModel) => {
            const scadaRow: ScadaModel = {
              parameterName: d.parameterName,
              parameterTimeseries:
                d.currentValue !== null || d.currentValue !== undefined
                  ? [
                      {
                        date: isNaN(parseFloat(d.currentValue))
                          ? null
                          : new Date(d.timeStamp),
                        value: parseFloat(d.currentValue),
                        error: isNaN(parseFloat(d.currentValue))
                          ? d.currentValue
                          : null
                      }
                    ]
                  : [],
              pid: d.webId,
              unit: d.unit,
              helper: d.scadaTag
            };
            return scadaRow;
          });
          return {
            items: mappedData,
            totalSize: mappedData.length
          };
        })
      );
  }
}

export interface Error {
  fieldName: string;
  message: string[];
}

export interface ParameterTimeserie {
  timestamp: Date;
  value: any;
  good: boolean;
  errors: Error[];
}

export interface ScadaResponseModel {
  parameterName: string;
  webId: string;
  currentValue: string;
  scadaTag: string;
  timeStamp: string;
  unit: string;
}
