import { AhcFrontUiModule } from '@ahc/ahc-front-ui';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import Auth from '@aws-amplify/auth';
import {
  AmplifyAngularModule,
  AmplifyModules,
  AmplifyService
} from 'aws-amplify-angular';
import { SharedModule } from 'libs/shared-module/src/index';
import { CachingInterceptor } from 'libs/shared-module/src/lib/interceptors/cache.interceptor';
import { AuthService } from 'libs/shared-module/src/lib/providers/auth.provider';
import { CompanyService } from 'libs/shared-module/src/lib/providers/company.provider';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { GlobalErrorHandler } from 'libs/shared-module/src/lib/providers/global-error-handler.provider';
import { Logger } from 'libs/shared-module/src/lib/providers/Logger.provider';
import { AhcPaginatorIntl } from 'libs/shared-module/src/lib/providers/paginator-intl.provider';
import { RequestCacheService } from 'libs/shared-module/src/lib/providers/request-cache.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AppComponent } from './app.component';
import { AppRouterModule } from './app.router.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AhcFrontUiModule,
    BrowserModule,
    HttpClientModule,
    AppRouterModule,
    BrowserAnimationsModule,
    SharedModule,
    AmplifyAngularModule,
    NgxPermissionsModule.forRoot(),
    NgxPermissionsModule.forChild()
  ],
  providers: [
    {
      provide: AmplifyService,
      useFactory: () => {
        return AmplifyModules({
          Auth
        });
      }
    },
    ConnectedUserService,
    AuthService,
    SnackbarService,
    CompanyService,
    RequestCacheService,
    { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: MatPaginatorIntl,
      useClass: AhcPaginatorIntl
    },
    Logger
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
