<div class="container" fxLayout="column" fxLayoutAlign="center stretch">
  <div class="suggestion-list">
    <mat-form-field>
      <input
        matInput
        placeholder="{{ placeHolder }}"
        #valueInput
        [formControl]="valuesCtrl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-form-field>
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="let value of (filteredValues | async)"
        [value]="value.id"
      >
        {{ value.name }}
      </mat-option>
    </mat-autocomplete>
  </div>
  <div class="selected-list">
    <mat-chip-list #chipList>
      <mat-chip
        class="value"
        *ngFor="let value of selectedValues"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(value)"
        fxLayout="row"
        fxLayoutAlign="space-around center"
      >
        <mat-icon class="delete" matChipRemove *ngIf="removable"
          >clear</mat-icon
        >
        {{ value.displayName || value.name }}
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
