import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { GraphParameterModel } from '../models/common/graph-parameter.model';
import { ParameterUnitModel } from '../models/common/parameter-unit.model';
import { ParameterModel } from '../models/common/parameter.model';
import { SearchResultModel } from '../models/common/search-result.model';
import { EquipmentAssetsSpecs } from '../models/equipment';
import { EquipmentViewModel } from '../viewmodels/equipment.viewmodel';

import { Logger } from './Logger.provider';
import { ParameterService } from './parameter.provider';

@Injectable()
export class EquipmentService {
  constructor(
    private http: HttpClient,
    private logger: Logger,
    private parameterService: ParameterService
  ) {}

  toggleMain(equipment: EquipmentViewModel): Observable<boolean> {
    this.logger.info(
      'toggle equipment main status, original: ',
      equipment.pid,
      equipment.main
    );
    this.http
      .put(
        `${ApiEndpointsEnum.EQUIPMENTS}/${equipment.pid}/${
          equipment.main ? 'unmain' : 'main'
        }`,
        {}
      )
      .subscribe(done => {
        equipment.main = !equipment.main;
      });
    return of(!equipment.main);
  }

  getShowcase(
    equipment: EquipmentViewModel
  ): Observable<Map<number, GraphParameterModel<number>>> {
    const showcase = new BehaviorSubject<
      Map<number, GraphParameterModel<number>>
    >(new Map());
    this.parameterService
      .getParameterByExpertiseDomain(
        equipment.class.expertiseDomain,
        true,
        1,
        5
      )
      .pipe(map((data: SearchResultModel<ParameterModel[]>) => data.items))
      .subscribe(parameters => {
        _.take(
          parameters.sort((a, b) =>
            a.displayPriority < b.displayPriority ? 1 : -1
          ),
          5
        ).map((parameter: ParameterModel) => {
          forkJoin([
            of(parameter.displayPriority ? parameter.displayPriority : 0),
            this.parameterService.getParameterUnit(parameter.id),
            this.parameterService.getParameterValues(
              equipment.pid,
              parameter.id,
              new Date(2000, 1, 1),
              new Date()
            )
          ]).subscribe(
            (result: [number, ParameterUnitModel, Map<string, string>]) => {
              const values = result[2] as Map<string, string>;
              const mappedValues: number[] = [];
              Object.keys(values)
                .slice(0, 5)
                .forEach(key => {
                  mappedValues.push(parseFloat(values[key].toPrecision(2)));
                });
              const graphData: GraphParameterModel<number> = {
                name: parameter.name,
                unit: result[1].name,
                values: mappedValues
              };
              const order = result[0];
              const showcaseList = showcase.getValue();
              showcaseList.set(order, graphData);
              showcase.next(showcaseList);
            }
          );
        });
      });
    return showcase.asObservable();
  }

  getAssetSpecs(
    equipment: EquipmentViewModel
  ): Observable<SearchResultModel<EquipmentAssetsSpecs[]>> {
    return this.http.get<SearchResultModel<EquipmentAssetsSpecs[]>>(
      `${ApiEndpointsEnum.EQUIPMENTS}/${
        equipment.pid
      }/assetsspecs?page=1&page_size=1000`
    );
  }

  updateLastTAR(equipment: EquipmentViewModel) {
    if (moment(equipment.lastTarDate).isValid()) {
      return this.http.put(
        `${ApiEndpointsEnum.EQUIPMENTS}/${equipment.pid}/tars`,
        {
          lastTarDate: moment(equipment.lastTarDate).format('YYYY-MM-DD')
        }
      );
    }
    return of(null);
  }

  updateNextTarDeferredDate(equipment: EquipmentViewModel) {
    if (moment(equipment.nextTarDeferredDate).isValid()) {
      return this.http.put(
        `${ApiEndpointsEnum.EQUIPMENTS}/${equipment.pid}/tars`,
        {
          nextTarDeferredDate: moment(equipment.nextTarDeferredDate).format(
            'YYYY-MM-DD'
          )
        }
      );
    }
    return of(null);
  }

  updateNextTarPmDate(equipment: EquipmentViewModel) {
    if (moment(equipment.nextTarPmDate).isValid()) {
      return this.http.put(
        `${ApiEndpointsEnum.EQUIPMENTS}/${equipment.pid}/tars`,
        {
          nextTarPmDate: moment(equipment.nextTarPmDate).format('YYYY-MM-DD')
        }
      );
    }
    return of(null);
  }
}
