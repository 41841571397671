<div class="parameters" fxLayout="row wrap">
  <mat-progress-bar
    class="loading"
    *ngIf="pendingCalls.length > 0"
    mode="indeterminate"
  ></mat-progress-bar>
  <ng-container *ngFor="let param of parameters">
    <div
      class="item"
      fxFlex="50%"
      fxLayout="row"
      fxLayoutAlign="space-between start"
    >
      <span class="label">{{ param.label }}:</span>
      <div class="value-section" fxLayout="row" fxLayoutAlign="end center">
        <span class="value">{{ param.value ? param.value : '-' }}</span>
        <span *ngIf="param.value" class="value">&nbsp;{{ param.unit }}</span>
      </div>
    </div>
  </ng-container>
</div>
