import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { ScopeItemModel } from '../../../../../shared-module/src/lib/models/common/scope-item.model';

import { AutoCompleteValueItem } from './auto-complete-value-item.model';

export interface Fruit {
  name: string;
}

// <!-- This is an exemple of the autocomplete component usage -->
//   <ahc-auto-complete
//     [placeHolder]="'Production unit permission'"
//     [values]="[
//       { id: '1', label: 'hello' },
//       { id: '2', label: 'hooooo' },
//       { id: '3', label: 'mmmmmmm' }
//     ]"
//     (valueChange)="receiveValues($event)"
//   ></ahc-auto-complete>

@Component({
  selector: 'ahc-auto-complete',
  templateUrl: './auto-complete.component.html',
  styleUrls: ['./auto-complete.component.scss']
})
export class AutoCompleteComponent implements OnInit, OnChanges {
  @Input()
  creationModeEnabled = false;

  @Input()
  placeHolder = '';

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredValues: Observable<AutoCompleteValueItem[]> = of([]);
  @Input()
  selectedValues: ScopeItemModel[] = [];
  @Input()
  values: AutoCompleteValueItem[] = [];

  valuesCtrl: FormControl = new FormControl();
  @ViewChild('valueInput', { static: false }) valueInput: ElementRef<
    HTMLInputElement
  >;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  @Output() valueChange: EventEmitter<ScopeItemModel[]> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.values = _.difference(this.values, this.selectedValues);
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    this.filteredValues = this.valuesCtrl.valueChanges.pipe(
      startWith(''),
      map((value: string | null) => {
        return value ? this._filter(value) : this.values.slice();
      })
    );
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen && this.creationModeEnabled) {
      const input = event.input;
      const value = event.value;

      if ((value || '').trim()) {
        this.selectedValues.push({
          id: value.trim(),
          name: value.trim()
        });
      }

      if (input) {
        input.value = '';
      }

      this.valuesCtrl.setValue(null);
    }
  }

  remove(value: AutoCompleteValueItem): void {
    const index = this.selectedValues.indexOf(value);

    if (index >= 0) {
      this.values.push(value);
      this.selectedValues.splice(index, 1);
      this.valueChange.emit(this.selectedValues);
      this.valuesCtrl.setValue(null);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const value = this.values.find(
      (v: AutoCompleteValueItem) => v.id === event.option.value
    );

    this.selectedValues.push(value);
    this.values = _.difference(this.values, [value]);
    this.valuesCtrl.setValue(null);
    this.valueChange.emit(this.selectedValues);
  }

  private _filter(id: string): ScopeItemModel[] {
    const filterValue = id.toLowerCase();
    return this.values.filter(
      val => val.name.toLowerCase().indexOf(filterValue) > -1
    );
  }
}
