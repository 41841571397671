<div
  class="table-upload"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxFill
>
  <p *ngIf="errorMessage" class="error-upload-message">
    {{ 'common.error_message.generic_error' | translate }}
  </p>
  <mat-table
    fxFill
    [dataSource]="data"
    *ngIf="data.length > 0; else noData"
    class="mat-elevation-z1 cockpit-table"
    fxFlexAlign="center"
  >
    <!-- column name -->
    <ng-container matColumnDef="name">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'cockpit.report.table.labels.name' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.name }} </mat-cell>
    </ng-container>
    <!-- column date -->
    <ng-container matColumnDef="date">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'cockpit.report.table.labels.date' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.createdAt | date: 'medium' }}
      </mat-cell>
    </ng-container>
    <!-- column action -->
    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'cockpit.report.table.labels.action' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a
          [matTooltip]="'cockpit.report.table.labels.open_link' | translate"
          mat-icon-button
          [href]="element.url"
          target="_blank"
        >
          <mat-icon>link</mat-icon>
        </a>
        <!-- <i class="material-icons">link</i> -->
        <button
          *ngxPermissionsOnly="[permissions.ADMIN, permissions.INTERNAL_KEY]"
          (click)="deleteLink(element.equipmentPid, element.id)"
          [matTooltip]="'cockpit.report.table.labels.delete_link' | translate"
          mat-icon-button
        >
          <mat-icon>delete</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    fxFlexAlign="stretch"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
  <ng-template #noData>
    <ahc-no-data></ahc-no-data>
  </ng-template>
</div>
