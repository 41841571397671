import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import PwdResetStatus from 'libs/shared-module/src/lib/models/auth/password.reset.status.enum';
import { AuthService } from 'libs/shared-module/src/lib/providers/auth.provider';

export const passwordConfirmationValidator: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  return password && confirmPassword && password.value !== confirmPassword.value
    ? { notEqual: true }
    : null;
};

@Component({
  selector: 'ahc-login-define-new-password',
  templateUrl: './login-define-new-password.component.html',
  styleUrls: ['./login-define-new-password.component.scss']
})
export class LoginDefineNewPasswordComponent implements OnInit {
  resetFG: FormGroup;
  PwdResetStatusLocal = PwdResetStatus;
  updateError = PwdResetStatus.SUCCESS;

  get password() {
    return this.resetFG.get('password').value;
  }
  get confirmPassword() {
    return this.resetFG.get('confirmPassword').value;
  }
  get code() {
    return this.resetFG.get('code').value;
  }
  get email() {
    return this.resetFG.get('email').value;
  }

  constructor(
    fb: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
    this.resetFG = fb.group(
      {
        email: new FormControl('', [Validators.required, Validators.email]),
        code: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
        confirmPassword: new FormControl('', [Validators.required])
      },
      {
        validators: passwordConfirmationValidator
      }
    );
  }

  ngOnInit() {}

  isFormValid(): boolean {
    return (
      !this.resetFG.errors &&
      this.resetFG.controls['email'].valid &&
      this.resetFG.controls['email'].dirty &&
      this.resetFG.controls['password'].valid &&
      this.resetFG.controls['password'].dirty &&
      this.resetFG.controls['code'].valid &&
      this.resetFG.controls['code'].dirty &&
      this.resetFG.controls['confirmPassword'].valid &&
      this.resetFG.controls['confirmPassword'].dirty
    );
  }

  async resetPassword() {
    if (this.isFormValid()) {
      const pwdUpdatedStatus = await this.authService.updatePassword(
        this.email,
        this.code,
        this.password
      );
      if (pwdUpdatedStatus === PwdResetStatus.SUCCESS) {
        this.router.navigate(['login']);
        return;
      }
      this.updateError = pwdUpdatedStatus;
    }
  }
}
