import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'ahc-report-data-quality-issue',
  templateUrl: './report-data-quality-issue.component.html',
  styleUrls: ['./report-data-quality-issue.component.scss']
})
export class ReportDataQualityIssueComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ReportDataQualityIssueComponent>
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.form = this.fb.group({
      parameterName: new FormControl('', Validators.required),
      comment: new FormControl('', Validators.required)
    });
  }

  close() {
    this.dialogRef.close();
  }
}
