import { OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import DisplayZone from '../models/common/enums/display-zone.enum';
import ExpertiseDomain from '../models/common/enums/expertise-domain.enum';
import Tabs from '../models/common/enums/tabs.enum';
import { ParameterService } from './parameter.provider';
import * as i0 from "@angular/core";
import * as i1 from "./parameter.provider";
var FeedbackService = /** @class */ (function () {
    /**
     *
     */
    function FeedbackService(parameterService) {
        this.parameterService = parameterService;
        this.feedbacks$ = new BehaviorSubject([]);
        this.loading = new BehaviorSubject(false);
        this.ngUnsubscribe = new Subject();
    }
    FeedbackService.prototype.init = function (currentTab, equipment) {
        var _this = this;
        this.loading.next(true);
        this.tab = currentTab;
        this.equipment = equipment;
        var expertiseDomain = this.tab === Tabs.HOME
            ? ExpertiseDomain.N_A
            : this.equipment.class.expertiseDomain;
        this.parameterService
            .getParameters(expertiseDomain, 1, 1000, null, new Map([['display_zone', DisplayZone.COMMENT_BOX]]))
            .pipe(catchError(function (err) {
            return [];
        }))
            .subscribe(function (parameters) {
            _this.paramComment = parameters.items.find(function (param) {
                return param.name.toLocaleLowerCase().indexOf('comments') > -1 &&
                    param.tab === _this.tab;
            });
            _this.paramIndicator = parameters.items.find(function (param) {
                return param.name.toLocaleLowerCase().indexOf('indicator') > -1 &&
                    param.tab === _this.tab;
            });
            if (_this.paramComment && _this.paramIndicator) {
                _this.loadData();
            }
            else {
                _this.loading.next(false);
            }
        });
    };
    FeedbackService.prototype.add = function (feedback) {
        var _this = this;
        this.loading.next(true);
        var comment = new Map();
        comment[feedback.date.format('YYYY-MM-DD')] = JSON.stringify(feedback.comment);
        var indicator = new Map();
        indicator[feedback.date.format('YYYY-MM-DD')] = parseFloat(feedback.status);
        this.parameterService
            .batch({
            data: [
                {
                    equipmentId: this.equipment.pid,
                    parameterId: this.paramComment.id,
                    data: comment
                },
                {
                    equipmentId: this.equipment.pid,
                    parameterId: this.paramIndicator.id,
                    data: indicator
                }
            ]
        })
            .subscribe(function () {
            _this.loadData();
        });
    };
    FeedbackService.prototype.onFeedbacks = function () {
        return this.feedbacks$.asObservable();
    };
    FeedbackService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.complete();
        this.ngUnsubscribe.unsubscribe();
    };
    FeedbackService.prototype.loadData = function () {
        var _this = this;
        var startDate = new Date(2000, 1, 1);
        var now = moment().toDate();
        var reqComments = this.parameterService
            .getParameterValues(this.equipment.pid, this.paramComment.id, startDate, now)
            .pipe(takeUntil(this.ngUnsubscribe));
        var reqIndicators = this.parameterService
            .getParameterValues(this.equipment.pid, this.paramIndicator.id, startDate, now)
            .pipe(takeUntil(this.ngUnsubscribe));
        forkJoin([reqComments, reqIndicators]).subscribe(function (results) {
            var indicators = results[1];
            var comments = results[0];
            var feedbacks = [];
            Object.keys(indicators).forEach(function (key) {
                var feedback = {
                    date: moment(key),
                    status: indicators[key],
                    comment: comments[key] ? JSON.parse(comments[key]) : null
                };
                feedbacks.push(feedback);
            });
            feedbacks.sort(function (a, b) { return (a.date.isAfter(b.date) ? -1 : 1); });
            _this.feedbacks$.next(feedbacks);
            _this.loading.next(false);
        });
    };
    FeedbackService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeedbackService_Factory() { return new FeedbackService(i0.ɵɵinject(i1.ParameterService)); }, token: FeedbackService, providedIn: "root" });
    return FeedbackService;
}());
export { FeedbackService };
