import { HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  /**
   *
   */
  constructor() {}
  intercept(
    req: import('@angular/common/http').HttpRequest<any>,
    next: import('@angular/common/http').HttpHandler
  ): import('rxjs').Observable<import('@angular/common/http').HttpEvent<any>> {
    if (req.method.toLocaleLowerCase() === 'get') {
      return next.handle(req).pipe(
        map(evt => {
          if (evt instanceof HttpResponse) {
            if (evt.body && evt.ok) {
              const newBody = JSON.stringify(evt.body, (key, value) => {
                return value === 'None' ? null : value;
              });
              return evt.clone({
                body: JSON.parse(newBody)
              });
            }
          }
          return evt;
        })
      );
    } else if (req.method.toLocaleLowerCase() === 'delete') {
      const request = req.clone({
        headers: req.headers.delete('Content-Type')
      });
      return next.handle(request);
    } else {
      const request = req.clone({
        headers: req.headers.has('Content-Type')
          ? req.headers
          : req.headers.set('Content-Type', 'application/json')
      });
      return next.handle(request);
    }
  }
}
