import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import * as moment from 'moment';

import { GraphParameterModel } from 'libs/shared-module/src/lib/models/common/graph-parameter.model';
import { BaseGraphParameterService } from 'libs/shared-module/src/lib/providers/graph-parameter.base.provider';
import { ParameterGraphParameterService } from 'libs/shared-module/src/lib/providers/parameter-graph-parameter.provider';

@Component({
  selector: 'ahc-menu-graph-point-deletion',
  templateUrl: './menu-graph-point-deletion.component.html',
  styleUrls: ['./menu-graph-point-deletion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuGraphPointDeletionComponent implements OnInit {
  points: GraphParameterModel<Highcharts.Point>;
  graphservice: BaseGraphParameterService;

  constructor(
    public parameterGraphParameterService: ParameterGraphParameterService
  ) {}

  ngOnInit() {
    this.parameterGraphParameterService.points$.subscribe(
      (data: GraphParameterModel<Highcharts.Point>) => {
        this.points = { ...data };
      }
    );
  }
  deletePoint(point: Highcharts.Point) {
    this.parameterGraphParameterService.delete([
      (point.x as unknown) as moment.Moment
    ]);
  }
}
