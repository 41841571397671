import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ParameterModel } from '../models/common/parameter.model';
import { SearchResultModel } from '../models/common/search-result.model';
import { ImportMainFilter } from '../models/import/main-filter.model';
import { ParameterImportValueModel } from '../models/import/parameter-import-value.model';
import {
  ParameterImportDataModel,
  ParametersBulkImportModel
} from '../models/import/parameters-bulk-import.model';

import { ParameterService } from './parameter.provider';

@Injectable()
export class ImportParametersValueService {
  /**
   *
   */
  constructor(private parametersService: ParameterService) {}

  getParameters(filter: ImportMainFilter): Observable<ParameterModel[]> {
    return this.parametersService
      .getParameterByExpertiseDomain(filter.discipline.discipline)
      .pipe(
        map((data: SearchResultModel<ParameterModel[]>) =>
          data.items.filter(
            (parameter: ParameterModel) =>
              parameter.tab === filter.discipline.tab
          )
        )
      );
  }

  import(data: ParameterImportValueModel[]): Observable<Object> {
    const batchData: ParametersBulkImportModel = {
      data: data.map(value => {
        const row: ParameterImportDataModel = {
          equipmentId: value.equipmentId,
          parameterId: value.parameter.id,
          data: value.values
        };
        return row;
      })
    };
    return this.parametersService.batch(batchData);
  }
}
