import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SearchEquipmenFilterEnum } from 'libs/shared-module/src/lib/models/common/search-equipment-filter.enum';
import { SearchCriteria } from 'libs/shared-module/src/lib/models/equipment/search-criteria.model';
import { SearchEquipmentService } from 'libs/shared-module/src/lib/providers/search-equipment.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-search-equipment',
  templateUrl: './search-equipment.component.html',
  styleUrls: ['./search-equipment.component.scss'],
  providers: [SearchEquipmentService]
})
export class SearchEquipmentComponent implements OnInit, OnDestroy {
  isVisibleSearch = false;
  enumFilterType = SearchEquipmenFilterEnum;
  typeFilter: SearchEquipmenFilterEnum;
  equipments: EquipmentViewModel[] = [];
  resultCount = 0;
  queryControl = new FormControl('');
  criteria: SearchCriteria;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private searchProvider: SearchEquipmentService) {
    this.typeFilter = SearchEquipmenFilterEnum.MAIN;
  }
  private ngUnsubscribe: Subject<void> = new Subject();

  ngOnInit() {
    this.searchProvider.onFiltredResult().subscribe(result => {
      if (this.typeFilter === SearchEquipmenFilterEnum.PLANT) {
        this.resultCount = result.totalSize;
      } else {
        this.resultCount = result.totalSize;
      }
    });

    this.criteria = this.searchProvider.getCriteria();

    this.searchProvider
      .onCriteria()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((criteria: SearchCriteria) => {
        this.paginator.pageIndex = criteria.page - 1;
        this.criteria = criteria;
        this.queryControl.setValue(criteria.query);
      });

    this.paginator.page.pipe().subscribe((value: PageEvent) => {
      this.criteria.page = value.pageIndex + 1;
      this.criteria.pageSize = value.pageSize;
      this.searchProvider.search(this.criteria);
    });

    // Display equipment on load or back event
    //

    this.searchProvider.search(this.criteria);
  }

  search() {
    this.searchProvider.search(this.criteria);
  }

  filter() {
    this.criteria.page = 1;
    this.searchProvider.search(this.criteria);
    this.isVisibleSearch = false;
  }

  toggleAdvancedSearch() {
    this.isVisibleSearch = !this.isVisibleSearch;
  }

  handleVisibilityChange($event) {
    this.isVisibleSearch = $event;
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
