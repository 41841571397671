/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./equipment-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../common/no-data/no-data.component.ngfactory";
import * as i3 from "../../../common/no-data/no-data.component";
import * as i4 from "../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i5 from "@angular/flex-layout/flex";
import * as i6 from "@angular/flex-layout/core";
import * as i7 from "@angular/material/progress-bar";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../plant-panel/plant-panel.component.ngfactory";
import * as i10 from "../plant-panel/plant-panel.component";
import * as i11 from "@angular/material/icon";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../equipment-panel/equipment-panel.component.ngfactory";
import * as i14 from "../../../../../../shared-module/src/lib/providers/feedback.provider";
import * as i15 from "../../../../../../shared-module/src/lib/providers/parameter.provider";
import * as i16 from "../equipment-panel/equipment-panel.component";
import * as i17 from "@angular/router";
import * as i18 from "@angular/common";
import * as i19 from "@angular/cdk/bidi";
import * as i20 from "./equipment-list.component";
import * as i21 from "../../../../../../shared-module/src/lib/providers/search-equipment.provider";
import * as i22 from "../../../../../../shared-module/src/lib/providers/favorite-equipment.provider";
var styles_EquipmentListComponent = [i0.styles];
var RenderType_EquipmentListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EquipmentListComponent, data: {} });
export { RenderType_EquipmentListComponent as RenderType_EquipmentListComponent };
function View_EquipmentListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ahc-no-data", [], null, null, null, i2.View_NoDataComponent_0, i2.RenderType_NoDataComponent)), i1.ɵdid(1, 114688, null, 0, i3.NoDataComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_EquipmentListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "loading mat-progress-bar"], ["fxFlexAlign", "stretch"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatProgressBar_0, i4.RenderType_MatProgressBar)), i1.ɵdid(1, 671744, null, 0, i5.DefaultFlexAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.FlexAlignStyleBuilder], i6.MediaMarshaller], { fxFlexAlign: [0, "fxFlexAlign"] }, null), i1.ɵdid(2, 4374528, null, 0, i7.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i8.ANIMATION_MODULE_TYPE], [2, i7.MAT_PROGRESS_BAR_LOCATION]], { color: [0, "color"], mode: [1, "mode"] }, null)], function (_ck, _v) { var currVal_3 = "stretch"; _ck(_v, 1, 0, currVal_3); var currVal_4 = "primary"; var currVal_5 = "indeterminate"; _ck(_v, 2, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = (((i1.ɵnov(_v, 2).mode === "indeterminate") || (i1.ɵnov(_v, 2).mode === "query")) ? null : i1.ɵnov(_v, 2).value); var currVal_1 = i1.ɵnov(_v, 2).mode; var currVal_2 = i1.ɵnov(_v, 2)._isNoopAnimation; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_EquipmentListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ahc-plant-panel", [], null, null, null, i9.View_PlantPanelComponent_0, i9.RenderType_PlantPanelComponent)), i1.ɵdid(1, 114688, null, 0, i10.PlantPanelComponent, [i11.MatIconRegistry, i12.DomSanitizer], { plant: [0, "plant"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.key; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EquipmentListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ahc-equipment-panel", [], null, null, null, i13.View_EquipmentPanelComponent_0, i13.RenderType_EquipmentPanelComponent)), i1.ɵprd(135680, null, i14.FeedbackService, i14.FeedbackService, [i15.ParameterService]), i1.ɵdid(2, 49152, null, 0, i16.EquipmentPanelComponent, [i17.Router], { equipment: [0, "equipment"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_EquipmentListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentListComponent_7)), i1.ɵdid(2, 278528, null, 0, i18.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit.value; var currVal_1 = _co.trackByFn; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_EquipmentListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentListComponent_5)), i1.ɵdid(2, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentListComponent_6)), i1.ɵdid(4, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((((_co.searchFilter === _co.localSearchFilterEnum.FAVORITE) || (_co.searchFilter === _co.localSearchFilterEnum.ALL)) || (_co.searchFilter === _co.localSearchFilterEnum.PLANT)) || (_co.searchFilter === _co.localSearchFilterEnum.MAIN)); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((((_co.searchFilter === _co.localSearchFilterEnum.ALL) || (_co.searchFilter === _co.localSearchFilterEnum.FAVORITE)) || (_co.searchFilter === _co.localSearchFilterEnum.EQUIPMENTS)) || (_co.searchFilter === _co.localSearchFilterEnum.MAIN)); _ck(_v, 4, 0, currVal_1); }, null); }
function View_EquipmentListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["fxLayout", "column"], ["fxLayoutAlign", "start"], ["fxLayoutGap", "20px"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i5.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i19.Directionality, i6.StyleUtils, [2, i5.LayoutGapStyleBuilder], i6.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EquipmentListComponent_4)), i1.ɵdid(5, 278528, null, 0, i18.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), i1.ɵpid(0, i18.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "20px"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "start"; _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.result)); var currVal_4 = _co.trackByFn; _ck(_v, 5, 0, currVal_3, currVal_4); }, null); }
export function View_EquipmentListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["fxFill", ""], ["fxLayout", "column"], ["fxLayoutAlign", "start stretch"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i5.DefaultLayoutDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutStyleBuilder], i6.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i5.DefaultLayoutAlignDirective, [i1.ElementRef, i6.StyleUtils, [2, i5.LayoutAlignStyleBuilder], i6.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(3, 671744, null, 0, i5.FlexFillDirective, [i1.ElementRef, i6.StyleUtils, i5.FlexFillStyleBuilder, i6.MediaMarshaller], null, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EquipmentListComponent_1)), i1.ɵdid(5, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i18.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EquipmentListComponent_2)), i1.ɵdid(8, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i18.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EquipmentListComponent_3)), i1.ɵdid(11, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start stretch"; _ck(_v, 2, 0, currVal_1); var currVal_2 = ((_co.result.size === 0) && !i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.searchProvider.loading$))); _ck(_v, 5, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 8, 0, i1.ɵnov(_v, 9).transform(_co.searchProvider.loading$)); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.result.size !== 0); _ck(_v, 11, 0, currVal_4); }, null); }
export function View_EquipmentListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ahc-equipment-list", [], null, null, null, View_EquipmentListComponent_0, RenderType_EquipmentListComponent)), i1.ɵdid(1, 114688, null, 0, i20.EquipmentListComponent, [i21.SearchEquipmentService, i22.FavoriteEquipmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EquipmentListComponentNgFactory = i1.ɵccf("ahc-equipment-list", i20.EquipmentListComponent, View_EquipmentListComponent_Host_0, { searchFilter: "searchFilter" }, {}, []);
export { EquipmentListComponentNgFactory as EquipmentListComponentNgFactory };
