/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-data.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./no-data.component";
var styles_NoDataComponent = [i0.styles];
var RenderType_NoDataComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoDataComponent, data: {} });
export { RenderType_NoDataComponent as RenderType_NoDataComponent };
export function View_NoDataComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("common.no_data")); _ck(_v, 1, 0, currVal_0); }); }
export function View_NoDataComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ahc-no-data", [], null, null, null, View_NoDataComponent_0, RenderType_NoDataComponent)), i1.ɵdid(1, 114688, null, 0, i3.NoDataComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoDataComponentNgFactory = i1.ɵccf("ahc-no-data", i3.NoDataComponent, View_NoDataComponent_Host_0, {}, {}, []);
export { NoDataComponentNgFactory as NoDataComponentNgFactory };
