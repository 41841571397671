import { Component, OnInit } from '@angular/core';

import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ParameterTableDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/parameter-table-data-source.provider';
import { FeedbackService } from 'libs/shared-module/src/lib/providers/feedback.provider';
import { ParameterGraphParameterService } from 'libs/shared-module/src/lib/providers/parameter-graph-parameter.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-water',
  templateUrl: './water.component.html',
  styleUrls: ['./water.component.scss'],
  providers: [ParameterTableDataSourceService, FeedbackService]
})
export class WaterComponent implements OnInit {
  equipment: EquipmentViewModel;
  tab = Tabs.WATER;

  constructor(
    private cockpitProvider: CockpitService,
    public dataSource: ParameterTableDataSourceService,
    public graphService: ParameterGraphParameterService
  ) {
    this.cockpitProvider.onEquipment().subscribe(equipment => {
      if (equipment.class && equipment.class.expertiseDomain) {
        this.equipment = equipment;
        this.dataSource.initialize(this.tab, 1000);
      }
    });
  }
  ngOnInit() {}
}
