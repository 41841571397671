import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs/add/observable/of';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';

import { RequestCacheService } from 'libs/shared-module/src/lib/providers/request-cache.provider';

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCacheService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Cache only the get method
    if (
      req.method.toLocaleLowerCase() !== 'get' ||
      req.headers.has('no-cache')
    ) {
      return next.handle(req);
    }
    const cachedResponse = this.cache.get(req);
    return cachedResponse
      ? Observable.of(cachedResponse)
      : this.sendRequest(req, next, this.cache);
  }

  sendRequest(
    req: HttpRequest<any>,
    next: HttpHandler,
    cache: RequestCacheService
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          cache.put(req, event);
        }
      })
    );
  }
}
