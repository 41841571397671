/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./vibration-table.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-bar";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../common/parameters-table/parameters-table.component.ngfactory";
import * as i8 from "../../../../../../shared-module/src/lib/providers/scada-graph-parameter.provider";
import * as i9 from "../../../../../../shared-module/src/lib/providers/dataServices/scada-data-service.provider";
import * as i10 from "../../../common/parameters-table/parameters-table.component";
import * as i11 from "../../components/insert-parameter-value/insert-parameter-value.component.ngfactory";
import * as i12 from "../../../../../../shared-module/src/lib/providers/parameter.provider";
import * as i13 from "@angular/common/http";
import * as i14 from "../../../../../../shared-module/src/lib/providers/Logger.provider";
import * as i15 from "../../components/insert-parameter-value/insert-parameter-value.component";
import * as i16 from "../../../../../../shared-module/src/lib/providers/cockpit.provider";
import * as i17 from "../../../../../../shared-module/src/lib/providers/dataSource/parameter-table-data-source.provider";
import * as i18 from "@angular/common";
import * as i19 from "../../../../../../shared-module/src/lib/providers/threshold.provider";
import * as i20 from "./vibration-table.component";
import * as i21 from "../../../../../../shared-module/src/lib/providers/parameter-graph-parameter.provider";
var styles_VibrationTableComponent = [i0.styles];
var RenderType_VibrationTableComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_VibrationTableComponent, data: {} });
export { RenderType_VibrationTableComponent as RenderType_VibrationTableComponent };
function View_VibrationTableComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["fxFlexAlign", "stretch"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultFlexAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.FlexAlignStyleBuilder], i3.MediaMarshaller], { fxFlexAlign: [0, "fxFlexAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "loading mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatProgressBar_0, i4.RenderType_MatProgressBar)), i1.ɵdid(3, 4374528, null, 0, i5.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i6.ANIMATION_MODULE_TYPE], [2, i5.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_0 = "stretch"; _ck(_v, 1, 0, currVal_0); var currVal_4 = "indeterminate"; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = (((i1.ɵnov(_v, 3).mode === "indeterminate") || (i1.ɵnov(_v, 3).mode === "query")) ? null : i1.ɵnov(_v, 3).value); var currVal_2 = i1.ɵnov(_v, 3).mode; var currVal_3 = i1.ɵnov(_v, 3)._isNoopAnimation; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
function View_VibrationTableComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "ahc-parameters-table", [], null, null, null, i7.View_ParametersTableComponent_0, i7.RenderType_ParametersTableComponent)), i1.ɵprd(4608, null, i8.ScadaGraphParameterService, i8.ScadaGraphParameterService, [i9.ScadaDataService]), i1.ɵdid(3, 4308992, null, 0, i10.ParametersTableComponent, [], { pageSize: [0, "pageSize"], dataSource: [1, "dataSource"], equipment: [2, "equipment"], graphService: [3, "graphService"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ahc-insert-parameter-value", [], null, null, null, i11.View_InsertParameterValueComponent_0, i11.RenderType_InsertParameterValueComponent)), i1.ɵprd(131584, null, i12.ParameterService, i12.ParameterService, [i13.HttpClient, i14.Logger]), i1.ɵdid(6, 114688, null, 0, i15.InsertParameterValueComponent, [i12.ParameterService, i16.CockpitService, i17.ParameterTableDataSourceService], { expertiseDomain: [0, "expertiseDomain"], tab: [1, "tab"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = 1000; var currVal_1 = _co.dataSource; var currVal_2 = _co.equipment; var currVal_3 = _co.graphService; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.equipment.class.expertiseDomain; var currVal_5 = _co.tab; _ck(_v, 6, 0, currVal_4, currVal_5); }, null); }
export function View_VibrationTableComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "oil-table"], ["fxFlex", ""], ["fxLayout", "column"], ["fxLayoutAlign", "start stretch"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultFlexDirective, [i1.ElementRef, i3.StyleUtils, i3.LAYOUT_CONFIG, i2.FlexStyleBuilder, i3.MediaMarshaller], { fxFlex: [0, "fxFlex"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_VibrationTableComponent_1)), i1.ɵdid(5, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i18.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_VibrationTableComponent_2)), i1.ɵdid(8, 16384, null, 0, i18.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start stretch"; _ck(_v, 2, 0, currVal_1); var currVal_2 = ""; _ck(_v, 3, 0, currVal_2); var currVal_3 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform(_co.dataSource.loading$)); _ck(_v, 5, 0, currVal_3); var currVal_4 = (_co.equipment && _co.equipment.pid); _ck(_v, 8, 0, currVal_4); }, null); }
export function View_VibrationTableComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ahc-vibration-table", [["class", "height-100"]], null, null, null, View_VibrationTableComponent_0, RenderType_VibrationTableComponent)), i1.ɵprd(131584, null, i17.ParameterTableDataSourceService, i17.ParameterTableDataSourceService, [i12.ParameterService, i19.ThresholdService]), i1.ɵdid(2, 114688, null, 0, i20.VibrationTableComponent, [i16.CockpitService, i17.ParameterTableDataSourceService, i21.ParameterGraphParameterService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var VibrationTableComponentNgFactory = i1.ɵccf("ahc-vibration-table", i20.VibrationTableComponent, View_VibrationTableComponent_Host_0, {}, {}, []);
export { VibrationTableComponentNgFactory as VibrationTableComponentNgFactory };
