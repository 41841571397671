import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../providers/auth.provider';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  /**
   *
   */
  constructor(private authService: AuthService) {}
  intercept(
    req: import('@angular/common/http').HttpRequest<any>,
    next: import('@angular/common/http').HttpHandler
  ): import('rxjs').Observable<import('@angular/common/http').HttpEvent<any>> {
    const userToken = this.authService.getToken();
    if (
      req.headers.has('no-auth')
    ) {
      return next.handle(req);
    }
    if (userToken) {
      const authReq = req.clone({
        headers: req.headers
          .set('x-sso', this.authService.IsSso.toString())
          .set('Authorization', `Bearer ${userToken}`)
      });

      return next
        .handle(authReq)
        .pipe(catchError(error => this.handleError(error)));
    } else {
      return next.handle(req);
    }
  }

  handleError(error) {
    switch (error.status) {
      case 401: {
        this.authService.logOut(true, window.location.pathname);
        break;
      }
    }
    return throwError(error);
  }
}
