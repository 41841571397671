import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import * as _ from 'lodash';
import * as moment from 'moment';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { GraphParameterModel } from '../models/common/graph-parameter.model';
import { SearchResultModel } from '../models/common/search-result.model';
import { ThresholdModel } from '../models/common/threshold.model';
import { EquipmentViewModel } from '../viewmodels/equipment.viewmodel';

import { BaseGraphParameterService } from './graph-parameter.base.provider';
import { Logger } from './Logger.provider';
import { ParameterService } from './parameter.provider';
import { ThresholdService } from './threshold.provider';

@Injectable()
export class ParameterGraphParameterService extends BaseGraphParameterService {
  constructor(
    private parameterService: ParameterService,
    private logger: Logger,
    private thresholdService: ThresholdService
  ) {
    super();
  }

  private equipment: EquipmentViewModel;
  private parameter: string;
  private parameterId: string;

  public loadData(
    parameterId: string,
    parameter: string,
    equipment: EquipmentViewModel
  ) {
    this.equipment = equipment;
    this.parameter = parameter;
    this.parameterId = parameterId;
    // super.loadData(parameterId, parameter);
    super.loadData(parameterId, parameter, equipment);
  }

  getValues(
    parameterId: string,
    parameter: string,
    moduleId: string
  ): Observable<GraphParameterModel<Highcharts.Point>> {

    if (!this.equipment) {
      this.logger.error('ParameterGraphParameterService', 'equipment is null');
      return of({
        name: parameter,
        values: []
      });
    }

    return forkJoin([
      this.thresholdService.batchThreshold({ parameterListPid: [parameterId] }),
      this.parameterService.getParameterValues(
        this.equipment.pid,
        parameterId,
        new Date(2000, 1, 1),
        moment().toDate()
      )
    ]).pipe(
      map(
        (
          results: [SearchResultModel<ThresholdModel[]>, Map<string, string>]
        ) => {
          const mappedValues: Highcharts.Point[] = [];
          Object.keys(results[1]).forEach(key =>
            mappedValues.push(
              Object.assign({
                x: moment(key).toDate(),
                y: results[1][key]
              })
            )
          );

          const graphData: GraphParameterModel<Highcharts.Point> = {
            name: parameter,
            values: mappedValues,
            threshold: results[0].items.find(
              item => item.parameterPid === parameterId
            )
          };

          return graphData;
        }
      )
    );
  }

  delete(dates: moment.Moment[]) {
    this.loading$.next(true);
    this.parameterService
      .delete(this.parameterId, this.equipment.pid, dates)
      .subscribe(done => {
        this.loadData(this.parameterId, this.parameter, this.equipment);
        this.points$.subscribe(points =>
          this.deletedPoint$.next(this.points$.value)
        );
      });
  }
}
