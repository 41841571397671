import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import DisplayZone from '../models/common/enums/display-zone.enum';
import EquipmentStatus from '../models/common/enums/equipment.status.enum';
import ExpertiseDomain from '../models/common/enums/expertise-domain.enum';
import Tabs from '../models/common/enums/tabs.enum';
import { ParameterModel } from '../models/common/parameter.model';
import { FeedbackModel } from '../models/feedback/feedback.model';
import { EquipmentViewModel } from '../viewmodels/equipment.viewmodel';

import { ParameterService } from './parameter.provider';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService implements OnDestroy {
  feedbacks$: BehaviorSubject<FeedbackModel[]> = new BehaviorSubject([]);
  loading = new BehaviorSubject(false);

  /**
   *
   */
  constructor(private parameterService: ParameterService) {}

  private ngUnsubscribe: Subject<void> = new Subject();

  private tab: Tabs;
  private equipment: EquipmentViewModel;
  private paramComment: ParameterModel;
  private paramIndicator: ParameterModel;

  init(currentTab: Tabs, equipment: EquipmentViewModel) {
    this.loading.next(true);
    this.tab = currentTab;
    this.equipment = equipment;

    const expertiseDomain =
      this.tab === Tabs.HOME
        ? ExpertiseDomain.N_A
        : this.equipment.class.expertiseDomain;

    this.parameterService
      .getParameters(
        expertiseDomain,
        1,
        1000,
        null,
        new Map([['display_zone', DisplayZone.COMMENT_BOX]])
      )
      .pipe(
        catchError(err => {
          return [];
        })
      )
      .subscribe(parameters => {
        this.paramComment = parameters.items.find(
          param =>
            param.name.toLocaleLowerCase().indexOf('comments') > -1 &&
            param.tab === this.tab
        );
        this.paramIndicator = parameters.items.find(
          param =>
            param.name.toLocaleLowerCase().indexOf('indicator') > -1 &&
            param.tab === this.tab
        );
        if (this.paramComment && this.paramIndicator) {
          this.loadData();
        } else {
          this.loading.next(false);
        }
      });
  }

  add(feedback: FeedbackModel) {
    this.loading.next(true);
    const comment = new Map<string, string>();
    comment[feedback.date.format('YYYY-MM-DD')] = JSON.stringify(
      feedback.comment
    );
    const indicator = new Map<string, string>();
    indicator[feedback.date.format('YYYY-MM-DD')] = parseFloat(feedback.status);

    this.parameterService
      .batch({
        data: [
          {
            equipmentId: this.equipment.pid,
            parameterId: this.paramComment.id,
            data: comment
          },
          {
            equipmentId: this.equipment.pid,
            parameterId: this.paramIndicator.id,
            data: indicator
          }
        ]
      })
      .subscribe(() => {
        this.loadData();
      });
  }

  onFeedbacks(): Observable<FeedbackModel[]> {
    return this.feedbacks$.asObservable();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }

  private loadData() {
    const startDate = new Date(2000, 1, 1);
    const now = moment().toDate();
    const reqComments = this.parameterService
      .getParameterValues(
        this.equipment.pid,
        this.paramComment.id,
        startDate,
        now
      )
      .pipe(takeUntil(this.ngUnsubscribe));
    const reqIndicators = this.parameterService
      .getParameterValues(
        this.equipment.pid,
        this.paramIndicator.id,
        startDate,
        now
      )
      .pipe(takeUntil(this.ngUnsubscribe));

    forkJoin([reqComments, reqIndicators]).subscribe(results => {
      const indicators = results[1];
      const comments = results[0];
      const feedbacks: FeedbackModel[] = [];

      Object.keys(indicators).forEach(key => {
        const feedback: FeedbackModel = {
          date: moment(key),
          status: indicators[key] as EquipmentStatus,
          comment: comments[key] ? JSON.parse(comments[key]) : null
        };
        feedbacks.push(feedback);
      });

      feedbacks.sort((a, b) => (a.date.isAfter(b.date) ? -1 : 1));

      this.feedbacks$.next(feedbacks);
      this.loading.next(false);
    });
  }
}
