import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ahc-corrective',
  templateUrl: './corrective.component.html',
  styleUrls: ['./corrective.component.scss']
})
export class CorrectiveComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
