import { Component, OnInit } from '@angular/core';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ScadaDataService } from 'libs/shared-module/src/lib/providers/dataServices/scada-data-service.provider';
import { ScadaDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/scada-data-source.provider';
import { ScadaGraphParameterService } from 'libs/shared-module/src/lib/providers/scada-graph-parameter.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-cockpit-home',
  templateUrl: './cockpit-home.component.html',
  styleUrls: ['./cockpit-home.component.scss'],
  providers: [ScadaDataSourceService, ScadaDataService]
})
export class CockpitHomeComponent implements OnInit {
  equipment: EquipmentViewModel;

  constructor(
    scadaService: ScadaDataService,
    public dataSource: ScadaDataSourceService,
    public graphService: ScadaGraphParameterService,
    public cockpitService: CockpitService
  ) {
    scadaService.category = 'home';
    this.cockpitService.onEquipment().subscribe(eq => (this.equipment = eq));
  }

  ngOnInit() {}
}
