<div class="maximo" fxLayout="column" fxLayoutAlign="start stretch">
  <span class="label">{{ 'cockpit.predictive.maximo.label' | translate }}</span>
  <div *ngIf="(tableDataSource.loading$ | async)" fxFlexAlign="stretch">
    <mat-progress-bar class="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-table
    fxFill
    [dataSource]="data"
    class="mat-elevation-z1 cockpit-table"
    fxFlexAlign="center"
  >
    <!-- column type -->
    <ng-container matColumnDef="type">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.type' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter.get('type') }"
          (search)="filter('type', $event)"
          (used)="showFilter.set('type', $event)"
          (sort)="sort('type', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.type }} </mat-cell>
    </ng-container>
    <!-- column process tag -->
    <ng-container matColumnDef="processTag">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.process_tag' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.processTag }} </mat-cell>
    </ng-container>
    <!-- column number -->
    <ng-container matColumnDef="number">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.number' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter.get('number') }"
          (search)="filter('number', $event)"
          [sortType]="sortEnum.desc"
          (used)="showFilter.set('number', $event)"
          (sort)="sort('number', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <a href="{{ element.link }}" target="_blank" title="Maximo link">{{
          element.number
        }}</a>
      </mat-cell>
    </ng-container>
    <!-- column description -->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.description' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.description }} </mat-cell>
    </ng-container>
    <!-- column failure -->
    <ng-container matColumnDef="failure">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.failure' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element">
        {{ element.failureDescription }}
      </mat-cell>
    </ng-container>
    <!-- column priority -->
    <ng-container matColumnDef="priority">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.priority' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter.get('priority') }"
          (search)="filter('priority', $event)"
          (used)="showFilter.set('priority', $event)"
          (sort)="sort('priority', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.priority }} </mat-cell>
    </ng-container>
    <!-- column eisWork -->
    <ng-container matColumnDef="eisWork">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.eis_work' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.eisWork }} </mat-cell>
    </ng-container>
    <!-- column eisWork -->
    <ng-container matColumnDef="finishDate">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.finish_date' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.finishDate }} </mat-cell>
    </ng-container>
    <!-- column status -->
    <ng-container matColumnDef="status">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.status' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter.get('status') }"
          (search)="filter('status', $event)"
          (used)="showFilter.set('status', $event)"
          (sort)="sort('status', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.status }} </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    fxFlexAlign="stretch"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
</div>
