import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { SearchResultModel } from '../models/common/search-result.model';
import { ClassStructureModel } from '../models/equipment/class-structure.model';

@Injectable()
export class ClassStructureService {
  constructor(private http: HttpClient) {}

  expertiseDomains(): Observable<ClassStructureModel[]> {
    return this.http
      .get<SearchResultModel<ClassStructureModel[]>>(
        `${ApiEndpointsEnum.EXPERTISE_CLASS}`
      )
      .pipe(
        map((result: SearchResultModel<ClassStructureModel[]>) => result.items)
      ); // TODO: pagination;
  }
}
