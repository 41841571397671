<div class="lire" fxLayout="column" fxLayoutAlign="start stretch">
  <span class="label">{{ 'cockpit.corrective.lire.label' | translate }}</span>
  <div *ngIf="(tableDataSource.loading$ | async)" fxFlexAlign="stretch">
    <mat-progress-bar class="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-table
    fxFill
    [dataSource]="data"
    class="mat-elevation-z1 cockpit-table"
    fxFlexAlign="center"
  >
    <!-- column number -->
    <ng-container matColumnDef="number">
      <mat-header-cell *matHeaderCellDef class="header-cell" fxFlex="10">
        {{ 'common.tables_columns.number' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter.get('technicalEventId') }"
          (search)="filter('technicalEventId', $event)"
          [sortType]="sortEnum.desc"
          (used)="showFilter.set('technicalEventId', $event)"
          (sort)="sort('technicalEventId', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" fxFlex="10">
        <a
          href="https://lire.apps.airliquide.com/lire#Reliability/Technicalevent/{{
            element.technicalEventId
          }}"
          target="_blank"
        >
          {{ element.technicalEventId }}</a
        >
      </mat-cell>
    </ng-container>
    <!-- column level -->
    <ng-container matColumnDef="level">
      <mat-header-cell *matHeaderCellDef class="header-cell" fxFlex="4">
        {{ 'common.tables_columns.level' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter.get('level') }"
          (search)="filter('level', $event)"
          (used)="showFilter.set('level', $event)"
          (sort)="sort('level', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" fxFlex="4">
        {{ element.level }}
      </mat-cell>
    </ng-container>
    <!-- column title -->
    <ng-container matColumnDef="title">
      <mat-header-cell *matHeaderCellDef class="header-cell">
        {{ 'common.tables_columns.title' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.title }} </mat-cell>
    </ng-container>
    <!-- column description -->
    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef class="header-cell" fxFlex="25">
        {{ 'common.tables_columns.description' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" fxFlex="25">
        {{ element.description }}
      </mat-cell>
    </ng-container>
    <!-- column consequence -->
    <ng-container matColumnDef="consequence">
      <mat-header-cell *matHeaderCellDef class="header-cell" fxFlex="20">
        {{ 'common.tables_columns.consequence' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" fxFlex="20">
        {{ element.consequence }}
      </mat-cell>
    </ng-container>
    <!-- column costs -->
    <ng-container matColumnDef="costs">
      <mat-header-cell *matHeaderCellDef class="header-cell" fxFlex="9">
        {{ 'common.tables_columns.costs' | translate }}
        <ahc-table-column-filter
          class="filter"
          [ngClass]="{ show: showFilter.get('costsEur') }"
          (search)="filter('costsEur', $event)"
          (used)="showFilter.set('costsEur', $event)"
          (sort)="sort('costsEur', $event)"
        ></ahc-table-column-filter>
      </mat-header-cell>
      <mat-cell *matCellDef="let element" fxFlex="9">
        {{ element.costsEur ? element.costsEur.toFixed(2) : '-' }}
      </mat-cell>
    </ng-container>
    <!-- column start date -->
    <ng-container matColumnDef="startDate">
      <mat-header-cell *matHeaderCellDef class="header-cell" fxFlex="5">
        {{ 'common.tables_columns.startDate' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" fxFlex="5">
        {{ moment(element.startTime).format('YYYY-MM-DD HH:MM') }}
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <mat-paginator
    fxFlexAlign="stretch"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
</div>
