import { ScopeModel } from '../scope/scope.model';

export class NewUserBaseModel {
  email: string;
  firstname: string;
  lastname: string;
  level: string;
  companyPid: string;
  scopes: ScopeModel;
  language: string;
}
