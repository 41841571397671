enum StorageKeysEnum {
  IsFirstConnection = 'IsFirstConnection',
  SsoActivate = 'SsoActivate',
  SsoPreviousUrl = 'SsoPreviousUrl',
  SsoIdToken = 'SsoIdToken',
  SsoAccessToken = 'SsoAccessToken',
  SearchCriteria = 'SearchCriteria'
}

export default StorageKeysEnum;
