import { Component, OnInit } from '@angular/core';
import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ParameterTableDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/parameter-table-data-source.provider';
import { ParameterGraphParameterService } from 'libs/shared-module/src/lib/providers/parameter-graph-parameter.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-oil-table',
  templateUrl: './oil-table.component.html',
  styleUrls: ['./oil-table.component.scss'],
  providers: [ParameterGraphParameterService],

  host: {
    class: 'height-100'
  }
})
export class OilTableComponent implements OnInit {
  equipment: EquipmentViewModel;
  tab = Tabs.OIL_ANALYSIS;

  constructor(
    private cockpitProvider: CockpitService,
    public dataSource: ParameterTableDataSourceService,
    public graphService: ParameterGraphParameterService
  ) {
    this.cockpitProvider.onEquipment().subscribe(equipment => {
      if (equipment && equipment.class && equipment.class.expertiseDomain) {
        this.equipment = equipment;
      }
    });
  }

  ngOnInit() {}
}
