<div fxLayout="column" fxLayoutAlign="center stretch" fxFill fxLayoutGap="20px">
  <h1 mat-dialog-title>{{ 'cockpit.report.modal_link.title' | translate }}</h1>
  <mat-progress-bar
    *ngIf="isFileuploded"
    color="primary"
    mode="indeterminate"
  ></mat-progress-bar>
  <p *ngIf="errorMessage" class="error-upload-message">
    {{ 'common.error_message.generic_error' | translate }}
  </p>
  <form
    class="modal-upload-link-form"
    [formGroup]="uploadForm"
    (ngSubmit)="sendLink()"
  >
    <div mat-dialog-content class="cantent">
      <mat-form-field class="form-field">
        <mat-label>{{
          'cockpit.report.modal_link.name_of_the_link' | translate
        }}</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
      <mat-form-field class="form-field">
        <mat-label>{{
          'cockpit.report.modal_link.choose' | translate
        }}</mat-label>
        <input matInput formControlName="url" type="url" />
      </mat-form-field>
    </div>
    <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
      <button mat-button type="reset" (click)="closeModal()">
        {{ 'common.cancel' | translate }}
      </button>
      <button
        mat-button
        type="submit"
        color="primary"
        cdkFocusInitial
        [disabled]="!uploadForm.valid"
      >
        {{ 'cockpit.report.modal_link.add_link' | translate }}
      </button>
    </div>
  </form>
</div>
