import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import LevelEnum from 'libs/shared-module/src/lib/models/common/level.enum';
import { NewUserBaseModel } from 'libs/shared-module/src/lib/models/user/new-user-base.model';
import { ClusterDataService } from 'libs/shared-module/src/lib/providers/dataServices/cluster-data-service.provider';
import { CountryDataService } from 'libs/shared-module/src/lib/providers/dataServices/country-data-service.provider';
import { PlantDataService } from 'libs/shared-module/src/lib/providers/dataServices/plant-data-service.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
var UserInformationPanelComponent = /** @class */ (function () {
    function UserInformationPanelComponent(scopeParameterService, clusterDataService, countryDataService, plantDataService, userService, snackbarService, translate) {
        this.scopeParameterService = scopeParameterService;
        this.clusterDataService = clusterDataService;
        this.countryDataService = countryDataService;
        this.plantDataService = plantDataService;
        this.userService = userService;
        this.snackbarService = snackbarService;
        this.translate = translate;
        this.togglePanelEvent = new EventEmitter();
        this.loading = false;
        this.permissions = Permissions;
        this.initialScope = {
            clusters: [],
            countries: [],
            expertiseDomains: [],
            plants: []
        };
        this.userScope = {
            clusters: [],
            countries: [],
            expertiseDomains: [],
            plants: []
        };
        this.userFavorites = {
            clusters: [],
            countries: [],
            expertiseDomains: [],
            plants: []
        };
    }
    UserInformationPanelComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.getData();
                if (this.user.scopes) {
                    this.getSelectedClusters();
                    this.getSelectedCountries();
                    this.getSelectedPlants();
                    this.getSelectedExpertiseDomains();
                }
                return [2 /*return*/];
            });
        });
    };
    UserInformationPanelComponent.prototype.getData = function () {
        var _this = this;
        var clustersSource = this.scopeParameterService.loadClusters();
        var countriesSource = this.scopeParameterService.loadCountries();
        var plantsSource = this.scopeParameterService.loadPlants();
        var expertiseDomainsSource = this.scopeParameterService.loadExpertiseDomains();
        forkJoin([
            clustersSource,
            countriesSource,
            plantsSource,
            expertiseDomainsSource
        ]).subscribe(function (results) {
            _this.initialScope.clusters = results[0];
            _this.initialScope.countries = results[1];
            _this.initialScope.plants = results[2];
            _this.initialScope.expertiseDomains = results[3];
            _this.userFavorites.clusters = _this.user.favorite.clusters
                ? _this.initialScope.clusters.filter(function (c) { return _this.user.favorite.clusters.indexOf(c.id) > -1; })
                : [];
            _this.userFavorites.countries = _this.user.favorite.countries
                ? _this.initialScope.countries.filter(function (c) { return _this.user.favorite.countries.indexOf(c.id) > -1; })
                : [];
            _this.userFavorites.expertiseDomains = _this.user.favorite.expertiseDomains
                ? _this.initialScope.expertiseDomains.filter(function (c) {
                    return _this.user.favorite.expertiseDomains.indexOf(c.id) > -1;
                })
                : [];
            _this.userFavorites.plants = _this.user.favorite.plants
                ? _this.initialScope.plants.filter(function (c) { return _this.user.favorite.plants.indexOf(c.id) > -1; })
                : [];
        });
    };
    UserInformationPanelComponent.prototype.setClusters = function (values) {
        this.user.scopes.clusters = values.map(function (value) { return value.id; });
    };
    UserInformationPanelComponent.prototype.setCountries = function (values) {
        this.user.scopes.countries = values.map(function (value) { return value.id; });
    };
    UserInformationPanelComponent.prototype.setPlants = function (values) {
        this.user.scopes.plants = values.map(function (value) { return value.id; });
    };
    UserInformationPanelComponent.prototype.setExpertiseDomain = function (values) {
        this.user.scopes.expertiseDomains = values.map(function (value) { return value.id; });
    };
    UserInformationPanelComponent.prototype.setFavoriteClusters = function (values) {
        this.user.favorite.clusters = values.map(function (value) { return value.id; });
    };
    UserInformationPanelComponent.prototype.getSelectedClusters = function () {
        var _this = this;
        if (this.user.scopes.clusters) {
            var clusters = this.user.scopes.clusters;
            clusters.forEach(function (clusterId) {
                _this.clusterDataService.getClusterById(clusterId).subscribe(function (data) {
                    _this.userScope.clusters.push(data);
                });
            });
            {
            }
        }
    };
    UserInformationPanelComponent.prototype.getSelectedCountries = function () {
        var _this = this;
        if (this.user.scopes.countries) {
            var countries = this.user.scopes.countries;
            countries.forEach(function (countryId) {
                _this.countryDataService.getCountryById(countryId).subscribe(function (data) {
                    _this.userScope.countries.push(data);
                });
            });
        }
    };
    UserInformationPanelComponent.prototype.getSelectedPlants = function () {
        var _this = this;
        if (this.user.scopes.plants) {
            var plants = this.user.scopes.plants;
            plants.forEach(function (plantId) {
                _this.plantDataService.getPlantById(plantId).subscribe(function (data) {
                    _this.userScope.plants.push(data);
                });
            });
        }
    };
    UserInformationPanelComponent.prototype.getSelectedExpertiseDomains = function () {
        var _this = this;
        if (this.user.scopes && this.user.scopes.expertiseDomains) {
            this.userScope.expertiseDomains = this.user.scopes.expertiseDomains.map(function (domain) {
                var scopeItem = {
                    id: domain,
                    name: _this.translate.instant("common." + domain),
                    displayName: _this.translate.instant("common." + domain)
                };
                return scopeItem;
            });
        }
    };
    UserInformationPanelComponent.prototype.updateUser = function () {
        var _this = this;
        this.loading = true;
        var request;
        if (this.user.isInternal) {
            request = this.userService.updateFavorites(this.user.id, {
                favorites: {
                    clusters: this.userFavorites.clusters.map(function (cluster) { return cluster.id; }),
                    countries: this.userFavorites.countries.map(function (item) { return item.id; }),
                    expertiseDomains: this.userFavorites.expertiseDomains.map(function (item) { return item.id; }),
                    plants: this.userFavorites.plants.map(function (item) { return item.id; }),
                    productionUnits: []
                }
            });
        }
        else {
            var updatedUser = new NewUserBaseModel();
            updatedUser.scopes = this.user.scopes;
            request = this.userService.updateScopes(this.user.id, updatedUser);
        }
        request
            .pipe(catchError(function (error) {
            _this.handleError(_this.translate.instant('dashboard.users.update_error'));
            return of(null);
        }))
            .subscribe(function (response) {
            if (response) {
                _this.snackbarService.open(_this.translate.instant('dashboard.users.updated'), _this.translate.instant('common.success'));
                _this.togglePanelEvent.emit();
            }
            else {
                _this.handleError(_this.translate.instant('dashboard.users.update_error'));
            }
            _this.loading = false;
        });
    };
    UserInformationPanelComponent.prototype.isUserInternal = function () {
        return (this.user.level === LevelEnum.INTERNAL ||
            this.user.level === LevelEnum.INTERNAL_KEY ||
            this.user.level === LevelEnum.ADMIN);
    };
    UserInformationPanelComponent.prototype.isUserExternal = function () {
        return (this.user.level === LevelEnum.EXTERNAL ||
            this.user.level === LevelEnum.EXTERNAL_KEY);
    };
    UserInformationPanelComponent.prototype.handleError = function (message) {
        this.snackbarService.open(message, null);
    };
    return UserInformationPanelComponent;
}());
export { UserInformationPanelComponent };
