<div
  class="report-quality-issue"
  fxLayout="column"
  fxLayoutAlign="space-around"
>
  <form [formGroup]="form" fxLayout="column" fxLayoutAlign="space-around">
    <div fxLayout="column" fxLayoutAlign="start">
      <span class="title">{{
        'cockpit.report_data_quality.title' | translate
      }}</span>
      <span class="subtitle">{{
        'cockpit.report_data_quality.subtitle' | translate
      }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start">
      <label fxFlex="30">{{
        'cockpit.report_data_quality.parameter_name' | translate
      }}</label>
      <mat-form-field appearance="outline" fxFlex [floatLabel]="'never'">
        <input
          type="text"
          matInput
          formControlName="parameterName"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option [value]="'param 1'">
            param 1
          </mat-option>
          <mat-option [value]="'param 2'">
            param 2
          </mat-option>
          <mat-option [value]="'param 3'">
            param 3
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start">
      <label fxFlex="30">{{
        'cockpit.report_data_quality.comment' | translate
      }}</label>
      <mat-form-field fxFlex appearance="outline" [floatLabel]="'never'">
        <textarea matInput formControlName="comment"></textarea
      ></mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxFlexAlign="end"
      fxLayouAlign="end center"
      fxLayoutGap="20px"
    >
      <button mat-button (click)="close()" class="action">
        {{ 'common.cancel' | translate }}
      </button>
      <button mat-flat-button color="primary" class="action">
        {{ 'common.send' | translate }}
      </button>
    </div>
  </form>
</div>
