import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger, MatPaginator, PageEvent } from '@angular/material';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import LevelEnum from 'libs/shared-module/src/lib/models/common/level.enum';
import { SearchResultModel } from 'libs/shared-module/src/lib/models/common/search-result.model';
import { CompanyBaseModel } from 'libs/shared-module/src/lib/models/company/company.base.model';
import { UserSearchFilter } from 'libs/shared-module/src/lib/models/user/user-search-filter.model';
import { CompanyService } from 'libs/shared-module/src/lib/providers/company.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
import { UserViewModel } from 'libs/shared-module/src/lib/viewmodels/user.viewmodel';

@Component({
  selector: 'ahc-user-information-list',
  templateUrl: './user-information-list.component.html',
  styleUrls: ['./user-information-list.component.scss']
})
export class UserInformationListComponent implements OnInit {
  @ViewChild(MatMenuTrigger, { static: false }) trigger: MatMenuTrigger;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  permissions = Permissions;

  users: SearchResultModel<UserViewModel[]> = { totalSize: 0, items: [] };
  filteredUsers: UserViewModel[] = [];

  companies: CompanyBaseModel[] = [];
  filtredCompanies: Observable<CompanyBaseModel[]>;
  companiesCtrl = new FormControl();
  selectedCompany: CompanyBaseModel;

  selectedValue = 'ALL';
  translateKey = 'all';

  searchCtrl = new FormControl();
  searchCriteria: UserSearchFilter = {
    page: 1,
    pageSize: 10,
    query: undefined,
    level: undefined
  };

  constructor(
    public usersService: UsersService,
    private companyService: CompanyService
  ) {}

  async ngOnInit() {
    this.usersService
      .onUsers()
      .subscribe((data: SearchResultModel<UserViewModel[]>) => {
        if (!data) {
          return;
        }
        this.users = data;
      });

    this.usersService.init(this.searchCriteria);

    this.filtredCompanies = this.companiesCtrl.valueChanges.pipe(
      startWith(''),
      map(name => (name ? this._filter(name) : this.companies.slice()))
    );
    this.companiesCtrl.valueChanges.subscribe((value: CompanyBaseModel) => {
      this.selectedCompany = value;
    });

    this.searchCtrl.valueChanges.subscribe(value => {
      this.usersService.query(value);
    });

    this.paginator.page.pipe().subscribe((value: PageEvent) => {
      this.usersService.goToPage(value.pageIndex + 1, value.pageSize);
    });

    this.companyService
      .loadCompanies()
      .subscribe(
        (companies: CompanyBaseModel[]) => (this.companies = companies)
      );

    this.usersService.init({
      page: 1,
      pageSize: 10,
      query: '',
      level: undefined
    });
  }

  closeDisplayMenu() {
    this.trigger.closeMenu();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  filterOptionSelected() {
    this.searchCtrl.setValue('');
    this.companiesCtrl.setValue('');
    this.doFilter();
  }

  companySelected(company: CompanyBaseModel) {
    this.selectedValue = company.name;
    this.translateKey = null;
    this.searchCtrl.setValue('');
    this.closeDisplayMenu();
    this.usersService.query(this.selectedValue);
  }

  doFilter() {
    let level: LevelEnum;
    if (this.selectedValue) {
      switch (this.selectedValue.toLocaleLowerCase()) {
        case '':
        case 'all': {
          level = undefined;
          break;
        }
        case 'admin users': {
          level = LevelEnum.ADMIN;
          break;
        }
        case 'internal users': {
          level = LevelEnum.INTERNAL;
          break;
        }
        case 'internal key users': {
          level = LevelEnum.INTERNAL_KEY;
          break;
        }
        case 'external key users': {
          level = LevelEnum.EXTERNAL_KEY;
          break;
        }
        case 'external users': {
          level = LevelEnum.EXTERNAL;
          break;
        }
        default: {
          level = undefined;
        }
      }
    }

    this.usersService.queryLevel(level);
  }

  displayFn(parameter?: CompanyBaseModel): string | undefined {
    return parameter ? parameter.name : undefined;
  }

  private _filter(name: string | CompanyBaseModel): CompanyBaseModel[] {
    let filterValue = '';

    if (typeof name === 'string') {
      filterValue = name;
    } else {
      filterValue = (name as CompanyBaseModel).name;
    }

    return this.companies
      .slice()
      .filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
  }
}
