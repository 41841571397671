<div
  class="equipment"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign="space-between center"
  fxLayoutGap="40px"
  (click)="goToCockpit()"
>
  <ahc-equipement-card
    [horizontalDirection]="true"
    class="card"
    [equipment]="equipment"
    fxFlex="40%"
  >
  </ahc-equipement-card>
  <div fxFlex="20%">
    <span class="label"
      >{{ 'dashboard.search.install_year' | translate }}&nbsp;&nbsp;</span
    >
    <span class="value">{{ buildInstallDate() }}</span>
  </div>
  <div fxFlex="30%">
    <span class="label"
      >{{ 'dashboard.search.manufacturer' | translate }}&nbsp;&nbsp;</span
    >
    <span class="value">{{ equipment.manufacturer }}</span>
  </div>
  <div
    class="options"
    fxLayout="column"
    fxLayoutAlign="space-evenly center"
    fxLayoutGap="10px"
  >
    <ahc-equipment-favorite [equipment]="equipment"></ahc-equipment-favorite>
    <ahc-equipment-main [equipment]="equipment"></ahc-equipment-main>
  </div>
</div>
