<div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
  <div
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-evenly center"
    fxLayoutGap="30px"
    fxFlexFill
  >
      <div
        class="login-welcome"
        fxLayout="column"
        fxFlex="100%"
        fxLayoutAlign="space-between start"
        fxLayoutGap="10px"
        fxFlexFill
      >
        <span class="info">{{ 'login.welcomeTo' | translate }}</span>
        <span>{{ 'common.airLiquide' | translate }}</span>
        <span class="site">{{ 'common.siteName' | translate }}</span>
        <span class="info">{{
          'login.errors.userNotAuthorized.welcome' | translate
        }}</span>
        <span class="info">{{
          'login.errors.userNotAuthorized.contact' | translate
        }}</span>

        <span class="signature"
          >{{ 'login.errors.userNotAuthorized.signature' | translate }} <br />{{
            'login.errors.userNotAuthorized.team' | translate
          }}</span
        >
      </div>
  </div>
</div>
