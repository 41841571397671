import * as tslib_1 from "tslib";
import { MaximoDataService } from '../dataServices/maximo-data-service.provider';
import { TableDataSource } from './table-data-source.provider';
var CorrectiveDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(CorrectiveDataSourceService, _super);
    /**
     *
     */
    function CorrectiveDataSourceService(maximoDataService) {
        var _this = _super.call(this) || this;
        _this.maximoDataService = maximoDataService;
        return _this;
    }
    CorrectiveDataSourceService.prototype.getData = function (sort, filter, query, page) {
        return this.maximoDataService.getCorrective(this.equipmentId, page, this.pageSize, sort, filter);
    };
    return CorrectiveDataSourceService;
}(TableDataSource));
export { CorrectiveDataSourceService };
