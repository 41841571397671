import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule,
  MatTooltipModule
} from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

import { ParameterUnitService } from 'libs/shared-module/src/lib/providers/parameter-unit.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { ThresholdService } from 'libs/shared-module/src/lib/providers/threshold.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';

import { UiCommonModule } from '../../common/uicommon.module';

import { AdminCreateModifyParameterComponent } from './admin-create-modify-parameter/admin-create-modify-parameter.component';
import { AdminComponent } from './admin.component';
// import { AlarmModalComponent } from './alarm-modal/alarm-modal.component';
// import { UpdateParamUnitComponent } from './update-param-unit/update-param-unit.component';

@NgModule({
  declarations: [
    AdminCreateModifyParameterComponent,
    AdminComponent,
    // AlarmModalComponent,
    // UpdateParamUnitComponent
  ],
  // entryComponents: [AlarmModalComponent, UpdateParamUnitComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatButtonModule,
    FormsModule,
    UiCommonModule,
    MatCardModule,
    MatProgressBarModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    MatDialogModule
  ],
  providers: [UsersService, ScopeParameterService, ParameterUnitService, ThresholdService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AdminModule {}
