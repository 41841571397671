import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';

import { ParameterTableModel } from '../models/cockpit/parameter-table.model';
import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import ExpertiseDomain from '../models/common/enums/expertise-domain.enum';
import SortEnum from '../models/common/enums/sort.enum';
import Tabs from '../models/common/enums/tabs.enum';
import { ParameterUnitModel } from '../models/common/parameter-unit.model';
import { ParameterModel } from '../models/common/parameter.model';
import { SearchResultModel } from '../models/common/search-result.model';
import { ParametersBulkImportModel } from '../models/import/parameters-bulk-import.model';

import { SortFilterDataServiceBase } from './dataServices/sort-filter-data-service.base.provider';
import { Logger } from './Logger.provider';

@Injectable()
export class ParameterService extends SortFilterDataServiceBase
  implements OnDestroy {
  /**
   *
   */
  constructor(private http: HttpClient, private logger: Logger) {
    super();
  }

  private ngUnsubscribe: Subject<void> = new Subject();

  private httpOptions = {
    headers: new HttpHeaders({
      'no-cache': 'true'
    })
  };

  getParameterByExpertiseDomain(
    domain: ExpertiseDomain,
    isShowcase?: boolean,
    page = 1,
    pageSize = 1000
  ): Observable<SearchResultModel<ParameterModel[]>> {
    let requestUrl = `${
      ApiEndpointsEnum.PARAMETERS
    }?expertise_domain=${domain}&page=${page}&page_size=${pageSize}`;
    if (isShowcase !== undefined) {
      requestUrl += `&is_showcase=${isShowcase}`;
    }
    return this.http.get<SearchResultModel<ParameterModel[]>>(requestUrl).pipe(
      catchError(error => {
        this.logger.error(error);
        return [];
      })
    );
  }

  getParameters(
    domain: ExpertiseDomain,
    page: number,
    pageSize: number,
    sort?: Map<string, SortEnum>,
    filter?: Map<string, string>
  ): Observable<SearchResultModel<ParameterModel[]>> {
    const sortString = sort ? this.buildSort(sort) : '';
    const filterString = filter ? this.buildFilter(filter) : '';
    return this.http
      .get<SearchResultModel<ParameterModel[]>>(
        `${
          ApiEndpointsEnum.PARAMETERS
        }?expertise_domain=${domain}&page=${page}&page_size=${pageSize}${sortString}${filterString}`,
        this.httpOptions
      )
      .pipe(
        catchError(error => {
          this.logger.error('getParameters', domain, error);
          return [];
        })
      );
  }

  getParameterById(
    pid: string,
    page: number,
    pageSize: number
  ): Observable<ParameterModel[]> {
    return of([]);
  }

  getParameterUnit(parameterId: string): Observable<ParameterUnitModel> {
    return this.http
      .get<ParameterUnitModel[]>(
        ApiEndpointsEnum.PARAMETER_UNITS.replace('{0}', parameterId)
      )
      .pipe(
        tap((list: ParameterUnitModel[]) => {
          return list.find(p => p.isDefault);
        }),
        catchError(error => of(undefined))
      );
  }

  getEquipmentParameters(
    equipmentId: string,
    start: Date,
    end: Date,
    view: Tabs,
    page: number,
    pageSize: number,
    filter?: Map<string, string>
  ): Observable<SearchResultModel<ParameterTableModel[]>> {
    const filterString = filter ? this.buildFilter(filter) : '';
    const url = ApiEndpointsEnum.PARAMETERS_VALUE_VIEW.replace(
      '{0}',
      equipmentId
    );
    return this.http
      .get<SearchResultModel<ParameterTableModel[]>>(
        `${url}/${view}?page=${page}&page_size=${pageSize}&start_date=${moment(
          start
        ).format('YYYY-MM-DD')}&end_date=${moment(end).format(
          'YYYY-MM-DD'
        )}${filterString}`,
        this.httpOptions
      )
      .pipe(
        takeUntil(this.ngUnsubscribe),
        map(result => {
          return result;
        }),
        catchError(error => {
          this.logger.error(error);
          return of(Object.assign({ items: [], totalSize: 0 }));
        })
      );
  }

  getParameterValues(
    equipementId: string,
    parameterId: string,
    start: Date,
    end: Date
  ): Observable<Map<string, string>> {
    return this.http
      .get<Map<string, string>>(
        `${ApiEndpointsEnum.PARAMETERS_VALUE.replace(
          '{0}',
          parameterId
        ).replace('{1}', equipementId)}?start_date=${moment(start).format(
          'YYYY-MM-DD'
        )}&end_date=${moment(end).format('YYYY-MM-DD')}`,
        this.httpOptions
      )
      .pipe(
        catchError(error => {
          this.logger.error(error);
          return of(new Map<string, string>());
        })
      );
  }

  addParameterData(
    parameter: ParameterModel,
    equipementId: string,
    values: Map<Date, any>
  ): Observable<Object> {
    return this.http.post(
      ApiEndpointsEnum.PARAMETERS_VALUE.replace('{0}', parameter.id).replace(
        '{1}',
        equipementId
      ),
      values
    );
  }

  batch(data: ParametersBulkImportModel) {
    return this.http.post(ApiEndpointsEnum.PARAMETERS_DATA, data);
  }

  delete(
    parameterId: string,
    equipmentId: string,
    dates: moment.Moment[]
  ): Observable<Object> {
    return this.http.delete(
      ApiEndpointsEnum.PARAMETERS_VALUE.replace('{0}', parameterId).replace(
        '{1}',
        equipmentId
      ) +
        `?dates=${dates
          .map(date => moment(date).format('YYYY-MM-DD'))
          .reduce((acc, curr) => acc + ',' + curr)}`
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
