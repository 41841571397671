import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { GraphParameterModel } from '../models/common/graph-parameter.model';
import { ScadaPoint } from '../models/common/scada-point.model';

import { ScadaDataService } from './dataServices/scada-data-service.provider';
import { BaseGraphParameterService } from './graph-parameter.base.provider';

@Injectable()
export class ScadaGraphParameterService extends BaseGraphParameterService {
  constructor(private scadaService: ScadaDataService) {
    super();
  }

  /*
  extends BaseGraphParameterService
    protected abstract getValues(
  */

  getValues(
    parameterId: string,
    parameter: string,
    ...args: any
  ): Observable<GraphParameterModel<Highcharts.Point>> {

    // Looking for args to be ["clusterName"] as args 
    // should be an array and "clusterName" is passed in from caller.
    //

    let clusterName = '';

    if (args && args.length > 0) {
      clusterName = '' + args[0];
    }

    return this.scadaService.getScadaParameterDetail(parameterId, 1, 100, 
      clusterName).pipe(
      map(points => {
        const result: GraphParameterModel<Highcharts.Point> = {
          name: parameter,
          values: points.map((point: ScadaPoint) => {
            const p: Highcharts.Point = Object.assign({
              x: Date.parse(point.timestamp.toString()),
              y: point.value
            });
            return p;
          })
        };
        return result;
      })
    );
  }

  getOilParmaValues(
    equipmentId: string,
    parameter: string
  ): Observable<GraphParameterModel<Highcharts.Point>> {

    const param: GraphParameterModel<Highcharts.Point> = {
      name: parameter,
      values: []
    };

    for (let i = 0; i < 8; i++) {
      param.values.push(
        Object.assign({
          x: new Date(2019, 1, 1, i).getTime(),
          y: Math.floor(Math.random() * 100),
          color: this.generateColor()
        })
      );
    }

    return of(param);
  }

  generateColor() {
    const color =
      'rgb(' +
      Math.floor(Math.random() * 255) +
      ',' +
      Math.floor(Math.random() * 255) +
      ',' +
      Math.floor(Math.random() * 255) +
      ')';
    return color;
  }

  delete(dates: moment.Moment[]) {}
}
