import { Injectable } from '@angular/core';

import StorageKeysEnum from '../models/common/enums/storage-keys.enum';

@Injectable()
export class StorageService {
  constructor() {}

  set(key: StorageKeysEnum, value: Object) {
    localStorage.setItem(`ahc:${key}`, JSON.stringify(value));
  }

  delete(key: StorageKeysEnum) {
    localStorage.removeItem(`ahc:${key}`);
  }

  get<T>(key: StorageKeysEnum): T {
    const value = localStorage.getItem(`ahc:${key}`);

    return JSON.parse(value);
  }
}
