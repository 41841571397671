import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { startWith } from 'rxjs/operators';

import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import StorageKeysEnum from 'libs/shared-module/src/lib/models/common/enums/storage-keys.enum';
import { ScopeItemModel } from 'libs/shared-module/src/lib/models/common/scope-item.model';
import { SearchEquipmenFilterEnum } from 'libs/shared-module/src/lib/models/common/search-equipment-filter.enum';
import { ClassStructureModel } from 'libs/shared-module/src/lib/models/equipment/class-structure.model';
import { SearchCriteria } from 'libs/shared-module/src/lib/models/equipment/search-criteria.model';
import { ClassStructureService } from 'libs/shared-module/src/lib/providers/class-structure.provider';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SearchEquipmentService } from 'libs/shared-module/src/lib/providers/search-equipment.provider';
import { StorageService } from 'libs/shared-module/src/lib/providers/storage.provider';

@Component({
  selector: 'ahc-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss']
})
export class AdvancedSearchComponent implements OnInit {
  @Input() isVisibleSearch: boolean;
  @Output() formChangeEvent: EventEmitter<any> = new EventEmitter();
  form: FormGroup;
  clusterCtrl = new FormControl();
  countryCtrl = new FormControl();
  plantCtrl = new FormControl();
  unitCtrl = new FormControl();
  expertiseDomainCtrl = new FormControl();
  equipmentClassCtrl = new FormControl();

  optionsClusters: ScopeItemModel[] = [];
  optionsCountries: ScopeItemModel[] = [];
  optionsPlants: ScopeItemModel[] = [];
  optionsUnits: ScopeItemModel[] = [];
  optionExpertiseDomains: ScopeItemModel[] = [];
  optionClassStructure: ScopeItemModel[] = [];
  userExpertiseDomains: ExpertiseDomain[];
  other: ScopeItemModel = { id: 'NA', name: 'N/A' };

  criteria: SearchCriteria;

  filteredClusterOptions: ScopeItemModel[] = [];
  filterCountriesOptions: ScopeItemModel[] = [];
  filterPlantsOptions: ScopeItemModel[] = [];
  filterUnitsOptions: ScopeItemModel[] = [];
  filterExpertiseDomainOptions: ScopeItemModel[] = [];
  filterClassStructureOptions: ScopeItemModel[] = [];
  constructor(
    private fb: FormBuilder,
    private scopeParameterService: ScopeParameterService,
    private searchService: SearchEquipmentService,
    private storageService: StorageService,
    private classStructureService: ClassStructureService,
    private connectedUserService: ConnectedUserService
  ) {
    connectedUserService.onUser().subscribe(user => {});
  }

  private AUTOCOPLETE_MINIMUM = 0;

  async ngOnInit() {
    this.criteria = this.searchService.getCriteria();
    this.connectedUserService.onUser().subscribe(user => {
      this.userExpertiseDomains = user.scopes.expertiseDomains;
      this.initForm();
    });
    this.initForm();
    this.isVisibleSearch = false;

    // this.scopeParameterService
    //   .loadExpertiseDomains()
    //   .subscribe(expertiseDomains => {
    //     this.optionExpertiseDomains = expertiseDomains;
    //     this.optionExpertiseDomains.push(this.other);
    //     this.expertiseDomainCtrl.setValue(
    //       this.optionExpertiseDomains.find(
    //         (c: ScopeItemModel) => c.id === this.criteria.expertiseDomainId
    //       ) || undefined
    //     );
    //   });
  }
  initForm() {
    this.form = this.fb.group({
      cluster: this.clusterCtrl,
      country: this.countryCtrl,
      plant: this.plantCtrl,
      unit: this.unitCtrl,
      expertiseDomain: this.expertiseDomainCtrl,
      classStructure: this.equipmentClassCtrl
    });

    forkJoin([
      this.scopeParameterService.loadClusters(),
      this.scopeParameterService.loadCountries(),
      this.scopeParameterService.loadPlants(),
      this.scopeParameterService.loadProductionUnits(),
      this.classStructureService.expertiseDomains(),
      this.scopeParameterService.loadExpertiseDomains()
    ]).subscribe(results => {
      this.optionsClusters = results[0];
      this.optionsCountries = results[1];
      this.optionsPlants = results[2];
      this.optionsUnits = results[3];
      this.optionClassStructure = results[4].map(
        (domain: ClassStructureModel) => {
          const item: ScopeItemModel = {
            id: domain.id,
            name: `${domain.classStructureId} ${domain.name}`
          };
          return item;
        }
      );

      this.optionExpertiseDomains = results[5];
      this.optionExpertiseDomains = this.optionExpertiseDomains.filter(
        domain =>
          this.userExpertiseDomains.length === 0 ||
          this.userExpertiseDomains.indexOf(domain.id as ExpertiseDomain) > -1
      );

      this.optionExpertiseDomains.push(this.other);
      this.expertiseDomainCtrl.setValue(
        this.optionExpertiseDomains.find(
          (c: ScopeItemModel) => c.id === this.criteria.expertiseDomainId
        ) || undefined
      );

      this.getCountryByClusterId(this.clusterCtrl.value);

      this.clusterCtrl.setValue(
        this.optionsClusters.find(
          (c: ScopeItemModel) => c.id === this.criteria.clusterId
        ) || undefined
      );
      this.countryCtrl.setValue(
        this.optionsCountries.find(
          (c: ScopeItemModel) => c.id === this.criteria.countryId
        ) || undefined
      );
      this.plantCtrl.setValue(
        this.optionsPlants.find(
          (c: ScopeItemModel) => c.id === this.criteria.plantId
        ) || undefined
      );
      this.unitCtrl.setValue(
        this.optionsUnits.find(
          (c: ScopeItemModel) => c.id === this.criteria.unitId
        ) || undefined
      );

      this.equipmentClassCtrl.setValue(
        this.optionClassStructure.find(
          (c: ScopeItemModel) => c.id === this.criteria.classId
        )
      );

      this.clusterCtrl.valueChanges
        .pipe(startWith(''))
        .subscribe((cluster: string) => {
          this.filteredClusterOptions = this.filter(
            this.optionsClusters,
            cluster
          );
        });
      this.countryCtrl.valueChanges
        .pipe(startWith(''))
        .subscribe((country: string) => {
          this.filterCountriesOptions = this.filter(
            this.optionsCountries,
            country
          );
        });
      this.plantCtrl.valueChanges
        .pipe(startWith(''))
        .subscribe((item: string) => {
          this.filterPlantsOptions = this.filter(this.optionsPlants, item);
        });
      this.unitCtrl.valueChanges
        .pipe(startWith(''))
        .subscribe((item: string) => {
          this.filterUnitsOptions = this.filter(this.filterUnitsOptions, item);
        });
      this.expertiseDomainCtrl.valueChanges
        .pipe(startWith(''))
        .subscribe((item: string) => {
          this.filterExpertiseDomainOptions = this.filter(
            this.optionExpertiseDomains,
            item
          );
        });
      this.equipmentClassCtrl.valueChanges
        .pipe(startWith(''))
        .subscribe((item: string) => {
          this.filterClassStructureOptions = this.filter(
            this.optionClassStructure,
            item
          );
        });

      // filter countries by pre-selected cluster
      this.updateFiltredOptions();
    });
  }

  updateFiltredOptions() {
    this.filterCountriesOptions = this.optionsCountries.filter(
      (country: ScopeItemModel) =>
        (this.clusterCtrl.value &&
          this.clusterCtrl.value.id === country.clusterPid) ||
        !this.clusterCtrl.value
    );
    // pre-select plants
    this.filterPlantsOptions = this.optionsPlants
      .filter(
        (plant: ScopeItemModel) =>
          (this.clusterCtrl.value &&
            this.clusterCtrl.value.id === plant.clusterPid) ||
          !this.clusterCtrl.value
      )
      .filter(
        (plant: ScopeItemModel) =>
          (this.countryCtrl.value &&
            this.countryCtrl.value.id === plant.countryPid) ||
          !this.countryCtrl.value
      );
    // pre-select units
    this.filterUnitsOptions = this.optionsUnits
      .filter(
        (unit: ScopeItemModel) =>
          (this.clusterCtrl.value &&
            this.clusterCtrl.value.id === unit.clusterPid) ||
          !this.clusterCtrl.value
      )
      .filter(
        (unit: ScopeItemModel) =>
          (this.countryCtrl.value &&
            this.countryCtrl.value.id === unit.countryPid) ||
          !this.countryCtrl.value
      )
      .filter(
        (unit: ScopeItemModel) =>
          (this.plantCtrl.value && this.plantCtrl.value.id === unit.plantPid) ||
          !this.plantCtrl.value
      );

    this.filterClassStructureOptions = this.optionClassStructure.filter(
      (classStructure: ScopeItemModel) =>
        (this.equipmentClassCtrl.value &&
          this.equipmentClassCtrl.value.id === classStructure.id) ||
        !this.equipmentClassCtrl.value
    );
  }

  openAdvancedSearch() {
    this.isVisibleSearch = true;
  }
  resetAll() {
    this.criteria = Object.assign({
      page: 1,
      pageSize: this.criteria.pageSize,
      filter: SearchEquipmenFilterEnum.MAIN
    });

    this.searchService.search(this.criteria);
    this.formChangeEvent.emit(false);
  }

  displayWith(value: any): string | undefined {
    return value ? value.name : undefined;
  }

  search() {
    this.formChangeEvent.emit(false);
    this.criteria.page = 1;
    this.criteria.clusterId = this.form.value.cluster
      ? this.form.value.cluster.id
      : undefined;
    this.criteria.countryId = this.form.value.country
      ? this.form.value.country.id
      : undefined;
    this.criteria.plantId = this.form.value.plant
      ? this.form.value.plant.id
      : undefined;
    this.criteria.unitId = this.form.value.unit
      ? this.form.value.unit.id
      : undefined;
    this.criteria.expertiseDomainId = this.form.value.expertiseDomain
      ? this.form.value.expertiseDomain.id
      : undefined;
    this.criteria.classId = this.form.value.classStructure
      ? this.form.value.classStructure.id
      : undefined;
    this.searchService.search(this.criteria);
    this.storageService.set(StorageKeysEnum.SearchCriteria, this.criteria);
  }

  formUpdated() {
    this.filterCountriesOptions = this.filter(
      this.optionsCountries,
      this.countryCtrl.value
    );
    this.filterPlantsOptions = this.filter(
      this.optionsPlants,
      this.plantCtrl.value
    );
    this.filterUnitsOptions = this.filter(
      this.optionsUnits,
      this.unitCtrl.value
    );
    this.filterExpertiseDomainOptions = this.filter(
      this.optionExpertiseDomains,
      this.expertiseDomainCtrl.value
    );

    this.filterClassStructureOptions = this.filter(
      this.optionClassStructure,
      this.equipmentClassCtrl.value
    );
  }

  getCountryByClusterId(cluster: ScopeItemModel) {
    let result: Observable<ScopeItemModel[]>;
    if (!cluster) {
      result = this.scopeParameterService.loadCountries();
    } else {
      result = this.scopeParameterService.getCountryByClusterId(cluster.id);
    }
    result.subscribe(data => {
      this.optionsCountries = data;
      this.countryCtrl.setValue(undefined);
      this.getPlantsByCountryId(undefined);
    });
  }

  getPlantsByCountryId(country: ScopeItemModel) {
    let result: Observable<ScopeItemModel[]>;
    if (!country) {
      result = this.scopeParameterService.loadPlants();
    } else {
      result = this.scopeParameterService.getPlantsByCountryId(country.id);
    }
    result.subscribe(data => {
      this.optionsPlants = !country
        ? data.filter((d: ScopeItemModel) => {
            if (this.clusterCtrl.value) {
              return d.clusterPid === this.clusterCtrl.value.id;
            }
            return true;
          })
        : data;
      this.plantCtrl.setValue(undefined);
      this.getUnitsByPlantId(undefined);
    });
  }

  getUnitsByPlantId(plant: ScopeItemModel) {
    let result: Observable<ScopeItemModel[]>;
    if (!plant) {
      result = this.scopeParameterService.loadProductionUnits();
    } else {
      result = this.scopeParameterService.getUnitsByPlantId(plant.id);
    }
    result.subscribe(data => {
      this.optionsUnits = !plant
        ? data.filter((d: ScopeItemModel) => {
            if (plant) {
              return d.plantPid === plant.id;
            } else if (this.countryCtrl.value) {
              return d.countryPid === this.countryCtrl.value.id;
            } else if (this.clusterCtrl.value) {
              return d.clusterPid === this.clusterCtrl.value.id;
            }
            return true;
          })
        : data;
      this.unitCtrl.setValue(undefined);
      this.updateFiltredOptions();
    });
  }

  filter(collection: ScopeItemModel[], query: any) {
    if (!query) {
      return collection;
    }
    if (typeof query === 'string' && query.length >= this.AUTOCOPLETE_MINIMUM) {
      return collection.filter(
        option =>
          option.name.toLowerCase().indexOf(query.toLocaleLowerCase()) > -1
      );
    }
    const item = query as ScopeItemModel;
    return collection.filter(
      (option: ScopeItemModel) =>
        option.name.toLowerCase().indexOf(item.name.toLocaleLowerCase()) > -1
    );
  }
}
