import { Component, Input, OnInit } from '@angular/core';
import { ParameterTableModel } from 'libs/shared-module/src/lib/models/cockpit/parameter-table.model';
import DisplayZone from 'libs/shared-module/src/lib/models/common/enums/display-zone.enum';
import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { EquipmentParameterModel } from 'libs/shared-module/src/lib/models/equipment/equipment.parameter.model';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ParameterService } from 'libs/shared-module/src/lib/providers/parameter.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';
import * as _ from 'lodash';
import * as moment from 'moment';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ahc-parameters-preview',
  templateUrl: './parameters-preview.component.html',
  styleUrls: ['./parameters-preview.component.scss'],
  providers: [ParameterService]
})
export class ParametersPreviewComponent implements OnInit {
  pendingCalls: number[] = [];
  @Input()
  equipment: EquipmentViewModel;
  @Input()
  activeTab: Tabs;

  value: string;

  parameters: EquipmentParameterModel[] = [];
  constructor(
    private cockpitProvider: CockpitService,
    private parameterService: ParameterService
  ) {}

  ngOnInit() {
    this.pendingCalls.push(0);
    this.cockpitProvider
      .onEquipment()
      .pipe(tap(() => this.pendingCalls.push(0)))
      .subscribe((equipment: EquipmentViewModel) => {
        this.equipment = equipment;
        this.initParameters();
        this.pendingCalls.pop();
      });
    this.pendingCalls.pop();
  }
  extractValueFromParameter(item: ParameterTableModel): string {
    try {
      const value = _.last(
        item.timeserie.sort((a, b) => (a.date < b.date ? -1 : 1))
      ).value.toString();

      // Date.parse is accepting number values and converting
      // those fields to dates. Example: 5 -> 01 May 2001
      //

      if (!isNaN(Number(value))) {
        return value;
      }

      if (!isNaN(Date.parse(value))) {

        // Filter out strings containing Unicode escape
        // sequences as a workaround.
        //

        let regex = /[^\u0000-\u00ff]+/;

        if (regex.test(value)) {
          return value;
        }

        // Filter out strings that don't begin with yyyy-MM-dd.
        //

        regex = /^\d{4}-([0][1-9]|1[0-2])-([0-2][1-9]|[1-3]0|3[01]).*/;

        if (!regex.test(value)) {
          return value;
        }

        return moment(value).format('DD MMM YYYY');
      }

      return value;
    } catch (error) {
      return null;
    }
  }
  extractUnitFromParameter(item: ParameterTableModel): string {
    try {
      const value = _.last(
        item.timeserie.sort((a, b) => (a.date < b.date ? -1 : 1))
      ).value.toString();
      if (!isNaN(Date.parse(value))) {
        return '';
      }
      return item.unit;
    } catch (error) {
      return null;
    }
  }

  private initParameters() {
    if (
      !this.equipment ||
      !this.equipment.class ||
      !this.equipment.class.expertiseDomain
    ) {
      return;
    }
    this.parameterService
      .getEquipmentParameters(
        this.equipment.pid,
        new Date(2000, 1, 1),
        moment().toDate(),
        this.activeTab,
        1,
        1000,
        new Map([['display_zone', DisplayZone.NON_TRENDABLE]])
      )
      .subscribe(data => {
        this.parameters = data.items.map(item => {
          const parameter: EquipmentParameterModel = {
            label: item.name,
            unit: this.extractUnitFromParameter(item),
            value:
              item.timeserie.length > 0
                ? this.extractValueFromParameter(item)
                : null
          };
          return parameter;
        });
      });
  }
}
