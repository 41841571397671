enum ApiEndpointsEnum {
  ACCOUNT = '/api/v1/accounts',
  COMPANY = '/api/v1/companies',
  COUNTRY = '/api/v1/countries',
  CLUSTER = '/api/v1/clusters',
  CORRECTIVE = 'api/v1/equipments/{0}/wo/corrective',
  CATCHES = 'api/v1/equipments/{0}/predictcatches',
  EQUIPMENTS = '/api/v1/equipments',
  EQUIPMENTS_FAVORITE = '/api/v1/equipments/status/favorite',
  EQUIPMENTS_REPORTS_ADD = '/api/v1/equipments/{0}/reports',
  EQUIPMENTS_REPORTS_LINK_ADD = '/api/v1/equipments/{0}/urls',
  EQUIPMENTS_REPORTS_LINK_ID = '/api/v1/equipments/{0}/urls/{1}',
  EQUIPMENTS_REPORTS_ID = '/api/v1/equipments/{0}/reports/{1}',
  EQUIPMENTS_EXPORT = '/api/v1/equipments/{0}/export',
  EXPERTISE_CLASS = 'api/v1/equipmentclass',
  LIRE = 'api/v1/equipments/{0}/lire',
  LOG = 'api/v1/log',
  PLANT = '/api/v1/plants',
  PRODUCTION_UNIT = '/api/v1/productionunits',
  PREDICTIVE = 'api/v1/equipments/{0}/wo/predictive',
  PARAMETERS = 'api/v1/parameters',
  PARAMETERS_DATA = 'api/v1/parameters/data',
  PARAMETERS_VALUE = 'api/v1/parameters/{0}/equipments/{1}/data',
  PARAMETERS_VALUE_VIEW = 'api/v1/equipments/{0}/parameters',
  PARAMETER_UNITS = 'api/v1/parameters/{0}/parameter-units',
  THRESHOLDS = 'api/v1/thresholds',
  SCADA = 'api/v1/equipments/{0}/scada',
  SCADA_HISTORY = 'api/v1/equipments/scada?web_id={0}',
  USER_PREFERED_UNIT = 'api/v1/self/preferred-units',
  USER = '/api/v1/users'
}

export default ApiEndpointsEnum;
