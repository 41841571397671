import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ScadaDataService } from 'libs/shared-module/src/lib/providers/dataServices/scada-data-service.provider';
import { ScadaDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/scada-data-source.provider';
import { ScadaGraphParameterService } from 'libs/shared-module/src/lib/providers/scada-graph-parameter.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-scada',
  templateUrl: './scada.component.html',
  styleUrls: ['./scada.component.scss'],
  providers: [ScadaDataSourceService, ScadaDataService]
})
export class ScadaComponent implements OnInit {
  public equipment: EquipmentViewModel;
  activeTab = Tabs.SCADA;
  public isLoading = false;
  constructor(
    private cdRef: ChangeDetectorRef,
    public graphService: ScadaGraphParameterService,
    private cockpitProvider: CockpitService,
    public dataSource: ScadaDataSourceService
  ) {
    this.cockpitProvider.onEquipment().subscribe(equipment => {
      this.equipment = equipment;
    });
  }

  ngOnInit() {
    this.dataSource.loading$.subscribe((done: boolean) => {
      if (this.isLoading !== done) {
        this.isLoading = done;
        this.cdRef.detectChanges();
      }
    });
  }
}
