import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { Guid } from 'guid-typescript';
import * as Highcharts from 'highcharts';
import * as _ from 'lodash';

import { ParameterTableModel } from 'libs/shared-module/src/lib/models/cockpit/parameter-table.model';
import { GraphParameterModel } from 'libs/shared-module/src/lib/models/common/graph-parameter.model';
import { BehaviorSubject } from 'rxjs';

import { CellStatus } from '../../dashboard/import/models';

@Component({
  selector: 'ahc-parameter-preview-graph',
  templateUrl: './parameter-preview-graph.component.html',
  styleUrls: ['./parameter-preview-graph.component.scss']
})
export class ParameterPreviewGraphComponent
  implements OnInit, AfterViewInit, OnChanges {
  graphData: GraphParameterModel<number>;

  @Input()
  data: ParameterTableModel;

  @Input()
  labelVisibility = true;

  @Input()
  unit = '';

  lastValueStatus: CellStatus;

  containerId: Guid;

  public options: Highcharts.Options = {};

  constructor() {
    this.containerId = Guid.create();
    this.graphRender$ = new BehaviorSubject(false);
  }

  private graphRender$: BehaviorSubject<boolean>;

  ngOnInit() {
    this.renderGraph();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.renderGraph();
  }

  lastPointStatusColor(): string {
    switch (this.lastValueStatus) {
      case CellStatus.valid: {
        return '#41b572';
      }
      case CellStatus.warning: {
        return 'orange';
      }
      case CellStatus.error: {
        return 'red';
      }
      case CellStatus.none: {
        return 'grey';
      }
    }
  }

  initLastPointStatus() {
    const point = _.last(this.data.timeserie);
    try {
      if (point) {
        if (
          point.value <= parseFloat(this.data.threshold.veryLow) ||
          point.value >= parseFloat(this.data.threshold.veryHigh)
        ) {
          this.lastValueStatus = CellStatus.error;
        } else if (
          point.value <= parseFloat(this.data.threshold.low) ||
          point.value >= parseFloat(this.data.threshold.high)
        ) {
          this.lastValueStatus = CellStatus.warning;
        } else {
          this.lastValueStatus = CellStatus.valid;
        }
      }
    } catch (error) {
      this.lastValueStatus = CellStatus.none;
    }
  }

  ngAfterViewInit() {
    this.graphRender$.subscribe((render: boolean) => {
      if (render) {
        Highcharts.chart(this.containerId.toString(), this.options);
      }
    });
  }

  lastPoint(): string {
    if (this.data.timeserie.length > 0) {
      const point = this.data.timeserie[this.data.timeserie.length - 1];
      if (point.error) {
        return point.error;
      } else {
        return point.value !== null &&
          point.value !== undefined && 
          typeof point.value === 'number' 
          ? point.value.toFixed(2) 
          : '';
      }
    }
    return '';
  }

  private renderGraph() {
    this.transform();
    this.initLastPointStatus();

    if (this.data.timeserie.length < 2) {
      return;
    }

    this.options = {
      chart: {
        type: 'line',
        backgroundColor: 'transparent',
        height: 50,
        animation: false,
        width: 100
      },
      title: {
        text: null
      },
      credits: {
        enabled: false
      },
      xAxis: {
        visible: false
      },
      yAxis: {
        visible: false,
        labels: {
          format: '{value:.2f}'
        }
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: false
      },
      series: [
        {
          animation: false,
          states: {
            hover: {
              enabled: false
            }
          },
          type: 'line',
          data: [
            ...this.graphData.values.slice(0, this.graphData.values.length - 1),
            {
              y: this.graphData.values[this.graphData.values.length - 1],
              marker: {
                lineColor: this.lastPointStatusColor(),
                fillColor: this.lastPointStatusColor()
              }
            }
          ],
          color: '#E3E3E3',
          lineWidth: 1,
          marker: {
            radius: 2.5,
            fillColor: 'white',
            lineColor: '#8D8F9A',
            lineWidth: 1
          }
        }
      ]
    };
    this.graphRender$.next(true);
  }

  private transform() {
    const values: GraphParameterModel<number> = {
      name: this.data.name,
      values: !this.data.timeserie
        ? []
        : this.data.timeserie
            .sort((a, b) => (a.date < b.date ? -1 : 1))
            .map(p => {
              return p.value !== null &&
                p.value !== undefined &&
                typeof p.value === 'number'
                ? parseFloat(p.value.toFixed(2))
                : null;
            })
    };
    this.graphData = values;
  }
}
