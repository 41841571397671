import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import AuthStatus from 'libs/shared-module/src/lib/models/auth/auth.status.enum';
import { AuthService } from 'libs/shared-module/src/lib/providers/auth.provider';
import { Logger } from 'libs/shared-module/src/lib/providers/Logger.provider';
var ExternalLoginComponent = /** @class */ (function () {
    function ExternalLoginComponent(logger, authService, fb, router) {
        this.logger = logger;
        this.authService = authService;
        this.router = router;
        this.authHasError = false;
        this.authFormGroup = fb.group({
            username: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required])
        });
    }
    ExternalLoginComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(ExternalLoginComponent.prototype, "username", {
        get: function () {
            return this.authFormGroup.get('username');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExternalLoginComponent.prototype, "password", {
        get: function () {
            return this.authFormGroup.get('password');
        },
        enumerable: true,
        configurable: true
    });
    ExternalLoginComponent.prototype.signIn = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.authHasError = false;
                if (this.isFormValid()) {
                    this.authService
                        .signIn(this.username.value, this.password.value)
                        .then(function (status) {
                        if (status === AuthStatus.SUCCESS) {
                            _this.router.navigate(['main']);
                        }
                        if (status === AuthStatus.NEW_PASSWORD_REQUIRED) {
                            _this.router.navigate(['login/set']);
                        }
                        else {
                            _this.authHasError = true;
                            _this.errorMessage = status;
                            _this.logger.error('User sign in failed', status);
                        }
                    })
                        .catch(function (err) {
                        _this.logger.error('User sign in request error', err);
                    });
                }
                return [2 /*return*/];
            });
        });
    };
    ExternalLoginComponent.prototype.isFormValid = function () {
        return (this.authFormGroup.controls['username'].valid &&
            this.authFormGroup.controls['username'].dirty &&
            this.authFormGroup.controls['password'].valid &&
            this.authFormGroup.controls['password'].dirty);
    };
    return ExternalLoginComponent;
}());
export { ExternalLoginComponent };
