import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ReportDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/report-data-source.provider';
import { ReportLinkDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/report-link-data-source.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

import { ModalUploadFileComponent } from './modal-upload-file/modal-upload-file.component';
import { ModalUploadLinkComponent } from './modal-upload-link/modal-upload-link.component';

@Component({
  selector: 'ahc-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  equipment: EquipmentViewModel;
  constructor(
    public dialog: MatDialog,
    public reportDataSource: ReportDataSourceService,
    public reportLinkDataSource: ReportLinkDataSourceService,
    private cockpitProvider: CockpitService
  ) {}

  ngOnInit() {
    this.cockpitProvider.onEquipment().subscribe(equipment => {
      if (equipment) {
        this.equipment = equipment;
      }
    });
  }

  openUploadFileModal() {
    const dialogRef = this.dialog.open(ModalUploadFileComponent, {
      width: '25rem',
      data: this.equipment
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reportDataSource.loadData();
      }
    });
  }

  openUploadLinkModal() {
    const dialogRef = this.dialog.open(ModalUploadLinkComponent, {
      width: '25rem',
      data: this.equipment
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.reportLinkDataSource.loadData();
      }
    });
  }
}
