import * as tslib_1 from "tslib";
import { MaximoDataService } from '../dataServices/maximo-data-service.provider';
import { TableDataSource } from './table-data-source.provider';
var PredictiveDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(PredictiveDataSourceService, _super);
    /**
     *
     */
    function PredictiveDataSourceService(maximoDataService) {
        var _this = _super.call(this) || this;
        _this.maximoDataService = maximoDataService;
        return _this;
    }
    PredictiveDataSourceService.prototype.getData = function (sort, filter, query, page) {
        return this.maximoDataService.getPredictive(this.equipmentId, page, this.pageSize, sort, filter);
    };
    return PredictiveDataSourceService;
}(TableDataSource));
export { PredictiveDataSourceService };
