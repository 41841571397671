import { Component, OnInit } from '@angular/core';
import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';

@Component({
  selector: 'ahc-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
  public permissions = Permissions;
  constructor(private connectedService: ConnectedUserService) {}

  ngOnInit() {}
  signOut() {
    this.connectedService.signOut();
  }
}
