import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { GaugeChartModule } from 'angular-gauge-chart';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { EquipementCardComponent } from './equipement-card/equipement-card.component';
import { EquipmentFavoriteComponent } from './equipment-favorite/equipment-favorite.component';
import { EquipmentIconComponent } from './equipment-icon/equipment-icon.component';
import { EquipmentMainComponent } from './equipment-main/equipment-main.component';
import { FeedbackItemComponent } from './feedback/feedback-item/feedback-item.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { NoDataComponent } from './no-data/no-data.component';
import { MenuGraphPointDeletionComponent } from './parameter-graph/menu-graph-point-deletion/menu-graph-point-deletion.component';
import { ParameterGraphComponent } from './parameter-graph/parameter-graph.component';
import { ParameterPreviewGraphComponent } from './parameter-preview-graph/parameter-preview-graph.component';
import { ParametersTableComponent } from './parameters-table/parameters-table.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { TableColumnFilterComponent } from './table-column-filter/table-column-filter.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    MatChipsModule,
    MatListModule,
    MatIconModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatMenuModule,
    FormsModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    NgxPermissionsModule,
    MatProgressBarModule,
    GaugeChartModule,
    TranslateModule
  ],
  declarations: [
    AutoCompleteComponent,
    EquipementCardComponent,
    EquipmentFavoriteComponent,
    NoDataComponent,
    ParameterPreviewGraphComponent,
    SideMenuComponent,
    TableColumnFilterComponent,
    FeedbackComponent,
    ParameterGraphComponent,
    ParametersTableComponent,
    FeedbackItemComponent,
    MenuGraphPointDeletionComponent,
    EquipmentMainComponent,
    EquipmentIconComponent
  ],
  exports: [
    AutoCompleteComponent,
    EquipementCardComponent,
    EquipmentFavoriteComponent,
    NoDataComponent,
    ParameterPreviewGraphComponent,
    SideMenuComponent,
    TableColumnFilterComponent,
    FeedbackComponent,
    ParameterGraphComponent,
    ParametersTableComponent,
    FeedbackItemComponent,
    MenuGraphPointDeletionComponent,
    EquipmentMainComponent
  ]
})
export class UiCommonModule {}
