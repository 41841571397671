import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CompanyBaseModel } from 'libs/shared-module/src/lib/models/company/company.base.model';
import { ScopeModel } from 'libs/shared-module/src/lib/models/scope/scope.model';
import { NewUserBaseModel } from 'libs/shared-module/src/lib/models/user/new-user-base.model';
import { CompanyService } from 'libs/shared-module/src/lib/providers/company.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
var CreateExternalUserComponent = /** @class */ (function () {
    function CreateExternalUserComponent(scopeParameterService, companyService, userService, snackbarService, translate, usersService, dialogRef, data) {
        this.scopeParameterService = scopeParameterService;
        this.companyService = companyService;
        this.userService = userService;
        this.snackbarService = snackbarService;
        this.translate = translate;
        this.usersService = usersService;
        this.dialogRef = dialogRef;
        this.data = data;
        this.newUser = new NewUserBaseModel();
        this.company = new CompanyBaseModel();
        this.clusters = [];
        this.countries = [];
        this.plants = [];
        this.productionUnits = [];
        this.expertiseDomains = [];
        this.companies = [];
        this.other = { id: 'other', name: 'other', isInternal: false };
        this.profileForm = new FormGroup({
            companyPid: new FormControl(this.newUser.companyPid, [Validators.required]),
            lastname: new FormControl(this.newUser.lastname, [Validators.required]),
            firstname: new FormControl(this.newUser.firstname, [Validators.required]),
            email: new FormControl(this.newUser.email),
            level: new FormControl(this.newUser.level, [Validators.required]),
            scopes: new FormControl((this.newUser.scopes = new ScopeModel())),
            language: new FormControl('fr')
        });
        this.companyForm = new FormGroup({
            name: new FormControl(this.company.name, [Validators.required]),
            isInternal: new FormControl(false)
        });
        dialogRef.disableClose = true;
        this.username = data;
    }
    CreateExternalUserComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.usersService
                    .onUsers()
                    .subscribe(function (data) {
                    if (!data) {
                        return;
                    }
                    _this.currentUser = data.items[0];
                    _this.getData(data.items[0]);
                });
                this.profileForm.controls['email'].setValue(this.username);
                this.scopeParameterService.loadExpertiseDomains().subscribe(function (data) {
                    _this.expertiseDomains = data;
                });
                return [2 /*return*/];
            });
        });
    };
    CreateExternalUserComponent.prototype.getData = function (user) {
        var _this = this;
        var clustersSource = this.scopeParameterService.loadClusters();
        var countriesSource = this.scopeParameterService.loadCountries();
        var plantsSource = this.scopeParameterService.loadPlants();
        var productionUnitsSource = this.scopeParameterService.loadProductionUnits();
        var expertiseDomainsSource = this.scopeParameterService.loadExpertiseDomains();
        if (user.level !== 'external_key') {
            this.companyService
                .loadCompanies()
                .subscribe(function (companies) {
                return (_this.companies = companies.concat([_this.other]));
            });
        }
        else {
            this.companyService
                .getCompanyByName(user.company.name)
                .subscribe(function (company) { return _this.companies.push(company); });
        }
        forkJoin([
            clustersSource,
            countriesSource,
            plantsSource,
            productionUnitsSource,
            expertiseDomainsSource
        ]).subscribe(function (results) {
            _this.clusters = results[0];
            _this.countries = results[1];
            _this.plants = results[2];
            _this.productionUnits = results[3];
            _this.expertiseDomains = results[4];
        });
    };
    CreateExternalUserComponent.prototype.setClusters = function (values) {
        this.newUser.scopes.clusters = values.map(function (value) { return value.id; });
    };
    CreateExternalUserComponent.prototype.setCountries = function (values) {
        this.newUser.scopes.countries = values.map(function (value) { return value.id; });
    };
    CreateExternalUserComponent.prototype.setPlants = function (values) {
        this.newUser.scopes.plants = values.map(function (value) { return value.id; });
    };
    CreateExternalUserComponent.prototype.setProductionUnits = function (values) {
        this.newUser.scopes.productionUnits = values.map(function (value) { return value.id; });
    };
    CreateExternalUserComponent.prototype.setExpertiseDomain = function (values) {
        this.newUser.scopes.expertiseDomains = values.map(function (value) { return value.id; });
    };
    CreateExternalUserComponent.prototype.createCompany = function () {
        var _this = this;
        this.company = this.companyForm.value;
        this.companyService
            .createCompany(this.company)
            .pipe(catchError(function (error) {
            _this.handleError(_this.translate.instant('dashboard.users.create.external.company.create_error'));
            return of(null);
        }))
            .subscribe(function (response) {
            if (response) {
                _this.snackbarService.open(_this.translate.instant('dashboard.users.create.external.company.created'), _this.translate.instant('common.success'));
                _this.company.id = response.id;
                _this.companies.push(_this.company);
                _this.profileForm.controls['companyPid'].setValue(_this.company.id);
            }
            else {
                _this.handleError(_this.translate.instant('dashboard.users.create.external.company.create_error'));
            }
        });
    };
    CreateExternalUserComponent.prototype.sendInvite = function () {
        var _this = this;
        if (this.profileForm.valid &&
            this.profileForm.controls['companyPid'].value !== 'other') {
            this.newUser.email = this.username;
            this.newUser = this.profileForm.value;
            this.userService
                .create(this.newUser)
                .pipe(catchError(function (error) {
                switch (error.status) {
                    case 409: {
                        _this.handleError(_this.translate.instant('dashboard.users.create_error_conflict'));
                        break;
                    }
                    case 400: {
                        _this.handleError(_this.translate.instant('dashboard.users.create_error_bad_request'));
                        break;
                    }
                    default: {
                        _this.handleError(_this.translate.instant('dashboard.users.create_error'));
                        break;
                    }
                }
                return of(null);
            }))
                .subscribe(function (response) {
                if (response) {
                    _this.snackbarService.open(_this.translate.instant('dashboard.users.created'), _this.translate.instant('common.success'));
                    _this.closeModal();
                }
                else {
                    _this.handleError(_this.translate.instant('dashboard.users.create_error'));
                }
            });
        }
        else {
            this.profileForm.markAsTouched();
            this.companyForm.markAllAsTouched();
        }
    };
    CreateExternalUserComponent.prototype.closeModal = function () {
        this.dialogRef.close();
    };
    CreateExternalUserComponent.prototype.handleError = function (message) {
        this.snackbarService.open(message, null);
    };
    return CreateExternalUserComponent;
}());
export { CreateExternalUserComponent };
