import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  template: '<div>{{ message }}</div>',
  selector: 'ahc-login-internal-user'
})
export class LoginInternalUserComponent implements OnInit {
  message = '';
  constructor(translate: TranslateService) {
    this.message = translate.instant('login.connecting');
  }

  ngOnInit() {
    this.connectInternal();
  }

  connectInternal() {
    window.location.href = environment.cognito.ssoUrl.replace(
      '[origin]',
      window.location.origin
    );
  }
}
