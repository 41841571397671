import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import StorageKeysEnum from '../models/common/enums/storage-keys.enum';

import { StorageService } from './storage.provider';

@Injectable()
export class WelcomeService {
  isFirstConnection = false;
  constructor(private storageService: StorageService, private router: Router) {
    this.updateFlag();
  }

  doWelcome() {
    this.updateFlag();
    if (this.isFirstConnection) {
      this.router.navigateByUrl('/main/welcome');
    }
  }

  done() {
    this.storageService.set(StorageKeysEnum.IsFirstConnection, false);
    this.isFirstConnection = false;
    this.router.navigate(['main']);
  }

  updateFlag() {
    this.isFirstConnection =
      this.storageService.get(StorageKeysEnum.IsFirstConnection) === null;
  }
}
