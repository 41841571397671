import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CatchesModel } from '../../models/cockpit/catches.model';
import { MaximoModel } from '../../models/cockpit/maximo.model';
import ApiEndpointsEnum from '../../models/common/enums/api_endpoints.enum';
import SortEnum from '../../models/common/enums/sort.enum';
import { TableDataSourceResponseModel } from '../../models/common/table-data-source-response.model';

import { SortFilterDataServiceBase } from './sort-filter-data-service.base.provider';

@Injectable()
export class MaximoDataService extends SortFilterDataServiceBase {
  constructor(private http: HttpClient) {
    super();
  }

  getPredictive(
    equipmentId: string,
    page: number,
    pageSize: number,
    sort: Map<string, SortEnum>,
    filter: Map<string, string>
  ): Observable<TableDataSourceResponseModel<MaximoModel>> {
    let requestUrl = `${ApiEndpointsEnum.PREDICTIVE.replace(
      '{0}',
      equipmentId
    )}?page=${page}&page_size=${pageSize}`;
    requestUrl += this.buildSort(sort);
    requestUrl += this.buildFilter(filter);
    return this.http.get<TableDataSourceResponseModel<MaximoModel>>(requestUrl);
  }

  getCorrective(
    equipmentId: string,
    page: number,
    pageSize: number,
    sort: Map<string, SortEnum>,
    filter: Map<string, string>
  ): Observable<TableDataSourceResponseModel<MaximoModel>> {
    let requestUrl = `${ApiEndpointsEnum.CORRECTIVE.replace(
      '{0}',
      equipmentId
    )}?page=${page}&page_size=${pageSize}`;
    requestUrl += this.buildSort(sort);
    requestUrl += this.buildFilter(filter);
    return this.http.get<TableDataSourceResponseModel<MaximoModel>>(requestUrl);
  }

  getCatches(
    equipmentId: string,
    page: number,
    pageSize: number,
    sort: Map<string, SortEnum>,
    filter: Map<string, string>
  ): Observable<TableDataSourceResponseModel<CatchesModel>> {
    let requestUrl = `${ApiEndpointsEnum.CATCHES.replace(
      '{0}',
      equipmentId
    )}?page=${page}&page_size=${pageSize}`;
    requestUrl += this.buildSort(sort);
    requestUrl += this.buildFilter(filter);
    return this.http.get<TableDataSourceResponseModel<CatchesModel>>(
      requestUrl
    );
  }
}
