<div class="card-id" fxLayout="row" fxLayoutAlign="start center">
  <div class="equipment-class" fxFlexAlign="center">
    <div class="status eq-status s-{{ feedback ? feedback.status : '0' }}">
      <ahc-equipment-icon
        [classId]="equipment.class.classStructureId"
      ></ahc-equipment-icon>
    </div>
    <div class="expertise-domain">
      <i
        title="{{ equipment.class.expertiseDomain }}"
        class="expertise-domain-icon ahc-fonts icon-domain-{{
          equipment.class.expertiseDomain
            ? equipment.class.expertiseDomain.toLocaleLowerCase()
            : ''
        }}"
      ></i>
    </div>
  </div>
  <div
    class="equipment-info"
    fxLayout="{{ horizontalDirection ? 'row' : 'column' }}"
    fxLayoutGap="{{ horizontalDirection ? '10px' : '5px' }}"
    fxLayoutAlign="space-between {{ horizontalDirection ? 'center' : 'start' }}"
  >
    <div fxLayout="column" fxLayoutAlign="space-around stretch" fxFlex="60">
      <span class="name" (click)="goToCockpit()">{{
        equipment.alProcessTag
      }}</span>
      <span class="maximo-id">
        <a
          target="_blank"
          href="{{ equipment.maximo.url }}"
          (click)="$event.stopPropagation()"
        >
          {{ equipment.maximo.numero }}
        </a>
      </span>
    </div>
    <span class="production-unit" fxFlex="40">{{
      equipment.productionUnit.name
    }}</span>
  </div>
</div>
