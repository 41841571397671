import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import { SearchResultModel } from 'libs/shared-module/src/lib/models/common/search-result.model';
import { CompanyBaseModel } from 'libs/shared-module/src/lib/models/company/company.base.model';
import { ScopeModel } from 'libs/shared-module/src/lib/models/scope/scope.model';
import { NewUserBaseModel } from 'libs/shared-module/src/lib/models/user/new-user-base.model';
import { CompanyService } from 'libs/shared-module/src/lib/providers/company.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
import { UserViewModel } from 'libs/shared-module/src/lib/viewmodels/user.viewmodel';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AutoCompleteValueItem } from '../../../common/auto-complete/auto-complete-value-item.model';

@Component({
  selector: 'ahc-create-external-user',
  templateUrl: './create-external-user.component.html',
  styleUrls: ['./create-external-user.component.scss']
})
export class CreateExternalUserComponent implements OnInit {
  errorMessage: string;
  username: string;
  newUser: NewUserBaseModel = new NewUserBaseModel();
  company: CompanyBaseModel = new CompanyBaseModel();
  clusters: AutoCompleteValueItem[] = [];
  countries: AutoCompleteValueItem[] = [];
  plants: AutoCompleteValueItem[] = [];
  productionUnits: AutoCompleteValueItem[] = [];
  expertiseDomains: AutoCompleteValueItem[] = [];
  companies: CompanyBaseModel[] = [];
  other: CompanyBaseModel = { id: 'other', name: 'other', isInternal: false };

  profileForm = new FormGroup({
    companyPid: new FormControl(this.newUser.companyPid, [Validators.required]),
    lastname: new FormControl(this.newUser.lastname, [Validators.required]),
    firstname: new FormControl(this.newUser.firstname, [Validators.required]),
    email: new FormControl(this.newUser.email),
    level: new FormControl(this.newUser.level, [Validators.required]),
    scopes: new FormControl((this.newUser.scopes = new ScopeModel())),
    language: new FormControl('fr')
  });
  companyForm = new FormGroup({
    name: new FormControl(this.company.name, [Validators.required]),
    isInternal: new FormControl(false)
  });
  currentUser: UserViewModel;

  constructor(
    private scopeParameterService: ScopeParameterService,
    private companyService: CompanyService,
    private userService: UsersService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    public usersService: UsersService,
    public dialogRef: MatDialogRef<CreateExternalUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    this.username = data;
  }

  async ngOnInit() {
    this.usersService
      .onUsers()
      .subscribe((data: SearchResultModel<UserViewModel[]>) => {
        if (!data) {
          return;
        }
        this.currentUser = data.items[0];
        this.getData(data.items[0]);
      });
    this.profileForm.controls['email'].setValue(this.username);
    this.scopeParameterService.loadExpertiseDomains().subscribe(data => {
      this.expertiseDomains = data;
    });
  }

  getData(user: UserViewModel) {
    const clustersSource = this.scopeParameterService.loadClusters();
    const countriesSource = this.scopeParameterService.loadCountries();
    const plantsSource = this.scopeParameterService.loadPlants();
    const productionUnitsSource = this.scopeParameterService.loadProductionUnits();
    const expertiseDomainsSource = this.scopeParameterService.loadExpertiseDomains();
    if (user.level !== 'external_key') {
      this.companyService
        .loadCompanies()
        .subscribe(
          (companies: CompanyBaseModel[]) =>
            (this.companies = [...companies, this.other])
        );
    } else {
      this.companyService
        .getCompanyByName(user.company.name)
        .subscribe((company: CompanyBaseModel) => this.companies.push(company));
    }

    forkJoin([
      clustersSource,
      countriesSource,
      plantsSource,
      productionUnitsSource,
      expertiseDomainsSource
    ]).subscribe(results => {
      this.clusters = results[0];
      this.countries = results[1];
      this.plants = results[2];
      this.productionUnits = results[3];
      this.expertiseDomains = results[4];
    });
  }
  setClusters(values: AutoCompleteValueItem[]) {
    this.newUser.scopes.clusters = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setCountries(values: AutoCompleteValueItem[]) {
    this.newUser.scopes.countries = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setPlants(values: AutoCompleteValueItem[]) {
    this.newUser.scopes.plants = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setProductionUnits(values: AutoCompleteValueItem[]) {
    this.newUser.scopes.productionUnits = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setExpertiseDomain(values: AutoCompleteValueItem[]) {
    this.newUser.scopes.expertiseDomains = values.map(
      (value: AutoCompleteValueItem) => value.id as ExpertiseDomain
    );
  }
  createCompany() {
    this.company = this.companyForm.value;
    this.companyService
      .createCompany(this.company)
      .pipe(
        catchError(error => {
          this.handleError(
            this.translate.instant(
              'dashboard.users.create.external.company.create_error'
            )
          );
          return of(null);
        })
      )
      .subscribe(response => {
        if (response) {
          this.snackbarService.open(
            this.translate.instant(
              'dashboard.users.create.external.company.created'
            ),
            this.translate.instant('common.success')
          );
          this.company.id = response.id;
          this.companies.push(this.company);
          this.profileForm.controls['companyPid'].setValue(this.company.id);
        } else {
          this.handleError(
            this.translate.instant(
              'dashboard.users.create.external.company.create_error'
            )
          );
        }
      });
  }
  sendInvite() {
    if (
      this.profileForm.valid &&
      this.profileForm.controls['companyPid'].value !== 'other'
    ) {
      this.newUser.email = this.username;
      this.newUser = this.profileForm.value;
      this.userService
        .create(this.newUser)
        .pipe(
          catchError(error => {
            switch (error.status) {
              case 409: {
                this.handleError(
                  this.translate.instant(
                    'dashboard.users.create_error_conflict'
                  )
                );
                break;
              }
              case 400: {
                this.handleError(
                  this.translate.instant(
                    'dashboard.users.create_error_bad_request'
                  )
                );
                break;
              }
              default: {
                this.handleError(
                  this.translate.instant('dashboard.users.create_error')
                );
                break;
              }
            }
            return of(null);
          })
        )
        .subscribe(response => {
          if (response) {
            this.snackbarService.open(
              this.translate.instant('dashboard.users.created'),
              this.translate.instant('common.success')
            );
            this.closeModal();
          } else {
            this.handleError(
              this.translate.instant('dashboard.users.create_error')
            );
          }
        });
    } else {
      this.profileForm.markAsTouched();
      this.companyForm.markAllAsTouched();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
  handleError(message: string) {
    this.snackbarService.open(message, null);
  }
}
