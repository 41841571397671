import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material';

import { ReportDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/report-data-source.provider';

import { ReportModel } from 'libs/shared-module/src/lib/models/cockpit/report.model';
import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import { TableDataSourceResponseModel } from 'libs/shared-module/src/lib/models/common/table-data-source-response.model';
import { UrlModel } from 'libs/shared-module/src/lib/models/common/url.model';
import { ReportService } from 'libs/shared-module/src/lib/providers/report.provider';

@Component({
  selector: 'ahc-table-upload',
  templateUrl: './table-upload.component.html',
  styleUrls: ['./table-upload.component.scss']
})
export class TableUploadComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  equipmentId: string;

  @Input()
  reportDataSource: ReportDataSourceService;

  displayedColumns: string[] = ['name', 'date', 'action'];
  data: ReportModel[] = [];
  errorMessage = false;

  permissions = Permissions;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(private dataReportService: ReportService) {}

  private retryTimer: NodeJS.Timer;

  ngOnInit() {}

  ngAfterViewInit(): void {
    let retryAttempt = 3;
    setTimeout(() => {
      this.reportDataSource.initialize(this.equipmentId, 5);
      this.reportDataSource.data$.subscribe(
        (result: TableDataSourceResponseModel<ReportModel>) => {
          this.data = result.items;
          this.paginator.length = result.totalSize;
          // if one or more file are still uploading, refresh the data to get a new status
          if (
            this.data.filter(item => !item.uploaded).length > 0 &&
            retryAttempt > 0
          ) {
            this.retryTimer = setTimeout(() => {
              retryAttempt--;
              this.reportDataSource.loadData();
            }, 2000);
          }
        }
      );
    });
    this.paginator.page.pipe().subscribe(() => {
      this.reportDataSource.page(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
      );
    });
  }

  downloadFile(equipmentId: string, reportId: string) {
    this.dataReportService
      .getPresignedUrlForDownload(equipmentId, reportId)
      .subscribe(
        (presignedUrl: UrlModel) => {
          this.errorMessage = false;
          const a = document.createElement('a');
          a.setAttribute('href', presignedUrl.url);
          a.click();
        },
        () => {
          this.errorMessage = true;
        }
      );
  }

  deleteFile(equipmentId: string, reportId: string) {
    this.dataReportService.deleteReportById(equipmentId, reportId).subscribe(
      () => {
        this.errorMessage = false;
        this.reportDataSource.page(1, this.reportDataSource.pageSize);
      },
      () => {
        this.errorMessage = true;
      }
    );
  }

  ngOnDestroy(): void {
    clearTimeout(this.retryTimer);
  }
}
