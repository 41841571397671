import { Routes } from '@angular/router';
var ɵ0 = function () {
    return import("./../../../../../libs/ahc-front-ui/src/lib/login/login.router.module.ngfactory").then(function (mod) { return mod.LoginRouterModuleNgFactory; });
}, ɵ1 = function () {
    return import("./../../../../../libs/ahc-front-ui/src/lib/dashboard/dashboard.router.module.ngfactory").then(function (mod) { return mod.DashboardRouterModuleNgFactory; });
}, ɵ2 = function () {
    return import("./../../../../../libs/ahc-front-ui/src/lib/cockpit/cockpit.routing.module.ngfactory").then(function (mod) { return mod.CockpitRoutingModuleNgFactory; });
};
var routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'login',
        loadChildren: ɵ0
    },
    {
        path: 'main',
        loadChildren: ɵ1
    },
    {
        path: 'cockpit',
        loadChildren: ɵ2
    },
    { path: '**', redirectTo: '/login' }
];
var AppRouterModule = /** @class */ (function () {
    function AppRouterModule() {
    }
    return AppRouterModule;
}());
export { AppRouterModule };
export { ɵ0, ɵ1, ɵ2 };
