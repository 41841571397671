import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { CatchesModel } from 'libs/shared-module/src/lib/models/cockpit/catches.model';
import { IRondeModel } from 'libs/shared-module/src/lib/models/cockpit/ironde.model';
import { LireModel } from 'libs/shared-module/src/lib/models/cockpit/lire.model';
import { MaximoModel } from 'libs/shared-module/src/lib/models/cockpit/maximo.model';
import { ParameterTableModel } from 'libs/shared-module/src/lib/models/cockpit/parameter-table.model';
import { ScadaModel } from 'libs/shared-module/src/lib/models/cockpit/scada.model';
import { TimelineModel } from 'libs/shared-module/src/lib/models/cockpit/timeline.model';
import DisplayZone from 'libs/shared-module/src/lib/models/common/enums/display-zone.enum';
import SortEnum from 'libs/shared-module/src/lib/models/common/enums/sort.enum';
import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { Point } from 'libs/shared-module/src/lib/models/common/point.model';
import { TableDataSourceResponseModel } from 'libs/shared-module/src/lib/models/common/table-data-source-response.model';
import { TableDataSource } from 'libs/shared-module/src/lib/providers/dataSource/table-data-source.provider';
import { BaseGraphParameterService } from 'libs/shared-module/src/lib/providers/graph-parameter.base.provider';
import { ScadaGraphParameterService } from 'libs/shared-module/src/lib/providers/scada-graph-parameter.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

import { CellStatus } from '../../dashboard/import/models';

@Component({
  selector: 'ahc-parameters-table',
  templateUrl: './parameters-table.component.html',
  styleUrls: ['./parameters-table.component.scss'],
  providers: [ScadaGraphParameterService],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ParametersTableComponent implements OnInit, AfterViewInit {
  data: ParameterTableModel[] = [];
  columnsToDisplay = ['parameter', 'value', 'timestamp', 'actions'];
  expandedElement: ScadaModel | null;
  @Input()
  pageSize = 10;
  showFilter: boolean[] = [];
  totalPages: number;

  @Input()
  dataSource: TableDataSource<
    | TimelineModel
    | MaximoModel
    | IRondeModel
    | CatchesModel
    | LireModel
    | ParameterTableModel
    | ScadaModel
  >;

  @Input()
  equipment: EquipmentViewModel;

  @Input()
  module: Tabs;

  @Input()
  graphService: BaseGraphParameterService;

  @Input()
  dateFormat = 'dd MMM yyyy';

  constructor() {}

  ngOnInit() {
    this.init();
  }

  init() {
    if (this.equipment) {
      this.dataSource.init(
        this.pageSize,
        this.equipment.pid,
        new Map(),
        new Map([['display_zone', DisplayZone.TREND_BOX]])
      );
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.dataSource.data$.subscribe(
        (result: TableDataSourceResponseModel<ParameterTableModel>) => {
          this.data = result.items;
        }
      );
    });

    this.graphService.deletedPoint$.subscribe(points => {
      if (points && points.name) {
        const parameterRow = this.data.find(p => p.name === points.name);
        const newParameterValues =
          points.values.length > 5
            ? points.values.slice(
                points.values.length - 5,
                points.values.length
              )
            : points.values;
        parameterRow.timeserie = newParameterValues.map(p => {
          const timeseriePoint: Point = {
            date: new Date(p.x),
            value: p.y
          };
          return timeseriePoint;
        });
      }
    });
  }

  filter(column: string, query: string) {
    if (query) {
      this.dataSource.addFilter(column, query);
    } else {
      this.dataSource.removeFilter(column);
    }
  }

  sort(column: string, direction: SortEnum) {
    if (direction === SortEnum.none) {
      this.dataSource.removeSort(column);
    } else {
      this.dataSource.addSort(column, direction);
    }
  }

  initGraphProvider(parameter: ParameterTableModel) {
    this.graphService.loadData(parameter.id, parameter.name, 
      this.equipment);
  }

  lastPointStatus(item: ParameterTableModel): CellStatus {
    const point = _.last(item.timeserie);
    try {
      if (point) {
        if (
          point.value <= parseFloat(item.threshold.veryLow) ||
          point.value >= parseFloat(item.threshold.veryHigh)
        ) {
          return CellStatus.error;
        } else if (
          point.value <= parseFloat(item.threshold.low) ||
          point.value >= parseFloat(item.threshold.high)
        ) {
          return CellStatus.warning;
        } else {
          return CellStatus.valid;
        }
      }
      return CellStatus.valid;
    } catch (error) {
      return CellStatus.none;
    }
  }
}
