<mat-progress-bar
  class="loading"
  *ngIf="loading"
  mode="indeterminate"
></mat-progress-bar>
<div
  class="information"
  fxLayout="row"
  fxLayoutGap="50px"
  fxLayoutAlign="center "
>
  <div *ngIf="!isUserInternal()" fxFlex>
    <span class="title">
      {{ 'dashboard.users.information.scope' | translate }}</span
    >
    <div class="scrollable">
      <div
        class="panel"
        fxLayout="column"
        fxLayoutGap="20px"
        fxLayoutAlign="start "
      >
        <ahc-auto-complete
          [placeHolder]="
            'dashboard.users.create.cluster_permission' | translate
          "
          [values]="initialScope.clusters"
          [selectedValues]="userScope.clusters"
          (valueChange)="setClusters($event)"
        ></ahc-auto-complete>
        <ahc-auto-complete
          [placeHolder]="
            'dashboard.users.create.country_permission' | translate
          "
          [values]="initialScope.countries"
          [selectedValues]="userScope.countries"
          (valueChange)="setCountries($event)"
        ></ahc-auto-complete>
        <ahc-auto-complete
          [placeHolder]="'dashboard.users.create.plant_permission' | translate"
          [values]="initialScope.plants"
          [selectedValues]="userScope.plants"
          (valueChange)="setPlants($event)"
        ></ahc-auto-complete>
        <ahc-auto-complete
          [placeHolder]="
            'dashboard.users.create.expertise_domain_permission' | translate
          "
          [values]="initialScope.expertiseDomains"
          [selectedValues]="userScope.expertiseDomains"
          (valueChange)="setExpertiseDomain($event)"
        ></ahc-auto-complete>
      </div>
    </div>
  </div>
  <div *ngIf="!isUserExternal()" fxFlex>
    <span class="title">
      {{ 'dashboard.users.information.favorite' | translate }}</span
    >
    <div class="scrollable">
      <div
        class="panel"
        fxLayout="column"
        fxLayoutGap="20px"
        fxLayoutAlign="space-around"
      >
        <ahc-auto-complete
          [placeHolder]="
            'dashboard.users.create.cluster_permission' | translate
          "
          [values]="initialScope.clusters"
          [selectedValues]="userFavorites.clusters"
          (valueChange)="setFavoriteClusters($event)"
        ></ahc-auto-complete>
        <ahc-auto-complete
          [placeHolder]="
            'dashboard.users.create.country_permission' | translate
          "
          [values]="initialScope.countries"
          [selectedValues]="userFavorites.countries"
        ></ahc-auto-complete>

        <ahc-auto-complete
          [placeHolder]="'dashboard.users.create.plant_permission' | translate"
          [values]="initialScope.plants"
          [selectedValues]="userFavorites.plants"
        ></ahc-auto-complete>

        <ahc-auto-complete
          [placeHolder]="
            'dashboard.users.create.expertise_domain_permission' | translate
          "
          [values]="initialScope.expertiseDomains"
          [selectedValues]="userFavorites.expertiseDomains"
        ></ahc-auto-complete>
      </div>
    </div>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="end">
  <button
    class="action-button"
    mat-flat-button
    color="primary"
    (click)="updateUser()"
  >
    {{ 'common.save' | translate }}
  </button>
</div>
