import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { FeedbackModel } from 'libs/shared-module/src/lib/models/feedback/feedback.model';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { FeedbackService } from 'libs/shared-module/src/lib/providers/feedback.provider';
import { UserViewModel } from 'libs/shared-module/src/lib/viewmodels/user.viewmodel';
import * as moment from 'moment';

@Component({
  selector: 'ahc-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {
  feedbacks: FeedbackModel[] = [];
  feedbackForm = new FormGroup({
    message: new FormControl('', Validators.required),
    status: new FormControl(true)
  });

  @Input()
  tab: Tabs;
  @ViewChild('formPanel', { static: false }) formPanel: MatExpansionPanel;
  @ViewChild('historyPanel', { static: false }) historyPanel: MatExpansionPanel;

  constructor(
    public feedbackService: FeedbackService,
    private userService: ConnectedUserService,
    private cockpitService: CockpitService
  ) {}

  private ngUnsubscribe: Subject<void> = new Subject();
  private user: UserViewModel;
  ngOnInit() {
    this.feedbackService
      .onFeedbacks()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((f: FeedbackModel[]) => {
        this.feedbacks = f;
      });

    this.cockpitService.onEquipment().subscribe(equipment => {
      if (equipment) {
        this.feedbackService.init(this.tab, equipment);
      }
    });

    this.userService.onUser().subscribe((u: UserViewModel) => (this.user = u));
  }

  toggleFeedbackForm() {
    this.historyPanel.close();
    this.formPanel.toggle();
    this.feedbackForm.reset();
  }

  toggleHistoryPanel() {
    this.formPanel.close();
    this.historyPanel.toggle();
  }

  saveFeedback() {
    if (this.feedbackForm.valid) {
      this.feedbackService.add({
        status: this.feedbackForm.get('status').value,
        comment: {
          author: `${this.user.firstname} ${this.user.lastname}`,
          comment: this.feedbackForm.get('message').value
        },
        date: moment()
      });
      this.toggleFeedbackForm();
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
