import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import * as moment from 'moment';

import { LireModel } from 'libs/shared-module/src/lib/models/cockpit/lire.model';
import SortEnum from 'libs/shared-module/src/lib/models/common/enums/sort.enum';
import { TableDataSourceResponseModel } from 'libs/shared-module/src/lib/models/common/table-data-source-response.model';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { LireDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/lire-data-source.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-lire',
  templateUrl: './lire.component.html',
  styleUrls: ['./lire.component.scss']
})
export class LireComponent implements OnInit, AfterViewInit {
  sortEnum = SortEnum;
  moment = moment;
  pageSize = 5;
  showFilter: Map<string, boolean> = new Map([['technicalEventId', true]]);
  totalPages: number;
  displayedColumns: string[] = [
    'number',
    'level',
    'title',
    'description',
    'consequence',
    'costs',
    'startDate'
  ];
  data: LireModel[] = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  constructor(
    public tableDataSource: LireDataSourceService,
    cockpitService: CockpitService
  ) {
    cockpitService.onEquipment().subscribe((equipment: EquipmentViewModel) => {
      this.equipment = equipment;
      this.tableDataSource.init(
        this.pageSize,
        equipment.pid,
        new Map([['technicalEventId', SortEnum.desc]])
      );
    });
  }
  private equipment: EquipmentViewModel;

  ngOnInit() {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableDataSource.data$.subscribe(
        (result: TableDataSourceResponseModel<LireModel>) => {
          this.data = result.items.map(item => {
            item.processTag = this.equipment.alProcessTag;
            return item;
          });
          this.paginator.length = result.totalSize;
        }
      );
    });
    this.paginator.page.pipe().subscribe(() => {
      this.tableDataSource.page(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
      );
    });
  }

  filter(column: string, query: string) {
    if (query) {
      this.tableDataSource.addFilter(column, query);
    } else {
      this.tableDataSource.removeFilter(column);
    }
  }

  sort(column: string, direction: SortEnum) {
    if (direction === SortEnum.none) {
      this.tableDataSource.removeSort(column);
    } else {
      this.tableDataSource.addSort(column, direction);
    }
  }
}
