import { Component, OnInit } from '@angular/core';
import { FeedbackService } from 'libs/shared-module/src/lib/providers/feedback.provider';

@Component({
  selector: 'ahc-vibration',
  templateUrl: './vibration.component.html',
  styleUrls: ['./vibration.component.scss'],
  providers: [FeedbackService]
})
export class VibrationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
