<div
  class="profile"
  fxLayout="column"
  fxLayoutAlign="stretch"
  fxFlexFill
  fxLayoutGap="40px"
>
  <div class="header" fxLayout="column" fxLayoutAlign="start center">
    <span class="title">{{ 'login.welcome.title' | translate }}</span>
    <span class="sub-title">{{ 'login.welcome.sub_title' | translate }}</span>
  </div>
  <div fxFlexAlign="center">
    <ahc-profile-information></ahc-profile-information>
  </div>
</div>
