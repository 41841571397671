import Auth from '@aws-amplify/auth';
import { AmplifyModules } from 'aws-amplify-angular';
var ɵ0 = function () {
    return AmplifyModules({
        Auth: Auth
    });
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
