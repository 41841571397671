<div
  class="global-info"
  fxLayout="row"
  fxLayoutAlign="space-between start"
  fxFlex
>
  <!-- MAINTENANCE -->
  <div
    class="section"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxFlex="33"
  >
    <span class="title">{{ 'cockpit.home.maintenance' | translate }}</span>
    <div
      class="values"
      fxLayout="column"
      fxLayoutAlign="space-around stretch"
      fxLayoutGap="10px"
    >
      <div fxLayout="row" fxLayoutAlign="start start">
        <span class="label" fxFlex="60"
          >{{ 'cockpit.home.running_hours_since_last_tar' | translate }}:</span
        >
        <span>
          {{
            'cockpit.home.running_hours_since_last_tar_value'
              | translate
                : {
                    value:
                      info && info.timeSinceLastTar
                        ? (info.timeSinceLastTar | number: '.2')
                        : '-'
                  }
          }}
        </span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start">
        <span class="label" fxFlex="60"
          >{{ 'cockpit.home.corrective_wo' | translate }}:</span
        >
        <span>
          {{
            'cockpit.home.corrective_wo_value'
              | translate
                : { value: info && info.correctiveWo ? info.correctiveWo : '-' }
          }}
        </span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start">
        <span class="label" fxFlex="60"
          >{{ 'cockpit.home.last_corrective_wo' | translate }}:</span
        >
        <span>
          {{ info ? (info.lastCorrectiveWoDate | date: 'mediumDate') : '-' }}
        </span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start start">
        <span class="label" fxFlex="60"
          >{{ 'cockpit.home.non_closed_wo' | translate }}:</span
        >
        <span> {{ info ? info.nonClosureWo : '-' }}</span>
      </div>
    </div>
  </div>
  <!-- TECHNICAL EVENTS -->
  <div
    class="section"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxFlex="33"
  >
    <span class="title">{{ 'cockpit.home.technical' | translate }}</span>
    <div
      class="values"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxLayoutGap="10px"
    >
      <div fxLayout="row" fxLayoutAlign="start">
        <span class="label" fxFlex="50"
          >{{ 'cockpit.home.l1_events_in_the_plant' | translate }}:</span
        >
        <span>
          {{
            'cockpit.home.lx_event_value'
              | translate: { value: info ? info.l1Events : '-' }
          }}</span
        >
      </div>
      <div fxLayout="row" fxLayoutAlign="start">
        <span class="label" fxFlex="50"
          >{{ 'cockpit.home.l2_events_in_the_plant' | translate }}:</span
        >
        <span>
          {{
            'cockpit.home.lx_event_value'
              | translate: { value: info ? info.l2Events : '-' }
          }}</span
        >
      </div>
      <div fxLayout="row" fxLayoutAlign="start">
        <span class="label" fxFlex="50"
          >{{ 'cockpit.home.l3_events_in_the_plant' | translate }}:</span
        >
        <span>
          {{
            'cockpit.home.lx_event_value'
              | translate: { value: info ? info.l3Events : '-' }
          }}
        </span>
      </div>
      <div fxLayout="row" fxLayoutAlign="start">
        <span class="label" fxFlex="50"
          >{{ 'cockpit.home.predict_catches' | translate }}:</span
        >
        <span>
          {{
            'cockpit.home.predict_catches_value'
              | translate
                : {
                    value:
                      info && info.predictCatches ? info.predictCatches : '-'
                  }
          }}</span
        >
      </div>
    </div>
  </div>
  <!-- ANALYSES -->
  <div
    class="section"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxFlex="33"
  >
    <span class="title">{{ 'cockpit.home.analyses' | translate }}</span>
    <div
      class="values"
      fxLayout="row"
      fxLayoutAlign="space-between start"
      fxLayoutGap="10px"
    >
      <ng-container *ngIf="firstAnalysis">
        <div fxLayout="column" fxFlex="50" fxLayoutAlign="start stretch">
          <div fxLayout="row" fxLayoutAlign="start">
            <span class="label" fxFlex="90"
              >{{
                'cockpit.home.' + firstAnalysis + '_health_indicator'
                  | translate
              }}:</span
            >
            <span>
              <i
                class="material-icons eq-status s-{{
                  firstHealthIndicator ? firstHealthIndicator : '0'
                }}"
              >
                fiber_manual_record
              </i></span
            >
          </div>
          <div fxLayout="row" fxLayoutAlign="start">
            <span class="label" fxFlex="90"
              >{{
                'cockpit.home.' + secondAnalysis + '_health_indicator'
                  | translate
              }}:</span
            >
            <i
              class="material-icons eq-status s-{{
                secondHealthIndicator ? secondHealthIndicator : '0'
              }}"
            >
              fiber_manual_record
            </i>
          </div>
        </div>
        <div
          fxLayout="column"
          fxFlex="50"
          fxLayoutGap="10px"
          fxLayoutAlign="start stretch"
        >
          <div fxLayout="row" fxLayoutAlign="start">
            <span class="label" fxFlex="90"
              >{{
                'cockpit.home.' + firstAnalysis + '_parameters_in_alarm'
                  | translate
              }}:</span
            >
            <span> {{ firstAlarm ? firstAlarm : '0' }}</span>
          </div>
          <div fxLayout="row" fxLayoutAlign="start">
            <span class="label" fxFlex="90"
              >{{
                'cockpit.home.' + secondAnalysis + '_parameters_in_alarm'
                  | translate
              }}:</span
            ><span> {{ secondAlarm ? secondAlarm : '0' }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
