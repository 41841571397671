import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { UrlModel } from '../models/common/url.model';
import { EquipmentViewModel } from '../viewmodels/equipment.viewmodel';

import { EquipmentDataService } from './dataServices/equipment-data-service.provider';
import { FavoriteEquipmentService } from './favorite-equipment.provider';
import { Logger } from './Logger.provider';

@Injectable()
export class CockpitService {
  constructor(
    private http: HttpClient,
    route: ActivatedRoute,
    private equipmentDataService: EquipmentDataService,
    private favoriteService: FavoriteEquipmentService,
    private logger: Logger
  ) {
    const uid = route.snapshot.paramMap.get('uid');
    if (uid) {
      this.equipmentDataService
        .getEquipmentViewModelById(uid)
        .subscribe((eq: EquipmentViewModel) => {
          this.favoriteService
            .loadStatus([eq])
            .subscribe(result => this.equipment$.next(result[0]));
        });
    }
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'no-cache': 'true'
    })
  };
  private equipment$: ReplaySubject<EquipmentViewModel> = new ReplaySubject(1);

  onEquipment(): Observable<EquipmentViewModel> {
    return this.equipment$.asObservable();
  }

  getPresignedUrlForDownloadExcel(equipmentId: string): Observable<UrlModel> {
    return this.http
      .get<UrlModel>(
        `${ApiEndpointsEnum.EQUIPMENTS_EXPORT.replace('{0}', equipmentId)}`,
        this.httpOptions
      )
      .pipe(
        retry(3),
        catchError(error => {
          this.logger.error(error);
          return of(null);
        })
      );
  }
}
