import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ParameterTableModel } from '../../models/cockpit/parameter-table.model';
import { ScadaModel } from '../../models/cockpit/scada.model';
import SortEnum from '../../models/common/enums/sort.enum';
import { TableDataSourceResponseModel } from '../../models/common/table-data-source-response.model';
import { ScadaDataService } from '../dataServices/scada-data-service.provider';

import { TableDataSource } from './table-data-source.provider';

@Injectable()
export class ScadaDataSourceService extends TableDataSource<
  ParameterTableModel
> {
  constructor(private scadaDataService: ScadaDataService) {
    super();
  }

  getData(
    sort: Map<string, SortEnum>,
    filter: Map<string, string>,
    query: string,
    page: number
  ): Observable<TableDataSourceResponseModel<ParameterTableModel>> {
    return this.scadaDataService
      .getScadaForEquipment(this.equipmentId, page, this.pageSize)
      .pipe(
        map((scadaData: TableDataSourceResponseModel<ScadaModel>) => {
          return {
            totalSize: scadaData.totalSize,
            items: scadaData.items.map(scada => {
              return {
                id: scada.pid,
                timeserie: scada.parameterTimeseries,
                unit: scada.unit,
                name: scada.parameterName,
                pid: scada.pid,
                helper: scada.helper
              };
            })
          };
        })
      );
  }
}
