<form [formGroup]="authFormGroup">
  <div fxLayout="column">
    <mat-form-field>
      <input
        id="username"
        type="email"
        formControlName="username"
        matInput
        placeholder="{{ 'login.login' | translate }}"
      />
    </mat-form-field>
    <mat-form-field>
      <input
        formControlName="password"
        type="password"
        matInput
        placeholder="{{ 'login.password' | translate }}"
      />
    </mat-form-field>
    <mat-error
      *ngIf="
        username.invalid &&
        username.value &&
        (username.dirty || username.touched)
      "
    >
      {{ 'login.errors.mailFormatInvalid' | translate }} ici<br>
      {{ 'login.password_format' | translate  }}
    </mat-error>
    <mat-error *ngIf="authHasError">
      {{ 'login.errors.' + errorMessage | translate }} la<br>
      {{ 'login.password_format' | translate  }}
    </mat-error>
    <div fxLayout="row" fxLayoutGap="20px">
      <button
        type="submit"
        [disabled]="!isFormValid()"
        mat-raised-button
        color="primary"
        fxFlex="50"
        (click)="signIn()"
      >
        {{ 'login.login' | translate }}
      </button>
      <button
        mat-raised-button
        color="accent"
        fxFlex="50"
        [routerLink]="['reset']"
      >
        {{ 'login.forgotPassword' | translate }}
      </button>
    </div>
  </div>
</form>

