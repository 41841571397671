import { Injectable, NgZone } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition
} from '@angular/material';

@Injectable()
export class SnackbarService {
  snackBarConfig: MatSnackBarConfig;
  snackBarRef: MatSnackBarRef<any>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  snackBarAutoHide = '1500';

  constructor(public snackBar: MatSnackBar, private zone: NgZone) {}

  public open(message, action?, duration = 3000) {
    this.zone.run(() => {
      this.snackBar.open(message, action, {
        duration,
        panelClass: 'blue-snackbar'
      });
    });
  }
}
