import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LireModel } from '../../models/cockpit/lire.model';
import ApiEndpointsEnum from '../../models/common/enums/api_endpoints.enum';
import SortEnum from '../../models/common/enums/sort.enum';
import { TableDataSourceResponseModel } from '../../models/common/table-data-source-response.model';

import { SortFilterDataServiceBase } from './sort-filter-data-service.base.provider';

@Injectable()
export class LireDataService extends SortFilterDataServiceBase {
  constructor(private http: HttpClient) {
    super();
  }
  getLireById(
    pid: string,
    page: number,
    pageSize: number,
    sort: Map<string, SortEnum>,
    filter: Map<string, string>
  ): Observable<TableDataSourceResponseModel<LireModel>> {
    let requestUrl = `${ApiEndpointsEnum.LIRE.replace(
      '{0}',
      pid
    )}?page=${page}&page_size=${pageSize}`;
    requestUrl += this.buildSort(sort);
    requestUrl += this.buildFilter(filter);
    return this.http.get<TableDataSourceResponseModel<LireModel>>(requestUrl);
  }
}
