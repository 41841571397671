import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { LinkModel } from 'libs/shared-module/src/lib/models/cockpit/link.model';
import { ReportService } from 'libs/shared-module/src/lib/providers/report.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-modal-upload-link',
  templateUrl: './modal-upload-link.component.html',
  styleUrls: ['./modal-upload-link.component.scss']
})
export class ModalUploadLinkComponent implements OnInit {
  isFileuploded = false;
  errorMessage = false;
  uploadForm: FormGroup;
  newLink: LinkModel;
  constructor(
    private reportService: ReportService,
    public uploadDialogRef: MatDialogRef<ModalUploadLinkComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EquipmentViewModel
  ) {}

  ngOnInit() {
    this.uploadForm = new FormGroup({
      url: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required])
    });
  }

  sendLink() {
    this.isFileuploded = true;
    this.newLink = {
      name: this.uploadForm.get('name').value,
      url: this.uploadForm.get('url').value
    };
    if (this.uploadForm.get('url') && this.uploadForm.get('name')) {
      this.reportService
        .postUrlLink(this.data.pid, this.newLink)
        .subscribe(() => this.uploadDialogRef.close(true));
    }
  }

  closeModal() {
    this.uploadDialogRef.close(false);
  }
}
