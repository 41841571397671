import { Component, Input, OnInit } from '@angular/core';

import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-equipment-type-info',
  templateUrl: './equipment-type-info.component.html',
  styleUrls: ['./equipment-type-info.component.scss']
})
export class EquipmentTypeInfoComponent implements OnInit {
  @Input()
  equipment: EquipmentViewModel;

  constructor() {}

  ngOnInit() {}
}
