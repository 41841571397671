<div class="filter" fxLayout="row" fxLayoutAlign="start center">
  <button
    *ngIf="enableSort"
    mat-icon-button
    class="btn-small action asc"
    [ngClass]="{ rotate: sortType === 'asc' }"
    (click)="doSort()"
  >
    <mat-icon class="icon">{{ sortType === '' ? 'list' : 'sort' }}</mat-icon>
  </button>
  <button
    *ngIf="enableFilter"
    mat-icon-button
    class="action btn-small"
    (click)="toggleFilter()"
  >
    <mat-icon class="icon">filter_list</mat-icon>
  </button>
  <mat-form-field
    class="filter-input"
    [ngClass]="{ open: isOpened, close: !isOpened }"
  >
    <input
      matInput
      [(ngModel)]="filterValue"
      placeholder="{{ placeHolder }}"
      (keyup.enter)="filter()"
    />
  </mat-form-field>
</div>
