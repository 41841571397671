import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import ApiEndpointsEnum from '../../models/common/enums/api_endpoints.enum';
import { SortFilterDataServiceBase } from './sort-filter-data-service.base.provider';
var LireDataService = /** @class */ (function (_super) {
    tslib_1.__extends(LireDataService, _super);
    function LireDataService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    LireDataService.prototype.getLireById = function (pid, page, pageSize, sort, filter) {
        var requestUrl = ApiEndpointsEnum.LIRE.replace('{0}', pid) + "?page=" + page + "&page_size=" + pageSize;
        requestUrl += this.buildSort(sort);
        requestUrl += this.buildFilter(filter);
        return this.http.get(requestUrl);
    };
    return LireDataService;
}(SortFilterDataServiceBase));
export { LireDataService };
