import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatFormFieldModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSelectModule,
  MatSortModule,
  MatTableModule
} from '@angular/material';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { UiCommonModule } from '../common/uicommon.module';

import { AdminModule } from './admin/admin.module';
import { DashboardComponent } from './dashboard.component';
import { DashboardRouterModule } from './dashboard.router.module';
import { SpreadSheetsModule } from './gc.spread.sheets.angular';
import { ImportComponent } from './import/import.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfileModule } from './profile/profile.module';
import { SearchEquipementModule } from './search-equipment/search-equipment.module';
import { UsersModule } from './users/users.module';
import { WelcomeComponent } from './welcome/welcome/welcome.component';

@NgModule({
  declarations: [
    NotificationsComponent,
    DashboardComponent,
    ImportComponent,
    WelcomeComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    DashboardRouterModule,
    UsersModule,
    SearchEquipementModule,
    FlexLayoutModule,
    ProfileModule,
    MatIconModule,
    UsersModule,
    AdminModule,
    UiCommonModule,
    MatInputModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SpreadSheetsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressBarModule,
    MatAutocompleteModule
  ]
})
export class DashboardModule {}
