<div fxLayout="column" fxLayoutAlign="start stretch" class="oil-table" fxFlex>
  <div *ngIf="(dataSource.loading$ | async)" fxFlexAlign="stretch">
    <mat-progress-bar
      class="loading"
      class="loading"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>
  <ng-container *ngIf="equipment && equipment.pid">
    <ahc-parameters-table
      [pageSize]="1000"
      [dataSource]="dataSource"
      [equipment]="equipment"
      [graphService]="graphService"
    ></ahc-parameters-table>
    <ahc-insert-parameter-value
      [expertiseDomain]="equipment.class.expertiseDomain"
      [tab]="tab"
    ></ahc-insert-parameter-value>
  </ng-container>
</div>
