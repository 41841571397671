<form [formGroup]="profileForm" *ngIf="user">
  <div class="information" fxLayout="column" fxLayoutAlign="center none">
    <div fxLayout="row" fxLayoutGap="50px" fxLayoutAlign="center center">
      <mat-form-field
        class="mat-form-field--rounded mat-form-field--white form--item"
        appearance="fill"
        diseabled
      >
        <mat-label>{{
          'dashboard.profile.information.email' | translate
        }}</mat-label>
        <input
          [value]="user?.email"
          readonly
          matInput
          placeholder="{{ 'dashboard.profile.information.email' | translate }}"
        />
      </mat-form-field>
      <mat-form-field
        class="mat-form-field--rounded mat-form-field--white form--item"
        appearance="fill"
        diseabled
      >
        <mat-label>{{ 'common.society' | translate }}</mat-label>
        <input
          [value]="user?.company?.name"
          readonly
          matInput
          placeholder="{{ 'common.society' | translate }}"
        />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="50px" fxLayoutAlign="center center">
      <mat-form-field
        class="mat-form-field--rounded mat-form-field--white form--item"
        appearance="fill"
      >
        <mat-label>{{ 'common.firstName' | translate }}</mat-label>
        <input
          formControlName="firstname"
          matInput
          placeholder="{{ 'common.firstName' | translate }}"
        />
      </mat-form-field>
      <mat-form-field
        class="mat-form-field--rounded mat-form-field--white form--item"
        appearance="fill"
      >
        <mat-label>{{ 'common.lastName' | translate }}</mat-label>
        <input
          formControlName="lastname"
          matInput
          placeholder="{{ 'common.lastName' | translate }}"
        />
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutGap="50px" fxLayoutAlign="center center">
      <mat-form-field
        class="mat-form-field--rounded mat-form-field--white form--item"
        appearance="fill"
      >
        <mat-label>{{ 'common.language' | translate }}</mat-label>
        <mat-select formControlName="language" matInput [value]="user.language">
          <mat-option value="en">
            {{ 'common.english' | translate }}
          </mat-option>
          <mat-option value="fr">
            {{ 'common.french' | translate }}
          </mat-option>
          <mat-option value="cn">
            {{ 'common.chinese' | translate }}
          </mat-option>
          <mat-option value="nl">
            {{ 'common.dutch' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div
      class="action-bar"
      fxLayout="row"
      fxLayoutAlign="{{ welcomeMode ? 'end center' : 'space-evenly center' }}"
      fxLayoutGap="50px"
    >
      <button
        class="action-item"
        mat-flat-button
        color="primary"
        type="submit"
        (click)="save()"
      >
        {{ 'common.save' | translate }}
      </button>
    </div>
  </div>
</form>
