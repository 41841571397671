import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ReportModel } from '../../models/cockpit/report.model';
import SortEnum from '../../models/common/enums/sort.enum';
import { SearchResultModel } from '../../models/common/search-result.model';
import { ReportService } from '../report.provider';

import { TableDataSource } from './table-data-source.provider';

@Injectable()
export class ReportDataSourceService extends TableDataSource<ReportModel> {
  /**
   *
   */
  constructor(private dataReportService: ReportService) {
    super();
  }

  initialize(equipmentId: string, pageSize = 5) {
    this.currentPage = 1;
    this.init(pageSize, equipmentId);
  }

  getData(
    sort: Map<string, SortEnum>,
    filter: Map<string, string>,
    query: string,
    page: number
  ): Observable<SearchResultModel<ReportModel[]>> {
    return this.dataReportService
      .getEquipmentReports(this.equipmentId, this.currentPage, this.pageSize)
      .pipe(
        map(data => {
          data.items.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
          return data;
        })
      );
  }
}
