import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';

import StorageKeysEnum from 'libs/shared-module/src/lib/models/common/enums/storage-keys.enum';
import { AuthService } from 'libs/shared-module/src/lib/providers/auth.provider';
import { StorageService } from 'libs/shared-module/src/lib/providers/storage.provider';

@Component({
  template: '<div>{{ message }}</div>',
  selector: 'ahc-login-callback'
})
export class LoginCallbackComponent implements OnInit {
  message = '';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    translate: TranslateService,
    private storageService: StorageService
  ) {
    this.message = translate.instant('login.connecting');
  }
  ngOnInit() {
    this.route.fragment.subscribe((url: string) => {
      try {
        const params = url.split('&');
        const IdToken = params[1].split('=')[1];
        const accessToken = params[0].split('=')[1];
        this.storageService.set(StorageKeysEnum.SsoIdToken, IdToken);
        this.storageService.set(StorageKeysEnum.SsoAccessToken, accessToken);
        this.storageService.set(StorageKeysEnum.SsoActivate, true);
        this.authService
          .signInSso()
          .pipe(
            catchError((err: boolean) => {
              this.router.navigate(['/login']);
              throw err;
            })
          )
          .subscribe((done: boolean) => this.router.navigate(['/']));
      } catch (ex) {
        this.router.navigate(['/']);
      }
    });
  }
}
