import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';

import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import { AppVersionService } from 'libs/shared-module/src/lib/providers/app-version.provider';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { Logger } from 'libs/shared-module/src/lib/providers/Logger.provider';
import { UserViewModel } from 'libs/shared-module/src/lib/viewmodels/user.viewmodel';
@Component({
  selector: 'ahc-f-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Assets Health Center';

  /**
   *
   */
  constructor(
    private translate: TranslateService,
    appVersion: AppVersionService,
    private logger: Logger,
    private permissionsService: NgxPermissionsService,
    private connectedUser: ConnectedUserService
  ) {
    
    translate.addLangs(['en', 'fr', 'cn', 'nl']);
    translate.setDefaultLang('en');
    translate.use('en');

    appVersion
      .getBuildInfo()
      .subscribe(version =>
        this.logger.info(
          `Version ${version.version} | date: ${version.buildDate}`
        )
      );
  }

  ngOnInit(): void {
    this.connectedUser.onUser().subscribe((user: UserViewModel) => {
      if (user) {

        this.translate.use(user.language.match(/en|fr|cn|nl/) ? user.language : 'en');
        this.loadPermission(user);

        if (user.isInternal) {
          this.addPermission(user);
        }
      } else {
        this.permissionsService.flushPermissions();
      }
    });
  }

  private loadPermission(user) {
    this.permissionsService.loadPermissions([
      user.level.toUpperCase(),
      ...(user.scopes.expertiseDomains
        ? user.scopes.expertiseDomains.map((domain: ExpertiseDomain) =>
            `${user.level}_${domain}`.toUpperCase()
          )
        : [])
    ]);
  }

  private addPermission(user) {
    this.permissionsService.addPermission(
      user.scopes.expertiseDomains.map((domain: ExpertiseDomain) =>
        `TAB_INTERNAL_EQUIPMENT_${domain}`.toUpperCase()
      )
    );
  }
}
