import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { FeedbackService } from 'libs/shared-module/src/lib/providers/feedback.provider';

@Component({
  selector: 'ahc-cockpit',
  templateUrl: './cockpit.component.html',
  styleUrls: ['./cockpit.component.scss'],
  providers: [CockpitService, FeedbackService]
})
export class CockpitComponent implements OnInit {
  isLoading = true;
  constructor(
    cockpitProvider: CockpitService,
    router: Router,
    connectedService: ConnectedUserService
  ) {
    connectedService
      .onUser()
      .pipe(map(user => (user ? user.isInternal : false)))
      .subscribe(connected => {
        this.isInternal = connected;
        cockpitProvider.onEquipment().subscribe(equipment => {
          this.isLoading = false;
          if (equipment.class.expertiseDomain) {
            if (
              _.intersection(
                router.url.split('/'),
                this.urlsMap[this.isInternal ? 'true' : 'false'][
                  equipment.class.expertiseDomain
                ]
              ).length === 0
            ) {
              router.navigateByUrl('/');
            }
          }
        });
      });
  }
  private isInternal = false;
  private urlsMap = {
    true: {
      electrical: [
        'home',
        'timeline',
        'predective',
        'corrective',
        'scada',
        'oil',
        'vibration',
        'report',
        'log'
      ],
      water: [
        'home',
        'timeline',
        'predective',
        'corrective',
        'scada',
        'water',
        'service-report',
        'report',
        'log'
      ],
      mechanical: [
        'home',
        'timeline',
        'predective',
        'corrective',
        'scada',
        'oil',
        'vibration',
        'report',
        'log'
      ]
    },
    false: {
      electrical: ['home', 'oil', 'vibration'],
      water: ['home', 'water', 'service-report'],
      mechanical: ['home', 'oil', 'vibration']
    }
  };

  ngOnInit() {}
}
