<div class="parameter">
  <div fxLayout="column" fxLayoutAlign="start stretch">
    <div fxLayout="row" fxLayoutGap="50px" fxLayoutAlign="start center">
      <mat-form-field
        class="mat-form-field--rounded mat-form-field--white form--item"
        appearance="fill"
      >
        <mat-label>{{
          'dashboard.admin.expertiseDomainLabel' | translate
        }}</mat-label>
        <mat-select [formControl]="expertiseDomainCtrl">
          <mat-option [value]="domains.WATER">{{
            'common.water' | translate
          }}</mat-option>
          <mat-option [value]="domains.MECHANICAL">{{
            'common.mechanical' | translate
          }}</mat-option>
          <mat-option [value]="domains.ELECTRICAL">{{
            'common.electrical' | translate
          }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="(tableDataSource.loading$ | async)" fxFlexAlign="stretch">
      <mat-progress-bar class="loading" mode="indeterminate"></mat-progress-bar>
    </div>
    <mat-table
      fxFill
      [dataSource]="data"
      class="mat-elevation-z1 cockpit-table"
      fxFlexAlign="center"
    >
      <!-- column name -->
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef class="header-cell" fxFlex="25">
          {{ 'common.tables_columns.parameter' | translate }}
          <ahc-table-column-filter
            class="filter"
            [ngClass]="{ show: showFilter.get('name') }"
            (search)="filter('name', $event)"
            (used)="showFilter.set('name', $event)"
            (sort)="sort('name', $event)"
          ></ahc-table-column-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let parameterInDataSource" fxFlex="25">
          {{ parameterInDataSource.name }}
        </mat-cell>
      </ng-container>
      <!-- column tab -->
      <ng-container matColumnDef="tab">
        <mat-header-cell *matHeaderCellDef class="header-cell">
          {{ 'common.tables_columns.tab' | translate }}
          <ahc-table-column-filter
            class="filter"
            [enableFilter]="false"
            [sortType]="sortEnum.asc"
            [ngClass]="{ show: showFilter.get('tab') }"
            (search)="filter('tab', $event)"
            (used)="showFilter.set('tab', $event)"
            (sort)="sort('tab', $event)"
          ></ahc-table-column-filter>
        </mat-header-cell>
        <mat-cell *matCellDef="let parameterInDataSource">
          {{ parameterInDataSource.tab }}
        </mat-cell>
      </ng-container>

      <!-- column v_low -->
      <ng-container matColumnDef="v_low">
        <mat-header-cell *matHeaderCellDef class="header-cell">
          {{ 'common.tables_columns.v_low' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let parameterInDataSource">
          <mat-form-field class="thresholdFields">
            <input
              matInput
              type="number"
              [(ngModel)]="parameterInDataSource.threshold.veryLow"
              (change)="deleteIfEmpty(parameterInDataSource.threshold, 'veryLow')"
            />
          </mat-form-field>
        </mat-cell>
      </ng-container>
      <!-- column low -->
      <ng-container matColumnDef="low">
        <mat-header-cell *matHeaderCellDef class="header-cell">
          {{ 'common.tables_columns.low' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let parameterInDataSource">
          <mat-form-field class="thresholdFields">
            <input 
            matInput
            type="number" 
            [(ngModel)]="parameterInDataSource.threshold.low"
            (change)="deleteIfEmpty(parameterInDataSource.threshold, 'low')" 
          />
          </mat-form-field>
        </mat-cell>
      </ng-container>
      <!-- column high -->
      <ng-container matColumnDef="high">
        <mat-header-cell *matHeaderCellDef class="header-cell">
          {{ 'common.tables_columns.high' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let parameterInDataSource">
          <mat-form-field class="thresholdFields">
            <input
              matInput
              type="number"
              [(ngModel)]="parameterInDataSource.threshold.high"
              (change)="deleteIfEmpty(parameterInDataSource.threshold, 'high')"
            />
          </mat-form-field>
        </mat-cell>
      </ng-container>
      <!-- column v_high -->
      <ng-container matColumnDef="v_high">
        <mat-header-cell *matHeaderCellDef class="header-cell">
          {{ 'common.tables_columns.v_high' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let parameterInDataSource">
          <mat-form-field class="thresholdFields">
            <input
              matInput
              type="number"
              [(ngModel)]="parameterInDataSource.threshold.veryHigh"
              (change)="deleteIfEmpty(parameterInDataSource.threshold, 'veryHigh')"
            />
          </mat-form-field>
        </mat-cell>
      </ng-container>
      <!-- column defaultUnits -->
      <ng-container matColumnDef="defaultUnits">
        <mat-header-cell *matHeaderCellDef class="header-cell">
          {{ 'common.tables_columns.defaultUnits' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let parameterInDataSource">
          {{ parameterInDataSource.unit }}
        </mat-cell>
      </ng-container>
      <!-- column action -->
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef class="header-cell">
          {{ 'common.tables_columns.action' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let parameterInDataSource">
          <div
            class="action"
            fxLayoutGap="30px"
            *ngIf="parameterInDataSource.threshold"
          >
            <button
              mat-icon-button
              class="btnActionParameter"
              matTooltip="Save thresholds"
              (click)="saveParameter(parameterInDataSource.threshold)"
            >
              <mat-icon>save</mat-icon>
            </button>
            <button
              mat-icon-button
              class="btnActionParameter"
              matTooltip="Delete thresholds"
              (click)="deleteParameter(parameterInDataSource.threshold)"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <!-- 
    Set aside for the moment
  -->
  <!-- <div fxLayout="row" class="footer-parameter" fxLayoutAlign="space-between">
    <button mat-button color="primary" (click)="addNewParameter()">
      {{ 'dashboard.admin.datatable.newParameter' | translate }}
    </button>
  </div> -->
</div>
