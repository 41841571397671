import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CatchesModel } from '../../models/cockpit/catches.model';
import SortEnum from '../../models/common/enums/sort.enum';
import { TableDataSourceResponseModel } from '../../models/common/table-data-source-response.model';
import { MaximoDataService } from '../dataServices/maximo-data-service.provider';

import { TableDataSource } from './table-data-source.provider';

@Injectable()
export class CatchesDataSourceService extends TableDataSource<CatchesModel> {
  /**
   *
   */
  constructor(private maximoDataService: MaximoDataService) {
    super();
  }

  getData(
    sort: Map<string, SortEnum>,
    filter: Map<string, string>,
    query: string,
    page: number
  ): Observable<TableDataSourceResponseModel<CatchesModel>> {
    return this.maximoDataService.getCatches(
      this.equipmentId,
      page,
      this.pageSize,
      sort,
      filter
    );
  }
}
