import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Spread } from '@grapecity/spread-sheets';
import { TranslateService } from '@ngx-translate/core';
import DisplayZone from 'libs/shared-module/src/lib/models/common/enums/display-zone.enum';
import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { SearchEquipmenFilterEnum } from 'libs/shared-module/src/lib/models/common/search-equipment-filter.enum';
import { DisciplineTypeOfAnalysis } from 'libs/shared-module/src/lib/models/import/disciplineTypeOfAnalysis.model';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { ImportParametersValueService } from 'libs/shared-module/src/lib/providers/import-parameters-value.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SearchEquipmentService } from 'libs/shared-module/src/lib/providers/search-equipment.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { ThresholdService } from 'libs/shared-module/src/lib/providers/threshold.provider';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { environment } from '../../../../../../apps/ahc/ahc-front/src/environments/environment';
import { CellStatus, ImportTableModel } from './models';
var ImportComponent = /** @class */ (function () {
    function ImportComponent(formBuilder, scopeProvider, importService, equipmentSearch, translate, thresholdService, snackbarService, connectedUserService) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.scopeProvider = scopeProvider;
        this.importService = importService;
        this.equipmentSearch = equipmentSearch;
        this.translate = translate;
        this.thresholdService = thresholdService;
        this.snackbarService = snackbarService;
        this.tabs = Tabs;
        this.sheetName = 'import sheet';
        this.columnWidth = 200;
        this.isLoading = false;
        this.plants = [];
        this.filteredPlants = new Observable();
        this.mainFilter = {
            discipline: new DisciplineTypeOfAnalysis(ExpertiseDomain.ELECTRICAL, Tabs.OIL_ANALYSIS, this.translate),
            plantId: ''
        };
        this.errorCells = [];
        this.dataSource = new ImportTableModel();
        this.disciplines = [
            new DisciplineTypeOfAnalysis(ExpertiseDomain.ELECTRICAL, Tabs.OIL_ANALYSIS, this.translate),
            new DisciplineTypeOfAnalysis(ExpertiseDomain.ELECTRICAL, Tabs.VIBRATION, this.translate),
            new DisciplineTypeOfAnalysis(ExpertiseDomain.MECHANICAL, Tabs.OIL_ANALYSIS, this.translate),
            new DisciplineTypeOfAnalysis(ExpertiseDomain.MECHANICAL, Tabs.VIBRATION, this.translate),
            new DisciplineTypeOfAnalysis(ExpertiseDomain.WATER, Tabs.WATER, this.translate),
            new DisciplineTypeOfAnalysis(ExpertiseDomain.WATER, Tabs.SERVICE_REPORT, this.translate)
        ];
        this.backColor = 'lightgrey';
        this.errorBackColor = '#F54B4B';
        this.warningBackColor = '#FFB200';
        this.row_assetId = 2;
        this.row_date = 3;
        if (window.location.hostname !== 'localhost') {
            Spread.Sheets.LicenseKey = environment.spreadJsLicense;
        }
        connectedUserService.onUser().subscribe(function (u) { return (_this.connectedUser = u); });
    }
    ImportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filterForm = this.formBuilder.group({
            plant: new FormControl('', Validators.required),
            discipline: new FormControl(this.disciplines[0], Validators.required)
        });
        // init for autocomplete filter values
        this.filteredPlants = this.filterForm.controls['plant'].valueChanges.pipe(startWith(''), map(function (value) { return (typeof value === 'string' ? value : value.name); }), map(function (name) { return (name ? _this._filter(name) : _this.plants.slice()); }));
        this.filterForm.valueChanges.subscribe(function (values) {
            _this.mainFilter.plantId = values.plant.id;
            _this.mainFilter.discipline = values.discipline;
        });
        this.scopeProvider.loadPlants().subscribe(function (data) {
            _this.plants = data;
            _this.mainFilter.plantId =
                _this.plants.length > 0 ? _this.plants[0].id : null;
        });
        var sheetCulture = new Spread.Common.CultureInfo();
        // set default date format to use
        sheetCulture.DateTimeFormat.shortDatePattern = 'dd/MM/yyyy';
        Spread.Common.CultureManager.addCultureInfo('ahc-culture', sheetCulture);
        // update culture
        Spread.Common.CultureManager.culture('ahc-culture');
        this.spread = new Spread.Sheets.Workbook(document.getElementById('importTable'));
        this.spread.options.newTabVisible = false;
        this.spread.options.showVerticalScrollbar = true;
        this.spread.options.showHorizontalScrollbar = true;
        this.spread.options.highlightInvalidData = true;
        this.spread.options.allowContextMenu = false;
        this.sheet = this.spread.getActiveSheet();
        // lock header row and parameters column
        this.sheet.bind(Spread.Sheets.Events.EditStarting, function (s, args) {
            if (args.col === 0 ||
                args.col === 1 ||
                args.row === 0 ||
                args.row === 1) {
                args.cancel = true;
            }
        });
        this.sheet.bind(Spread.Sheets.Events.ClipboardPasting, function (s, args) {
            if (args.cellRange.col < this.row_assetId) {
                args.cancel = true;
            }
        });
        this.sheet.options.allowCellOverflow = false;
        this.dataSource.equipments = [];
        this.cellUpdated();
    };
    ImportComponent.prototype.initFromFilter = function () {
        var _this = this;
        this.isLoading = true;
        this.importService
            .getParameters(this.mainFilter)
            .subscribe(function (parameters) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var thresholds;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (parameters) {
                            this.dataSource.equipments = [];
                            this.dataSource.parameters = parameters
                                .sort(function (a, b) {
                                return !a.displayPriority
                                    ? 1
                                    : a.displayPriority > b.displayPriority
                                        ? 1
                                        : -1;
                            })
                                .map(function (param, index) {
                                var row = {
                                    row: index,
                                    parameter: param
                                };
                                return row;
                            });
                        }
                        return [4 /*yield*/, this.loadThresholds()];
                    case 1:
                        thresholds = _a.sent();
                        thresholds.items.forEach(function (threshold) {
                            var param = _this.dataSource.parameters.find(function (parameter) { return parameter.parameter.id === threshold.parameterPid; });
                            if (param) {
                                param.parameter.threshold = threshold;
                            }
                        });
                        this.build();
                        this.pushEquipment(2);
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        }); });
    };
    ImportComponent.prototype.loadThresholds = function () {
        return this.thresholdService
            .batchThreshold({
            parameterListPid: this.dataSource.parameters.map(function (p) { return p.parameter.id; })
        })
            .toPromise();
    };
    ImportComponent.prototype.build = function () {
        var _this = this;
        this.spread.suspendPaint();
        var currentRow = 0;
        this.sheet.autoGenerateColumns = true;
        this.sheet.deleteColumns(0, this.sheet.getColumnCount());
        // Parameters column
        this.sheet.addColumns(0, 1);
        // units column
        this.sheet.addColumns(1, 1);
        this.sheet
            .getCell(currentRow, 0)
            .text(this.translate.instant('dashboard.import.labels.parameters_characteristic'));
        this.sheet.getCell(currentRow, 0).backColor(this.backColor);
        currentRow++;
        this.sheet
            .getCell(currentRow, 0)
            .text(this.translate.instant('dashboard.import.labels.process_tag'));
        this.sheet.getCell(currentRow, 0).backColor(this.backColor);
        // this.sheet.getCell(currentRow, 2).backColor(this.backColor);
        currentRow++;
        this.sheet
            .getCell(currentRow, 0)
            .text(this.translate.instant('dashboard.import.labels.asset_number'));
        this.sheet.getCell(currentRow, 0).backColor(this.backColor);
        currentRow++;
        this.sheet
            .getCell(currentRow, 0)
            .text(this.translate.instant('dashboard.import.labels.date'));
        this.sheet.getCell(currentRow, 1).text('DD/MM/YY');
        this.sheet.getCell(currentRow, 0).backColor(this.backColor);
        currentRow++;
        // units column
        this.sheet
            .getCell(0, 1)
            .text(this.translate.instant('dashboard.import.labels.units'));
        this.sheet.getCell(0, 1).backColor(this.backColor);
        this.sheet.getCell(1, 1).backColor(this.backColor);
        this.sheet.getCell(2, 1).backColor(this.backColor);
        this.sheet.getCell(3, 1).backColor(this.backColor);
        this.sheet.getCell(this.row_date, 1).backColor(this.backColor);
        this.dataSource.parameters.forEach(function (row, index) {
            _this.sheet.getCell(currentRow + index, 0).text(row.parameter.name);
            _this.sheet.comments.add(currentRow + index, 0, " " + _this.translate.instant('dashboard.admin.alarm.veryHigh') + ": " + (row.parameter.threshold ? row.parameter.threshold.veryHigh : '-') + "\n " + _this.translate.instant('dashboard.admin.alarm.veryLow') + ": " + (row.parameter.threshold ? row.parameter.threshold.veryLow : '-'));
            _this.sheet.getCell(currentRow + index, 0).backColor(_this.backColor);
            _this.sheet.getCell(currentRow + index, 1).text(row.parameter.unit);
            _this.sheet.getCell(currentRow + index, 1).backColor(_this.backColor);
            row.row = currentRow + index;
            _this.sheet.autoFitRow(currentRow + index);
        });
        this.sheet.autoFitColumn(0);
        this.sheet.autoFitColumn(1);
        this.buildSheetActions();
        this.sheet.resumePaint();
    };
    ImportComponent.prototype.import = function () {
        var _this = this;
        this.isLoading = true;
        var hasError = false;
        this.dataSource.equipments.forEach(function (eq) {
            // clear existing data
            eq.values = [];
            if (!eq.assetId) {
                hasError = true;
                _this.pushError(_this.row_assetId, eq.col);
            }
            if (!eq.date) {
                hasError = true;
                _this.pushError(_this.row_date, eq.col);
            }
            _this.dataSource.parameters.forEach(function (param) {
                if (param.parameter.unit && _this.sheet.getValue(param.row, eq.col)) {
                    var unit = param.parameter.unit.toLocaleLowerCase();
                    hasError = !_this.parameterDateIsValid(unit, param, eq);
                    if (hasError) {
                        _this.pushError(param.row, eq.col);
                    }
                }
            });
            if (!hasError) {
                _this.dataSource.parameters.forEach(function (param) {
                    var value = _this.sheet.getValue(param.row, eq.col);
                    if (value === null || value === undefined) {
                        return;
                    }
                    eq.values.push({
                        coord: {
                            col: eq.col,
                            row: param.row
                        },
                        status: CellStatus.valid,
                        value: value
                    });
                });
            }
        });
        if (!hasError) {
            this.doImport();
        }
        else {
            this.isLoading = false;
        }
    };
    ImportComponent.prototype.displayFn = function (p) {
        return p ? p.name : undefined;
    };
    ImportComponent.prototype.doImport = function () {
        var _this = this;
        var data = [];
        this.dataSource.equipments.forEach(function (eq) {
            data.push.apply(data, eq.values.map(function (val) {
                var values = new Map();
                var parameter = _this.dataSource.parameters.find(function (paramRow) { return paramRow.row === val.coord.row; }).parameter;
                if (parameter.displayZone === DisplayZone.COMMENT_BOX &&
                    parameter.name.toLocaleLowerCase().indexOf('comments') > -1) {
                    var comment = {
                        author: _this.connectedUser.firstname +
                            ' ' +
                            _this.connectedUser.lastname,
                        comment: val.value
                    };
                    values[eq.date.format('YYYY-MM-DD')] = JSON.stringify(comment);
                }
                else {
                    values[eq.date.format('YYYY-MM-DD')] = val.value;
                }
                var dataToInsert = {
                    equipmentId: eq.id,
                    parameter: parameter,
                    values: values
                };
                return dataToInsert;
            }));
        });
        this.importService
            .import(data)
            .pipe(catchError(function (err) {
            _this.snackbarService.open(_this.translate.instant('dashboard.import.fail_import'), null);
            return of(false);
        }))
            .subscribe(function (done) {
            _this.isLoading = false;
            if (done) {
                _this.snackbarService.open(_this.translate.instant('dashboard.import.success_import'), null);
            }
        });
    };
    ImportComponent.prototype.cellUpdated = function () {
        var _this = this;
        // if we delete the content of a cell
        this.spread.bind(Spread.Sheets.Events.LeaveCell, function (e, args) {
            var value = _this.sheet.getValue(args.row, args.col);
            if (!value && args.row > _this.row_date && args.col > 1) {
                _this.popError(args.row, args.col);
            }
        });
        this.spread.bind(Spread.Sheets.Events.ClipboardPasted, function (e, args) {
            // row of asset ID and only one cell
            if (args.cellRange.row === _this.row_assetId &&
                args.cellRange.col > 1 &&
                args.cellRange.colCount === 1 &&
                args.cellRange.rowCount === 1) {
                _this.addEquipment(args.pasteData.text, args.cellRange.col);
            }
            var rowsData = args.pasteData.text.split('\n');
            rowsData.forEach(function (rowData, rowIndex) {
                var cellsData = rowData.split('\t');
                cellsData.forEach(function (value, colIndex) {
                    _this.checkCellValue(args.cellRange.row + rowIndex, args.cellRange.col + colIndex, value);
                });
            });
        });
        this.spread.bind(Spread.Sheets.Events.ValueChanged, function (e, args) {
            var row = args.row;
            var col = args.col;
            var value = args.newValue;
            _this.checkCellValue(row, col, value);
        });
    };
    ImportComponent.prototype.checkCellValue = function (row, col, value) {
        var equipment = this.dataSource.equipments.find(function (eq) { return eq.col === col; });
        if (!equipment) {
            return;
        }
        // asset ID updated
        if (row === this.row_assetId && col > 1) {
            this.popError(this.row_assetId, col);
            this.addEquipment(value, col);
            return;
        }
        // date cell
        if (row === this.row_date) {
            this.popError(this.row_date, col);
            var date = moment(this.sheet.getValue(this.row_date, col));
            if (date.isValid() && date.year() > 1970 && date.year() < 2100) {
                equipment.date = date;
                this.popError(row, col);
            }
            else {
                this.pushError(row, col);
            }
            return;
        }
        if (row > this.row_date) {
            var parameterRow = this.dataSource.parameters.find(function (item) { return item.row === row; });
            if (parameterRow.parameter.displayZone === DisplayZone.NON_TRENDABLE ||
                parameterRow.parameter.displayZone === DisplayZone.COMMENT_BOX) {
                if (parameterRow.parameter.name.toLocaleLowerCase().indexOf('health') >
                    -1 ||
                    parameterRow.parameter.name.toLocaleLowerCase().indexOf('indicator') >
                        -1) {
                    var indicator = parseFloat(value);
                    if (!indicator || indicator < 1 || indicator > 5) {
                        this.pushError(row, col);
                    }
                    else {
                        this.popError(row, col);
                    }
                }
                if (value) {
                    if (this.parameterDateIsValid(parameterRow.parameter.unit, parameterRow, equipment)) {
                        this.popError(row, col);
                    }
                    else {
                        this.pushError(row, col);
                    }
                }
                return;
            }
            if (isNaN(Number(value))) {
                this.pushError(row, col);
            }
            else {
                var numValue = parseFloat(value);
                if (parameterRow) {
                    if (parameterRow.parameter.threshold) {
                        var ll = parseFloat(parameterRow.parameter.threshold.veryLow);
                        var hh = parseFloat(parameterRow.parameter.threshold.veryHigh);
                        if (!isNaN(ll) && numValue <= ll) {
                            this.pushWarning(row, col);
                        }
                        else if (!isNaN(hh) && numValue >= hh) {
                            this.pushWarning(row, col);
                        }
                        else {
                            this.popError(row, col);
                        }
                    }
                    else {
                    }
                }
            }
            return;
        }
    };
    ImportComponent.prototype.addEquipment = function (value, col) {
        var _this = this;
        this.isLoading = true;
        var equipment = this.dataSource.equipments.find(function (eq) { return eq.col === col; });
        if (equipment) {
            this.searchEquipmentByAssetID(value).subscribe(function (eq) {
                if (eq) {
                    _this.popError(_this.row_assetId, col);
                    equipment.assetId = value;
                    equipment.id = eq.id;
                    equipment.processTag = eq.alProcessTag;
                    _this.sheet.setValue(1, col, equipment.processTag);
                }
                else {
                    _this.pushError(_this.row_assetId, col);
                }
                _this.isLoading = false;
            });
        }
    };
    ImportComponent.prototype.searchEquipmentByAssetID = function (value) {
        var _this = this;
        return this.equipmentSearch
            .doRequest(Object.assign({
            assetId: value,
            plantId: this.mainFilter.plantId,
            page: 1,
            pageSize: 1,
            filter: SearchEquipmenFilterEnum.ALL
        }))
            .pipe(map(function (result) {
            var equipment = _.first(result.items);
            if (!equipment ||
                equipment.expertiseDomain !==
                    _this.filterForm.controls['discipline'].value.discipline) {
                return null;
            }
            return equipment;
        }));
    };
    ImportComponent.prototype.buildSheetActions = function () {
        var _this = this;
        this.sheet.addColumns(this.sheet.getColumnCount() + 1, 1);
        var addEquipmentAction = new Spread.Sheets.CellTypes.Button();
        addEquipmentAction.text("  " + this.translate.instant('dashboard.import.add_equipment') + "  ");
        this.sheet
            .getCell(0, this.sheet.getColumnCount() - 1)
            .cellType(addEquipmentAction);
        addEquipmentAction.marginTop(10);
        addEquipmentAction.marginBottom(10);
        var removeEquipmentAction = new Spread.Sheets.CellTypes.Button();
        removeEquipmentAction.text("  " + this.translate.instant('dashboard.import.remove_equipment') + "  ");
        this.sheet
            .getCell(1, this.sheet.getColumnCount() - 1)
            .cellType(removeEquipmentAction);
        removeEquipmentAction.marginTop(10);
        removeEquipmentAction.marginBottom(10);
        this.sheet.autoFitColumn(this.sheet.getColumnCount() - 1);
        this.sheet.autoFitRow(0);
        this.sheet.autoFitRow(1);
        this.spread.bind(Spread.Sheets.Events.ButtonClicked, function (e, args) {
            var sheet = args.sheet, row = args.row, col = args.col;
            var cellType = sheet.getCellType(row, col);
            if (cellType instanceof Spread.Sheets.CellTypes.Button) {
                if (col === sheet.getColumnCount() - 1) {
                    if (row === 0 && _this.mainFilter.plantId) {
                        _this.pushEquipment(col);
                    }
                    if (row === 1) {
                        _this.popEquipment(col - 1);
                    }
                }
            }
        });
    };
    ImportComponent.prototype.popEquipment = function (col) {
        var _this = this;
        if (this.dataSource.equipments.length > 0) {
            this.popError(this.row_assetId, col);
            // clear errors for this equipment
            this.dataSource.parameters.map(function (param) {
                _this.popError(param.row, col);
            });
            this.sheet.deleteColumns(col, 1);
        }
        this.dataSource.equipments.pop();
    };
    ImportComponent.prototype.pushEquipment = function (col) {
        var _this = this;
        this.dataSource.equipments.push({
            id: null,
            assetId: null,
            col: col,
            date: null,
            processTag: '',
            siteId: this.plants.find(function (p) { return p.id === _this.mainFilter.plantId; }).id,
            values: []
        });
        this.sheet.addColumns(col, 1);
        this.sheet.setValue(0, col, this.translate.instant('dashboard.import.sample') + " " + (col - 1));
        this.sheet.getCell(0, col).backColor(this.backColor);
        this.sheet
            .getCell(this.row_date, col)
            .formatter(new Spread.Formatter.GeneralFormatter('dd MMM YYYY', 'fr-fr'));
        this.sheet.getCell(1, col).backColor(this.backColor);
        this.sheet.setColumnWidth(col, 160);
    };
    ImportComponent.prototype.popError = function (row, col) {
        this.errorCells = this.errorCells.filter(function (cell) { return cell.col !== col || cell.row !== row; });
        this.sheet.getCell(row, col).backColor('');
    };
    ImportComponent.prototype.pushError = function (row, col) {
        this.sheet.getCell(row, col).backColor(this.errorBackColor);
        this.errorCells.push({ col: col, row: row });
    };
    ImportComponent.prototype.pushWarning = function (row, col) {
        this.sheet.getCell(row, col).backColor(this.warningBackColor);
    };
    ImportComponent.prototype._filter = function (name) {
        var filterValue = name.toLowerCase();
        return this.plants.filter(function (option) { return option.name.toLowerCase().indexOf(filterValue) > -1; });
    };
    ImportComponent.prototype.parameterDateIsValid = function (unit, param, eq) {
        var dateFormatFound = this.parameterIsDateFormat(unit);
        if (dateFormatFound) {
            try {
                return !isNaN(Date.parse(this.sheet.getValue(param.row, eq.col)));
            }
            catch (error) {
                return false;
            }
        }
        return true;
    };
    ImportComponent.prototype.parameterIsDateFormat = function (unit) {
        var dateFormatFound = false;
        ['jj/', 'dd/', 'mm/', '/yy', '/aa'].forEach(function (item) {
            return unit.toLocaleLowerCase().indexOf(item) > -1
                ? (dateFormatFound = true)
                : (dateFormatFound = dateFormatFound || false);
        });
        return dateFormatFound;
    };
    return ImportComponent;
}());
export { ImportComponent };
