import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { startWith } from 'rxjs/operators';
import StorageKeysEnum from 'libs/shared-module/src/lib/models/common/enums/storage-keys.enum';
import { SearchEquipmenFilterEnum } from 'libs/shared-module/src/lib/models/common/search-equipment-filter.enum';
import { ClassStructureService } from 'libs/shared-module/src/lib/providers/class-structure.provider';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SearchEquipmentService } from 'libs/shared-module/src/lib/providers/search-equipment.provider';
import { StorageService } from 'libs/shared-module/src/lib/providers/storage.provider';
var AdvancedSearchComponent = /** @class */ (function () {
    function AdvancedSearchComponent(fb, scopeParameterService, searchService, storageService, classStructureService, connectedUserService) {
        this.fb = fb;
        this.scopeParameterService = scopeParameterService;
        this.searchService = searchService;
        this.storageService = storageService;
        this.classStructureService = classStructureService;
        this.connectedUserService = connectedUserService;
        this.formChangeEvent = new EventEmitter();
        this.clusterCtrl = new FormControl();
        this.countryCtrl = new FormControl();
        this.plantCtrl = new FormControl();
        this.unitCtrl = new FormControl();
        this.expertiseDomainCtrl = new FormControl();
        this.equipmentClassCtrl = new FormControl();
        this.optionsClusters = [];
        this.optionsCountries = [];
        this.optionsPlants = [];
        this.optionsUnits = [];
        this.optionExpertiseDomains = [];
        this.optionClassStructure = [];
        this.other = { id: 'NA', name: 'N/A' };
        this.filteredClusterOptions = [];
        this.filterCountriesOptions = [];
        this.filterPlantsOptions = [];
        this.filterUnitsOptions = [];
        this.filterExpertiseDomainOptions = [];
        this.filterClassStructureOptions = [];
        this.AUTOCOPLETE_MINIMUM = 0;
        connectedUserService.onUser().subscribe(function (user) { });
    }
    AdvancedSearchComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.criteria = this.searchService.getCriteria();
                this.connectedUserService.onUser().subscribe(function (user) {
                    _this.userExpertiseDomains = user.scopes.expertiseDomains;
                    _this.initForm();
                });
                this.initForm();
                this.isVisibleSearch = false;
                return [2 /*return*/];
            });
        });
    };
    AdvancedSearchComponent.prototype.initForm = function () {
        var _this = this;
        this.form = this.fb.group({
            cluster: this.clusterCtrl,
            country: this.countryCtrl,
            plant: this.plantCtrl,
            unit: this.unitCtrl,
            expertiseDomain: this.expertiseDomainCtrl,
            classStructure: this.equipmentClassCtrl
        });
        forkJoin([
            this.scopeParameterService.loadClusters(),
            this.scopeParameterService.loadCountries(),
            this.scopeParameterService.loadPlants(),
            this.scopeParameterService.loadProductionUnits(),
            this.classStructureService.expertiseDomains(),
            this.scopeParameterService.loadExpertiseDomains()
        ]).subscribe(function (results) {
            _this.optionsClusters = results[0];
            _this.optionsCountries = results[1];
            _this.optionsPlants = results[2];
            _this.optionsUnits = results[3];
            _this.optionClassStructure = results[4].map(function (domain) {
                var item = {
                    id: domain.id,
                    name: domain.classStructureId + " " + domain.name
                };
                return item;
            });
            _this.optionExpertiseDomains = results[5];
            _this.optionExpertiseDomains = _this.optionExpertiseDomains.filter(function (domain) {
                return _this.userExpertiseDomains.length === 0 ||
                    _this.userExpertiseDomains.indexOf(domain.id) > -1;
            });
            _this.optionExpertiseDomains.push(_this.other);
            _this.expertiseDomainCtrl.setValue(_this.optionExpertiseDomains.find(function (c) { return c.id === _this.criteria.expertiseDomainId; }) || undefined);
            _this.getCountryByClusterId(_this.clusterCtrl.value);
            _this.clusterCtrl.setValue(_this.optionsClusters.find(function (c) { return c.id === _this.criteria.clusterId; }) || undefined);
            _this.countryCtrl.setValue(_this.optionsCountries.find(function (c) { return c.id === _this.criteria.countryId; }) || undefined);
            _this.plantCtrl.setValue(_this.optionsPlants.find(function (c) { return c.id === _this.criteria.plantId; }) || undefined);
            _this.unitCtrl.setValue(_this.optionsUnits.find(function (c) { return c.id === _this.criteria.unitId; }) || undefined);
            _this.equipmentClassCtrl.setValue(_this.optionClassStructure.find(function (c) { return c.id === _this.criteria.classId; }));
            _this.clusterCtrl.valueChanges
                .pipe(startWith(''))
                .subscribe(function (cluster) {
                _this.filteredClusterOptions = _this.filter(_this.optionsClusters, cluster);
            });
            _this.countryCtrl.valueChanges
                .pipe(startWith(''))
                .subscribe(function (country) {
                _this.filterCountriesOptions = _this.filter(_this.optionsCountries, country);
            });
            _this.plantCtrl.valueChanges
                .pipe(startWith(''))
                .subscribe(function (item) {
                _this.filterPlantsOptions = _this.filter(_this.optionsPlants, item);
            });
            _this.unitCtrl.valueChanges
                .pipe(startWith(''))
                .subscribe(function (item) {
                _this.filterUnitsOptions = _this.filter(_this.filterUnitsOptions, item);
            });
            _this.expertiseDomainCtrl.valueChanges
                .pipe(startWith(''))
                .subscribe(function (item) {
                _this.filterExpertiseDomainOptions = _this.filter(_this.optionExpertiseDomains, item);
            });
            _this.equipmentClassCtrl.valueChanges
                .pipe(startWith(''))
                .subscribe(function (item) {
                _this.filterClassStructureOptions = _this.filter(_this.optionClassStructure, item);
            });
            // filter countries by pre-selected cluster
            _this.updateFiltredOptions();
        });
    };
    AdvancedSearchComponent.prototype.updateFiltredOptions = function () {
        var _this = this;
        this.filterCountriesOptions = this.optionsCountries.filter(function (country) {
            return (_this.clusterCtrl.value &&
                _this.clusterCtrl.value.id === country.clusterPid) ||
                !_this.clusterCtrl.value;
        });
        // pre-select plants
        this.filterPlantsOptions = this.optionsPlants
            .filter(function (plant) {
            return (_this.clusterCtrl.value &&
                _this.clusterCtrl.value.id === plant.clusterPid) ||
                !_this.clusterCtrl.value;
        })
            .filter(function (plant) {
            return (_this.countryCtrl.value &&
                _this.countryCtrl.value.id === plant.countryPid) ||
                !_this.countryCtrl.value;
        });
        // pre-select units
        this.filterUnitsOptions = this.optionsUnits
            .filter(function (unit) {
            return (_this.clusterCtrl.value &&
                _this.clusterCtrl.value.id === unit.clusterPid) ||
                !_this.clusterCtrl.value;
        })
            .filter(function (unit) {
            return (_this.countryCtrl.value &&
                _this.countryCtrl.value.id === unit.countryPid) ||
                !_this.countryCtrl.value;
        })
            .filter(function (unit) {
            return (_this.plantCtrl.value && _this.plantCtrl.value.id === unit.plantPid) ||
                !_this.plantCtrl.value;
        });
        this.filterClassStructureOptions = this.optionClassStructure.filter(function (classStructure) {
            return (_this.equipmentClassCtrl.value &&
                _this.equipmentClassCtrl.value.id === classStructure.id) ||
                !_this.equipmentClassCtrl.value;
        });
    };
    AdvancedSearchComponent.prototype.openAdvancedSearch = function () {
        this.isVisibleSearch = true;
    };
    AdvancedSearchComponent.prototype.resetAll = function () {
        this.criteria = Object.assign({
            page: 1,
            pageSize: this.criteria.pageSize,
            filter: SearchEquipmenFilterEnum.MAIN
        });
        this.searchService.search(this.criteria);
        this.formChangeEvent.emit(false);
    };
    AdvancedSearchComponent.prototype.displayWith = function (value) {
        return value ? value.name : undefined;
    };
    AdvancedSearchComponent.prototype.search = function () {
        this.formChangeEvent.emit(false);
        this.criteria.page = 1;
        this.criteria.clusterId = this.form.value.cluster
            ? this.form.value.cluster.id
            : undefined;
        this.criteria.countryId = this.form.value.country
            ? this.form.value.country.id
            : undefined;
        this.criteria.plantId = this.form.value.plant
            ? this.form.value.plant.id
            : undefined;
        this.criteria.unitId = this.form.value.unit
            ? this.form.value.unit.id
            : undefined;
        this.criteria.expertiseDomainId = this.form.value.expertiseDomain
            ? this.form.value.expertiseDomain.id
            : undefined;
        this.criteria.classId = this.form.value.classStructure
            ? this.form.value.classStructure.id
            : undefined;
        this.searchService.search(this.criteria);
        this.storageService.set(StorageKeysEnum.SearchCriteria, this.criteria);
    };
    AdvancedSearchComponent.prototype.formUpdated = function () {
        this.filterCountriesOptions = this.filter(this.optionsCountries, this.countryCtrl.value);
        this.filterPlantsOptions = this.filter(this.optionsPlants, this.plantCtrl.value);
        this.filterUnitsOptions = this.filter(this.optionsUnits, this.unitCtrl.value);
        this.filterExpertiseDomainOptions = this.filter(this.optionExpertiseDomains, this.expertiseDomainCtrl.value);
        this.filterClassStructureOptions = this.filter(this.optionClassStructure, this.equipmentClassCtrl.value);
    };
    AdvancedSearchComponent.prototype.getCountryByClusterId = function (cluster) {
        var _this = this;
        var result;
        if (!cluster) {
            result = this.scopeParameterService.loadCountries();
        }
        else {
            result = this.scopeParameterService.getCountryByClusterId(cluster.id);
        }
        result.subscribe(function (data) {
            _this.optionsCountries = data;
            _this.countryCtrl.setValue(undefined);
            _this.getPlantsByCountryId(undefined);
        });
    };
    AdvancedSearchComponent.prototype.getPlantsByCountryId = function (country) {
        var _this = this;
        var result;
        if (!country) {
            result = this.scopeParameterService.loadPlants();
        }
        else {
            result = this.scopeParameterService.getPlantsByCountryId(country.id);
        }
        result.subscribe(function (data) {
            _this.optionsPlants = !country
                ? data.filter(function (d) {
                    if (_this.clusterCtrl.value) {
                        return d.clusterPid === _this.clusterCtrl.value.id;
                    }
                    return true;
                })
                : data;
            _this.plantCtrl.setValue(undefined);
            _this.getUnitsByPlantId(undefined);
        });
    };
    AdvancedSearchComponent.prototype.getUnitsByPlantId = function (plant) {
        var _this = this;
        var result;
        if (!plant) {
            result = this.scopeParameterService.loadProductionUnits();
        }
        else {
            result = this.scopeParameterService.getUnitsByPlantId(plant.id);
        }
        result.subscribe(function (data) {
            _this.optionsUnits = !plant
                ? data.filter(function (d) {
                    if (plant) {
                        return d.plantPid === plant.id;
                    }
                    else if (_this.countryCtrl.value) {
                        return d.countryPid === _this.countryCtrl.value.id;
                    }
                    else if (_this.clusterCtrl.value) {
                        return d.clusterPid === _this.clusterCtrl.value.id;
                    }
                    return true;
                })
                : data;
            _this.unitCtrl.setValue(undefined);
            _this.updateFiltredOptions();
        });
    };
    AdvancedSearchComponent.prototype.filter = function (collection, query) {
        if (!query) {
            return collection;
        }
        if (typeof query === 'string' && query.length >= this.AUTOCOPLETE_MINIMUM) {
            return collection.filter(function (option) {
                return option.name.toLowerCase().indexOf(query.toLocaleLowerCase()) > -1;
            });
        }
        var item = query;
        return collection.filter(function (option) {
            return option.name.toLowerCase().indexOf(item.name.toLocaleLowerCase()) > -1;
        });
    };
    return AdvancedSearchComponent;
}());
export { AdvancedSearchComponent };
