<div
  *ngIf="equipment"
  class="report"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxFill
  fxLayoutGap="20px"
>
  <div class="action" fxLayout="row" fxLayoutAlign="start center">
    <button
      class="uploadBtn"
      mat-flat-button
      color="primary"
      (click)="openUploadFileModal()"
    >
      <i class="material-icons">
        add_circle_outline
      </i>
      {{ 'cockpit.report.upload_new_file' | translate }}
    </button>
  </div>
  <div *ngIf="(reportDataSource.loading$ | async)" fxFlexAlign="stretch">
    <mat-progress-bar class="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  <ahc-table-upload
    [equipmentId]="equipment.pid"
    [reportDataSource]="reportDataSource"
  ></ahc-table-upload>
  <div class="action" fxLayout="row" fxLayoutAlign="start center">
    <button
      class="uploadBtn"
      mat-flat-button
      color="primary"
      (click)="openUploadLinkModal()"
    >
      <i class="material-icons">
        add_circle_outline
      </i>
      {{ 'cockpit.report.add_new_link' | translate }}
    </button>
  </div>
  <div *ngIf="(reportLinkDataSource.loading$ | async)" fxFlexAlign="stretch">
    <mat-progress-bar class="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  <ahc-table-link
    [equipmentId]="equipment.pid"
    [reportLinkDataSource]="reportLinkDataSource"
  ></ahc-table-link>
</div>
