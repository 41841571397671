import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit
} from '@angular/core';
import { FeedbackModel } from 'libs/shared-module/src/lib/models/feedback/feedback.model';

import * as GaugeChart from 'gauge-chart';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'ahc-feedback-item',
  templateUrl: './feedback-item.component.html',
  styleUrls: ['./feedback-item.component.scss']
})
export class FeedbackItemComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  feedback: FeedbackModel;

  @Input()
  compact = false;

  containerId: Guid;

  colors = ['#009933', '#00cc66', '#ffff00', '#ff9900', 'red'];
  constructor() {
    this.containerId = Guid.create();
  }

  private gauge;

  ngOnInit() {}

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (this.gauge) {
      this.gauge.updateNeedle(
        20 * parseInt(this.feedback.status.toString(), 0) - 10
      );
    }
  }

  ngAfterViewInit(): void {
    this.draw();
  }

  draw() {
    if (!this.compact) {
      // Element inside which you want to see the chart
      const element = document.getElementById(this.containerId.toString());

      // Properties of the gauge
      const gaugeOptions = {
        hasNeedle: true,
        needleColor: 'gray',
        needleUpdateSpeed: 1000,
        arcColors: this.colors,
        arcDelimiters: [20, 40, 60, 80]
      };

      // Drawing and updating the chart
      this.gauge = GaugeChart.gaugeChart(element, '120', gaugeOptions);
      this.gauge.updateNeedle(
        20 * parseInt(this.feedback.status.toString(), 0) - 10
      );
    }
  }
}
