import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import ExpertiseDomain from '../models/common/enums/expertise-domain.enum';
import { ScopeItemModel } from '../models/common/scope-item.model';
import { SearchResultModel } from '../models/common/search-result.model';

@Injectable()
export class ScopeParameterService {
  clusters: ScopeItemModel[] = [];
  countries: ScopeItemModel[] = [];
  plants: ScopeItemModel[] = [];
  units: ScopeItemModel[] = [];
  constructor(private http: HttpClient, private translator: TranslateService) {}

  loadClusters(): Observable<ScopeItemModel[]> {
    return this.http
      .get<SearchResultModel<ScopeItemModel[]>>('/api/v1/clusters')
      .pipe(map((result: SearchResultModel<ScopeItemModel[]>) => result.items)); // TODO: pagination
  }

  loadCountries(): Observable<ScopeItemModel[]> {
    return this.http
      .get<SearchResultModel<ScopeItemModel[]>>('/api/v1/countries')
      .pipe(map((result: SearchResultModel<ScopeItemModel[]>) => result.items)); // TODO: pagination
  }

  loadPlants(): Observable<ScopeItemModel[]> {
    return this.http
      .get<SearchResultModel<ScopeItemModel[]>>('/api/v1/plants')
      .pipe(map((result: SearchResultModel<ScopeItemModel[]>) => result.items)); // TODO: pagination
  }

  loadProductionUnits(): Observable<ScopeItemModel[]> {
    return this.http
      .get<SearchResultModel<ScopeItemModel[]>>(
        '/api/v1/productionunits?page=1&page_size=1000'
      )
      .pipe(map((result: SearchResultModel<ScopeItemModel[]>) => result.items)); // TODO: pagination
  }
  loadExpertiseDomains(): Observable<ScopeItemModel[]> {
    const values: ScopeItemModel[] = [
      {
        id: ExpertiseDomain.WATER,
        name: this.translator.instant('common.water')
      },
      {
        id: ExpertiseDomain.ELECTRICAL,
        name: this.translator.instant('common.electrical')
      },
      {
        id: ExpertiseDomain.MECHANICAL,
        name: this.translator.instant('common.mechanical')
      }
    ];

    return of(values);
  }

  getCountryByClusterId(cluster_id: string): Observable<ScopeItemModel[]> {
    return this.http
      .get<SearchResultModel<ScopeItemModel[]>>(
        'api/v1/countries?cluster_id=' + `${cluster_id}`
      )
      .pipe(map((result: SearchResultModel<ScopeItemModel[]>) => result.items));
  }
  getPlantsByCountryId(country_id: string): Observable<ScopeItemModel[]> {
    return this.http
      .get<SearchResultModel<ScopeItemModel[]>>(
        'api/v1/plants?country_id=' + `${country_id}`
      )
      .pipe(map((result: SearchResultModel<ScopeItemModel[]>) => result.items));
  }

  getUnitsByPlantId(plant_id: string): Observable<ScopeItemModel[]> {
    return this.http
      .get<SearchResultModel<ScopeItemModel[]>>(
        'api/v1/productionunits?plant_id=' + `${plant_id}&page=1&page_size=1000`
      )
      .pipe(map((result: SearchResultModel<ScopeItemModel[]>) => result.items));
  }
}
