import * as tslib_1 from "tslib";
import { map } from 'rxjs/operators';
import { ReportService } from '../report.provider';
import { TableDataSource } from './table-data-source.provider';
var ReportDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(ReportDataSourceService, _super);
    /**
     *
     */
    function ReportDataSourceService(dataReportService) {
        var _this = _super.call(this) || this;
        _this.dataReportService = dataReportService;
        return _this;
    }
    ReportDataSourceService.prototype.initialize = function (equipmentId, pageSize) {
        if (pageSize === void 0) { pageSize = 5; }
        this.currentPage = 1;
        this.init(pageSize, equipmentId);
    };
    ReportDataSourceService.prototype.getData = function (sort, filter, query, page) {
        return this.dataReportService
            .getEquipmentReports(this.equipmentId, this.currentPage, this.pageSize)
            .pipe(map(function (data) {
            data.items.sort(function (a, b) { return (a.createdAt < b.createdAt ? 1 : -1); });
            return data;
        }));
    };
    return ReportDataSourceService;
}(TableDataSource));
export { ReportDataSourceService };
