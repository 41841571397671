<div class="menu" fxLayout="row" fxLayoutAlign="space-between center">
  <nav mat-tab-nav-bar *ngIf="equipment">
    <a
      *ngIf="userIsInternal"
      class="item"
      mat-tab-link
      #homeRoute="routerLinkActive"
      (click)="activeLink = 'home'"
      [class.item--active]="homeRoute.isActive"
      [routerLink]="'home'"
      routerLinkActive
      [active]="homeRoute.isActive"
    >
      {{ 'cockpit.nav.home' | translate }}
    </a>
    <a
      *ngIf="userIsInternal"
      class="item"
      mat-tab-link
      #predictiveRoute="routerLinkActive"
      (click)="activeLink = 'predictive'"
      [class.item--active]="predictiveRoute.isActive"
      [routerLink]="'predictive'"
      routerLinkActive
      [active]="predictiveRoute.isActive"
    >
      {{ 'cockpit.nav.predictive_maintenance' | translate }}
    </a>
    <a
      *ngIf="userIsInternal"
      class="item"
      mat-tab-link
      #correctiveRoute="routerLinkActive"
      (click)="activeLink = 'corrective'"
      [class.item--active]="correctiveRoute.isActive"
      [routerLink]="'corrective'"
      routerLinkActive
      [active]="correctiveRoute.isActive"
    >
      {{ 'cockpit.nav.corrective_maintenance' | translate }}
    </a>
    <a
      class="item"
      mat-tab-link
      #scadaRoute="routerLinkActive"
      (click)="activeLink = 'scada'"
      [class.item--active]="scadaRoute.isActive"
      [routerLink]="'scada'"
      routerLinkActive
      [active]="scadaRoute.isActive"
    >
      {{ 'cockpit.nav.scada' | translate }}
    </a>
    <ng-container *ngIf="equipment">
      <a
        mat-tab-link
        class="item"
        *ngFor="let tab of getCurrentTabs()"
        (click)="activeLink = tab.route"
        [active]="activeLink === tab.route"
        [routerLink]="tab.route"
        [class.item--active]="activeLink === tab.route"
        routerLinkActive="{{ activeLink === tab.route }}"
        [routerLinkActiveOptions]="{ exact: true }"
      >
        {{ tab.label | translate }}
      </a>
    </ng-container>
    <a
      *ngIf="userIsInternal"
      class="item"
      mat-tab-link
      #reportRoute="routerLinkActive"
      (click)="activeLink = 'report'"
      [class.item--active]="reportRoute.isActive"
      [routerLink]="'report'"
      routerLinkActive
      [active]="reportRoute.isActive"
    >
      {{ 'cockpit.nav.report' | translate }}
    </a>
  </nav>
  <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="5px">
    <div *ngIf="equipment">
      <div
        *ngxPermissionsOnly="[
          permissions.ADMIN,
          permissions.INTERNAL,
          permissions.INTERNAL_KEY
        ]"
        (click)="exportEquipment()"
        class="download"
        matTooltip="{{ 'cockpit.nav.export_equipment.info' | translate }}"
      >
        <mat-spinner 
          *ngIf="loading; else elseBlock" 
          strokeWidth=3 
          diameter=20
        ></mat-spinner>
        <ng-template #elseBlock>
          <i class="download-icon material-icons">
            system_update_alt
          </i>
        </ng-template>
      </div>
    </div>
    <ahc-equipment-favorite
      *ngIf="equipment"
      [equipment]="equipment"
    ></ahc-equipment-favorite>
    <ahc-equipment-main
      *ngIf="equipment"
      [equipment]="equipment"
    ></ahc-equipment-main>
    <!-- 
      Do not delete, this functionality may be re-requested 
    -->
    <!-- <div class="more">
      <button class="trigger" mat-flat-button [matMenuTriggerFor]="menu">
        <mat-icon>more_horiz</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="menu-panel-dark">
        <button mat-menu-item (click)="openReportDataQualityIssue()">
          {{ 'cockpit.menu.report_data' | translate }}
        </button>
        <button mat-menu-item>
          {{ 'cockpit.menu.ask_for_data' | translate }}
        </button>
      </mat-menu>
    </div> -->
  </div>
</div>
