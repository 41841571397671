import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';

import { CognitoUser } from '@aws-amplify/auth';
import AuthStatus from 'libs/shared-module/src/lib/models/auth/auth.status.enum';
import { AuthService } from 'libs/shared-module/src/lib/providers/auth.provider';
import { Logger } from 'libs/shared-module/src/lib/providers/Logger.provider';

@Component({
  selector: 'ahc-external-login',
  templateUrl: './external-login.component.html',
  styleUrls: ['./external-login.component.scss']
})
export class ExternalLoginComponent implements OnInit {
  errorMessage: string;

  authFormGroup: FormGroup;
  authHasError = false;
  user: CognitoUser;

  constructor(
    private logger: Logger,
    private authService: AuthService,
    fb: FormBuilder,
    private router: Router
  ) {
    this.authFormGroup = fb.group({
      username: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {}

  get username() {
    return this.authFormGroup.get('username');
  }
  get password() {
    return this.authFormGroup.get('password');
  }

  async signIn() {
    this.authHasError = false;
    if (this.isFormValid()) {
      this.authService
        .signIn(this.username.value, this.password.value)
        .then((status: AuthStatus) => {
          if (status === AuthStatus.SUCCESS) {
            this.router.navigate(['main']);
          }
          if (status === AuthStatus.NEW_PASSWORD_REQUIRED) {
            this.router.navigate(['login/set']);
          } else {
            this.authHasError = true;
            this.errorMessage = status;
            this.logger.error('User sign in failed', status);
          }
        })
        .catch(err => {
          this.logger.error('User sign in request error', err);
        });
    }
  }

  isFormValid() {
    return (
      this.authFormGroup.controls['username'].valid &&
      this.authFormGroup.controls['username'].dirty &&
      this.authFormGroup.controls['password'].valid &&
      this.authFormGroup.controls['password'].dirty
    );
  }
}
