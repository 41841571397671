<div class="feedback" fxLayout="column" fxLayoutAlign="stretch">
  <mat-progress-bar
    *ngIf="(feedbackService.loading | async)"
    class="loading"
    mode="indeterminate"
  ></mat-progress-bar>
  <ahc-no-data
    *ngIf="feedbacks.length === 0 && !(feedbackService.loading | async)"
  ></ahc-no-data>
  <ahc-feedback-item
    *ngIf="feedbacks.length > 0"
    [feedback]="feedbacks[0]"
    [compact]="false"
  >
  </ahc-feedback-item>
  <div fxLayoutAlign="end" class="actions" fxLayoutGap="5px">
    <a (click)="toggleHistoryPanel()">{{
      'cockpit.feedback.actions.see_history' | translate
    }}</a>
    <a (click)="toggleFeedbackForm()">{{
      'cockpit.feedback.actions.add_feedback' | translate
    }}</a>
  </div>
  <div class="details">
    <mat-expansion-panel #formPanel class="mat-elevation-z1">
      <div
        [formGroup]="feedbackForm"
        fxLayout="column"
        fxLayoutAlign="space-around stretch"
      >
        <mat-form-field>
          <mat-label>{{ 'common.status' | translate }}</mat-label>
          <select matNativeControl required formControlName="status">
            <option value="1">{{
              'cockpit.feedback.status.very_good' | translate
            }}</option>
            <option value="2">{{
              'cockpit.feedback.status.good' | translate
            }}</option>
            <option value="3">{{
              'cockpit.feedback.status.warning' | translate
            }}</option>
            <option value="4">{{
              'cockpit.feedback.status.alert' | translate
            }}</option>
            <option value="5">{{
              'cockpit.feedback.status.danger' | translate
            }}</option>
          </select>
        </mat-form-field>
        <mat-form-field fxFill>
          <textarea
            matInput
            placeholder=""
            formControlName="message"
          ></textarea>
        </mat-form-field>

        <button
          fxFlexAlign="end"
          mat-flat-button
          color="primary"
          [disabled]="!feedbackForm.valid"
          (click)="saveFeedback()"
        >
          {{ 'cockpit.feedback.actions.add_feedback' | translate }}
        </button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel class="mat-elevation-z1" #historyPanel>
      <div fxLayout="column" fxLayoutAlign="center strech">
        <ng-container *ngFor="let feedback of feedbacks; let i = index">
          <ahc-feedback-item
            *ngIf="i > 0"
            [feedback]="feedback"
            [compact]="true"
          >
          </ahc-feedback-item>
        </ng-container>
      </div>
    </mat-expansion-panel>
  </div>
</div>
