import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats
} from '@angular/material';
import { SearchResultModel } from 'libs/shared-module/src/lib/models/common/search-result.model';
import { EquipmentAssetsSpecs } from 'libs/shared-module/src/lib/models/equipment';
import { EquipmentService } from 'libs/shared-module/src/lib/providers/equipment.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

export const AHC_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD MMM YYYY',
    monthYearA11yLabel: 'MMMM YYYY'
  }
} as MatDateFormats;

@Component({
  selector: 'ahc-equipment-extended-info',
  templateUrl: './equipment-extended-info.component.html',
  styleUrls: ['./equipment-extended-info.component.scss'],
  providers: [
    EquipmentService,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: AHC_FORMATS }
  ]
})
export class EquipmentExtendedInfoComponent implements OnInit, AfterViewInit {
  @Input()
  equipment: EquipmentViewModel;

  informations: Map<string, string> = new Map();

  constructor(
    private equipmentService: EquipmentService,
    private translator: TranslateService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.informations.set(
        this.translator.instant('cockpit.equipment.param_manufacturor'),
        this.equipment.manufacturer
      );
      this.informations.set(
        this.translator.instant('cockpit.equipment.param_installDate'),
        moment(this.equipment.installDate).format('DD MMM YYYY')
      );
      this.informations.set(
        this.translator.instant('cockpit.equipment.param_model'),
        this.equipment.assetTag
      );
      this.informations.set(
        this.translator.instant('cockpit.equipment.param_serialNum'),
        this.equipment.serialNum
      );

      this.equipmentService
        .getAssetSpecs(this.equipment)
        .subscribe((data: SearchResultModel<EquipmentAssetsSpecs[]>) => {
          data.items
            .sort((a, b) => (a.displaySequence < b.displaySequence ? 1 : -1))
            .forEach(specs => {
              if (specs.assetAttrId && specs.numValue) {
                this.informations.set(
                  specs.assetAttrId.toLocaleLowerCase(),
                  specs.numValue
                    ? `${specs.numValue} ${
                        specs.unit ? specs.unit.toLocaleLowerCase() : ''
                      }`
                    : '-'
                );
              }
            });
        });
    });
  }

  informationsToArray() {
    return Array.from(this.informations.entries());
  }

  onLastTARChange() {
    this.equipmentService.updateLastTAR(this.equipment).subscribe(done => {
      if (done) {
        this.snackbarService.open(
          this.translator.instant('common.update_success'),
          null
        );
      }
    });
  }

  onNextTarDeferredDateChange() {
    this.equipmentService
      .updateNextTarDeferredDate(this.equipment)
      .subscribe(done => {
        if (done) {
          this.snackbarService.open(
            this.translator.instant('common.update_success'),
            null
          );
        }
      });
  }

  onUpdateNextTarPmDateChange() {
    this.equipmentService
      .updateNextTarPmDate(this.equipment)
      .subscribe(done => {
        if (done) {
          this.snackbarService.open(
            this.translator.instant('common.update_success'),
            null
          );
        }
      });
  }
}
