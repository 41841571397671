var ImportTableModel = /** @class */ (function () {
    function ImportTableModel() {
        this.equipments = [];
        this.parameters = [];
    }
    ImportTableModel.prototype.convertToSheet = function () {
        var rows = [];
        rows.push([
            'Analyses',
            'Units'
        ].concat(this.equipments.map(function (eq, index) { return "Sample " + (index + 1); })));
        rows.push(['Site ID', ''].concat(this.equipments.map(function (eq) { return eq.siteId; })));
        rows.push(['Asset ID', ''].concat(this.equipments.map(function (eq) { return eq.assetId; })));
        this.parameters.map(function (parameter) {
            return rows.push([parameter.parameter.name, parameter.parameter.defaultUnits]);
        });
        return rows;
    };
    return ImportTableModel;
}());
export { ImportTableModel };
