import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
var Logger = /** @class */ (function () {
    function Logger(http) {
        this.http = http;
    }
    Logger.prototype.setUser = function (email) {
        this.user = email;
    };
    /**
     * log a info message
     * @param message message to add to log
     * @param obj optional object list to log
     */
    Logger.prototype.info = function (message) {
        var obj = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            obj[_i - 1] = arguments[_i];
        }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var event;
            return tslib_1.__generator(this, function (_a) {
                event = {
                    args: obj,
                    level: 'info',
                    message: message,
                    stackTrace: '',
                    user: this.user
                };
                this.http.post(ApiEndpointsEnum.LOG + "/info", event).subscribe();
                // tslint:disable-next-line: no-console
                if (!environment.production) {
                    console.info(message, event);
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * log a warn message
     * @param message message to add to log
     * @param obj optional object list to log
     */
    Logger.prototype.warn = function (message) {
        var obj = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            obj[_i - 1] = arguments[_i];
        }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var event;
            return tslib_1.__generator(this, function (_a) {
                event = {
                    args: obj,
                    level: 'warning',
                    message: message,
                    stackTrace: '',
                    user: this.user
                };
                this.http.post(ApiEndpointsEnum.LOG + "/warning", event).subscribe();
                if (!environment.production) {
                    console.warn(message, event);
                }
                return [2 /*return*/];
            });
        });
    };
    /**
     * log a error message
     * @param message message to add to log
     * @param obj optional object list to log
     */
    Logger.prototype.error = function (message) {
        var obj = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            obj[_i - 1] = arguments[_i];
        }
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var event;
            return tslib_1.__generator(this, function (_a) {
                event = {
                    args: obj,
                    level: 'error',
                    message: message,
                    stackTrace: '',
                    user: this.user
                };
                this.http.post(ApiEndpointsEnum.LOG + "/error", event).subscribe();
                if (!environment.production) {
                    console.error(message, event);
                }
                return [2 /*return*/];
            });
        });
    };
    return Logger;
}());
export { Logger };
