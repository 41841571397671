import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import ApiEndpointsEnum from '../../models/common/enums/api_endpoints.enum';
import { TableDataSourceResponseModel } from '../../models/common/table-data-source-response.model';
import { UnitSystemModel } from '../../models/profile/unit-system.model';

@Injectable()
export class UnitSystemDataService {
  /**
   *
   */
  constructor(private http: HttpClient) {}
  getUnits(): Observable<TableDataSourceResponseModel<UnitSystemModel>> {
    return this.http.get<TableDataSourceResponseModel<UnitSystemModel>>(
      ApiEndpointsEnum.USER_PREFERED_UNIT
    );
  }
}
