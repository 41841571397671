import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { CockpitModule } from './cockpit/cockpit.module';
import { UiCommonModule } from './common/uicommon.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginModule } from './login/login.module';
import { NotFoundComponent } from './notFound/notfound.component';

@NgModule({
  imports: [
    UiCommonModule,
    RouterModule,
    DashboardModule,
    LoginModule,
    CockpitModule,
    TranslateModule
  ],
  declarations: [NotFoundComponent]
})
export class AhcFrontUiModule {}
