<div
  class="home"
  fxLayout="column"
  fxLayoutAlign="space-around stretch"
  fxLayoutGap="50px"
>
  <ahc-feedback [tab]="'health_assessment'"></ahc-feedback>
  <ahc-global-info></ahc-global-info>
  <ahc-parameters-table
    *ngIf="equipment"
    [dataSource]="dataSource"
    [equipment]="equipment"
    [module]="activeTab"
    [graphService]="graphService"
    [dateFormat]="'dd MMM yyyy, hh:mm'"
  ></ahc-parameters-table>
</div>
