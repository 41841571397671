import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  Observable,
  of,
  Subject,
  Subscription,
  throwError
} from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import LevelEnum from '../models/common/level.enum';
import { SearchResultModel } from '../models/common/search-result.model';
import { NewUserBaseModel } from '../models/user/new-user-base.model';
import { UpdateUserModel } from '../models/user/update-user.model';
import { UserFavoriteScope } from '../models/user/user-favorite.model';
import { UserSearchFilter } from '../models/user/user-search-filter.model';
import { UserModel } from '../models/user/user.model';
import { UserViewModel } from '../viewmodels/user.viewmodel';

import { Logger } from './Logger.provider';
import { SnackbarService } from './snackbar.provider';

@Injectable()
export class UsersService {
  loading$ = new BehaviorSubject(false);

  constructor(
    private logger: Logger,
    private http: HttpClient,
    private snackBarService: SnackbarService,
    private translate: TranslateService
  ) {}

  private users$: BehaviorSubject<
    SearchResultModel<UserViewModel[]>
  > = new BehaviorSubject(Object.assign({}));
  private searchCriteria: UserSearchFilter = {
    page: 1,
    pageSize: 10,
    query: undefined,
    level: undefined
  };
  private httpRequest$: Subscription = new Subscription();
  private complete = new Subject();

  init(criteria?: UserSearchFilter) {
    if (criteria) {
      this.searchCriteria = criteria;
    }
    this.loadUsers();
  }

  refresh() {
    this.loadUsers();
  }

  onUsers(): Observable<SearchResultModel<UserViewModel[]>> {
    return this.users$.asObservable();
  }

  query(q: string) {
    this.complete.next();
    this.httpRequest$.unsubscribe();
    this.searchCriteria.page = 1;
    this.searchCriteria.query = q;
    this.loadUsers();
  }

  queryLevel(l: LevelEnum) {
    this.complete.next();
    this.searchCriteria.level = l;
    this.searchCriteria.page = 1;
    this.loadUsers();
  }

  goToPage(pageIndex: number, size: number) {
    this.complete.next();
    this.searchCriteria.page = pageIndex;
    this.searchCriteria.pageSize = size;
    this.loadUsers();
  }

  create(user: NewUserBaseModel) {
    return this.http.post(ApiEndpointsEnum.USER, user).pipe(
      catchError(error => {
        this.handleError(error, 'createUSer');
        return throwError(error);
      })
    );
  }
  update(user_id: string, user: UpdateUserModel) {
    return this.http.put(`${ApiEndpointsEnum.USER}/${user_id}`, user).pipe(
      catchError(error => {
        this.handleError(error, 'updateUser');
        return throwError(error);
      })
    );
  }
  updateScopes(user_id: string, user: NewUserBaseModel): Observable<object> {
    return this.http.put(`${ApiEndpointsEnum.USER}/${user_id}`, user).pipe(
      catchError(error => {
        this.handleError(error, 'updateScopesUser');
        return throwError(error);
      })
    );
  }

  updateFavorites(
    user_id: string,
    favorites: UserFavoriteScope
  ): Observable<object> {
    return this.http
      .put(`${ApiEndpointsEnum.USER}/${user_id}/favorites`, favorites)
      .pipe(
        catchError(error => {
          this.handleError(error, 'updateScopesUser');
          return throwError(error);
        })
      );
  }

  delete(userId: string) {
    this.logger.info('trying to delete user', { userId });
    return this.http.delete(`${ApiEndpointsEnum.USER}/${userId}`, {}).pipe(
      catchError(error => {
        this.handleError(error, 'deleteUser');
        return throwError(error);
      }),
      tap(result => this.logger.info('user deleted')),
      tap(done => {
        if (done != null) {
          this.snackBarService.open(
            this.translate.instant('dashboard.users.user_deleted'),
            null
          );
        }
      })
    );
  }

  private loadUsers() {
    this.loading$.next(true);
    const httpOptions = {
      headers: new HttpHeaders({
        'no-cache': 'true'
      })
    };
    let requestUrl = `${ApiEndpointsEnum.USER}?page=${
      this.searchCriteria.page
    }&page_size=${this.searchCriteria.pageSize}`;
    if (this.searchCriteria.query) {
      requestUrl += `&query=${this.searchCriteria.query}`;
    }
    if (this.searchCriteria.level) {
      requestUrl += `&level=${this.searchCriteria.level}`;
    }

    this.http
      .get<SearchResultModel<UserModel[]>>(requestUrl, httpOptions)
      .pipe(
        tap(() => this.loading$.next(false)),
        catchError(err => {
          this.users$.next(Object.assign({}));
          this.handleError(err, 'loadUsers');
          return of([]);
        }),
        takeUntil(this.complete)
      )
      .subscribe((result: SearchResultModel<UserViewModel[]>) => {
        this.users$.next({
          totalSize: result.totalSize,
          items: result.items
        });
      });
  }

  private handleError(error: HttpErrorResponse, action: string) {
    this.logger.error('UserProvider', action, error);
  }
}
