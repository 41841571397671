import { Component, Input, OnInit } from '@angular/core';

const ICONS_LIST: Map<string, string> = new Map([
  ['AS-1080', 'boiler'],
  ['AS-1080-A', 'boiler'],
  ['AS-1080-B', 'boiler'],
  ['AS-1080-C', 'boiler'],
  ['AS-1540', 'boiler'],
  ['AS-2360', 'boiler'],
  ['AS-2360-A', 'boiler'],
  ['AS-2360-C', 'boiler'],
  ['AS-2360-D', 'boiler'],
  ['AS-2360-H', 'boiler'],
  ['AS-2360-I', 'boiler'],
  ['AS-2360-P', 'boiler'],
  ['LO-1043-D', 'boiler'],
  ['LO-1043-E', 'boiler'],

  ['AS-1360', 'boiler'],
  ['AS-1360-A', 'boiler'],
  ['AS-1360-D', 'boiler'],
  ['AS-1360-E', 'boiler'],
  ['AS-1360-F', 'boiler'],
  ['AS-1360-G', 'boiler'],
  ['AS-1360-H', 'boiler'],
  ['AS-1360-I', 'boiler'],
  ['AS-1360-J', 'boiler'],
  ['AS-1360-K', 'boiler'],
  ['AS-1360-L', 'boiler'],

  ['AS-1360-C', 'tower'],
  ['AS-1360-B', 'tower'],

  ['AS-1290', 'compressor'],
  ['AS-1290-A', 'compressor'],
  ['AS-1290-B', 'compressor'],
  ['AS-1290-C', 'compressor'],
  ['AS-1290-D', 'compressor'],
  ['AS-1290-E', 'compressor'],
  ['AS-1290-F', 'compressor'],
  ['AS-1290-G', 'compressor'],
  ['AS-1290-I', 'compressor'],
  ['AS-1290-H', 'compressor'],
  ['AS-1295', 'compressor'],
  ['AS-1723-DC', 'compressor'],
  ['AS-1723-DP', 'compressor'],

  ['AS-1510', 'electrical-motor'],
  ['AS-1510-D', 'electrical-motor'],
  ['AS-1510-I', 'electrical-motor'],
  ['AS-1510-S', 'electrical-motor'],
  ['AS-1510-V', 'electrical-motor'],

  ['AS-1280-I', 'cold-box'],
  ['AS-1280-F', 'reactor'],
  ['AS-1280-A', 'tower'],

  ['LO-1043-A', 'tower'],
  ['LO-1043-B', 'tower'],
  ['LO-1043-C', 'tower'],
  ['LO-1043-F', 'tower'],

  ['AS-1055', 'tower'],
  ['AS-2305-W', 'tower'],
  ['AS-2400', 'tower'],

  ['AS-2280', 'transformer'],
  ['AS-2280-A', 'transformer'],
  ['AS-2280-B', 'transformer'],
  ['AS-2280-I', 'transformer'],
  ['AS-2280-P', 'transformer'],

  ['AS-1096', 'turbine'],
  ['AS-1096-B', 'turbine'],
  ['AS-1096-O', 'turbine'],
  ['AS-1190-T', 'turbine'],
  ['AS-1580', 'turbine'],
  ['AS-1590', 'turbine'],
  ['AS-1723-T', 'turbine'],
  ['AS-2300', 'turbine'],
  ['AS-2300-B', 'turbine'],
  ['AS-2300-C', 'turbine'],
  ['AS-2300-D', 'turbine'],
  ['AS-2300-I', 'turbine'],

  ['AS-1990-B2', 'pump'],
  ['AS-1990-B3', 'pump'],
  ['AS-1990-B4', 'pump'],
  ['AS-1990-B', 'pump'],
  ['AS-1990-M', 'pump'],

  ['AS-1610', 'centrifugal'],
  ['AS-1610-A', 'centrifugal'],
  ['AS-1610-B', 'centrifugal'],
  ['AS-1610-C', 'centrifugal']
]);

@Component({
  selector: 'ahc-equipment-icon',
  templateUrl: './equipment-icon.component.html',
  styleUrls: ['./equipment-icon.component.scss']
})
export class EquipmentIconComponent implements OnInit {
  @Input()
  classId: string;

  icon = 'unknown';

  constructor() {}

  ngOnInit() {
    if (ICONS_LIST.has(this.classId.toUpperCase())) {
      this.icon = ICONS_LIST.get(this.classId);
    }
  }
}
