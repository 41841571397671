<div fxLayout="column" fxLayoutAlign="center stretch" fxFill fxLayoutGap="20px">
    <h1 mat-dialog-title>{{ 'cockpit.report.modal_upload.title' | translate }}</h1>
    <mat-progress-bar
        *ngIf="isFileuploded"
        color="primary"
        mode="indeterminate"
    ></mat-progress-bar>
    <p *ngIf="errorMessage" class="error-upload-message">{{ 'common.error_message.generic_error' | translate }}</p>
    <form class="modal-upload-form" [formGroup]='uploadForm' (ngSubmit)="sendFiles()">
        <div mat-dialog-content class="cantent">
            <p>{{ 'cockpit.report.modal_upload.choose' | translate }}</p>
            <mat-form-field class="form-field">
                <ngx-mat-file-input 
                    formControlName="file"
                    placeholder="{{ 'cockpit.report.modal_upload.file' | translate }}" 
                    [accept]="'.pdf, .docx, .txt, .csv, .xlsx, image/*'"
                ></ngx-mat-file-input>
                <mat-icon matSuffix>folder</mat-icon>
            </mat-form-field>
        </div>
        <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
            <button 
                mat-button 
                type="reset" 
                (click)="closeModal()"
            >{{ 'common.cancel' | translate }}</button>
            <button 
                mat-button 
                type="submit"
                color="primary" 
                cdkFocusInitial 
                [disabled]="!uploadForm.valid" 
            >{{ 'cockpit.report.modal_upload.upload_file' | translate }}</button> 
        </div>
    </form>
</div>