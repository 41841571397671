<div class="plant" fxLayout="row" fxLayoutAlign="space-between center">
  <div
    class="card-id"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="30px"
  >
    <div class="plant-class" fxFlexAlign="center">
      <div class="status">
        <i class="status-icon ahc-fonts icon-plant"></i>
      </div>
      <div class="country">
        <mat-icon
          class="country-flag"
          svgIcon="{{ plant.country.toLocaleLowerCase() }}"
        >
        </mat-icon>
      </div>
    </div>
    <div
      class="plant-info"
      fxLayout="column"
      fxLayoutAlign="space-between start"
      fxLayoutGap="5px"
    >
      <span class="cluster">{{ plant.clusterName }}</span>
      <span class="name">{{ plant.name }}</span>
    </div>
  </div>
</div>
