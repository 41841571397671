import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { CatchesModel } from 'libs/shared-module/src/lib/models/cockpit/catches.model';
import SortEnum from 'libs/shared-module/src/lib/models/common/enums/sort.enum';
import { TableDataSourceResponseModel } from 'libs/shared-module/src/lib/models/common/table-data-source-response.model';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { CatchesDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/catches-data-source.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-catches',
  templateUrl: './catches.component.html',
  styleUrls: ['./catches.component.scss'],
  providers: [CatchesDataSourceService]
})
export class CatchesComponent implements OnInit, AfterViewInit {
  showFilter: boolean[] = [];
  pageSize = 5;
  totalPages: number;
  displayedColumns: string[] = [
    'assetNum',
    'description',
    'displayPriority',
    'status',
    'date',
    'feedback',
    'report'
  ];
  data: CatchesModel[] = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  constructor(
    public tableDataSource: CatchesDataSourceService,
    private cockpitProvider: CockpitService
  ) {
    this.cockpitProvider
      .onEquipment()
      .subscribe((equipment: EquipmentViewModel) => {
        this.equipment = equipment;
        this.tableDataSource.init(
          this.pageSize,
          equipment.pid,
          new Map([['date', SortEnum.desc]])
        );
      });
  }
  private equipment: EquipmentViewModel;

  ngOnInit() {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableDataSource.data$.subscribe(
        (result: TableDataSourceResponseModel<CatchesModel>) => {
          this.data = result.items;
          this.paginator.length = result.totalSize;
        }
      );
    });
    this.paginator.page.pipe().subscribe(() => {
      this.tableDataSource.page(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
      );
    });
  }

  filter(column: string, query: string) {
    if (query) {
      this.tableDataSource.addFilter(column, query);
    } else {
      this.tableDataSource.removeFilter(column);
    }
  }

  sort(column: string, direction: SortEnum) {
    if (direction === SortEnum.none) {
      this.tableDataSource.removeSort(column);
    } else {
      this.tableDataSource.addSort(column, direction);
    }
  }
}
