<form [formGroup]="profileForm">
  <div
    class="invite-user-panel"
    fxLayout="column"
    fxLayoutAlign="space-around stretch"
    fxLayoutGap="20px"
  >
    <span
      >{{ 'dashboard.users.create.external.header' | translate }}:
      {{ username }}
    </span>
    <div>
      <div class="profile-selection" fxLayoutGap="20px">
        <mat-form-field class="mat-form-field--rounded" appearance="fill">
          <mat-label>{{
            'dashboard.users.create.external.company.label' | translate
          }}</mat-label>
          <mat-select formControlName="companyPid">
            <mat-option *ngFor="let option of companies" [value]="option.id">{{
              option.name
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <span [formGroup]="companyForm">
          <mat-form-field
            *ngIf="profileForm.controls['companyPid'].value === 'other'"
            class="mat-form-field--rounded"
            appearance="fill"
          >
            <mat-label>{{
              'dashboard.users.create.external.company.new_company_label'
                | translate
            }}</mat-label>
            <input formControlName="name" matInput />
            <mat-icon
              style="cursor:pointer; font-size:30px"
              matSuffix
              color="primary"
              (click)="createCompany()"
              >add_circle</mat-icon
            >
          </mat-form-field>
        </span>

        <mat-form-field class="mat-form-field--rounded" appearance="fill">
          <mat-label>{{
            'dashboard.users.create.level.label' | translate
          }}</mat-label>
          <mat-select formControlName="level"
            ><mat-option value="external">external</mat-option
            ><mat-option value="external_key" *ngIf="currentUser.level !== 'external_key'">external_key</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="mat-form-field--rounded" appearance="fill">
          <mat-label>{{ 'common.firstName' | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'dashboard.users.invite_input_label' | translate }}"
            formControlName="firstname"
          />
        </mat-form-field>
        <mat-form-field class="mat-form-field--rounded" appearance="fill">
          <mat-label>{{ 'common.lastName' | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'dashboard.users.invite_input_label' | translate }}"
            formControlName="lastname"
          />
        </mat-form-field>
      </div>
    </div>
    <span>
      {{ 'dashboard.users.create.external.message' | translate }}
    </span>

    <ahc-auto-complete
      [placeHolder]="'dashboard.users.create.cluster_permission' | translate"
      [values]="clusters"
      (valueChange)="setClusters($event)"
    ></ahc-auto-complete>
    <ahc-auto-complete
      [placeHolder]="'dashboard.users.create.country_permission' | translate"
      [values]="countries"
      (valueChange)="setCountries($event)"
    ></ahc-auto-complete>
    <ahc-auto-complete
      [placeHolder]="'dashboard.users.create.plant_permission' | translate"
      [values]="plants"
      (valueChange)="setPlants($event)"
    ></ahc-auto-complete>
    <ahc-auto-complete
      [placeHolder]="
        'dashboard.users.create.expertise_domain_permission' | translate
      "
      [values]="expertiseDomains"
      (valueChange)="setExpertiseDomain($event)"
    ></ahc-auto-complete>
    <mat-error *ngIf="errorMessage">
      {{ errorMessage }}
    </mat-error>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
      <button mat-flat-button (click)="closeModal()">
        {{ 'common.cancel' | translate }}
      </button>
      <button mat-flat-button color="primary" (click)="sendInvite()">
        {{ 'dashboard.users.create.send_invite_action' | translate }}
      </button>
    </div>
  </div>
</form>
