/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./login-internal-user.component";
import * as i2 from "@ngx-translate/core";
var styles_LoginInternalUserComponent = [];
var RenderType_LoginInternalUserComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginInternalUserComponent, data: {} });
export { RenderType_LoginInternalUserComponent as RenderType_LoginInternalUserComponent };
export function View_LoginInternalUserComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
export function View_LoginInternalUserComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ahc-login-internal-user", [], null, null, null, View_LoginInternalUserComponent_0, RenderType_LoginInternalUserComponent)), i0.ɵdid(1, 114688, null, 0, i1.LoginInternalUserComponent, [i2.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginInternalUserComponentNgFactory = i0.ɵccf("ahc-login-internal-user", i1.LoginInternalUserComponent, View_LoginInternalUserComponent_Host_0, {}, {}, []);
export { LoginInternalUserComponentNgFactory as LoginInternalUserComponentNgFactory };
