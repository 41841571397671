<div
  class="extended-info"
  fxLayout="row"
  fxLayoutAlign="start start"
  fxLayoutGap="10px"
>
  <div fxLayout="column" fxLayoutAlign="start stretch" fxFlex="40">
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <mat-label fxFlex="50"
        >{{
          'cockpit.header.last_tar' | translate
        }}&nbsp;&nbsp;&nbsp;</mat-label
      >
      <mat-form-field class="compact-input" appearance="outline" fxFlex="50">
        <input
          matInput
          [matDatepicker]="pickerLastTar"
          (dateChange)="onLastTARChange()"
          [(ngModel)]="equipment.lastTarDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerLastTar"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerLastTar></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <mat-label fxFlex="50"
        >{{
          'cockpit.header.next_tar_pm' | translate
        }}&nbsp;&nbsp;&nbsp;</mat-label
      >
      <mat-form-field class="compact-input" appearance="outline" fxFlex="50">
        <input
          matInput
          [matDatepicker]="pickerTarPM"
          (dateChange)="onUpdateNextTarPmDateChange()"
          [(ngModel)]="equipment.nextTarPmDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerTarPM"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerTarPM></mat-datepicker>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <mat-label fxFlex="50"
        >{{
          'cockpit.header.next_tar_def' | translate
        }}&nbsp;&nbsp;&nbsp;</mat-label
      >
      <mat-form-field class="compact-input" appearance="outline" fxFlex="50">
        <input
          matInput
          [matDatepicker]="pickerTarDef"
          (dateChange)="onNextTarDeferredDateChange()"
          [(ngModel)]="equipment.nextTarDeferredDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="pickerTarDef"
        ></mat-datepicker-toggle>
        <mat-datepicker #pickerTarDef></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div
    class="parameters"
    fxLayout="row wrap"
    fxLayoutAlign="flex-start flex-start"
    fxFlex="60"
  >
    <ng-container *ngFor="let info of informationsToArray()">
      <div
        fxFlex="45%"
        *ngIf="info[0]"
        class="parameter item"
        fxLayout="row"
        fxLayoutAlign="space-between start"
      >
        <span class="label">{{ info[0] }}:</span>
        <span class="value"> {{ info[1] }}</span>
      </div>
    </ng-container>
  </div>
</div>
