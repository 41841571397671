import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationGuard } from 'libs/shared-module/src/lib/guards/application.guard';

import { NotFoundComponent } from '../notFound/notfound.component';

import { CockpitHomeComponent } from './cockpit-home/cockpit-home.component';
import { CockpitComponent } from './cockpit.component';
import { CorrectiveComponent } from './corrective/corrective.component';
import { OilAnalysisComponent } from './oil-analysis/oil-analysis.component';
import { OilTableComponent } from './oil-analysis/oil-table/oil-table.component';
import { PredictiveComponent } from './predictive/predictive.component';
import { ReportComponent } from './report/report.component';
import { ScadaComponent } from './scada/scada.component';
import { ServiceReportComponent } from './service-report/service-report.component';
import { TimelineComponent } from './timeline/timeline.component';
import { VibrationTableComponent } from './vibration/vibration-table/vibration-table.component';
import { VibrationComponent } from './vibration/vibration.component';
import { WaterComponent } from './water/water.component';

const routes: Routes = [
  {
    path: 'cockpit/:uid',
    component: CockpitComponent,
    canActivate: [ApplicationGuard],
    canActivateChild: [ApplicationGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: CockpitHomeComponent
      },
      {
        path: 'timeline',
        component: TimelineComponent
      },
      {
        path: 'predictive',
        component: PredictiveComponent
      },
      {
        path: 'corrective',
        component: CorrectiveComponent
      },
      {
        path: 'scada',
        component: ScadaComponent
      },
      {
        path: 'oil',
        component: OilAnalysisComponent,
        children: [
          { path: '', redirectTo: 'table', pathMatch: 'full' },
          { path: 'table', component: OilTableComponent }
        ]
      },
      {
        path: 'vibration',
        component: VibrationComponent,
        children: [
          { path: '', redirectTo: 'table', pathMatch: 'full' },
          { path: 'table', component: VibrationTableComponent }
        ]
      },
      {
        path: 'report',
        component: ReportComponent
      },
      {
        path: 'water',
        component: WaterComponent
      },
      {
        path: 'service-report',
        component: ServiceReportComponent
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CockpitRoutingModule {}
