/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./login-callback.component";
import * as i2 from "@angular/router";
import * as i3 from "../../../../../shared-module/src/lib/providers/auth.provider";
import * as i4 from "@ngx-translate/core";
import * as i5 from "../../../../../shared-module/src/lib/providers/storage.provider";
var styles_LoginCallbackComponent = [];
var RenderType_LoginCallbackComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginCallbackComponent, data: {} });
export { RenderType_LoginCallbackComponent as RenderType_LoginCallbackComponent };
export function View_LoginCallbackComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.message; _ck(_v, 1, 0, currVal_0); }); }
export function View_LoginCallbackComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ahc-login-callback", [], null, null, null, View_LoginCallbackComponent_0, RenderType_LoginCallbackComponent)), i0.ɵdid(1, 114688, null, 0, i1.LoginCallbackComponent, [i2.ActivatedRoute, i2.Router, i3.AuthService, i4.TranslateService, i5.StorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginCallbackComponentNgFactory = i0.ɵccf("ahc-login-callback", i1.LoginCallbackComponent, View_LoginCallbackComponent_Host_0, {}, {}, []);
export { LoginCallbackComponentNgFactory as LoginCallbackComponentNgFactory };
