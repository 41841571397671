import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseGraphParameterService } from './graph-parameter.base.provider';
import { Logger } from './Logger.provider';
import { ParameterService } from './parameter.provider';
import { ThresholdService } from './threshold.provider';
var ParameterGraphParameterService = /** @class */ (function (_super) {
    tslib_1.__extends(ParameterGraphParameterService, _super);
    function ParameterGraphParameterService(parameterService, logger, thresholdService) {
        var _this = _super.call(this) || this;
        _this.parameterService = parameterService;
        _this.logger = logger;
        _this.thresholdService = thresholdService;
        return _this;
    }
    ParameterGraphParameterService.prototype.loadData = function (parameterId, parameter, equipment) {
        this.equipment = equipment;
        this.parameter = parameter;
        this.parameterId = parameterId;
        // super.loadData(parameterId, parameter);
        _super.prototype.loadData.call(this, parameterId, parameter, equipment);
    };
    ParameterGraphParameterService.prototype.getValues = function (parameterId, parameter, moduleId) {
        if (!this.equipment) {
            this.logger.error('ParameterGraphParameterService', 'equipment is null');
            return of({
                name: parameter,
                values: []
            });
        }
        return forkJoin([
            this.thresholdService.batchThreshold({ parameterListPid: [parameterId] }),
            this.parameterService.getParameterValues(this.equipment.pid, parameterId, new Date(2000, 1, 1), moment().toDate())
        ]).pipe(map(function (results) {
            var mappedValues = [];
            Object.keys(results[1]).forEach(function (key) {
                return mappedValues.push(Object.assign({
                    x: moment(key).toDate(),
                    y: results[1][key]
                }));
            });
            var graphData = {
                name: parameter,
                values: mappedValues,
                threshold: results[0].items.find(function (item) { return item.parameterPid === parameterId; })
            };
            return graphData;
        }));
    };
    ParameterGraphParameterService.prototype.delete = function (dates) {
        var _this = this;
        this.loading$.next(true);
        this.parameterService
            .delete(this.parameterId, this.equipment.pid, dates)
            .subscribe(function (done) {
            _this.loadData(_this.parameterId, _this.parameter, _this.equipment);
            _this.points$.subscribe(function (points) {
                return _this.deletedPoint$.next(_this.points$.value);
            });
        });
    };
    return ParameterGraphParameterService;
}(BaseGraphParameterService));
export { ParameterGraphParameterService };
