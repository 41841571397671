import * as tslib_1 from "tslib";
import { map, switchMap } from 'rxjs/operators';
import DisplayZone from '../../models/common/enums/display-zone.enum';
import ExpertiseDomain from '../../models/common/enums/expertise-domain.enum';
import SortEnum from '../../models/common/enums/sort.enum';
import { ParameterService } from '../parameter.provider';
import { ThresholdService } from '../threshold.provider';
import { TableDataSource } from './table-data-source.provider';
var ParameterDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(ParameterDataSourceService, _super);
    /**
     *
     */
    function ParameterDataSourceService(dataService, thresholdService) {
        var _this = _super.call(this) || this;
        _this.dataService = dataService;
        _this.thresholdService = thresholdService;
        _this.expertiseDomain = ExpertiseDomain.ELECTRICAL;
        return _this;
    }
    ParameterDataSourceService.prototype.initialize = function (domain, pageSize, sort, filter) {
        if (pageSize === void 0) { pageSize = this.pageSize; }
        if (sort === void 0) { sort = new Map([['tab', SortEnum.asc]]); }
        if (filter === void 0) { filter = new Map([['display_zone', DisplayZone.TREND_BOX]]); }
        this.expertiseDomain = domain;
        this.currentPage = 1;
        this.init(pageSize, '', sort, filter);
    };
    ParameterDataSourceService.prototype.getData = function (sort, filter, query, page) {
        var _this = this;
        return this.dataService
            .getParameters(this.expertiseDomain, this.currentPage, this.pageSize, sort, filter)
            .pipe(switchMap(function (parameters) {
            return _this.thresholdService
                .batchThreshold({
                parameterListPid: parameters.items.map(function (item) { return item.id; })
            })
                .pipe(map(function (thresholds) {
                parameters.items.map(function (item) {
                    var found = thresholds.items.find(function (el) { return el.parameterPid === item.id; });
                    item.threshold =
                        found !== undefined
                            ? found
                            : {
                                parameterPid: item.id,
                                equipmentPid: null
                            };
                    return item;
                });
                return parameters;
            }));
        }));
    };
    return ParameterDataSourceService;
}(TableDataSource));
export { ParameterDataSourceService };
