<div
  *ngxPermissionsOnly="[
    permissions.ADMIN,
    permissions.INTERNAL,
    permissions.INTERNAL_KEY
  ]"
  class="favorite"
  (click)="toggle(); $event.stopPropagation()"
>
  <i
    class="favorite-icon material-icons"
    [ngClass]="{ active: this.equipment.isFavorite }"
  >
    grade
  </i>
</div>
