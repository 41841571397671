import * as tslib_1 from "tslib";
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ConnectedUserService } from './connected-user.provider';
var AhcPaginatorIntl = /** @class */ (function (_super) {
    tslib_1.__extends(AhcPaginatorIntl, _super);
    /**
     *
     */
    function AhcPaginatorIntl(connectedUser, translate) {
        var _this = _super.call(this) || this;
        connectedUser.onUser().subscribe(function (user) {
            _this.itemsPerPageLabel = translate.instant('common.ui.paginator_items_per_page');
        });
        return _this;
    }
    return AhcPaginatorIntl;
}(MatPaginatorIntl));
export { AhcPaginatorIntl };
