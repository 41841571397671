import { Component, Input, OnInit } from '@angular/core';

import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import { EquipmentService } from 'libs/shared-module/src/lib/providers/equipment.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-equipment-main',
  templateUrl: './equipment-main.component.html',
  styleUrls: ['./equipment-main.component.scss']
})
export class EquipmentMainComponent implements OnInit {
  @Input()
  equipment: EquipmentViewModel;

  public permissions = Permissions;

  constructor(private equipmentService: EquipmentService) {}

  ngOnInit() {}

  toggle() {
    this.equipmentService.toggleMain(this.equipment);
  }
}
