import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';

import { PlantViewModel } from 'libs/shared-module/src/lib/viewmodels/plant.viewmodel';

@Component({
  selector: 'ahc-plant-panel',
  templateUrl: './plant-panel.component.html',
  styleUrls: ['./plant-panel.component.scss']
})
export class PlantPanelComponent implements OnInit {
  @Input()
  plant: PlantViewModel;

  constructor(
    private iconRegister: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.iconRegister.addSvgIcon(
      this.plant.country.toLocaleLowerCase(),
      this.sanitizer.bypassSecurityTrustResourceUrl(
        `/assets/imgs/flags/${this.plant.country.toLocaleLowerCase()}.svg`
      )
    );
  }
}
