import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import ApiEndpointsEnum from '../../models/common/enums/api_endpoints.enum';
import { SortFilterDataServiceBase } from './sort-filter-data-service.base.provider';
var MaximoDataService = /** @class */ (function (_super) {
    tslib_1.__extends(MaximoDataService, _super);
    function MaximoDataService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        return _this;
    }
    MaximoDataService.prototype.getPredictive = function (equipmentId, page, pageSize, sort, filter) {
        var requestUrl = ApiEndpointsEnum.PREDICTIVE.replace('{0}', equipmentId) + "?page=" + page + "&page_size=" + pageSize;
        requestUrl += this.buildSort(sort);
        requestUrl += this.buildFilter(filter);
        return this.http.get(requestUrl);
    };
    MaximoDataService.prototype.getCorrective = function (equipmentId, page, pageSize, sort, filter) {
        var requestUrl = ApiEndpointsEnum.CORRECTIVE.replace('{0}', equipmentId) + "?page=" + page + "&page_size=" + pageSize;
        requestUrl += this.buildSort(sort);
        requestUrl += this.buildFilter(filter);
        return this.http.get(requestUrl);
    };
    MaximoDataService.prototype.getCatches = function (equipmentId, page, pageSize, sort, filter) {
        var requestUrl = ApiEndpointsEnum.CATCHES.replace('{0}', equipmentId) + "?page=" + page + "&page_size=" + pageSize;
        requestUrl += this.buildSort(sort);
        requestUrl += this.buildFilter(filter);
        return this.http.get(requestUrl);
    };
    return MaximoDataService;
}(SortFilterDataServiceBase));
export { MaximoDataService };
