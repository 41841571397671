<div
  class="search-equipement"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxLayoutGap="10px"
>
  <div class="header" fxLayoutAlign="start">
    <span class="title">{{ 'dashboard.search.title' | translate }}</span>
  </div>
  <div fxLayout="row" fxLayoutAlign="start">
    <div class="input-search" fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field
        [floatLabel]="'always'"
        class="search"
        (keyup.enter)="search()"
      >
        <input
          [formControl]="queryControl"
          [(ngModel)]="criteria.query"
          matInput
          placeholder="{{ 'dashboard.search.input_label' | translate }}"
        />
        <mat-icon
          matSuffix
          color="primary"
          class="search-action"
          (click)="search()"
          >search</mat-icon
        >
      </mat-form-field>
    </div>
    <div
      class="advanced-button"
      fxFlex.gt-sm="33%"
      fxLayoutAlign="space-between center"
      fxFlex="100"
    >
      <button
        class="advanced-button"
        mat-flat-button
        color="primary"
        (click)="toggleAdvancedSearch()"
      >
        {{ 'dashboard.search.advanced_action' | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="isVisibleSearch" fxLayout="row" fxLayoutWrap="wrap">
    <ahc-advanced-search
      [isVisibleSearch]="isVisibleSearch"
      (formChangeEvent)="handleVisibilityChange($event)"
    ></ahc-advanced-search>
  </div>
  <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
    <div>
      {{ 'dashboard.search.results' | translate: { count: resultCount } }}
    </div>
    <div class="selection">
      {{ 'common.display' | translate }}:
      <button class="selected-value" mat-button [matMenuTriggerFor]="appMenu">
        {{
          'dashboard.search.filter.' + criteria.filter.split(' ')[0] | translate
        }}
        <mat-icon class="trigger">keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #appMenu="matMenu">
        <mat-radio-group
          fxFill
          [(ngModel)]="criteria.filter"
          (change)="filter()"
          fxLayout="column"
        >
          <mat-radio-button
            class="menu-item"
            value="{{ enumFilterType.ALL }}"
            color="primary"
            >{{ 'dashboard.search.filter.all' | translate }}</mat-radio-button
          >
          <mat-radio-button
            class="menu-item"
            value="{{ enumFilterType.MAIN }}"
            color="primary"
            >{{ 'dashboard.search.filter.main' | translate }}</mat-radio-button
          >
          <mat-radio-button
            class="menu-item"
            value="{{ enumFilterType.FAVORITE }}"
            color="primary"
            >{{
              'dashboard.search.filter.favorite' | translate
            }}</mat-radio-button
          >
        </mat-radio-group>
      </mat-menu>
    </div>
  </div>
  <ahc-equipment-list
    fxLayoutAlign="stretch"
    [searchFilter]="criteria.filter"
  ></ahc-equipment-list>
  <mat-paginator
    [length]="resultCount"
    [pageSize]="25"
    [pageSizeOptions]="[5, 10, 25, 100]"
    [showFirstLastButtons]="true"
  >
  </mat-paginator>
</div>
