<div
  class="profile"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxFlexFill
  fxLayoutGap="40px"
>
  <div class="header" fxLayout="column" fxLayoutAlign="start center">
    <span class="title">{{ 'dashboard.profile.title' | translate }}</span>
  </div>
  <div class="menu">
    <nav mat-tab-nav-bar>
      <a
        class="item"
        mat-tab-link
        #infoRoute="routerLinkActive"
        [class.item--active]="infoRoute.isActive"
        [routerLink]="'info'"
        routerLinkActive
        [active]="infoRoute.isActive"
      >
        {{ 'dashboard.profile.sections.information' | translate }}
      </a>
      <a
        class="item"
        mat-tab-link
        #favRoute="routerLinkActive"
        [class.item--active]="favRoute.isActive"
        [routerLink]="'favorite'"
        routerLinkActive
        [active]="favRoute.isActive"
        *ngxPermissionsOnly="[
          permissions.ADMIN,
          permissions.INTERNAL,
          permissions.INTERNAL_KEY
        ]"
      >
        {{ 'dashboard.profile.sections.favoriteEquipement' | translate }}
      </a>
    </nav>
  </div>
  <div class="content" fxFlexAlign="center" fxFlex>
    <router-outlet></router-outlet>
  </div>
</div>
