import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError } from 'rxjs/operators';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { CompanyBaseModel } from '../models/company/company.base.model';
import { UserModel } from '../models/user/user.model';
import { UserViewModel } from '../viewmodels/user.viewmodel';

import { AuthService } from './auth.provider';
import { CompanyService } from './company.provider';

@Injectable()
export class ConnectedUserService {
  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private companyService: CompanyService,
    private injector: Injector
  ) {
    this.user$ = new ReplaySubject<UserViewModel>(1);
    this.auth.onUser().subscribe((_user: any) => {
      if (_user) {
        this.updateCurrentUser();
      } else {
        this.user$.next(undefined);
      }
    });
  }
  private user$: ReplaySubject<UserViewModel>;

  onUser(): Observable<UserViewModel> {
    return this.user$.asObservable();
  }

  signOut() {
    this.auth.logOut().subscribe((done: boolean) => {
      const router = this.injector.get(Router);
      router.navigate(['/login']);
    });
  }

  refresh() {
    this.updateCurrentUser();
  }

  private getConnectedUser(): Observable<UserModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'no-cache': 'true'
      })
    };

    return this.http.get<UserModel>(ApiEndpointsEnum.ACCOUNT, httpOptions).pipe(
      catchError(err => {
        return of(null);
      })
    );
  }
  private updateCurrentUser() {
    this.getConnectedUser().subscribe(_user => {
      if (_user) {
        this.user$.next({
          company: undefined,
          email: _user.email,
          firstname: _user.firstname,
          id: _user.id,
          isInternal: undefined,
          language: _user.language,
          lastname: _user.lastname,
          level: _user.level,
          scopes: _user.scopes,
          favorite: _user.favorite
        });
        this.companyService
          .getCompanyById(_user.company.id)
          .subscribe((company: CompanyBaseModel) => {
            this.user$.next({
              company: company,
              email: _user.email,
              firstname: _user.firstname,
              id: _user.id,
              isInternal: company.isInternal,
              language: _user.language,
              lastname: _user.lastname,
              level: _user.level,
              scopes: _user.scopes,
              favorite: _user.favorite
            });
          });
      } else {
        const router = this.injector.get(Router);
        // if it's the first connection of the external user, it will be redirected to the set password screen.
        // we add this exception.
        if (router.url.toLowerCase() !== '/login/set') {
          router.navigate(['/login/unauthorized']);
        }
      }
    });
  }
}
