import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { CognitoUser } from '@aws-amplify/auth';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../providers/auth.provider';
import { WelcomeService } from '../providers/welcome.provider';

@Injectable()
export class ApplicationGuard implements CanActivate, CanActivateChild {
  constructor(
    authService: AuthService,
    private _router: Router,
    private welcomeService: WelcomeService
  ) {
    authService
      .onUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((user: CognitoUser) => {
        this.isAuthenticated = user ? true : false;
      });
  }
  private ngUnsubscribe: Subject<void> = new Subject();
  private isAuthenticated = false;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canVisit(state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canVisit(state);
  }

  private canVisit(state: RouterStateSnapshot): boolean {
    if (!this.isAuthenticated) {
      this._router.navigate(['/login'], {
        queryParams: { returnUrl: state.url }
      });
    }
    this.welcomeService.updateFlag();
    if (
      this.isAuthenticated &&
      !state.url.toLocaleLowerCase().startsWith('/main/welcome') &&
      this.welcomeService.isFirstConnection
    ) {
      this.welcomeService.doWelcome();
      return;
    }

    return this.isAuthenticated;
  }
}
