import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

import { ParameterTableModel } from '../../models/cockpit/parameter-table.model';
import SortEnum from '../../models/common/enums/sort.enum';
import Tabs from '../../models/common/enums/tabs.enum';
import { TableDataSourceResponseModel } from '../../models/common/table-data-source-response.model';
import { ParameterService } from '../parameter.provider';
import { ThresholdService } from '../threshold.provider';

import { TableDataSource } from './table-data-source.provider';

@Injectable()
export class ParameterTableDataSourceService extends TableDataSource<
  ParameterTableModel
> {
  /**
   *
   */
  constructor(
    private parameterService: ParameterService,
    private thresholdService: ThresholdService
  ) {
    super();
  }
  private tab = Tabs.OIL_ANALYSIS;

  initialize(tab: Tabs, pageSize: number) {
    this.tab = tab;
    this.init(pageSize);
  }

  getData(
    sort: Map<string, SortEnum>,
    filter: Map<string, string>,
    query: string,
    page: number
  ): Observable<TableDataSourceResponseModel<ParameterTableModel>> {
    const results$: BehaviorSubject<
      TableDataSourceResponseModel<ParameterTableModel>
    > = new BehaviorSubject(null);

    this.parameterService
      .getEquipmentParameters(
        this.equipmentId,
        new Date(2000, 1, 1),
        moment().toDate(),
        this.tab,
        this.currentPage,
        this.pageSize,
        filter
      )
      .subscribe(result => {
        this.thresholdService
          .batchThreshold({
            parameterListPid: result.items.map(item => item.id)
          })
          .subscribe(threshold => {
            result.items.forEach(item => {
              item.threshold = threshold.items.find(
                values => values.parameterPid === item.id
              );
            });

            results$.next(result);
          });
      });

    return results$;
  }
}
