import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import ApiEndpointsEnum from '../../models/common/enums/api_endpoints.enum';
import { EquipmentModel } from '../../models/equipment/equipement.model';
import { EquipmentViewModel } from '../../viewmodels/equipment.viewmodel';


@Injectable()
export class EquipmentDataService {
  constructor(private http: HttpClient) {}

  getEquipmentViewModelById(id: string): Observable<EquipmentViewModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'no-cache': 'true'
      })
    };

    return this.http
      .get<EquipmentModel>(
        `${ApiEndpointsEnum.EQUIPMENTS}/${id}?with_scope=true`,
        httpOptions
      )
      .pipe(map(model => this.getEquipmentViewModelByModel(model)));
  }

  getEquipmentViewModelByModel(model: EquipmentModel): EquipmentViewModel {
    return EquipmentViewModel.fromModel(model);
  }
}
