import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger, MatPaginator } from '@angular/material';
import { map, startWith } from 'rxjs/operators';
import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import LevelEnum from 'libs/shared-module/src/lib/models/common/level.enum';
import { CompanyService } from 'libs/shared-module/src/lib/providers/company.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
var UserInformationListComponent = /** @class */ (function () {
    function UserInformationListComponent(usersService, companyService) {
        this.usersService = usersService;
        this.companyService = companyService;
        this.permissions = Permissions;
        this.users = { totalSize: 0, items: [] };
        this.filteredUsers = [];
        this.companies = [];
        this.companiesCtrl = new FormControl();
        this.selectedValue = 'ALL';
        this.translateKey = 'all';
        this.searchCtrl = new FormControl();
        this.searchCriteria = {
            page: 1,
            pageSize: 10,
            query: undefined,
            level: undefined
        };
    }
    UserInformationListComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.usersService
                    .onUsers()
                    .subscribe(function (data) {
                    if (!data) {
                        return;
                    }
                    _this.users = data;
                });
                this.usersService.init(this.searchCriteria);
                this.filtredCompanies = this.companiesCtrl.valueChanges.pipe(startWith(''), map(function (name) { return (name ? _this._filter(name) : _this.companies.slice()); }));
                this.companiesCtrl.valueChanges.subscribe(function (value) {
                    _this.selectedCompany = value;
                });
                this.searchCtrl.valueChanges.subscribe(function (value) {
                    _this.usersService.query(value);
                });
                this.paginator.page.pipe().subscribe(function (value) {
                    _this.usersService.goToPage(value.pageIndex + 1, value.pageSize);
                });
                this.companyService
                    .loadCompanies()
                    .subscribe(function (companies) { return (_this.companies = companies); });
                this.usersService.init({
                    page: 1,
                    pageSize: 10,
                    query: '',
                    level: undefined
                });
                return [2 /*return*/];
            });
        });
    };
    UserInformationListComponent.prototype.closeDisplayMenu = function () {
        this.trigger.closeMenu();
    };
    UserInformationListComponent.prototype.stopPropagation = function (event) {
        event.stopPropagation();
    };
    UserInformationListComponent.prototype.filterOptionSelected = function () {
        this.searchCtrl.setValue('');
        this.companiesCtrl.setValue('');
        this.doFilter();
    };
    UserInformationListComponent.prototype.companySelected = function (company) {
        this.selectedValue = company.name;
        this.translateKey = null;
        this.searchCtrl.setValue('');
        this.closeDisplayMenu();
        this.usersService.query(this.selectedValue);
    };
    UserInformationListComponent.prototype.doFilter = function () {
        var level;
        if (this.selectedValue) {
            switch (this.selectedValue.toLocaleLowerCase()) {
                case '':
                case 'all': {
                    level = undefined;
                    break;
                }
                case 'admin users': {
                    level = LevelEnum.ADMIN;
                    break;
                }
                case 'internal users': {
                    level = LevelEnum.INTERNAL;
                    break;
                }
                case 'internal key users': {
                    level = LevelEnum.INTERNAL_KEY;
                    break;
                }
                case 'external key users': {
                    level = LevelEnum.EXTERNAL_KEY;
                    break;
                }
                case 'external users': {
                    level = LevelEnum.EXTERNAL;
                    break;
                }
                default: {
                    level = undefined;
                }
            }
        }
        this.usersService.queryLevel(level);
    };
    UserInformationListComponent.prototype.displayFn = function (parameter) {
        return parameter ? parameter.name : undefined;
    };
    UserInformationListComponent.prototype._filter = function (name) {
        var filterValue = '';
        if (typeof name === 'string') {
            filterValue = name;
        }
        else {
            filterValue = name.name;
        }
        return this.companies
            .slice()
            .filter(function (option) { return option.name.toLowerCase().indexOf(filterValue) === 0; });
    };
    return UserInformationListComponent;
}());
export { UserInformationListComponent };
