import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppVersionModel } from '../models/common/app-version.model';

@Injectable()
export class AppVersionService {
  constructor(private httpClient: HttpClient) {}

  getBuildInfo(): Observable<AppVersionModel> {
    return this.httpClient.get<AppVersionModel>(`assets/data/build.json`).pipe(
      map(data => {
        return data;
      })
    );
  }
}
