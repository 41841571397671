<div fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="start center">
  <mat-card class="advanced-search" (clickOutside)="resetAll()">
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="search()">
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <mat-form-field
            class="mat-form-field--round mat-form-field--white form--item"
            appearance="fill"
            fxFlex="25"
          >
            <mat-label>{{ 'common.cluster' | translate }}</mat-label>
            <input
              matInput
              formControlName="cluster"
              [matAutocomplete]="auto"
            />
            <mat-icon
              class="clear"
              matSuffix
              (click)="
                clusterCtrl.setValue(undefined);
                getCountryByClusterId(undefined)
              "
              >clear</mat-icon
            >
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayWith"
              (optionSelected)="
                getCountryByClusterId($event.option.value); formUpdated()
              "
            >
              <mat-option
                *ngFor="let option of filteredClusterOptions"
                [value]="option"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field
            class="mat-form-field--round mat-form-field--white form--item"
            appearance="fill"
            fxFlex="25"
          >
            <mat-label>{{ 'common.country' | translate }}</mat-label>
            <input
              matInput
              formControlName="country"
              [matAutocomplete]="countrylist"
            />
            <mat-icon
              class="clear"
              matSuffix
              (click)="
                countryCtrl.setValue(undefined); getPlantsByCountryId(undefined)
              "
              >clear</mat-icon
            >
            <mat-autocomplete
              #countrylist="matAutocomplete"
              [displayWith]="displayWith"
              (optionSelected)="
                getPlantsByCountryId($event.option.value); formUpdated()
              "
            >
              <mat-option
                *ngFor="let option of filterCountriesOptions"
                [value]="option"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field
            class="mat-form-field--round mat-form-field--white form--item"
            appearance="fill"
            fxFlex="25"
          >
            <mat-label>{{ 'common.plant' | translate }}</mat-label>
            <input
              matInput
              formControlName="plant"
              [matAutocomplete]="plantList"
            />
            <mat-icon
              class="clear"
              matSuffix
              (click)="
                plantCtrl.setValue(undefined); getPlantsByCountryId(undefined)
              "
              >clear</mat-icon
            >
            <mat-autocomplete
              #plantList="matAutocomplete"
              [displayWith]="displayWith"
              (optionSelected)="
                getUnitsByPlantId($event.option.value); formUpdated()
              "
            >
              <mat-option
                *ngFor="let option of filterPlantsOptions"
                [value]="option"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field
            class="mat-form-field--round mat-form-field--white form--item"
            appearance="fill"
            fxFlex="25"
          >
            <mat-label>{{ 'common.unit' | translate }}</mat-label>
            <input
              matInput
              formControlName="unit"
              [matAutocomplete]="unitList"
            />
            <mat-icon
              class="clear"
              matSuffix
              (click)="
                unitCtrl.setValue(undefined); getUnitsByPlantId(undefined)
              "
              >clear</mat-icon
            >
            <mat-autocomplete
              #unitList="matAutocomplete"
              [displayWith]="displayWith"
            >
              <mat-option
                *ngFor="let option of filterUnitsOptions"
                [value]="option"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="center center">
          <mat-form-field
            class="mat-form-field--round mat-form-field--white form--item"
            appearance="fill"
            fxFlex="24"
          >
            <mat-label>{{ 'common.expertise_domain' | translate }}</mat-label>
            <input
              matInput
              formControlName="expertiseDomain"
              [matAutocomplete]="expertiseDomainList"
            />
            <mat-icon
              class="clear"
              matSuffix
              (click)="expertiseDomainCtrl.setValue(undefined)"
              >clear</mat-icon
            >
            <mat-autocomplete
              #expertiseDomainList="matAutocomplete"
              [displayWith]="displayWith"
            >
              <mat-option
                *ngFor="let option of filterExpertiseDomainOptions"
                [value]="option"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field
            class="mat-form-field--round mat-form-field--white form--item"
            appearance="fill"
            fxFlex
          >
            <mat-label>{{ 'common.equipment_class' | translate }}</mat-label>
            <input
              matInput
              formControlName="classStructure"
              [matAutocomplete]="expertiseClassList"
            />
            <mat-icon
              class="clear"
              matSuffix
              (click)="equipmentClassCtrl.setValue(undefined)"
              >clear</mat-icon
            >
            <mat-autocomplete
              #expertiseClassList="matAutocomplete"
              [displayWith]="displayWith"
            >
              <mat-option
                *ngFor="let option of filterClassStructureOptions"
                [value]="option"
              >
                {{ option.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </form>
    </mat-card-content>
    <div
      class="action-bar"
      fxLayout="row"
      fxLayoutAlign="end"
      fxLayoutGap="10px"
    >
      <button class="action-item" mat-raised-button (click)="resetAll()">
        {{ 'common.resetAll' | translate }}
      </button>
      <button
        class="action-item"
        mat-flat-button
        color="primary"
        (click)="search()"
      >
        {{ 'common.apply' | translate }}
      </button>
    </div>
  </mat-card>
</div>
