import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import { ScopeItemModel } from 'libs/shared-module/src/lib/models/common/scope-item.model';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
import { UserViewModel } from 'libs/shared-module/src/lib/viewmodels/user.viewmodel';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AutoCompleteValueItem } from '../../../common/auto-complete/auto-complete-value-item.model';

interface Scope {
  clusters: ScopeItemModel[];
  countries: ScopeItemModel[];
  plants: ScopeItemModel[];
  expertiseDomains: ScopeItemModel[];
}

@Component({
  selector: 'ahc-profile-favorite-equipment',
  templateUrl: './profile-favorite-equipment.component.html',
  styleUrls: ['./profile-favorite-equipment.component.scss']
})
export class ProfileFavoriteEquipmentComponent implements OnInit {
  loading = true;
  initial: Scope = {
    clusters: [],
    countries: [],
    expertiseDomains: [],
    plants: []
  };

  favorite: Scope = {
    clusters: [],
    countries: [],
    expertiseDomains: [],
    plants: []
  };
  user: UserViewModel;
  constructor(
    private scopeParameterService: ScopeParameterService,
    private connectedService: ConnectedUserService,
    private userService: UsersService,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {}
  private ngUnsubscribe: Subject<void> = new Subject();
  ngOnInit() {
    this.connectedService
      .onUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((_user: UserViewModel) => {
        if (_user) {
          this.user = _user;
          this.getData();
        }
      });
  }
  getData() {
    this.loading = true;
    const clustersSource = this.scopeParameterService.loadClusters();
    const countriesSource = this.scopeParameterService.loadCountries();
    const plantsSource = this.scopeParameterService.loadPlants();
    const expertiseDomainsSource = this.scopeParameterService.loadExpertiseDomains();

    forkJoin([
      clustersSource,
      countriesSource,
      plantsSource,
      expertiseDomainsSource
    ]).subscribe(results => {
      this.initial.clusters = results[0];
      this.initial.countries = results[1];
      this.initial.plants = results[2];
      this.initial.expertiseDomains = results[3];

      this.favorite.clusters = this.user.favorite.clusters
        ? this.initial.clusters.filter(
            c => this.user.favorite.clusters.indexOf(c.id) > -1
          )
        : [];
      this.favorite.countries = this.user.favorite.countries
        ? this.initial.countries.filter(
            c => this.user.favorite.countries.indexOf(c.id) > -1
          )
        : [];
      this.favorite.expertiseDomains = this.user.favorite.expertiseDomains
        ? this.initial.expertiseDomains.filter(
            c =>
              this.user.favorite.expertiseDomains.indexOf(
                c.id as ExpertiseDomain
              ) > -1
          )
        : [];
      this.favorite.plants = this.user.favorite.plants
        ? this.initial.plants.filter(
            c => this.user.favorite.plants.indexOf(c.id) > -1
          )
        : [];

      this.loading = false;
    });
  }
  setClusters(values: AutoCompleteValueItem[]) {
    this.user.favorite.clusters = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setCountries(values: AutoCompleteValueItem[]) {
    this.user.favorite.countries = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setPlants(values: AutoCompleteValueItem[]) {
    this.user.favorite.plants = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setProductionUnits(values: AutoCompleteValueItem[]) {
    this.user.favorite.productionUnits = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setExpertiseDomain(values: AutoCompleteValueItem[]) {
    this.user.favorite.expertiseDomains = values.map(
      (value: AutoCompleteValueItem) => value.id as ExpertiseDomain
    );
  }
  updateFavorites() {
    this.loading = true;
    this.userService
      .updateFavorites(this.user.id, {
        favorites: {
          clusters: this.favorite.clusters.map(item => item.id),
          countries: this.favorite.countries.map(item => item.id),
          expertiseDomains: this.favorite.expertiseDomains.map(
            item => item.id as ExpertiseDomain
          ),
          plants: this.favorite.plants.map(item => item.id),
          productionUnits: [] // TODO check if we keep this field
        }
      })
      .subscribe(done => {
        if (done) {
          this.snackbarService.open(
            this.translate.instant('dashboard.users.updated'),
            this.translate.instant('common.success')
          );
          this.loading = false;
        }
      });
  }
}
