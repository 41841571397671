<div
  class="invite"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutWrap="wrap"
  fxFlex="100"
>
  <mat-form-field [floatLabel]="'always'" class="email">
    <input
      matInput
      placeholder="{{ 'dashboard.users.invite_input_label' | translate }}"
      [formControl]="emailCtrl"
      required
    />
  </mat-form-field>
  <button
    mat-flat-button
    color="primary"
    [disabled]="emailCtrl.invalid"
    (click)="openCreateUser()"
  >
    {{ 'dashboard.users.invite_action' | translate }}
  </button>
</div>
