import { Component, Input, OnInit } from '@angular/core';

import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import { FavoriteEquipmentService } from 'libs/shared-module/src/lib/providers/favorite-equipment.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-equipment-favorite',
  templateUrl: './equipment-favorite.component.html',
  styleUrls: ['./equipment-favorite.component.scss']
})
export class EquipmentFavoriteComponent implements OnInit {
  @Input()
  equipment: EquipmentViewModel;
  permissions = Permissions;

  constructor(private favoriteProvider: FavoriteEquipmentService) {}

  ngOnInit() {}

  toggle() {
    this.favoriteProvider
      .toggle(this.equipment)
      .subscribe(
        () => (this.equipment.isFavorite = !this.equipment.isFavorite)
      );
  }
}
