import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ApplicationGuard } from 'libs/shared-module/src/lib/guards/application.guard';
import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import { NgxPermissionsGuard } from 'ngx-permissions';

import { NotFoundComponent } from '../notFound/notfound.component';

import { AdminComponent } from './admin/admin.component';
import { DashboardComponent } from './dashboard.component';
import { ImportComponent } from './import/import.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfileFavoriteEquipmentComponent } from './profile/profile-favorite-equipment/profile-favorite-equipment.component';
import { ProfileHomeComponent } from './profile/profile-home/profile-home.component';
import { ProfileInformationComponent } from './profile/profile-information/profile-information.component';
import { ProfileUnitSystemPreferenceComponent } from './profile/profile-unit-system-preference/profile-unit-system-preference.component';
import { SearchEquipmentComponent } from './search-equipment/search-equipment.component';
import { UsersComponent } from './users/users.component';
import { WelcomeComponent } from './welcome/welcome/welcome.component';

const routes: Routes = [
  {
    path: 'main',
    canActivate: [ApplicationGuard],
    canActivateChild: [ApplicationGuard],
    component: DashboardComponent,
    children: [
      { path: '', redirectTo: 'search', pathMatch: 'full' },
      { path: 'welcome', component: WelcomeComponent },
      { path: 'search', component: SearchEquipmentComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: 'upload', component: ImportComponent },
      {
        path: 'profile',
        component: ProfileHomeComponent,
        children: [
          { path: '', redirectTo: 'info', pathMatch: 'full' },
          { path: 'info', component: ProfileInformationComponent },
          {
            path: 'favorite',
            component: ProfileFavoriteEquipmentComponent,
            canActivate: [NgxPermissionsGuard],
            data: {
              permissions: {
                only: [
                  Permissions.ADMIN,
                  Permissions.INTERNAL,
                  Permissions.INTERNAL_KEY
                ],
                redirectTo: '/main/profile/info'
              }
            }
          },
          { path: 'unit', component: ProfileUnitSystemPreferenceComponent }
        ]
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: [Permissions.ADMIN, Permissions.INTERNAL_KEY],
            redirectTo: '/main'
          }
        }
      },
      {
        path: '**',
        component: NotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class DashboardRouterModule {}
