<div fxLayout="row" fxFlex="70%" fxLayoutAlign="start">
  <form [formGroup]="setFG" fxLayout="column" fxFlex="100%">
    <span class="header">
      {{ 'login.setPassword' | translate }}
    </span>
    <mat-form-field>
      <input
        formControlName="password"
        type="password"
        matInput
        placeholder="{{ 'login.password' | translate }}"
      />
      <mat-error *ngIf="password.invalid">{{
        'login.password_format' | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field>
      <input
        formControlName="confirmPassword"
        type="password"
        matInput
        placeholder="{{ 'login.reset.do.confirmPassword' | translate }}"
      />
      <mat-hint>{{ 'login.password_format' | translate }}</mat-hint>
    </mat-form-field>
    <div fxLayout="row" class="action-bar">
      <button
        type="submit"
        fxFlex="50%"
        [disabled]="!isFormValid()"
        mat-raised-button
        color="primary"
        (click)="setPassword()"
      >
        {{ 'login.reset.do.resetPassword' | translate }}
      </button>
    </div>
  </form>
</div>
