<div fxLayout="column" fxLayoutAlign="start stretch" fxFill fxLayoutGap="20px">
  <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px">
    <ahc-feedback [tab]="'oil_analysis'" fxFlex="55"></ahc-feedback>
    <ahc-parameters-preview
      fxFlex="45"
      [activeTab]="'oil_analysis'"
    ></ahc-parameters-preview>
  </div>
  <router-outlet></router-outlet>
</div>
