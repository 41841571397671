/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./equipment-favorite.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/flex-layout/extended";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "ngx-permissions";
import * as i6 from "./equipment-favorite.component";
import * as i7 from "../../../../../shared-module/src/lib/providers/favorite-equipment.provider";
var styles_EquipmentFavoriteComponent = [i0.styles];
var RenderType_EquipmentFavoriteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EquipmentFavoriteComponent, data: {} });
export { RenderType_EquipmentFavoriteComponent as RenderType_EquipmentFavoriteComponent };
function View_EquipmentFavoriteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "favorite"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.toggle();
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "i", [["class", "favorite-icon material-icons"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { active: 0 }), i1.ɵdid(5, 933888, null, 0, i3.DefaultClassDirective, [i1.ElementRef, i4.StyleUtils, i4.MediaMarshaller, i2.ɵNgClassImpl, [6, i2.NgClass]], { ngClass: [0, "ngClass"], klass: [1, "klass"] }, null), i1.ɵpod(6, { active: 0 }), (_l()(), i1.ɵted(-1, null, [" grade "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "favorite-icon material-icons"; var currVal_1 = _ck(_v, 4, 0, _co.equipment.isFavorite); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 6, 0, _co.equipment.isFavorite); var currVal_3 = "favorite-icon material-icons"; _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
export function View_EquipmentFavoriteComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_EquipmentFavoriteComponent_1)), i1.ɵdid(1, 737280, null, 0, i5.NgxPermissionsDirective, [i5.NgxPermissionsService, i5.NgxPermissionsConfigurationService, i5.NgxRolesService, i1.ViewContainerRef, i1.ChangeDetectorRef, i1.TemplateRef], { ngxPermissionsOnly: [0, "ngxPermissionsOnly"] }, null), i1.ɵpad(2, 3)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, _co.permissions.ADMIN, _co.permissions.INTERNAL, _co.permissions.INTERNAL_KEY); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_EquipmentFavoriteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ahc-equipment-favorite", [], null, null, null, View_EquipmentFavoriteComponent_0, RenderType_EquipmentFavoriteComponent)), i1.ɵdid(1, 114688, null, 0, i6.EquipmentFavoriteComponent, [i7.FavoriteEquipmentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EquipmentFavoriteComponentNgFactory = i1.ɵccf("ahc-equipment-favorite", i6.EquipmentFavoriteComponent, View_EquipmentFavoriteComponent_Host_0, { equipment: "equipment" }, {}, []);
export { EquipmentFavoriteComponentNgFactory as EquipmentFavoriteComponentNgFactory };
