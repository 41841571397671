import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { TimelineModel } from 'libs/shared-module/src/lib/models/cockpit/timeline.model';
import SortEnum from 'libs/shared-module/src/lib/models/common/enums/sort.enum';
import { TableDataSourceResponseModel } from 'libs/shared-module/src/lib/models/common/table-data-source-response.model';
import { TableDataSource } from 'libs/shared-module/src/lib/providers/dataSource/table-data-source.provider';

@Component({
  selector: 'ahc-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, AfterViewInit {
  showFilter: boolean[] = [];
  totalPages: number;
  displayedColumns: string[] = [
    'nature',
    'number',
    'description',
    'status',
    'priority',
    'first report date'
  ];
  data: TimelineModel[] = [];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(public tableDataSource: TableDataSource<TimelineModel>) {}

  ngOnInit() {
    this.tableDataSource.init(5, 'timeline');
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableDataSource.data$.subscribe(
        (result: TableDataSourceResponseModel<TimelineModel>) => {
          this.data = result.items;
          this.paginator.length = result.totalSize;
        }
      );
    });
    this.paginator.page.pipe().subscribe(() => {
      this.tableDataSource.page(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
      );
    });
  }

  filter(column: string, query: string) {
    if (query) {
      this.tableDataSource.addFilter(column, query);
    } else {
      this.tableDataSource.removeFilter(column);
    }
  }

  sort(column: string, direction: SortEnum) {
    if (direction === SortEnum.none) {
      this.tableDataSource.removeSort(column);
    } else {
      this.tableDataSource.addSort(column, direction);
    }
  }
}
