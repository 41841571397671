import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ParameterDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/parameter-data-source.provider';

import { ApplicationGuard } from './guards/application.guard';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AppVersionService } from './providers/app-version.provider';
import { ClassStructureService } from './providers/class-structure.provider';
import { CockpitService } from './providers/cockpit.provider';
import { ClusterDataService } from './providers/dataServices/cluster-data-service.provider';
import { CountryDataService } from './providers/dataServices/country-data-service.provider';
import { EquipmentDataService } from './providers/dataServices/equipment-data-service.provider';
import { LireDataService } from './providers/dataServices/lire-data-service.provider';
import { MaximoDataService } from './providers/dataServices/maximo-data-service.provider';
import { OilDataService } from './providers/dataServices/oil-data-service.provider';
import { PlantDataService } from './providers/dataServices/plant-data-service.provider';
import { ProductionUnitDataService } from './providers/dataServices/production-unit-data-service.provider';
import { ScadaDataService } from './providers/dataServices/scada-data-service.provider';
import { UnitSystemDataService } from './providers/dataServices/unit-system-data-service.provider';
import { CatchesDataSourceService } from './providers/dataSource';
import { CorrectiveDataSourceService } from './providers/dataSource/corrective-data-source.provider';
import { LireDataSourceService } from './providers/dataSource/lire-data-source.provider';
import { PredictiveDataSourceService } from './providers/dataSource/predictive-data-source.provider';
import { ScadaDataSourceService } from './providers/dataSource/scada-data-source.provider';
import { UnitSystemDataSourceService } from './providers/dataSource/unit-system-data-source.provider';
import { EquipmentService } from './providers/equipment.provider';
import { FavoriteEquipmentService } from './providers/favorite-equipment.provider';
import { ImportParametersValueService } from './providers/import-parameters-value.provider';
import { AhcPaginatorIntl } from './providers/paginator-intl.provider';
import { ParameterGraphParameterService } from './providers/parameter-graph-parameter.provider';
import { ScopeParameterService } from './providers/scope-parameters.provider';
import { StorageService } from './providers/storage.provider';
import { UsersService } from './providers/users.provider';
import { WelcomeService } from './providers/welcome.provider';

@NgModule({
  imports: [
    HttpClientModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    AppVersionService,
    TranslateService,
    ApplicationGuard,
    StorageService,
    WelcomeService,
    UsersService,
    ScopeParameterService,
    FavoriteEquipmentService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    CockpitService,
    EquipmentService,
    EquipmentDataService,
    CountryDataService,
    ClusterDataService,
    PlantDataService,
    ProductionUnitDataService,
    MaximoDataService,
    PredictiveDataSourceService,
    CatchesDataSourceService,
    CorrectiveDataSourceService,
    ScadaDataSourceService,
    ScadaDataService,
    OilDataService,
    LireDataSourceService,
    LireDataService,
    ParameterDataSourceService,
    UnitSystemDataSourceService,
    UnitSystemDataService,
    ParameterGraphParameterService,
    ClassStructureService,
    ImportParametersValueService,
    AhcPaginatorIntl
  ]
})
export class SharedModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
