<div class="reset" fxLayout="row" fxFill="auto" fxLayoutGap="20px">
  <div fxLayout="column">
    <span class="label">
      {{ 'login.reset.label' | translate }}
    </span>
    <mat-form-field class="email">
      <input type="email" matInput placeholder="{{ 'common.email' | translate }}" [formControl]="emailFormControl">
    </mat-form-field>
    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
      {{ 'login.reset.emailValidationError' | translate }}
    </mat-error>
    <button mat-raised-button class="reset--validation" color="primary" (click)="doReset()" [disabled]="!emailFormControl.valid"
      fxFlex="50">{{ 'login.reset.sendRequest' | translate }}</button>
  </div>
</div>