<div>
  <span class="title">
    {{ 'dashboard.profile.sections.edit_favorite_label' | translate }}</span
  >
  <div class="scrollable">
    <div
      fxLayout="column"
      class="favorite"
      fxLayoutGap="20px"
      fxLayoutAlign="space-around stretch"
    >
      <mat-progress-bar
        *ngIf="loading"
        class="loading"
        mode="indeterminate"
      ></mat-progress-bar>
      <ahc-auto-complete
        [placeHolder]="'dashboard.users.create.cluster_permission' | translate"
        [values]="initial.clusters"
        [selectedValues]="favorite.clusters"
        (valueChange)="setClusters($event)"
      ></ahc-auto-complete>
      <ahc-auto-complete
        [placeHolder]="'dashboard.users.create.country_permission' | translate"
        [values]="initial.countries"
        [selectedValues]="favorite.countries"
        (valueChange)="setCountries($event)"
      ></ahc-auto-complete>

      <ahc-auto-complete
        [placeHolder]="'dashboard.users.create.plant_permission' | translate"
        [values]="initial.plants"
        [selectedValues]="favorite.plants"
        (valueChange)="setPlants($event)"
      ></ahc-auto-complete>
      <ahc-auto-complete
        [placeHolder]="
          'dashboard.users.create.expertise_domain_permission' | translate
        "
        [values]="initial.expertiseDomains"
        [selectedValues]="favorite.expertiseDomains"
        (valueChange)="setExpertiseDomain($event)"
      ></ahc-auto-complete>
    </div>
  </div>
  <div fxLayout="row" fxLayoutAlign="center">
    <button
      class="action"
      mat-flat-button
      color="primary"
      (click)="updateFavorites()"
    >
      {{ 'common.save' | translate }}
    </button>
  </div>
</div>
