import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Auth } from 'aws-amplify';
import { AuthService } from 'libs/shared-module/src/lib/providers/auth.provider';
import { Router } from '@angular/router';
import { Logger } from 'libs/shared-module/src/lib/providers/Logger.provider';
export var passwordConfirmationValidator = function (control) {
    var password = control.get('password');
    var confirmPassword = control.get('confirmPassword');
    return password && confirmPassword && password.value !== confirmPassword.value
        ? { notEqual: true }
        : null;
};
var LoginSetFirstNewPasswordComponent = /** @class */ (function () {
    function LoginSetFirstNewPasswordComponent(logger, fb, auth, router) {
        this.logger = logger;
        this.auth = auth;
        this.router = router;
        this.setFG = fb.group({
            password: new FormControl('', [
                Validators.required,
                Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
            ]),
            confirmPassword: new FormControl('', [
                Validators.required,
                Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{8,}')
            ])
        }, {
            validators: passwordConfirmationValidator
        });
    }
    LoginSetFirstNewPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.auth.onUser().subscribe(function (userAws) { return (_this.user = userAws); });
    };
    Object.defineProperty(LoginSetFirstNewPasswordComponent.prototype, "password", {
        get: function () {
            return this.setFG.get('password');
        },
        enumerable: true,
        configurable: true
    });
    LoginSetFirstNewPasswordComponent.prototype.isFormValid = function () {
        return (!this.setFG.errors &&
            this.setFG.controls['password'].valid &&
            this.setFG.controls['password'].dirty &&
            this.setFG.controls['confirmPassword'].valid &&
            this.setFG.controls['confirmPassword'].dirty);
    };
    LoginSetFirstNewPasswordComponent.prototype.setPassword = function () {
        var _this = this;
        if (this.isFormValid()) {
            var userAttributes = null;
            Auth.completeNewPassword(this.user, this.password.value, userAttributes)
                .then(function () {
                _this.router.navigateByUrl('/login');
            })
                .catch(function (err) {
                _this.logger.error('Auto complete new password for user failed', _this.user, err);
            });
        }
    };
    return LoginSetFirstNewPasswordComponent;
}());
export { LoginSetFirstNewPasswordComponent };
