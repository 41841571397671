<div
  *ngIf="user"
  class="user-information"
  fxLayout="column"
  fxLayoutAlign="center stretch"
>
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxFlex="20">[{{ user.level }}] - {{ user.company?.name }}</div>
    <div fxFlex="60" fxLayout="column" fxLayoutAlign="center start">
      <span>{{ user.firstname }}</span>
      <span>{{ user.lastname }}</span>
      <span>{{ user.email }}</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="center start">
      <div
        fxLayout="row"
        class="action clickable"
        fxLayoutAlign="space-between center"
        (click)="togglePanel()"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="icon modify"
        >
          <mat-icon class="">edit</mat-icon>
        </div>
        <span class="label">{{ 'common.update' | translate }}</span>
      </div>
      <div
        *ngIf="canDelete()"
        fxLayout="row"
        class="action clickable"
        fxLayoutAlign="space-between center"
        (click)="delete()"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="center center"
          class="icon delete"
        >
          <mat-icon class="">delete</mat-icon>
        </div>
        <span class="label">{{ 'common.delete' | translate }}</span>
      </div>
    </div>
  </div>
  <mat-expansion-panel #infoPanel class="user-panel">
    <ahc-user-information-panel
      *ngIf="infoPanel.expanded"
      [user]="user"
      (togglePanelEvent)="togglePanel()"
    ></ahc-user-information-panel>
  </mat-expansion-panel>
</div>
