import { TranslateService } from '@ngx-translate/core';
import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';

export class DisciplineTypeOfAnalysis {
  discipline: ExpertiseDomain;
  tab: Tabs;

  /**
   *
   */
  constructor(
    discipline: ExpertiseDomain,
    tab: Tabs,
    private translator: TranslateService
  ) {
    this.discipline = discipline;
    this.tab = tab;
  }

  public toString(): string {
    return `${this.translator.instant(
      'common.' + this.discipline
    )} - ${this.tab.replace('_', ' ')}`;
  }
}
