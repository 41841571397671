import { Component, OnInit } from '@angular/core';

import { ParameterTableDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/parameter-table-data-source.provider';
import { FeedbackService } from 'libs/shared-module/src/lib/providers/feedback.provider';

@Component({
  selector: 'ahc-oil-analysis',
  templateUrl: './oil-analysis.component.html',
  styleUrls: ['./oil-analysis.component.scss'],
  providers: [ParameterTableDataSourceService, FeedbackService]
})
export class OilAnalysisComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
