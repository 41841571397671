import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import PwdResetStatus from 'libs/shared-module/src/lib/models/auth/password.reset.status.enum';
import { AuthService } from 'libs/shared-module/src/lib/providers/auth.provider';
export var passwordConfirmationValidator = function (control) {
    var password = control.get('password');
    var confirmPassword = control.get('confirmPassword');
    return password && confirmPassword && password.value !== confirmPassword.value
        ? { notEqual: true }
        : null;
};
var LoginDefineNewPasswordComponent = /** @class */ (function () {
    function LoginDefineNewPasswordComponent(fb, authService, router) {
        this.authService = authService;
        this.router = router;
        this.PwdResetStatusLocal = PwdResetStatus;
        this.updateError = PwdResetStatus.SUCCESS;
        this.resetFG = fb.group({
            email: new FormControl('', [Validators.required, Validators.email]),
            code: new FormControl('', [Validators.required]),
            password: new FormControl('', [Validators.required]),
            confirmPassword: new FormControl('', [Validators.required])
        }, {
            validators: passwordConfirmationValidator
        });
    }
    Object.defineProperty(LoginDefineNewPasswordComponent.prototype, "password", {
        get: function () {
            return this.resetFG.get('password').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginDefineNewPasswordComponent.prototype, "confirmPassword", {
        get: function () {
            return this.resetFG.get('confirmPassword').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginDefineNewPasswordComponent.prototype, "code", {
        get: function () {
            return this.resetFG.get('code').value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginDefineNewPasswordComponent.prototype, "email", {
        get: function () {
            return this.resetFG.get('email').value;
        },
        enumerable: true,
        configurable: true
    });
    LoginDefineNewPasswordComponent.prototype.ngOnInit = function () { };
    LoginDefineNewPasswordComponent.prototype.isFormValid = function () {
        return (!this.resetFG.errors &&
            this.resetFG.controls['email'].valid &&
            this.resetFG.controls['email'].dirty &&
            this.resetFG.controls['password'].valid &&
            this.resetFG.controls['password'].dirty &&
            this.resetFG.controls['code'].valid &&
            this.resetFG.controls['code'].dirty &&
            this.resetFG.controls['confirmPassword'].valid &&
            this.resetFG.controls['confirmPassword'].dirty);
    };
    LoginDefineNewPasswordComponent.prototype.resetPassword = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var pwdUpdatedStatus;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isFormValid()) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.authService.updatePassword(this.email, this.code, this.password)];
                    case 1:
                        pwdUpdatedStatus = _a.sent();
                        if (pwdUpdatedStatus === PwdResetStatus.SUCCESS) {
                            this.router.navigate(['login']);
                            return [2 /*return*/];
                        }
                        this.updateError = pwdUpdatedStatus;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return LoginDefineNewPasswordComponent;
}());
export { LoginDefineNewPasswordComponent };
