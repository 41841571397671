import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

import { UrlModel } from 'libs/shared-module/src/lib/models/common/url.model';
import { ReportService } from 'libs/shared-module/src/lib/providers/report.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';


@Component({
  selector: 'ahc-modal-upload-file',
  templateUrl: './modal-upload-file.component.html',
  styleUrls: ['./modal-upload-file.component.scss']
})
export class ModalUploadFileComponent implements OnInit {
  filesToUpload: FileList;
  isFileuploded = false;
  errorMessage = false;
  uploadForm: FormGroup;
  constructor(
    private reportService: ReportService,
    public uploadDialogRef: MatDialogRef<ModalUploadFileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EquipmentViewModel
  ) {} 

  ngOnInit() {
    this.uploadForm = new FormGroup({
      file: new FormControl(this.filesToUpload, [
        Validators.required
      ])
    });
  }

  sendFiles() {
    this.isFileuploded = true;
    if (this.uploadForm.get('file')) {
      this.reportService.getPresignedUrlUpload(this.data.pid, this.uploadForm.get('file').value).subscribe(
        (presignedUrl: UrlModel) => {
          this.reportService.uploadFileWithPresignedUrl(
            presignedUrl.url, 
            this.uploadForm.get('file').value._files[0], 
            this.uploadForm.get('file').value['type']
          ).subscribe(
            () => this.uploadDialogRef.close(true),
            () => {
              this.isFileuploded = false;
              this.errorMessage = true;
            }
          );
        }
      );
    }
  }

  closeModal() {
    this.uploadDialogRef.close(false);
  }
}
