import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material';

import { ReportLinkDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/report-link-data-source.provider';

import { LinkModel } from 'libs/shared-module/src/lib/models/cockpit/link.model';
import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import { TableDataSourceResponseModel } from 'libs/shared-module/src/lib/models/common/table-data-source-response.model';
import { ReportService } from 'libs/shared-module/src/lib/providers/report.provider';

@Component({
  selector: 'ahc-table-link',
  templateUrl: './table-link.component.html',
  styleUrls: ['./table-link.component.scss']
})
export class TableLinkComponent implements OnInit, AfterViewInit {
  @Input()
  equipmentId: string;

  @Input()
  reportLinkDataSource: ReportLinkDataSourceService;

  displayedColumns: string[] = ['name', 'date', 'action'];
  data: LinkModel[] = [];
  errorMessage = false;
  permissions = Permissions;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(private dataReportService: ReportService) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.reportLinkDataSource.initialize(this.equipmentId, 5);
      this.reportLinkDataSource.data$.subscribe(
        (result: TableDataSourceResponseModel<LinkModel>) => {
          this.data = result.items;
          this.paginator.length = result.totalSize;
        }
      );
    });
    this.paginator.page.pipe().subscribe(() => {
      this.reportLinkDataSource.page(
        this.paginator.pageIndex + 1,
        this.paginator.pageSize
      );
    });
  }

  deleteLink(equipmentId: string, urlId: string) {
    this.dataReportService.deleteLinkById(equipmentId, urlId).subscribe(
      () => {
        this.errorMessage = false;
        this.reportLinkDataSource.loadData();
      },
      () => {
        this.errorMessage = true;
      }
    );
  }
}
