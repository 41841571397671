<div
  class="graph-preview"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
>
  <span class="title" *ngIf="labelVisibility">
    {{ parameter.name }}
  </span>
  <div fxLayout="row" fxLayoutAlign="start center">
    <div id="{{ containerId.toString() }}"></div>
    <span
      *ngIf="graphData.values.length > 0"
      class="last-point"
      [style.color]="lastPointStatusColor()"
    >
      {{ lastPoint() }}
      <span class="unit">
        {{ unit }}
      </span>
    </span>
  </div>
</div>
