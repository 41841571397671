<div class="users" fxLayout="column" fxLayoutGap="20px">
  <mat-form-field class="search" fxFill>
    <input
      type="text"
      placeholder="{{ 'dashboard.users.search_input_label' | translate }}"
      matInput
      [formControl]="searchCtrl"
    />
    <mat-icon class="icon" matSuffix>search</mat-icon>
  </mat-form-field>
  <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="results">
      {{
        'dashboard.users.lists.results' | translate: { count: users.totalSize }
      }}
    </div>
    <div
      class="selection"
      *ngxPermissionsOnly="[permissions.ADMIN, permissions.INTERNAL_KEY]"
    >
      {{ 'dashboard.users.lists.filter.label_display' | translate }}
      <button
        class="selected-value"
        mat-button
        [matMenuTriggerFor]="displayMenu"
      >
        <span *ngIf="translateKey; else elseBlock">{{
          'dashboard.users.lists.filter.options.' + translateKey | translate
        }}</span>
        <ng-template #elseBlock>{{ selectedValue }}</ng-template>

        <mat-icon class="trigger">keyboard_arrow_down</mat-icon>
      </button>
      <mat-menu #displayMenu="matMenu" [overlapTrigger]="true">
        <button
          mat-menu-item
          (click)="selectedValue = 'all'; translateKey = 'all'"
          (click)="filterOptionSelected()"
        >
          {{ 'dashboard.users.lists.filter.options.all' | translate }}
        </button>
        <button
          mat-menu-item
          (click)="selectedValue = 'admin users'; translateKey = 'admin'"
          (click)="filterOptionSelected()"
        >
          {{ 'dashboard.users.lists.filter.options.admin' | translate }}
        </button>
        <button
          mat-menu-item
          (click)="
            selectedValue = 'internal users'; translateKey = 'internalUsers'
          "
          (click)="filterOptionSelected()"
        >
          {{ 'dashboard.users.lists.filter.options.internalUsers' | translate }}
        </button>
        <!-- Don't work for the moment, to implement on the back-end -->
        <button
          mat-menu-item
          (click)="
            selectedValue = 'internal key users';
            translateKey = 'internalKeyUsers'
          "
          (click)="filterOptionSelected()"
        >
          {{
            'dashboard.users.lists.filter.options.internalKeyUsers' | translate
          }}
        </button>
        <button
          mat-menu-item
          (click)="
            selectedValue = 'external users'; translateKey = 'externalUsers'
          "
          (click)="filterOptionSelected()"
        >
          {{ 'dashboard.users.lists.filter.options.externalUsers' | translate }}
        </button>
        <!-- Don't work for the moment, to implement on the back-end -->
        <button
          mat-menu-item
          (click)="
            selectedValue = 'external key users';
            translateKey = 'externalKeyUsers'
          "
          (click)="filterOptionSelected()"
        >
          {{
            'dashboard.users.lists.filter.options.externalKeyUsers' | translate
          }}
        </button>
        <div
          class="mat-menu-item"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="16px"
          (click)="stopPropagation($event)"
        >
          <span class="label">{{
            'dashboard.users.lists.filter.options.company' | translate
          }}</span>
          <mat-form-field class="filter">
            <input
              type="text"
              placeholder=""
              matInput
              [formControl]="companiesCtrl"
              [matAutocomplete]="companiesAutoComplete"
            />
            <mat-autocomplete
              #companiesAutoComplete="matAutocomplete"
              [displayWith]="displayFn"
              (optionSelected)="companySelected($event.option.value)"
            >
              <mat-option
                *ngFor="let company of (filtredCompanies | async)"
                [value]="company"
              >
                {{ company.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>
      </mat-menu>
    </div>
  </div>
  <mat-progress-bar
    class="loading"
    *ngIf="(usersService.loading$ | async)"
    [color]="'primary'"
    [mode]="'indeterminate'"
    fxFlexAlign="stretch"
  >
  </mat-progress-bar>
  <ahc-user-information
    *ngFor="let user of users.items"
    [user]="user"
  ></ahc-user-information>
  <mat-paginator
    [length]="users.totalSize"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 100]"
  >
  </mat-paginator>
</div>
