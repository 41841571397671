import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () =>
      import('libs/ahc-front-ui/src/lib/login/login.router.module').then(
        mod => mod.LoginRouterModule
      )
  },
  {
    path: 'main',
    loadChildren: () =>
      import('libs/ahc-front-ui/src/lib/dashboard/dashboard.router.module').then(
        mod => mod.DashboardRouterModule
      )
  },
  {
    path: 'cockpit',
    loadChildren: () =>
      import('libs/ahc-front-ui/src/lib/cockpit/cockpit.routing.module').then(
        mod => mod.CockpitRoutingModule
      )
  },
  { path: '**', redirectTo: '/login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRouterModule {}
