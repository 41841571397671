import * as tslib_1 from "tslib";
import { UnitSystemDataService } from '../dataServices/unit-system-data-service.provider';
import { TableDataSource } from './table-data-source.provider';
var UnitSystemDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(UnitSystemDataSourceService, _super);
    function UnitSystemDataSourceService(unitDataService) {
        var _this = _super.call(this) || this;
        _this.unitDataService = unitDataService;
        return _this;
    }
    UnitSystemDataSourceService.prototype.getData = function (sort, filter, query, page) {
        return this.unitDataService.getUnits();
    };
    return UnitSystemDataSourceService;
}(TableDataSource));
export { UnitSystemDataSourceService };
