import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginHomeComponent } from 'libs/ahc-front-ui/src/lib/login/login-home/login-home.component';
import { LoginGuard } from 'libs/shared-module/src/lib/guards/login.guard';

import { NotFoundComponent } from '../notFound/notfound.component';

import { LoginCallbackComponent } from './login-callback/login-callback.component';
import { LoginDefineNewPasswordComponent } from './login-define-new-password/login-define-new-password.component';
import { LoginInternalUserComponent } from './login-internal-user/login-internal-user.component';
import { LoginResetPwdComponent } from './login-reset-pwd/login-reset-pwd.component';
import { LoginSelectionComponent } from './login-selection/login-selection.component';
import { LoginSetFirstNewPasswordComponent } from './login-set-first-new-password/login-set-first-new-password.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginHomeComponent,
    children: [
      {
        path: '',
        component: LoginSelectionComponent
      },
      {
        path: 'reset',
        component: LoginResetPwdComponent
      },
      {
        path: 'reset/do',
        component: LoginDefineNewPasswordComponent
      },
      {
        path: 'set',
        component: LoginSetFirstNewPasswordComponent
      },
      {
        path: 'internal',
        component: LoginInternalUserComponent
      },
      {
        path: 'callback',
        component: LoginCallbackComponent
      },
      { path: 'unauthorized', component: UnauthorizedComponent },
      {
        path: '**',
        component: NotFoundComponent
      }
    ],
    canActivate: [LoginGuard],
    canActivateChild: [LoginGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class LoginRouterModule {}
