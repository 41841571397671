<div
  class="admin"
  fxLayout="column"
  fxLayoutAlign="start stretch"
  fxFlexFill
  fxLayoutGap="20px"
>
  <div class="header" fxLayout="column" fxLayoutAlign="start center">
    <span class="title">{{ 'dashboard.admin.title' | translate }}</span>
  </div>
  <div>
    <ahc-admin-create-modify-parameter></ahc-admin-create-modify-parameter>
  </div>
</div>
