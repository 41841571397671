import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LireModel } from '../../models/cockpit/lire.model';
import SortEnum from '../../models/common/enums/sort.enum';
import { TableDataSourceResponseModel } from '../../models/common/table-data-source-response.model';
import { LireDataService } from '../dataServices/lire-data-service.provider';

import { TableDataSource } from './table-data-source.provider';

@Injectable()
export class LireDataSourceService extends TableDataSource<LireModel> {
  /**
   *
   */
  constructor(private lireDataService: LireDataService) {
    super();
  }

  getData(
    sort: Map<string, SortEnum>,
    filter: Map<string, string>,
    query: string,
    page: number
  ): Observable<TableDataSourceResponseModel<LireModel>> {
    return this.lireDataService.getLireById(
      this.equipmentId,
      page,
      this.pageSize,
      sort,
      filter
    );
  }
}
