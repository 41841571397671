<div fxLayout="column" fxLayoutAlign="start stretch" fxFill>
  <ahc-no-data
    *ngIf="result.size === 0 && !(searchProvider.loading$ | async)"
  ></ahc-no-data>
  <mat-progress-bar
    class="loading"
    *ngIf="(searchProvider.loading$ | async)"
    [color]="'primary'"
    [mode]="'indeterminate'"
    fxFlexAlign="stretch"
  >
  </mat-progress-bar>
  <div
    *ngIf="result.size !== 0"
    fxLayout="column"
    fxLayoutAlign="start"
    fxLayoutGap="20px"
  >
    <ng-container
      *ngFor="let plantEquipments of (result | keyvalue); trackBy: trackByFn"
    >
      <ahc-plant-panel
        *ngIf="
          searchFilter === localSearchFilterEnum.FAVORITE ||
          searchFilter === localSearchFilterEnum.ALL ||
          searchFilter === localSearchFilterEnum.PLANT ||
          searchFilter === localSearchFilterEnum.MAIN
        "
        [plant]="plantEquipments.key"
      ></ahc-plant-panel>
      <ng-container
        *ngIf="
          searchFilter === localSearchFilterEnum.ALL ||
          searchFilter === localSearchFilterEnum.FAVORITE ||
          searchFilter === localSearchFilterEnum.EQUIPMENTS ||
          searchFilter === localSearchFilterEnum.MAIN
        "
      >
        <ahc-equipment-panel
          *ngFor="let equipment of plantEquipments.value; trackBy: trackByFn"
          [equipment]="equipment"
        ></ahc-equipment-panel
      ></ng-container>
    </ng-container>
  </div>
</div>
