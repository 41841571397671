/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cockpit-home.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../common/parameters-table/parameters-table.component.ngfactory";
import * as i3 from "../../../../../shared-module/src/lib/providers/scada-graph-parameter.provider";
import * as i4 from "../../../../../shared-module/src/lib/providers/dataServices/scada-data-service.provider";
import * as i5 from "../../common/parameters-table/parameters-table.component";
import * as i6 from "@angular/flex-layout/flex";
import * as i7 from "@angular/flex-layout/core";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "../../common/feedback/feedback.component.ngfactory";
import * as i10 from "../../common/feedback/feedback.component";
import * as i11 from "../../../../../shared-module/src/lib/providers/feedback.provider";
import * as i12 from "../../../../../shared-module/src/lib/providers/connected-user.provider";
import * as i13 from "../../../../../shared-module/src/lib/providers/cockpit.provider";
import * as i14 from "./components/global-info/global-info.component.ngfactory";
import * as i15 from "./components/global-info/global-info.component";
import * as i16 from "../../../../../shared-module/src/lib/providers/equipment-global-info.provider";
import * as i17 from "@angular/common";
import * as i18 from "@angular/common/http";
import * as i19 from "../../../../../shared-module/src/lib/providers/dataSource/scada-data-source.provider";
import * as i20 from "./cockpit-home.component";
var styles_CockpitHomeComponent = [i0.styles];
var RenderType_CockpitHomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CockpitHomeComponent, data: {} });
export { RenderType_CockpitHomeComponent as RenderType_CockpitHomeComponent };
function View_CockpitHomeComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ahc-parameters-table", [], null, null, null, i2.View_ParametersTableComponent_0, i2.RenderType_ParametersTableComponent)), i1.ɵprd(4608, null, i3.ScadaGraphParameterService, i3.ScadaGraphParameterService, [i4.ScadaDataService]), i1.ɵdid(2, 4308992, null, 0, i5.ParametersTableComponent, [], { dataSource: [0, "dataSource"], equipment: [1, "equipment"], module: [2, "module"], graphService: [3, "graphService"], dateFormat: [4, "dateFormat"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; var currVal_1 = _co.equipment; var currVal_2 = _co.activeTab; var currVal_3 = _co.graphService; var currVal_4 = "dd MMM yyyy, hh:mm"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_CockpitHomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "home"], ["fxLayout", "column"], ["fxLayoutAlign", "space-around stretch"], ["fxLayoutGap", "50px"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i6.DefaultLayoutDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutStyleBuilder], i7.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i6.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i8.Directionality, i7.StyleUtils, [2, i6.LayoutGapStyleBuilder], i7.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i6.DefaultLayoutAlignDirective, [i1.ElementRef, i7.StyleUtils, [2, i6.LayoutAlignStyleBuilder], i7.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ahc-feedback", [], null, null, null, i9.View_FeedbackComponent_0, i9.RenderType_FeedbackComponent)), i1.ɵdid(5, 245760, null, 0, i10.FeedbackComponent, [i11.FeedbackService, i12.ConnectedUserService, i13.CockpitService], { tab: [0, "tab"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "ahc-global-info", [], null, null, null, i14.View_GlobalInfoComponent_0, i14.RenderType_GlobalInfoComponent)), i1.ɵdid(7, 114688, null, 0, i15.GlobalInfoComponent, [i16.EquipmentGlobalInfoService, i13.CockpitService], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CockpitHomeComponent_1)), i1.ɵdid(9, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "50px"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "space-around stretch"; _ck(_v, 3, 0, currVal_2); var currVal_3 = "health_assessment"; _ck(_v, 5, 0, currVal_3); _ck(_v, 7, 0); var currVal_4 = _co.equipment; _ck(_v, 9, 0, currVal_4); }, null); }
export function View_CockpitHomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ahc-cockpit-home", [], null, null, null, View_CockpitHomeComponent_0, RenderType_CockpitHomeComponent)), i1.ɵprd(512, null, i4.ScadaDataService, i4.ScadaDataService, [i18.HttpClient]), i1.ɵprd(131584, null, i19.ScadaDataSourceService, i19.ScadaDataSourceService, [i4.ScadaDataService]), i1.ɵdid(3, 114688, null, 0, i20.CockpitHomeComponent, [i4.ScadaDataService, i19.ScadaDataSourceService, i3.ScadaGraphParameterService, i13.CockpitService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var CockpitHomeComponentNgFactory = i1.ɵccf("ahc-cockpit-home", i20.CockpitHomeComponent, View_CockpitHomeComponent_Host_0, {}, {}, []);
export { CockpitHomeComponentNgFactory as CockpitHomeComponentNgFactory };
