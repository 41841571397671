import { Component, Input, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';

import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { FeedbackModel } from 'libs/shared-module/src/lib/models/feedback/feedback.model';
import { FeedbackService } from 'libs/shared-module/src/lib/providers/feedback.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ahc-equipement-card',
  templateUrl: './equipement-card.component.html',
  styleUrls: ['./equipement-card.component.scss'],
  providers: [FeedbackService]
})
export class EquipementCardComponent implements OnInit, OnDestroy {
  @Input()
  equipment: EquipmentViewModel;

  @Input()
  horizontalDirection = false;

  feedback: FeedbackModel;

  constructor(
    private router: Router,
    @SkipSelf() private feedbackService: FeedbackService
  ) {}

  private ngUnsubscribe: Subject<void> = new Subject();

  ngOnInit() {
    this.feedbackService
      .onFeedbacks()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(feedbacks => {
        if (feedbacks && feedbacks.length > 0) {
          this.feedback = feedbacks[0];
        }
      });
    if (this.equipment) {
      this.feedbackService.init(Tabs.HOME, this.equipment);
    }
  }

  goToCockpit() {
    this.router.navigate([`/cockpit/${this.equipment.pid}`]);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
