import { Component, Input, OnInit } from '@angular/core';

import { SearchEquipmenFilterEnum } from 'libs/shared-module/src/lib/models/common/search-equipment-filter.enum';
import { SearchResultModel } from 'libs/shared-module/src/lib/models/common/search-result.model';
import { FavoriteEquipmentService } from 'libs/shared-module/src/lib/providers/favorite-equipment.provider';
import { SearchEquipmentService } from 'libs/shared-module/src/lib/providers/search-equipment.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';
import { PlantViewModel } from 'libs/shared-module/src/lib/viewmodels/plant.viewmodel';
import * as _ from 'lodash';

@Component({
  selector: 'ahc-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss']
})
export class EquipmentListComponent implements OnInit {
  @Input()
  searchFilter: SearchEquipmenFilterEnum = SearchEquipmenFilterEnum.ALL;
  result: Map<PlantViewModel, EquipmentViewModel[]> = new Map();

  localSearchFilterEnum = SearchEquipmenFilterEnum;

  constructor(
    public searchProvider: SearchEquipmentService,
    private favoriteService: FavoriteEquipmentService
  ) {}

  ngOnInit() {
    this.searchProvider
      .onFiltredResult()
      .subscribe(
        (r: SearchResultModel<Map<PlantViewModel, EquipmentViewModel[]>>) => {
          this.result = r.items;
          this.updateFavoriteStatus(
            _.flattenDeep(Array.from(r.items.values()))
          );
        }
      );
  }

  updateFavoriteStatus(equipments: EquipmentViewModel[]) {
    this.favoriteService
      .loadStatus(equipments)
      .subscribe(result => (equipments = result));
  }

  trackByFn(index, item) {
    if (item.key) {
      return item.key.id;
    } else {
      return item.pid;
    }
  }
}
