<button
  fxFlexAlign="start"
  mat-flat-button
  class="toggle-action"
  (click)="formPanel.toggle()"
>
  {{ 'cockpit.common.insert_new_data' | translate }}
</button>
<div
  class="insert-value sticky"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <mat-expansion-panel class="mat-elevation-z0 insert-panel" #formPanel>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="12px"
    >
      <div
        class="container"
        fxLayout="row"
        fxLayoutAlign="start center"
        fxLayoutGap="20px"
        fxFlex="80"
      >
        <mat-form-field
          class="input-field mat-form-field--white"
          [floatLabel]="'never'"
          fxFlex="40"
        >
          <input
            placeholder="{{
              'cockpit.insert_parameter.select_parameter' | translate
            }}"
            type="text"
            matInput
            [matAutocomplete]="parameterSelector"
            [formControl]="parametersCtrl"
          />
        </mat-form-field>
        <mat-autocomplete
          [displayWith]="displayFn"
          #parameterSelector
          class="input-field mat-form-field--white"
        >
          <mat-option
            *ngFor="let option of (filteredParameters | async)"
            [value]="option"
          >
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
        <mat-form-field
          class="input-field mat-form-field--white"
          [floatLabel]="'never'"
          fxFlex="40"
        >
          <input
            matInput
            placeholder="{{
              'cockpit.insert_parameter.insert_value' | translate
            }}"
            [formControl]="parameterValueCtrl"
            type="number"
          />
        </mat-form-field>
        <mat-form-field
          class="input-field mat-form-field--white"
          [floatLabel]="'never'"
        >
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="{{
              'cockpit.insert_parameter.choose_date' | translate
            }}"
            [formControl]="parameterDateCtrl"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div fxFlexAlign="stretch">
        <button
          fxFlexAlign="start"
          mat-icon-button
          (click)="formPanel.toggle()"
        >
          <mat-icon>keyboard_arrow_down</mat-icon>
        </button>
        <button
          mat-flat-button
          class="insert-action"
          (click)="insertData()"
          [class.active]="parameterForm.valid"
        >
          {{ 'cockpit.insert_parameter.insert_data' | translate }}
        </button>
      </div>
    </div>
  </mat-expansion-panel>
</div>
