import * as tslib_1 from "tslib";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { of, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { SortFilterDataServiceBase } from './dataServices/sort-filter-data-service.base.provider';
import { Logger } from './Logger.provider';
var ParameterService = /** @class */ (function (_super) {
    tslib_1.__extends(ParameterService, _super);
    /**
     *
     */
    function ParameterService(http, logger) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.logger = logger;
        _this.ngUnsubscribe = new Subject();
        _this.httpOptions = {
            headers: new HttpHeaders({
                'no-cache': 'true'
            })
        };
        return _this;
    }
    ParameterService.prototype.getParameterByExpertiseDomain = function (domain, isShowcase, page, pageSize) {
        var _this = this;
        if (page === void 0) { page = 1; }
        if (pageSize === void 0) { pageSize = 1000; }
        var requestUrl = ApiEndpointsEnum.PARAMETERS + "?expertise_domain=" + domain + "&page=" + page + "&page_size=" + pageSize;
        if (isShowcase !== undefined) {
            requestUrl += "&is_showcase=" + isShowcase;
        }
        return this.http.get(requestUrl).pipe(catchError(function (error) {
            _this.logger.error(error);
            return [];
        }));
    };
    ParameterService.prototype.getParameters = function (domain, page, pageSize, sort, filter) {
        var _this = this;
        var sortString = sort ? this.buildSort(sort) : '';
        var filterString = filter ? this.buildFilter(filter) : '';
        return this.http
            .get(ApiEndpointsEnum.PARAMETERS + "?expertise_domain=" + domain + "&page=" + page + "&page_size=" + pageSize + sortString + filterString, this.httpOptions)
            .pipe(catchError(function (error) {
            _this.logger.error('getParameters', domain, error);
            return [];
        }));
    };
    ParameterService.prototype.getParameterById = function (pid, page, pageSize) {
        return of([]);
    };
    ParameterService.prototype.getParameterUnit = function (parameterId) {
        return this.http
            .get(ApiEndpointsEnum.PARAMETER_UNITS.replace('{0}', parameterId))
            .pipe(tap(function (list) {
            return list.find(function (p) { return p.isDefault; });
        }), catchError(function (error) { return of(undefined); }));
    };
    ParameterService.prototype.getEquipmentParameters = function (equipmentId, start, end, view, page, pageSize, filter) {
        var _this = this;
        var filterString = filter ? this.buildFilter(filter) : '';
        var url = ApiEndpointsEnum.PARAMETERS_VALUE_VIEW.replace('{0}', equipmentId);
        return this.http
            .get(url + "/" + view + "?page=" + page + "&page_size=" + pageSize + "&start_date=" + moment(start).format('YYYY-MM-DD') + "&end_date=" + moment(end).format('YYYY-MM-DD') + filterString, this.httpOptions)
            .pipe(takeUntil(this.ngUnsubscribe), map(function (result) {
            return result;
        }), catchError(function (error) {
            _this.logger.error(error);
            return of(Object.assign({ items: [], totalSize: 0 }));
        }));
    };
    ParameterService.prototype.getParameterValues = function (equipementId, parameterId, start, end) {
        var _this = this;
        return this.http
            .get(ApiEndpointsEnum.PARAMETERS_VALUE.replace('{0}', parameterId).replace('{1}', equipementId) + "?start_date=" + moment(start).format('YYYY-MM-DD') + "&end_date=" + moment(end).format('YYYY-MM-DD'), this.httpOptions)
            .pipe(catchError(function (error) {
            _this.logger.error(error);
            return of(new Map());
        }));
    };
    ParameterService.prototype.addParameterData = function (parameter, equipementId, values) {
        return this.http.post(ApiEndpointsEnum.PARAMETERS_VALUE.replace('{0}', parameter.id).replace('{1}', equipementId), values);
    };
    ParameterService.prototype.batch = function (data) {
        return this.http.post(ApiEndpointsEnum.PARAMETERS_DATA, data);
    };
    ParameterService.prototype.delete = function (parameterId, equipmentId, dates) {
        return this.http.delete(ApiEndpointsEnum.PARAMETERS_VALUE.replace('{0}', parameterId).replace('{1}', equipmentId) +
            ("?dates=" + dates
                .map(function (date) { return moment(date).format('YYYY-MM-DD'); })
                .reduce(function (acc, curr) { return acc + ',' + curr; })));
    };
    ParameterService.prototype.ngOnDestroy = function () {
        this.ngUnsubscribe.complete();
        this.ngUnsubscribe.unsubscribe();
    };
    return ParameterService;
}(SortFilterDataServiceBase));
export { ParameterService };
