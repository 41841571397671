import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import SortEnum from 'libs/shared-module/src/lib/models/common/enums/sort.enum';
import { ParameterModel } from 'libs/shared-module/src/lib/models/common/parameter.model';
import { TableDataSourceResponseModel } from 'libs/shared-module/src/lib/models/common/table-data-source-response.model';
import { ThresholdModel } from 'libs/shared-module/src/lib/models/common/threshold.model';
import { ParameterDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/parameter-data-source.provider';
import { Logger } from 'libs/shared-module/src/lib/providers/Logger.provider';
import { ThresholdService } from 'libs/shared-module/src/lib/providers/threshold.provider';

@Component({
  selector: 'ahc-admin-create-modify-parameter',
  templateUrl: './admin-create-modify-parameter.component.html',
  styleUrls: ['./admin-create-modify-parameter.component.scss']
})
export class AdminCreateModifyParameterComponent
  implements OnInit, AfterViewInit {
  sortEnum = SortEnum;
  pageSize = 5;
  showFilter: Map<string, boolean> = new Map([['tab', true]]);
  totalPages: number;
  domains = ExpertiseDomain;
  displayedColumns: string[] = [
    'name',
    'tab',
    'v_low',
    'low',
    'high',
    'v_high',
    'defaultUnits',
    'action'
  ];
  data: ParameterModel[] = [];
  unitCtrl = new FormControl('');
  expertiseDomainCtrl = new FormControl(ExpertiseDomain.WATER);

  constructor(
    public tableDataSource: ParameterDataSourceService,
    public dialog: MatDialog,
    public thresholdService: ThresholdService,
    private logger: Logger
  ) {}

  ngOnInit() {
    this.tableDataSource.initialize(this.expertiseDomainCtrl.value, 1000);
    this.expertiseDomainCtrl.valueChanges.subscribe(
      (domain: ExpertiseDomain) => {
        this.tableDataSource.initialize(domain, 1000);
        this.showFilter.set('map', true);
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.tableDataSource.data$.subscribe(
        (result: TableDataSourceResponseModel<ParameterModel>) => {
          this.data = result.items;
        }
      );
    });
  }

  filter(column: string, query: string) {
    if (query) {
      this.tableDataSource.addFilter(column, query);
    } else {
      this.tableDataSource.removeFilter(column);
    }
  }

  sort(column: string, direction: SortEnum) {
    if (direction === SortEnum.none) {
      this.tableDataSource.removeSort(column);
    } else {
      this.tableDataSource.addSort(column, direction);
    }
  }

  saveParameter(thresholds: ThresholdModel) {
    this.thresholdService
      .updateThreshold({
        high: thresholds.high ? thresholds.high.toString() : undefined,
        low: thresholds.low ? thresholds.low.toString() : undefined,
        veryHigh: thresholds.veryHigh
          ? thresholds.veryHigh.toString()
          : undefined,
        veryLow: thresholds.veryLow ? thresholds.veryLow.toString() : undefined,
        parameterPid: thresholds.parameterPid
      })
      .subscribe(() => {});
  }

  deleteParameter(threshold: ThresholdModel) {
    this.thresholdService.deleteThreshold(threshold).subscribe(
      () => {
        delete threshold.veryHigh;
        delete threshold.veryLow;
        delete threshold.high;
        delete threshold.low;
      },
      error => this.logger.error(error)
    );
  }
  deleteIfEmpty(threshold: ThresholdModel, nameOfField: string) {
    if (threshold[nameOfField] === '') {
      delete threshold[nameOfField];
    }
  }
}
