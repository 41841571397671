import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import DisplayZone from '../../models/common/enums/display-zone.enum';
import ExpertiseDomain from '../../models/common/enums/expertise-domain.enum';
import SortEnum from '../../models/common/enums/sort.enum';
import { ParameterModel } from '../../models/common/parameter.model';
import { SearchResultModel } from '../../models/common/search-result.model';
import { ThresholdModel } from '../../models/common/threshold.model';
import { ParameterService } from '../parameter.provider';
import { ThresholdService } from '../threshold.provider';

import { TableDataSource } from './table-data-source.provider';

@Injectable()
export class ParameterDataSourceService extends TableDataSource<
  ParameterModel
> {
  /**
   *
   */
  constructor(
    private dataService: ParameterService,
    private thresholdService: ThresholdService
  ) {
    super();
  }

  private expertiseDomain = ExpertiseDomain.ELECTRICAL;

  initialize(
    domain: ExpertiseDomain,
    pageSize: number = this.pageSize,
    sort = new Map([['tab', SortEnum.asc]]),
    filter = new Map([['display_zone', DisplayZone.TREND_BOX]])
  ) {
    this.expertiseDomain = domain;
    this.currentPage = 1;
    this.init(pageSize, '', sort, filter);
  }

  getData(
    sort: Map<string, SortEnum>,
    filter: Map<string, string>,
    query: string,
    page: number
  ): Observable<SearchResultModel<ParameterModel[]>> {
    return this.dataService
      .getParameters(
        this.expertiseDomain,
        this.currentPage,
        this.pageSize,
        sort,
        filter
      )
      .pipe(
        switchMap((parameters: SearchResultModel<ParameterModel[]>) => {
          return this.thresholdService
            .batchThreshold({
              parameterListPid: parameters.items.map(item => item.id)
            })
            .pipe(
              map((thresholds: SearchResultModel<ThresholdModel[]>) => {
                parameters.items.map(item => {
                  const found = thresholds.items.find(
                    el => el.parameterPid === item.id
                  );
                  item.threshold =
                    found !== undefined
                      ? found
                      : {
                          parameterPid: item.id,
                          equipmentPid: null
                        };
                  return item;
                });
                return parameters;
              })
            );
        })
      );
  }
}
