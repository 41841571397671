<div class="panel">
  <table
    mat-table
    [dataSource]="data"
    class="cockpit-table"
    multiTemplateDataRows
    fxFill
  >
    <ng-container matColumnDef="parameter">
      <th mat-header-cell *matHeaderCellDef class="header-cell">
        <div fxLayout="row" fxLayoutAlign="start center">
          <span>{{ 'common.tables_columns.parameter' | translate }}</span>
          <!-- TODO check before delete -->
          <ahc-table-column-filter
            enableSort
            class="filter"
            [ngClass]="{ show: showFilter['name'] }"
            (search)="filter('name', $event)"
            (used)="showFilter['name'] = $event"
            (sort)="sort('name', $event)"
          ></ahc-table-column-filter>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        <span class="tooltip">
          {{ element.name }}
          <span *ngIf="element['helper'] !== 'null'" class="tooltiptext">{{
            element['helper']
          }}</span>
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef class="value-column">
        {{ 'common.tables_columns.value' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="value-column space">
        <ahc-parameter-preview-graph
          [unit]="element.unit"
          [labelVisibility]="false"
          [data]="element"
        ></ahc-parameter-preview-graph>
      </td>
    </ng-container>
    <ng-container matColumnDef="timestamp">
      <th mat-header-cell *matHeaderCellDef class="header-cell">
        <div fxLayout="row" fxLayoutAlign="start center">
          <span>{{ 'common.tables_columns.timestamp' | translate }}</span>
        </div>
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          element.timeserie.length > 0
            ? element.timeserie[element.timeserie.length - 1].timestamp
              ? (element.timeserie[element.timeserie.length - 1].timestamp
                | date: dateFormat)
              : (element.timeserie[element.timeserie.length - 1].date
                | date: dateFormat)
            : '-'
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="action-column header-cell"
      ></th>
      <td
        mat-cell
        *matCellDef="let element"
        class="action-column"
        align="right"
      >
        <button
          mat-flat-button
          color="primary"
          class="more-detail"
          [ngClass]="{
            open: expandedElement === element,
            close: expandedElement !== element
          }"
          (click)="
            expandedElement = expandedElement === element ? null : element;
            initGraphProvider(element)
          "
        >
          {{ 'cockpit.scada.more_detail' | translate }}
          <mat-icon class="toggle">keyboard_arrow_right</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td
        mat-cell
        *matCellDef="let element"
        [attr.colspan]="columnsToDisplay.length"
      >
        <div
          class="parameter-detail mat-elevation-z3"
          [@detailExpand]="
            element == expandedElement ? 'expanded' : 'collapsed'
          "
        >
          <div class="panel">
            <ahc-parameter-graph
              *ngIf="expandedElement === element"
              [parameter]="element.name"
              [equipmentId]="equipmentId"
              [parameterId]="element.id"
              [graphService]="graphService"
              [module]="module"
            ></ahc-parameter-graph>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
    <tr
      mat-row
      *matRowDef="let element; columns: columnsToDisplay"
      class="parameter-row"
    ></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: ['expandedDetail']"
      class="graph-detail-row"
    ></tr>
  </table>
</div>
