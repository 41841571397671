import SortEnum from '../../models/common/enums/sort.enum';

export class SortFilterDataServiceBase {
  protected buildSort(sort: Map<string, SortEnum>): string {
    if (sort.size > 0) {
      return `&order_by=${Array.from(sort.entries())
        .map(keySort => `${keySort[0]}-${keySort[1]}`)
        .reduce((prev, curr) => `${prev},${curr}`)}`;
    }
    return '';
  }

  protected buildFilter(filter: Map<string, string>) {
    if (filter.size > 0) {
      return (
        '&' +
        Array.from(filter.entries())
          .map(keyFilter => `${keyFilter[0]}=${keyFilter[1]}`)
          .reduce((prev, curr) => `${prev}&${curr}`)
      );
    }
    return '';
  }
}
