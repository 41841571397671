enum Tabs {
  SCADA = 'scada',
  OIL_ANALYSIS = 'oil_analysis',
  VIBRATION = 'vibration',
  WATER = 'kpis',
  REPORT = 'report',
  SERVICE_REPORT = 'service_report',
  HOME = 'health_assessment'
}

export default Tabs;
