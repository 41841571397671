<div
  class="login-welcome"
  fxLayout="column"
  fxFlex="100%"
  fxLayoutAlign="space-between start"
  fxLayoutGap="10px"
  fxFlexFill
>
  <span class="to">{{ 'login.welcomeTo' | translate }}</span>
  <span>{{ 'common.airLiquide' | translate }}</span>
  <span class="site">{{ 'common.siteName' | translate }}</span>
  <span class="who">{{ 'login.whoAreYou' | translate }}</span>
  <div fxLayout="row" fxFlexFill>
    <button
      id="internalLoginAction"
      fxFlex="80%"
      *ngIf="!externalLoginPanel.expanded"
      mat-raised-button
      (click)="connectInternal()"
      color="primary"
      fxFlexFill
    >
      {{ 'login.airLiquideEmployee' | translate }}
    </button>
  </div>
  <div fxLayout="row" fxFlexFill>
    <button
      id="externalLoginAction"
      fxFlex="80%"
      mat-raised-button
      color="accent"
      (click)="externalLoginPanel.toggle()"
    >
      {{ 'login.externalPartner' | translate }}
    </button>
  </div>
  <div fxLayout="row" fxFlexFill>
    <mat-expansion-panel
      fxFlex="80%"
      #externalLoginPanel
      fxFlexFill
      class="mat-elevation-z0"
    >
      <ahc-external-login></ahc-external-login>
    </mat-expansion-panel>
  </div>
</div>
