import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'libs/shared-module/src/lib/providers/auth.provider';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class EmailErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'ahc-login-reset-pwd',
  templateUrl: './login-reset-pwd.component.html',
  styleUrls: ['./login-reset-pwd.component.scss']
})
export class LoginResetPwdComponent implements OnInit, OnDestroy {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  matcher = new EmailErrorStateMatcher();

  constructor(
    private authService: AuthService,
    private _snackBar: MatSnackBar,
    private translateService: TranslateService,
    private router: Router
  ) {}

  private durationInSeconds = 3;
  private ngUnsubscribe: Subject<void> = new Subject();

  ngOnInit() {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }

  doReset() {    
    if (this.emailFormControl.valid) {
      this.authService.resetPassword(this.emailFormControl.value);

      this.translateService
        .get('login.reset.validRequestMessage')
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((msg: string) => {
          this._snackBar
            .open(msg, '', {
              duration: this.durationInSeconds * 1000
            })
            .afterDismissed()
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => this.router.navigate(['login/reset/do']));
        });
    }
  }
}
