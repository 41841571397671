import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import LevelEnum from 'libs/shared-module/src/lib/models/common/level.enum';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
import { UserViewModel } from 'libs/shared-module/src/lib/viewmodels/user.viewmodel';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

import { CreateExternalUserComponent } from '../create-external-user/create-external-user.component';
import { CreateNewUserComponent } from '../create-new-user/create-new-user.component';

@Component({
  selector: 'ahc-invite-users',
  templateUrl: './invite-users.component.html',
  styleUrls: ['./invite-users.component.scss']
})
export class InviteUsersComponent implements OnInit {
  emailCtrl = new FormControl('', [
    Validators.minLength(1),
    Validators.required,
    Validators.email
  ]);

  constructor(
    public dialog: MatDialog,
    private userService: UsersService,
    connectedUser: ConnectedUserService,
    private snackService: SnackbarService,
    private translate: TranslateService
  ) {
    connectedUser.onUser().subscribe(_user => (this.connectedUser = _user));
  }

  private connectedUser: UserViewModel;

  ngOnInit() {}

  openCreateUser(): void {
    const domain = this.emailCtrl.value.substring(
      this.emailCtrl.value.lastIndexOf('@') + 1
    );
    if (this.connectedUser.level === LevelEnum.EXTERNAL_KEY) {
      if (this.connectedUser.email.split('@')[1] !== domain) {
        this.snackService.open(
          this.translate.instant('dashboard.users.create_error_not_in_company')
        );
        return;
      }
    }
    if (!this.emailCtrl.valid) {
      return;
    }
    let dialogClosed: Observable<boolean>;
    if (_.includes(['alizent.com', 'airliquide.com'], domain.toLowerCase())) {
      dialogClosed = this.dialog
        .open(CreateNewUserComponent, {
          data: this.emailCtrl.value,
          panelClass: 'invite-panel'
        })
        .afterClosed();
    } else {
      dialogClosed = this.dialog
        .open(CreateExternalUserComponent, {
          data: this.emailCtrl.value,
          panelClass: 'invite-panel'
        })
        .afterClosed();
    }

    dialogClosed.subscribe(() => {
      this.userService.refresh();
      this.emailCtrl.setValue('');
    });
  }
}
