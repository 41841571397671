import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ahc-predictive',
  templateUrl: './predictive.component.html',
  styleUrls: ['./predictive.component.scss']
})
export class PredictiveComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
