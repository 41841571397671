/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./corrective.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./maximo-work-order/maximo-work-order.component.ngfactory";
import * as i6 from "../../../../../shared-module/src/lib/providers/dataSource/corrective-data-source.provider";
import * as i7 from "../../../../../shared-module/src/lib/providers/dataServices/maximo-data-service.provider";
import * as i8 from "./maximo-work-order/maximo-work-order.component";
import * as i9 from "../../../../../shared-module/src/lib/providers/cockpit.provider";
import * as i10 from "./lire/lire.component.ngfactory";
import * as i11 from "./lire/lire.component";
import * as i12 from "../../../../../shared-module/src/lib/providers/dataSource/lire-data-source.provider";
import * as i13 from "./corrective.component";
var styles_CorrectiveComponent = [i0.styles];
var RenderType_CorrectiveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CorrectiveComponent, data: {} });
export { RenderType_CorrectiveComponent as RenderType_CorrectiveComponent };
export function View_CorrectiveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["fxLayout", "column"], ["fxLayoutAlign", "start stretch"], ["fxLayoutGap", "1rem"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ahc-maximo-work-order", [], null, null, null, i5.View_MaximoWorkOrderComponent_0, i5.RenderType_MaximoWorkOrderComponent)), i1.ɵprd(131584, null, i6.CorrectiveDataSourceService, i6.CorrectiveDataSourceService, [i7.MaximoDataService]), i1.ɵdid(6, 4308992, null, 0, i8.MaximoWorkOrderComponent, [i6.CorrectiveDataSourceService, i9.CockpitService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "ahc-lire", [], null, null, null, i10.View_LireComponent_0, i10.RenderType_LireComponent)), i1.ɵdid(8, 4308992, null, 0, i11.LireComponent, [i12.LireDataSourceService, i9.CockpitService], null, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "1rem"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "start stretch"; _ck(_v, 3, 0, currVal_2); _ck(_v, 6, 0); _ck(_v, 8, 0); }, null); }
export function View_CorrectiveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ahc-corrective", [], null, null, null, View_CorrectiveComponent_0, RenderType_CorrectiveComponent)), i1.ɵdid(1, 114688, null, 0, i13.CorrectiveComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CorrectiveComponentNgFactory = i1.ɵccf("ahc-corrective", i13.CorrectiveComponent, View_CorrectiveComponent_Host_0, {}, {}, []);
export { CorrectiveComponentNgFactory as CorrectiveComponentNgFactory };
