import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatRadioModule,
  MatSelectModule
} from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

import { SearchEquipmentService } from 'libs/shared-module/src/lib/providers/search-equipment.provider';

import { UiCommonModule } from '../../common/uicommon.module';

import { AdvancedSearchComponent } from './advanced-search/advanced-search.component';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { EquipmentPanelComponent } from './equipment-panel/equipment-panel.component';
import { PlantPanelComponent } from './plant-panel/plant-panel.component';
import { SearchEquipmentComponent } from './search-equipment.component';

@NgModule({
  declarations: [
    AdvancedSearchComponent,
    SearchEquipmentComponent,
    EquipmentPanelComponent,
    PlantPanelComponent,
    EquipmentListComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatCardModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatButtonModule,
    FormsModule,
    UiCommonModule,
    MatRadioModule,
    MatProgressBarModule,
    MatPaginatorModule
  ],
  providers: [SearchEquipmentService]
})
export class SearchEquipementModule {}
