<div class="graph-deletion" *ngIf="points.values.length !== 0">
  <button mat-button [matMenuTriggerFor]="menu">
    <mat-icon>settings</mat-icon>
  </button>
  <mat-menu #menu="matMenu" class="menu-panel-dark">
    <h3 class="title">
      {{ 'cockpit.common.delete_point' | translate }}
    </h3>
    <div class="panel" fxLayout="column" (click)="$event.stopPropagation()">
      <div
        class="item"
        fxLayout="row"
        fxLayoutAlign="space-between center"
        *ngFor="let point of points.values"
      >
        <div
          class="label"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div fxFlex="85">
            {{ point.x | date: 'mediumDate' }}
          </div>
          <div fxFlex="15">{{ point.y }}</div>
        </div>
        <button
          mat-icon-button
          aria-label="Delete point"
          (click)="deletePoint(point)"
        >
          <mat-icon class="delete-action">delete</mat-icon>
        </button>
      </div>
    </div>
  </mat-menu>
</div>
