import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { environment } from '@env/environment';
import { CognitoAccessToken, CognitoIdToken, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js';
import { AmplifyService } from 'aws-amplify-angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import AuthStatus from '../models/auth/auth.status.enum';
import PwdResetStatus from '../models/auth/password.reset.status.enum';
import StorageKeysEnum from '../models/common/enums/storage-keys.enum';
import { Logger } from './Logger.provider';
import { RequestCacheService } from './request-cache.provider';
import { StorageService } from './storage.provider';
var AuthService = /** @class */ (function () {
    function AuthService(logger, awsAmplifyAuthService, router, route, cacheManager, storageService) {
        this.logger = logger;
        this.awsAmplifyAuthService = awsAmplifyAuthService;
        this.router = router;
        this.route = route;
        this.cacheManager = cacheManager;
        this.storageService = storageService;
        this.userAws$ = new BehaviorSubject(undefined);
        this.isSso = false;
        this.silentSignIn();
        this.onUser().subscribe(function (user) {
            if (user) {
                logger.setUser(user.getUsername());
            }
        });
    }
    Object.defineProperty(AuthService.prototype, "IsSso", {
        get: function () {
            return this.isSso ? 1 : 0;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.onUser = function () {
        return this.userAws$.asObservable();
    };
    AuthService.prototype.signInSso = function () {
        var _this = this;
        return new Observable(function (observer) {
            try {
                var idToken = _this.storageService.get(StorageKeysEnum.SsoIdToken);
                var accessToken = _this.storageService.get(StorageKeysEnum.SsoAccessToken);
                var cognitoUserSession = {
                    AccessToken: new CognitoAccessToken({
                        AccessToken: accessToken
                    }),
                    IdToken: new CognitoIdToken({ IdToken: idToken })
                };
                var userSession = new CognitoUserSession(cognitoUserSession);
                var userData = {
                    Username: userSession.getIdToken().payload.email,
                    Pool: new CognitoUserPool({
                        ClientId: environment.cognito.multipassClientId,
                        UserPoolId: environment.cognito.amplify.awsConfig.Auth.aws_user_pools_id
                    })
                };
                var cognitoUser = new CognitoUser(userData);
                _this.isSso = true;
                _this.userAws$.next(cognitoUser);
                _this.storageService.set(StorageKeysEnum.SsoActivate, _this.isSso);
                observer.next(true);
            }
            catch (err) {
                observer.error(false);
            }
        });
    };
    AuthService.prototype.signIn = function (username, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var user, err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.awsAmplifyAuthService
                                .auth()
                                .signIn(username, password)];
                    case 1:
                        user = _a.sent();
                        if (user.challengeName === AuthStatus.NEW_PASSWORD_REQUIRED) {
                            this.userAws$.next(user);
                            return [2 /*return*/, AuthStatus.NEW_PASSWORD_REQUIRED];
                        }
                        else {
                            this.storageService.set(StorageKeysEnum.SsoActivate, 0);
                            this.userAws$.next(user);
                            return [2 /*return*/, AuthStatus.SUCCESS];
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.userAws$.next(undefined);
                        if (err_1.code === 'UserNotConfirmedException' ||
                            err_1.code === 'PasswordResetRequiredException') {
                            return [2 /*return*/, AuthStatus.REGISTRATION_PROCESS_INCOMPLETE];
                        }
                        else if (err_1.code === 'NotAuthorizedException' ||
                            err_1.code === 'UserNotFoundException') {
                            return [2 /*return*/, AuthStatus.EMAIL_OR_PASSWORD_INCORRECT];
                        }
                        return [2 /*return*/, AuthStatus.UNKNOWN_ERROR];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.resetPassword = function (email) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var result, err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.awsAmplifyAuthService
                                .auth()
                                .forgotPassword(email)];
                    case 1:
                        result = _a.sent();
                        this.logger.info('User defined a new password', email, result);
                        return [2 /*return*/, true];
                    case 2:
                        err_2 = _a.sent();
                        this.logger.error('User defined a new password failed', email, err_2);
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.updatePassword = function (email, code, password) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_3;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.awsAmplifyAuthService
                                .auth()
                                .forgotPasswordSubmit(email, code, password)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, PwdResetStatus.SUCCESS];
                    case 2:
                        err_3 = _a.sent();
                        this.logger.error('update password failed for user', email, err_3);
                        switch (err_3.code) {
                            case 'InvalidParameterException':
                                return [2 /*return*/, PwdResetStatus.INVALID_PARAMETER_EXCEPTION];
                            case 'CodeMismatchException':
                                return [2 /*return*/, PwdResetStatus.CODE_MISMATCH];
                            case 'UserNotFoundException':
                                return [2 /*return*/, PwdResetStatus.USER_NOT_FOUND];
                            default:
                                return [2 /*return*/, PwdResetStatus.UNKNOWN_ERROR];
                        }
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.logOut = function (autoLogout, returnUrl) {
        if (autoLogout === void 0) { autoLogout = false; }
        if (returnUrl === void 0) { returnUrl = null; }
        this.cacheManager.clear();
        this.isSso ? this.ssoLogOut(autoLogout, returnUrl) : this.externalLogOut();
        return of(true);
    };
    AuthService.prototype.getToken = function () {
        if (this.IsSso) {
            return this.storageService.get(StorageKeysEnum.SsoIdToken);
        }
        else {
            var session = this.getUserSession();
            return session ? session.getIdToken().getJwtToken() : undefined;
        }
    };
    AuthService.prototype.getUserSession = function () {
        var user = this.userAws$.getValue();
        return user ? user.getSignInUserSession() : undefined;
    };
    AuthService.prototype.silentSignIn = function () {
        var _this = this;
        this.isSso = this.storageService.get(StorageKeysEnum.SsoActivate);
        // DO CONNECT
        // if the user is already authenticated using sso, so use it
        if (this.isSso) {
            this.signInSso().subscribe(function (done) {
                var returnUrl = _this.storageService.get(StorageKeysEnum.SsoPreviousUrl);
                _this.storageService.delete(StorageKeysEnum.SsoPreviousUrl);
                returnUrl
                    ? _this.router.navigateByUrl(returnUrl)
                    : _this.router.navigate([window.location.pathname]);
            });
        }
        else {
            this.userAws$ = new BehaviorSubject(undefined);
            Auth.currentAuthenticatedUser({
                bypassCache: true
            })
                .then(function (user) {
                _this.userAws$.next(user);
                _this.router.navigate([
                    _this.route.snapshot.queryParams['returnUrl'] || 'main'
                ]);
            })
                .catch(function (err) { return _this.logger.error('ASW Silent sign in user failed', err); });
        }
    };
    AuthService.prototype.ssoLogOut = function (autoLogout, returnUrl) {
        this.storageService.delete(StorageKeysEnum.SsoIdToken);
        this.storageService.delete(StorageKeysEnum.SsoActivate);
        this.storageService.delete(StorageKeysEnum.SsoAccessToken);
        this.userAws$.next(undefined);
        if (autoLogout) {
            this.storageService.set(StorageKeysEnum.SsoPreviousUrl, returnUrl);
            this.router.navigate(['/login/internal']);
        }
    };
    AuthService.prototype.externalLogOut = function () {
        var _this = this;
        Auth.signOut()
            .then(function (data) {
            _this.userAws$.next(undefined);
            _this.router.navigateByUrl('/login');
            _this.logger.info('User logged out');
        })
            .catch(function (err) { return _this.logger.error('user logout failed', err); });
    };
    return AuthService;
}());
export { AuthService };
