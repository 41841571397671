import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';

import { AuthService } from '../providers/auth.provider';

@Injectable()
export class LoginGuard implements CanActivate, CanActivateChild {
  constructor(
    authService: AuthService,
    private _router: Router,
    private route: ActivatedRoute
  ) {
    // TODO remove any
    authService.onUser().subscribe((user: any) => {
      this.isAuthenticated =
        user !== undefined && user.challengeName !== 'NEW_PASSWORD_REQUIRED';
    });
  }
  private isAuthenticated = false;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canVisit(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.canVisit(childRoute, state);
  }

  canVisit(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (
      !this.isAuthenticated ||
      state.url.toLowerCase().indexOf('unauthorized') > -1
    ) {
      return true;
    }

    this._router.navigate([
      this.route.snapshot.queryParams['returnUrl'] || '/main'
    ]);
  }
}
