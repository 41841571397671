import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import CompanyEnum from 'libs/shared-module/src/lib/models/common/company.enum';
import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import LanguageEnum from 'libs/shared-module/src/lib/models/common/enums/language.enum';
import LevelEnum from 'libs/shared-module/src/lib/models/common/level.enum';
import { CompanyBaseModel } from 'libs/shared-module/src/lib/models/company/company.base.model';
import { ScopeModel } from 'libs/shared-module/src/lib/models/scope/scope.model';
import { NewUserBaseModel } from 'libs/shared-module/src/lib/models/user/new-user-base.model';
import { CompanyService } from 'libs/shared-module/src/lib/providers/company.provider';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';

import { AutoCompleteValueItem } from '../../../common/auto-complete/auto-complete-value-item.model';

interface Scope {
  clusters: string[];
  countries: string[];
  plants: string[];
  expertiseDomains: string[];
}

interface CreateResponse {
  userPid: string;
}

@Component({
  selector: 'ahc-create-new-user',
  templateUrl: './create-new-user.component.html',
  styleUrls: ['./create-new-user.component.scss']
})
export class CreateNewUserComponent implements OnInit {
  errorMessage: '';
  username: string;
  companyname: string;
  newUser: NewUserBaseModel = new NewUserBaseModel();
  clusters: AutoCompleteValueItem[] = [];
  countries: AutoCompleteValueItem[] = [];
  plants: AutoCompleteValueItem[] = [];
  productionUnits: AutoCompleteValueItem[] = [];
  expertiseDomains: AutoCompleteValueItem[] = [];
  levels = [LevelEnum.ADMIN, LevelEnum.INTERNAL, LevelEnum.INTERNAL_KEY];
  languages = LanguageEnum;

  favorites: Scope = {
    clusters: [],
    countries: [],
    expertiseDomains: [],
    plants: []
  };

  profileForm = new FormGroup({
    companyPid: new FormControl(this.newUser.companyPid),
    lastname: new FormControl(this.newUser.lastname, [Validators.required]),
    firstname: new FormControl(this.newUser.firstname, [Validators.required]),
    email: new FormControl(this.newUser.email),
    level: new FormControl(this.newUser.level),
    language: new FormControl(this.newUser.language, [Validators.required]),
    scopes: new FormControl(new ScopeModel())
  });

  constructor(
    private connectedUser: ConnectedUserService,
    private scopeParameterService: ScopeParameterService,
    private userService: UsersService,
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    private companyService: CompanyService,
    public dialogRef: MatDialogRef<CreateNewUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
    this.username = data;
  }

  async ngOnInit() {
    this.profileForm.controls['email'].setValue(this.username);

    this.connectedUser.onUser().subscribe(user => {
      if (user.level === LevelEnum.INTERNAL) {
        this.levels = [LevelEnum.INTERNAL];
      }
    });

    if (this.username.toLowerCase().includes('alizent.com')) {
      this.companyname = CompanyEnum.ALIZENT;
    }
    if (this.username.toLowerCase().includes('airliquide.com')) {
      this.companyname = CompanyEnum.AIRLIQUIDE;
    }
    if (this.companyname !== undefined) {
      this.companyService
        .getCompanyByName(this.companyname)
        .subscribe((company: CompanyBaseModel) => {
          this.profileForm.controls['companyPid'].setValue(company.id);
        });
    }

    this.scopeParameterService.loadClusters().subscribe(clusters => {
      this.clusters = clusters;
    });

    const clustersSource = this.scopeParameterService.loadClusters();
    const countriesSource = this.scopeParameterService.loadCountries();
    const plantsSource = this.scopeParameterService.loadPlants();
    const productionUnitsSource = this.scopeParameterService.loadProductionUnits();
    const expertiseDomainsSource = this.scopeParameterService.loadExpertiseDomains();

    forkJoin([
      clustersSource,
      countriesSource,
      plantsSource,
      productionUnitsSource,
      expertiseDomainsSource
    ]).subscribe(results => {
      this.clusters = results[0];
      this.countries = results[1];
      this.plants = results[2];
      this.productionUnits = results[3];
      this.expertiseDomains = results[4];
    });
  }

  setClusters(values: AutoCompleteValueItem[]) {
    this.favorites.clusters = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setCountries(values: AutoCompleteValueItem[]) {
    this.favorites.countries = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setPlants(values: AutoCompleteValueItem[]) {
    this.favorites.plants = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  setExpertiseDomain(values: AutoCompleteValueItem[]) {
    this.favorites.expertiseDomains = values.map(
      (value: AutoCompleteValueItem) => value.id
    );
  }

  sendInvite() {
    if (this.profileForm.valid) {
      this.newUser = this.profileForm.value;
      this.userService
        .create(this.newUser)
        .pipe(
          catchError((error: HttpErrorResponse) => {
            switch (error.status) {
              case 409: {
                this.handleError(
                  this.translate.instant(
                    'dashboard.users.create_error_conflict'
                  )
                );
                break;
              }
              case 400: {
                this.handleError(
                  this.translate.instant(
                    'dashboard.users.create_error_bad_request'
                  )
                );
                break;
              }
              default: {
                this.handleError(
                  this.translate.instant('dashboard.users.create_error')
                );
                break;
              }
            }
            return of(null);
          })
        )
        .subscribe((response: CreateResponse) => {

          if (response) {
          
            try {
              console.info(
               'Creating new internal user' +
                ', user: " + this.newUser.email' + 
                ', userService.updateFavorites(), ' +
                ', clusters: ' + this.favorites.clusters.length +
                ', countries: ' + this.favorites.countries.length +
                ', expertiseDomains: ' + this.favorites.expertiseDomains.length + 
                ', plants: ' + this.favorites.plants.length);
            } catch (error) {             
            } 

            this.userService
              .updateFavorites(response.userPid, {
                favorites: {
                  clusters: this.favorites.clusters,
                  countries: this.favorites.countries,
                  expertiseDomains: this.favorites.expertiseDomains.map(
                    item => item as ExpertiseDomain
                  ),
                  plants: this.favorites.plants,
                  productionUnits: []
                }
              })
              .pipe(
                catchError(error => {
                  this.translate.instant('dashboard.users.create_error');
                  return of(null);
                })
              )
              .subscribe(done => {
                if (done) {
                  this.snackbarService.open(
                    this.translate.instant('dashboard.users.created'),
                    this.translate.instant('common.success')
                  );
                  this.closeModal();
                }
              });
          }
        });
    }
  }
  closeModal() {
    this.dialogRef.close();
  }
  handleError(message: string) {
    this.snackbarService.open(message, null);
  }
}
