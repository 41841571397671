<div fxLayout="column" fxLayoutAlign="start stretch" *ngIf="equipment">
  <div *ngIf="isLoading" fxFlexAlign="stretch">
    <mat-progress-bar class="loading" mode="indeterminate"></mat-progress-bar>
  </div>
  <ahc-parameters-table
    *ngIf="equipment"
    [dataSource]="dataSource"
    [equipment]="equipment"
    [module]="activeTab"
    [graphService]="graphService"
    [dateFormat]="'dd MMM yyyy, hh:mm'"
  ></ahc-parameters-table>
</div>
