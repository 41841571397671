<form [formGroup]="profileForm">
  <div
    class="invite-user-panel"
    fxLayout="column"
    fxLayoutAlign="space-around stretch"
    fxLayoutGap="20px"
  >
    <span
      >{{ 'dashboard.users.create.header' | translate }}: {{ username }}
    </span>
    <div fxLyout="row" class="profile-selection" fxLayoutGap="20px">
      <mat-form-field class="mat-form-field--rounded" appearance="fill">
        <mat-label>{{
          'dashboard.users.create.level.label' | translate
        }}</mat-label>
        <mat-select formControlName="level">
          <mat-option
            *ngFor="let level of (levels | keyvalue)"
            [value]="level.value"
            >{{ level.value }}</mat-option
          >
        </mat-select>
      </mat-form-field>
      <mat-form-field class="mat-form-field--rounded" appearance="fill">
        <mat-label>{{ 'common.firstName' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'dashboard.users.invite_input_label' | translate }}"
          formControlName="firstname"
        />
      </mat-form-field>
      <mat-form-field class="mat-form-field--rounded" appearance="fill">
        <mat-label>{{ 'common.lastName' | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'dashboard.users.invite_input_label' | translate }}"
          formControlName="lastname"
        />
      </mat-form-field>
      <mat-form-field class="mat-form-field--rounded" appearance="fill">
        <mat-label>{{ 'common.language' | translate }}</mat-label>
        <mat-select formControlName="language">
          <mat-option value="en">
            {{ 'common.english' | translate }}
          </mat-option>
          <mat-option value="fr">
            {{ 'common.french' | translate }}
          </mat-option>
          <mat-option value="cn">
            {{ 'common.chinese' | translate }}
          </mat-option>
          <mat-option value="nl">
            {{ 'common.dutch' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <span>
      {{ 'dashboard.users.create.message' | translate }}
    </span>

    <ahc-auto-complete
      [placeHolder]="'dashboard.users.create.cluster_permission' | translate"
      [values]="clusters"
      (valueChange)="setClusters($event)"
    ></ahc-auto-complete>
    <ahc-auto-complete
      [placeHolder]="'dashboard.users.create.country_permission' | translate"
      [values]="countries"
      (valueChange)="setCountries($event)"
    ></ahc-auto-complete>
    <ahc-auto-complete
      [placeHolder]="'dashboard.users.create.plant_permission' | translate"
      [values]="plants"
      (valueChange)="setPlants($event)"
    ></ahc-auto-complete>
    <ahc-auto-complete
      [placeHolder]="
        'dashboard.users.create.expertise_domain_permission' | translate
      "
      [values]="expertiseDomains"
      (valueChange)="setExpertiseDomain($event)"
    ></ahc-auto-complete>
    <mat-error *ngIf="errorMessage">
      {{ errorMessage }}
    </mat-error>
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="12px">
      <button mat-flat-button (click)="closeModal()">
        {{ 'common.cancel' | translate }}
      </button>
      <button mat-flat-button color="primary" (click)="sendInvite()">
        {{ 'dashboard.users.create.send_invite_action' | translate }}
      </button>
    </div>
  </div>
</form>
