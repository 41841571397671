<div
  class="import"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="15px"
>
  <div class="title">
    <span>{{ 'dashboard.import.title' | translate }}</span>
  </div>
  <form [formGroup]="filterForm" class="filter">
    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="50px">
      <mat-form-field
        class="mat-form-field--rounded mat-form-field--white"
        appearance="fill"
      >
        <mat-label>{{ 'common.plant' | translate }}</mat-label>
        <input
          type="text"
          placeholder="{{ 'common.plant' | translate }}"
          aria-label="Number"
          matInput
          class="option-label"
          formControlName="plant"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option
            class="option-label"
            *ngFor="let plant of (filteredPlants | async)"
            [value]="plant"
            >{{ plant.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field
        class="mat-form-field--rounded mat-form-field--white option-label"
        appearance="fill"
      >
        <mat-label>{{
          'dashboard.import.discipline_type' | translate
        }}</mat-label>
        <mat-select formControlName="discipline">
          <mat-option
            class="option-label"
            *ngFor="let discipline of disciplines"
            [value]="discipline"
          >
            {{ discipline }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        [disabled]="
          !(filterForm.value.plant && filterForm.value.plant.id) ||
          !filterForm.value.discipline
        "
        class="load"
        mat-flat-button
        color="primary"
        (click)="initFromFilter()"
      >
        {{ 'dashboard.import.generate_template' | translate }}
      </button>
    </div>
  </form>
  <div fxLayout="column" fxLayoutAlign="start start">
    <div fxFlex="60" *ngIf="isLoading" fxFlexAlign="stretch">
      <mat-progress-bar class="loading" mode="indeterminate"></mat-progress-bar>
    </div>
    <div id="importTable" fxFlex="60" class="import-table"></div>
  </div>
  <button
    class="action"
    mat-flat-button
    color="primary"
    disabled="{{
      errorCells.length > 0 || dataSource.equipments.length === 0 || isLoading
    }}"
    (click)="import()"
  >
    {{ 'dashboard.import.action_import' | translate }}
  </button>
</div>
