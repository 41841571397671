import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { ParameterListId } from '../models/common/parameter-list-id.model';
import { SearchResultModel } from '../models/common/search-result.model';
import { ThresholdUpdateModel } from '../models/common/threshold-update.model';
import { ThresholdModel } from '../models/common/threshold.model';

@Injectable()
export class ThresholdService {
  /**
   *
   */
  constructor(private http: HttpClient) {}

  updateThreshold(values: ThresholdUpdateModel): Observable<Object> {
    return this.http.put(ApiEndpointsEnum.THRESHOLDS, values);
  }
  deleteThreshold(values: ThresholdModel): Observable<Object> {
    return this.http.delete(ApiEndpointsEnum.THRESHOLDS, {
      params: {
        parameter_pid: values.parameterPid
      }
    });
  }
  batchThreshold(
    values: ParameterListId
  ): Observable<SearchResultModel<ThresholdModel[]>> {
    return this.http
      .post<SearchResultModel<ThresholdModel[]>>(
        ApiEndpointsEnum.THRESHOLDS,
        values
      )
      .pipe(
        catchError(() => new Observable<SearchResultModel<ThresholdModel[]>>())
      );
  }
}
