import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Auth, { CognitoUser } from '@aws-amplify/auth';
import { environment } from '@env/environment';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserData,
  ICognitoUserSessionData
} from 'amazon-cognito-identity-js';
import { AmplifyService } from 'aws-amplify-angular';
import { BehaviorSubject, Observable, of } from 'rxjs';

import AuthStatus from '../models/auth/auth.status.enum';
import PwdResetStatus from '../models/auth/password.reset.status.enum';
import StorageKeysEnum from '../models/common/enums/storage-keys.enum';

import { Logger } from './Logger.provider';
import { RequestCacheService } from './request-cache.provider';
import { StorageService } from './storage.provider';

@Injectable()
export class AuthService {
  constructor(
    private logger: Logger,
    private awsAmplifyAuthService: AmplifyService,
    private router: Router,
    private route: ActivatedRoute,
    private cacheManager: RequestCacheService,
    private storageService: StorageService
  ) {
    this.silentSignIn();
    this.onUser().subscribe(user => {
      if (user) {
        logger.setUser(user.getUsername());
      }
    });
  }
  private userAws$: BehaviorSubject<CognitoUser> = new BehaviorSubject(
    undefined
  );
  private isSso = false;

  get IsSso(): number {
    return this.isSso ? 1 : 0;
  }

  onUser(): Observable<CognitoUser> {
    return this.userAws$.asObservable();
  }

  signInSso(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      try {
        const idToken = this.storageService.get<string>(
          StorageKeysEnum.SsoIdToken
        );
        const accessToken = this.storageService.get<string>(
          StorageKeysEnum.SsoAccessToken
        );
        const cognitoUserSession: ICognitoUserSessionData = {
          AccessToken: new CognitoAccessToken({
            AccessToken: accessToken
          }),
          IdToken: new CognitoIdToken({ IdToken: idToken })
        };
        const userSession: CognitoUserSession = new CognitoUserSession(
          cognitoUserSession
        );

        const userData: ICognitoUserData = {
          Username: userSession.getIdToken().payload.email,
          Pool: new CognitoUserPool({
            ClientId: environment.cognito.multipassClientId,
            UserPoolId:
              environment.cognito.amplify.awsConfig.Auth.aws_user_pools_id
          })
        };
        const cognitoUser = new CognitoUser(userData);
        this.isSso = true;
        this.userAws$.next(cognitoUser);

        this.storageService.set(StorageKeysEnum.SsoActivate, this.isSso);

        observer.next(true);
      } catch (err) {
        observer.error(false);
      }
    });
  }

  async signIn(username: string, password: string): Promise<AuthStatus> {
    try {
      const user = await this.awsAmplifyAuthService
        .auth()
        .signIn(username, password);
      if (user.challengeName === AuthStatus.NEW_PASSWORD_REQUIRED) {
        this.userAws$.next(user);
        return AuthStatus.NEW_PASSWORD_REQUIRED;
      } else {
        this.storageService.set(StorageKeysEnum.SsoActivate, 0);
        this.userAws$.next(user);
        return AuthStatus.SUCCESS;
      }
    } catch (err) {
      this.userAws$.next(undefined);
      if (
        err.code === 'UserNotConfirmedException' ||
        err.code === 'PasswordResetRequiredException'
      ) {
        return AuthStatus.REGISTRATION_PROCESS_INCOMPLETE;
      } else if (
        err.code === 'NotAuthorizedException' ||
        err.code === 'UserNotFoundException'
      ) {
        return AuthStatus.EMAIL_OR_PASSWORD_INCORRECT;
      }
      return AuthStatus.UNKNOWN_ERROR;
    }
  }

  async resetPassword(email: string): Promise<boolean> {
    try {
      const result = await this.awsAmplifyAuthService
        .auth()
        .forgotPassword(email);
      this.logger.info('User defined a new password', email, result);
      return true;
    } catch (err) {
      this.logger.error('User defined a new password failed', email, err);
      return false;
    }
  }

  async updatePassword(
    email: string,
    code: string,
    password: string
  ): Promise<PwdResetStatus> {
    try {
      await this.awsAmplifyAuthService
        .auth()
        .forgotPasswordSubmit(email, code, password);

      return PwdResetStatus.SUCCESS;
    } catch (err) {
      this.logger.error('update password failed for user', email, err);
      switch (err.code) {
        case 'InvalidParameterException':
          return PwdResetStatus.INVALID_PARAMETER_EXCEPTION;
        case 'CodeMismatchException':
          return PwdResetStatus.CODE_MISMATCH;
        case 'UserNotFoundException':
          return PwdResetStatus.USER_NOT_FOUND;
        default:
          return PwdResetStatus.UNKNOWN_ERROR;
      }
    }
  }

  logOut(autoLogout = false, returnUrl = null): Observable<boolean> {
    this.cacheManager.clear();
    this.isSso ? this.ssoLogOut(autoLogout, returnUrl) : this.externalLogOut();
    return of(true);
  }

  getToken(): string {
    if (this.IsSso) {
      return this.storageService.get(StorageKeysEnum.SsoIdToken);
    } else {
      const session = this.getUserSession();
      return session ? session.getIdToken().getJwtToken() : undefined;
    }
  }

  private getUserSession(): CognitoUserSession {
    const user = this.userAws$.getValue();
    return user ? user.getSignInUserSession() : undefined;
  }

  private silentSignIn() {
    this.isSso = this.storageService.get<boolean>(StorageKeysEnum.SsoActivate);
    // DO CONNECT
    // if the user is already authenticated using sso, so use it
    if (this.isSso) {
      this.signInSso().subscribe((done: boolean) => {
        const returnUrl = this.storageService.get<string>(
          StorageKeysEnum.SsoPreviousUrl
        );
        this.storageService.delete(StorageKeysEnum.SsoPreviousUrl);
        returnUrl
          ? this.router.navigateByUrl(returnUrl)
          : this.router.navigate([window.location.pathname]);
      });
    } else {
      this.userAws$ = new BehaviorSubject<CognitoUser>(undefined);
      Auth.currentAuthenticatedUser({
        bypassCache: true
      })
        .then(user => {
          this.userAws$.next(user);
          this.router.navigate([
            this.route.snapshot.queryParams['returnUrl'] || 'main'
          ]);
        })
        .catch(err => this.logger.error('ASW Silent sign in user failed', err));
    }
  }

  private ssoLogOut(autoLogout: boolean, returnUrl: string) {
    this.storageService.delete(StorageKeysEnum.SsoIdToken);
    this.storageService.delete(StorageKeysEnum.SsoActivate);
    this.storageService.delete(StorageKeysEnum.SsoAccessToken);
    this.userAws$.next(undefined);
    if (autoLogout) {
      this.storageService.set(StorageKeysEnum.SsoPreviousUrl, returnUrl);
      this.router.navigate(['/login/internal']);
    }
  }

  private externalLogOut() {
    Auth.signOut()
      .then(data => {
        this.userAws$.next(undefined);
        this.router.navigateByUrl('/login');
        this.logger.info('User logged out');
      })
      .catch(err => this.logger.error('user logout failed', err));
  }
}
