/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./predictive.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./maximo/maximo.component.ngfactory";
import * as i6 from "../../../../../shared-module/src/lib/providers/dataSource/predictive-data-source.provider";
import * as i7 from "../../../../../shared-module/src/lib/providers/dataServices/maximo-data-service.provider";
import * as i8 from "./maximo/maximo.component";
import * as i9 from "../../../../../shared-module/src/lib/providers/cockpit.provider";
import * as i10 from "./catches/catches.component.ngfactory";
import * as i11 from "../../../../../shared-module/src/lib/providers/dataSource/catches-data-source.provider";
import * as i12 from "./catches/catches.component";
import * as i13 from "./predictive.component";
var styles_PredictiveComponent = [i0.styles];
var RenderType_PredictiveComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PredictiveComponent, data: {} });
export { RenderType_PredictiveComponent as RenderType_PredictiveComponent };
export function View_PredictiveComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["fxLayout", "column"], ["fxLayoutAlign", "start stretch"], ["fxLayoutGap", "1rem"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 1720320, null, 0, i2.DefaultLayoutGapDirective, [i1.ElementRef, i1.NgZone, i4.Directionality, i3.StyleUtils, [2, i2.LayoutGapStyleBuilder], i3.MediaMarshaller], { fxLayoutGap: [0, "fxLayoutGap"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "ahc-maximo", [], null, null, null, i5.View_MaximoComponent_0, i5.RenderType_MaximoComponent)), i1.ɵprd(131584, null, i6.PredictiveDataSourceService, i6.PredictiveDataSourceService, [i7.MaximoDataService]), i1.ɵdid(6, 4308992, null, 0, i8.MaximoComponent, [i6.PredictiveDataSourceService, i9.CockpitService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 2, "ahc-catches", [], null, null, null, i10.View_CatchesComponent_0, i10.RenderType_CatchesComponent)), i1.ɵprd(131584, null, i11.CatchesDataSourceService, i11.CatchesDataSourceService, [i7.MaximoDataService]), i1.ɵdid(9, 4308992, null, 0, i12.CatchesComponent, [i11.CatchesDataSourceService, i9.CockpitService], null, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "1rem"; _ck(_v, 2, 0, currVal_1); var currVal_2 = "start stretch"; _ck(_v, 3, 0, currVal_2); _ck(_v, 6, 0); _ck(_v, 9, 0); }, null); }
export function View_PredictiveComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ahc-predictive", [], null, null, null, View_PredictiveComponent_0, RenderType_PredictiveComponent)), i1.ɵdid(1, 114688, null, 0, i13.PredictiveComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PredictiveComponentNgFactory = i1.ɵccf("ahc-predictive", i13.PredictiveComponent, View_PredictiveComponent_Host_0, {}, {}, []);
export { PredictiveComponentNgFactory as PredictiveComponentNgFactory };
