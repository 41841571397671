import { OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { AppVersionService } from 'libs/shared-module/src/lib/providers/app-version.provider';
import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { Logger } from 'libs/shared-module/src/lib/providers/Logger.provider';
var AppComponent = /** @class */ (function () {
    /**
     *
     */
    function AppComponent(translate, appVersion, logger, permissionsService, connectedUser) {
        var _this = this;
        this.translate = translate;
        this.logger = logger;
        this.permissionsService = permissionsService;
        this.connectedUser = connectedUser;
        this.title = 'Assets Health Center';
        translate.addLangs(['en', 'fr', 'cn', 'nl']);
        translate.setDefaultLang('en');
        translate.use('en');
        appVersion
            .getBuildInfo()
            .subscribe(function (version) {
            return _this.logger.info("Version " + version.version + " | date: " + version.buildDate);
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.connectedUser.onUser().subscribe(function (user) {
            if (user) {
                _this.translate.use(user.language.match(/en|fr|cn|nl/) ? user.language : 'en');
                _this.loadPermission(user);
                if (user.isInternal) {
                    _this.addPermission(user);
                }
            }
            else {
                _this.permissionsService.flushPermissions();
            }
        });
    };
    AppComponent.prototype.loadPermission = function (user) {
        this.permissionsService.loadPermissions([
            user.level.toUpperCase()
        ].concat((user.scopes.expertiseDomains
            ? user.scopes.expertiseDomains.map(function (domain) {
                return (user.level + "_" + domain).toUpperCase();
            })
            : [])));
    };
    AppComponent.prototype.addPermission = function (user) {
        this.permissionsService.addPermission(user.scopes.expertiseDomains.map(function (domain) {
            return ("TAB_INTERNAL_EQUIPMENT_" + domain).toUpperCase();
        }));
    };
    return AppComponent;
}());
export { AppComponent };
