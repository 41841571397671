import * as tslib_1 from "tslib";
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import Tabs from '../../models/common/enums/tabs.enum';
import { ParameterService } from '../parameter.provider';
import { ThresholdService } from '../threshold.provider';
import { TableDataSource } from './table-data-source.provider';
var ParameterTableDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(ParameterTableDataSourceService, _super);
    /**
     *
     */
    function ParameterTableDataSourceService(parameterService, thresholdService) {
        var _this = _super.call(this) || this;
        _this.parameterService = parameterService;
        _this.thresholdService = thresholdService;
        _this.tab = Tabs.OIL_ANALYSIS;
        return _this;
    }
    ParameterTableDataSourceService.prototype.initialize = function (tab, pageSize) {
        this.tab = tab;
        this.init(pageSize);
    };
    ParameterTableDataSourceService.prototype.getData = function (sort, filter, query, page) {
        var _this = this;
        var results$ = new BehaviorSubject(null);
        this.parameterService
            .getEquipmentParameters(this.equipmentId, new Date(2000, 1, 1), moment().toDate(), this.tab, this.currentPage, this.pageSize, filter)
            .subscribe(function (result) {
            _this.thresholdService
                .batchThreshold({
                parameterListPid: result.items.map(function (item) { return item.id; })
            })
                .subscribe(function (threshold) {
                result.items.forEach(function (item) {
                    item.threshold = threshold.items.find(function (values) { return values.parameterPid === item.id; });
                });
                results$.next(result);
            });
        });
        return results$;
    };
    return ParameterTableDataSourceService;
}(TableDataSource));
export { ParameterTableDataSourceService };
