import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { FeedbackService } from 'libs/shared-module/src/lib/providers/feedback.provider';
import { EquipmentViewModel } from 'libs/shared-module/src/lib/viewmodels/equipment.viewmodel';

@Component({
  selector: 'ahc-equipment-panel',
  templateUrl: './equipment-panel.component.html',
  styleUrls: ['./equipment-panel.component.scss'],
  providers: [FeedbackService]
})
export class EquipmentPanelComponent {
  @Input()
  equipment: EquipmentViewModel;

  constructor(private router: Router) {}

  buildManufacturer() {
    if (
      this.equipment.manufacturer &&
      this.equipment.manufacturer.length <= 10
    ) {
      return this.equipment.manufacturer;
    }
    return this.equipment.manufacturer.substr(0, 10) + '...';
  }

  buildInstallDate() {
    return this.equipment.installDate &&
      moment(this.equipment.installDate).isValid()
      ? moment(this.equipment.installDate).year()
      : '-';
  }
  goToCockpit() {
    this.router.navigate([`/cockpit/${this.equipment.pid}`]);
  }
}
