import { Injectable } from '@angular/core';
import * as Highcharts from 'highcharts/highstock';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';

import { GraphParameterModel } from '../models/common/graph-parameter.model';

@Injectable()
export abstract class BaseGraphParameterService {
  points$ = new BehaviorSubject<GraphParameterModel<Highcharts.Point>>({
    name: '',
    values: []
  });
  loading$ = new BehaviorSubject<boolean>(false);
  deletedPoint$ = new BehaviorSubject<GraphParameterModel<Highcharts.Point>>(
    null
  );

  constructor() {}

  public loadData(parameterId: string, parameter: string, ...args: any) {
   
    // Looking for args to be [EquipmentViewModel] as args 
    // should be an array and EquipmentViewModel is passed in from caller.
    //

    let clusterName = '';

    if (args && args.length > 0) {

        const equipmentViewModel = args[0];

        if (equipmentViewModel.cluster) {
          clusterName = equipmentViewModel.cluster.name;
        }
    }

    this.loading$.next(true);

    this.points$.next({
      name: '',
      values: []
    });

    this.getValues(parameterId, parameter, clusterName).subscribe(values => {
      this.points$.next(values);
      this.loading$.next(false);
    });
  }

  abstract delete(dates: moment.Moment[]);

  protected abstract getValues(
    paramterId: string,
    parameter: string,
    ...args: any
  ): Observable<GraphParameterModel<Highcharts.Point>>;
}
