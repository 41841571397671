<div
  class="header"
  fxLayout="row"
  fxLayoutAlign="start stretch"
  fxLayoutGap="15px"
>
  <div class="action">
    <mat-icon (click)="goToPreviousPage()">arrow_back</mat-icon>
  </div>
  <ahc-equipement-card
    *ngIf="equipment"
    [equipment]="equipment"
    fxFlex="20%"
  ></ahc-equipement-card>
  <div class="divider" fxFlexAlign="stretch"></div>
  <ahc-equipment-type-info
    *ngIf="equipment"
    [equipment]="equipment"
    fxFlex="20%"
  ></ahc-equipment-type-info>
  <div class="divider" fxFlexAlign="stretch"></div>
  <div
    *ngxPermissionsOnly="[
      permissions.ADMIN,
      permissions.INTERNAL,
      permissions.INTERNAL_KEY
    ]"
  >
    <ahc-equipment-extended-info
      class="extended-info"
      *ngIf="equipment"
      [equipment]="equipment"
    ></ahc-equipment-extended-info>
  </div>
</div>
