import * as tslib_1 from "tslib";
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScadaDataService } from './dataServices/scada-data-service.provider';
import { BaseGraphParameterService } from './graph-parameter.base.provider';
var ScadaGraphParameterService = /** @class */ (function (_super) {
    tslib_1.__extends(ScadaGraphParameterService, _super);
    function ScadaGraphParameterService(scadaService) {
        var _this = _super.call(this) || this;
        _this.scadaService = scadaService;
        return _this;
    }
    /*
    extends BaseGraphParameterService
      protected abstract getValues(
    */
    ScadaGraphParameterService.prototype.getValues = function (parameterId, parameter) {
        // Looking for args to be ["clusterName"] as args 
        // should be an array and "clusterName" is passed in from caller.
        //
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        var clusterName = '';
        if (args && args.length > 0) {
            clusterName = '' + args[0];
        }
        return this.scadaService.getScadaParameterDetail(parameterId, 1, 100, clusterName).pipe(map(function (points) {
            var result = {
                name: parameter,
                values: points.map(function (point) {
                    var p = Object.assign({
                        x: Date.parse(point.timestamp.toString()),
                        y: point.value
                    });
                    return p;
                })
            };
            return result;
        }));
    };
    ScadaGraphParameterService.prototype.getOilParmaValues = function (equipmentId, parameter) {
        var param = {
            name: parameter,
            values: []
        };
        for (var i = 0; i < 8; i++) {
            param.values.push(Object.assign({
                x: new Date(2019, 1, 1, i).getTime(),
                y: Math.floor(Math.random() * 100),
                color: this.generateColor()
            }));
        }
        return of(param);
    };
    ScadaGraphParameterService.prototype.generateColor = function () {
        var color = 'rgb(' +
            Math.floor(Math.random() * 255) +
            ',' +
            Math.floor(Math.random() * 255) +
            ',' +
            Math.floor(Math.random() * 255) +
            ')';
        return color;
    };
    ScadaGraphParameterService.prototype.delete = function (dates) { };
    return ScadaGraphParameterService;
}(BaseGraphParameterService));
export { ScadaGraphParameterService };
