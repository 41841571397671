enum Permissions {
  ADMIN = 'ADMIN',
  INTERNAL = 'INTERNAL',
  INTERNAL_KEY = 'INTERNAL_KEY',
  EXTERNAL = 'EXTERNAL',
  EXTERNAL_KEY = 'EXTERNAL_KEY',
  INTERNAL_WATER = 'INTERNAL_WATER',
  INTERNAL_KEY_WATER = 'INTERNAL_KEY_WATER',
  EXTERNAL_WATER = 'EXTERNAL_WATER',
  EXTERNAL_KEY_WATER = 'EXTERNAL_KEY_WATER',
  INTERNAL_MECHANICAL = 'INTERNAL_MECHANICAL',
  INTERNAL_KEY_MECHANICAL = 'INTERNAL_KEY_MECHANICAL',
  EXTERNAL_MECHANICAL = 'EXTERNAL_MECHANICAL',
  EXTERNAL_KEY_MECHANICAL = 'EXTERNAL_KEY_MECHANICAL',
  TAB_INTERNAL_EQUIPMENT_WATER = 'TAB_INTERNAL_EQUIPMENT_WATER',
  TAB_INTERNAL_EQUIPMENT_MECHANICAL = 'TAB_INTERNAL_EQUIPMENT_MECHANICAL',
  TAB_INTERNAL_EQUIPMENT_ELECTRICAL = 'TAB_INTERNAL_EQUIPMENT_ELECTRICAL',
  TAB_EQUIPMENT_WATER = 'TAB_EQUIPMENT_WATER'
}

export default Permissions;
