import * as tslib_1 from "tslib";
import { LireDataService } from '../dataServices/lire-data-service.provider';
import { TableDataSource } from './table-data-source.provider';
var LireDataSourceService = /** @class */ (function (_super) {
    tslib_1.__extends(LireDataSourceService, _super);
    /**
     *
     */
    function LireDataSourceService(lireDataService) {
        var _this = _super.call(this) || this;
        _this.lireDataService = lireDataService;
        return _this;
    }
    LireDataSourceService.prototype.getData = function (sort, filter, query, page) {
        return this.lireDataService.getLireById(this.equipmentId, page, this.pageSize, sort, filter);
    };
    return LireDataSourceService;
}(TableDataSource));
export { LireDataSourceService };
