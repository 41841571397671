import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

import { ConnectedUserService } from 'libs/shared-module/src/lib/providers/connected-user.provider';
import { SnackbarService } from 'libs/shared-module/src/lib/providers/snackbar.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';
import { WelcomeService } from 'libs/shared-module/src/lib/providers/welcome.provider';
import { UserViewModel } from 'libs/shared-module/src/lib/viewmodels/user.viewmodel';

@Component({
  selector: 'ahc-profile-information',
  templateUrl: './profile-information.component.html',
  styleUrls: ['./profile-information.component.scss']
})
export class ProfileInformationComponent implements OnInit, OnDestroy {
  user: UserViewModel;
  welcomeMode = false;
  profileForm: FormGroup = new FormGroup({
    lastname: new FormControl(''),
    firstname: new FormControl(''),
    language: new FormControl('')
  });

  constructor(
    private connectedService: ConnectedUserService,
    private welcomeService: WelcomeService,
    private userService: UsersService,
    private snackbarService: SnackbarService,
    private translate: TranslateService
  ) {
    this.welcomeMode = welcomeService.isFirstConnection;
  }

  private ngUnsubscribe: Subject<void> = new Subject();

  ngOnInit() {
    this.connectedService
      .onUser()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((_user: UserViewModel) => {
        if (!_user) {
          return;
        }
        this.user = _user;
        this.profileForm.setValue({
          lastname: this.user.lastname,
          firstname: this.user.firstname,
          language: this.user.language
        });
      });
  }

  save() {
    if (this.profileForm.dirty) {
      this.userService
        .update(this.user.id, this.profileForm.value)
        .pipe(
          catchError(error => {
            this.handleError(
              this.translate.instant('dashboard.users.update_error')
            );
            return of(null);
          })
        )
        .subscribe(response => {
          if (response) {
            this.connectedService.refresh();
            this.snackbarService.open(
              this.translate.instant('dashboard.users.updated'),
              this.translate.instant('common.success')
            );
            this.profileForm.reset(this.profileForm.value);
          }
        });
      return;
    }
    if (this.welcomeMode) {
      this.welcomeService.done();

      return;
    }
  }

  handleError(message: string) {
    this.snackbarService.open(message, null);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
