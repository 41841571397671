import { SharedModule } from '@ahc/shared-module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { AmplifyAngularModule } from 'aws-amplify-angular';

import { LoginGuard } from 'libs/shared-module/src/lib/guards/login.guard';

import { ExternalLoginComponent } from './external-login/external-login.component';
import { LoginCallbackComponent } from './login-callback/login-callback.component';
import { LoginDefineNewPasswordComponent } from './login-define-new-password/login-define-new-password.component';
import { LoginHomeComponent } from './login-home/login-home.component';
import { LoginInternalUserComponent } from './login-internal-user/login-internal-user.component';
import { LoginResetPwdComponent } from './login-reset-pwd/login-reset-pwd.component';
import { LoginSelectionComponent } from './login-selection/login-selection.component';
import { LoginSetFirstNewPasswordComponent } from './login-set-first-new-password/login-set-first-new-password.component';
import { LoginRouterModule } from './login.router.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

@NgModule({
  declarations: [
    LoginHomeComponent,
    ExternalLoginComponent,
    LoginSelectionComponent,
    LoginResetPwdComponent,
    LoginDefineNewPasswordComponent,
    LoginSetFirstNewPasswordComponent,
    LoginCallbackComponent,
    LoginInternalUserComponent,
    UnauthorizedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatButtonModule,
    MatExpansionModule,
    MatInputModule,
    MatCardModule,
    MatSnackBarModule,
    FlexLayoutModule,
    LoginRouterModule,
    SharedModule,
    AmplifyAngularModule
  ],
  providers: [LoginGuard]
})
export class LoginModule {}
