import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { EquipmentGlobalInfoModel } from '../models/cockpit/global-info.model';
import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { EquipmentViewModel } from '../viewmodels/equipment.viewmodel';

import { ScadaDataService } from './dataServices/scada-data-service.provider';

@Injectable()
export class EquipmentGlobalInfoService {
  /**
   *
   */
  constructor(
    private http: HttpClient,
    public scadaService: ScadaDataService
  ) {}

  getInfo(equipment: EquipmentViewModel): Observable<EquipmentGlobalInfoModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'no-cache': 'true'
      })
    };

    this.scadaService.category = 'home';

    return forkJoin([
      this.http.get<EquipmentGlobalInfoModel>(
        `${ApiEndpointsEnum.EQUIPMENTS}/${equipment.pid}/home`,
        httpOptions
      ),
      this.scadaService.getScadaForEquipment(equipment.pid, 1, 1000)
    ]).pipe(
      map(results => {
        const runningSinceLastTar = results[1].items.find(
          item =>
            item.parameterName
              .toLowerCase()
              .indexOf('TIME SINCE LAST TAR'.toLowerCase()) > -1
        );
        const globalInfo = results[0];
        if (globalInfo) {
          globalInfo.timeSinceLastTar =
            runningSinceLastTar &&
            runningSinceLastTar.parameterTimeseries.length > 0
              ? runningSinceLastTar.parameterTimeseries[0].value
              : null;
        }

        return globalInfo;
      })
    );
  }
}
