import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatTooltipModule
} from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialFileInputModule } from 'ngx-material-file-input';

import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { ParameterTableDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/parameter-table-data-source.provider';
import { ReportDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/report-data-source.provider';
import { ReportLinkDataSourceService } from 'libs/shared-module/src/lib/providers/dataSource/report-link-data-source.provider';
import { EquipmentGlobalInfoService } from 'libs/shared-module/src/lib/providers/equipment-global-info.provider';
import { FavoriteEquipmentService } from 'libs/shared-module/src/lib/providers/favorite-equipment.provider';
import { ParameterGraphParameterService } from 'libs/shared-module/src/lib/providers/parameter-graph-parameter.provider';
import { ParameterService } from 'libs/shared-module/src/lib/providers/parameter.provider';
import { ReportService } from 'libs/shared-module/src/lib/providers/report.provider';
import { ScadaGraphParameterService } from 'libs/shared-module/src/lib/providers/scada-graph-parameter.provider';
import { NgxPermissionsModule } from 'ngx-permissions';

import { UiCommonModule } from '../common/uicommon.module';

import { CockpitHomeComponent } from './cockpit-home/cockpit-home.component';
import { GlobalInfoComponent } from './cockpit-home/components/global-info/global-info.component';
import { CockpitComponent } from './cockpit.component';
import { CockpitRoutingModule } from './cockpit.routing.module';
import { CockpitHeaderComponent } from './components/cockpit-header/cockpit-header.component';
import { CockpitTabSelectionComponent } from './components/cockpit-tab-selection/cockpit-tab-selection.component';
import { EquipmentExtendedInfoComponent } from './components/equipment-extended-info/equipment-extended-info.component';
import { EquipmentTypeInfoComponent } from './components/equipment-type-info/equipment-type-info.component';
import { InsertParameterValueComponent } from './components/insert-parameter-value/insert-parameter-value.component';
import { ParametersPreviewComponent } from './components/parameters-preview/parameters-preview.component';
import { ReportDataQualityIssueComponent } from './components/report-data-quality-issue/report-data-quality-issue.component';
import { CorrectiveComponent } from './corrective/corrective.component';
import { LireComponent } from './corrective/lire/lire.component';
import { MaximoWorkOrderComponent } from './corrective/maximo-work-order/maximo-work-order.component';
import { OilAnalysisComponent } from './oil-analysis/oil-analysis.component';
import { OilDegradationComponent } from './oil-analysis/oil-parameters/oil-degradation/oil-degradation.component';
import { OilParameterGraphComponent } from './oil-analysis/oil-parameters/oil-parameter-graph/oil-parameter-graph.component';
import { OilParametersComponent } from './oil-analysis/oil-parameters/oil-parameters.component';
import { OilTableComponent } from './oil-analysis/oil-table/oil-table.component';
import { CatchesComponent } from './predictive/catches/catches.component';
import { IRondeComponent } from './predictive/ironde/ironde.component';
import { MaximoComponent } from './predictive/maximo/maximo.component';
import { PredictiveComponent } from './predictive/predictive.component';
import { ModalUploadFileComponent } from './report/modal-upload-file/modal-upload-file.component';
import { ModalUploadLinkComponent } from './report/modal-upload-link/modal-upload-link.component';
import { ReportComponent } from './report/report.component';
import { TableLinkComponent } from './report/table-link/table-link.component';
import { TableUploadComponent } from './report/table-upload/table-upload.component';
import { ScadaComponent } from './scada/scada.component';
import { ServiceReportComponent } from './service-report/service-report.component';
import { TimelineComponent } from './timeline/timeline.component';
import { VibrationTableComponent } from './vibration/vibration-table/vibration-table.component';
import { VibrationComponent } from './vibration/vibration.component';
import { WaterComponent } from './water/water.component';

@NgModule({
  declarations: [
    CockpitComponent,
    CockpitHomeComponent,
    CockpitHeaderComponent,
    CockpitTabSelectionComponent,
    EquipmentTypeInfoComponent,
    EquipmentExtendedInfoComponent,
    TimelineComponent,
    PredictiveComponent,
    CorrectiveComponent,
    ScadaComponent,
    OilAnalysisComponent,
    VibrationComponent,
    ReportComponent,
    ModalUploadFileComponent,
    ModalUploadLinkComponent,
    TableUploadComponent,
    TableLinkComponent,
    MaximoComponent,
    CatchesComponent,
    IRondeComponent,
    MaximoWorkOrderComponent,
    LireComponent,
    ParametersPreviewComponent,
    OilParametersComponent,
    OilParameterGraphComponent,
    OilDegradationComponent,
    OilTableComponent,
    InsertParameterValueComponent,
    ReportDataQualityIssueComponent,
    VibrationTableComponent,
    ServiceReportComponent,
    WaterComponent,
    GlobalInfoComponent
  ],
  entryComponents: [
    ReportDataQualityIssueComponent,
    ModalUploadFileComponent,
    ModalUploadLinkComponent
  ],
  imports: [
    UiCommonModule,
    CommonModule,
    CockpitRoutingModule,
    MatIconModule,
    FlexLayoutModule,
    TranslateModule,
    MatTabsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatButtonModule,
    MatMenuModule,
    MaterialFileInputModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxPermissionsModule,
    MatGridListModule,
    FormsModule,
    MatTooltipModule
  ],
  providers: [
    FavoriteEquipmentService,
    CockpitService,
    ScadaGraphParameterService,
    ParameterService,
    ParameterTableDataSourceService,
    ParameterGraphParameterService,
    EquipmentGlobalInfoService,
    ReportDataSourceService,
    ReportLinkDataSourceService,
    ReportService
  ],
  exports: []
})
export class CockpitModule {}
