import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'guid-typescript';
import * as Highcharts from 'highcharts/highstock';

import Permissions from 'libs/shared-module/src/lib/models/common/enums/permissions.enum';
import Tabs from 'libs/shared-module/src/lib/models/common/enums/tabs.enum';
import { GraphParameterModel } from 'libs/shared-module/src/lib/models/common/graph-parameter.model';
import { BaseGraphParameterService } from 'libs/shared-module/src/lib/providers/graph-parameter.base.provider';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ahc-parameter-graph',
  templateUrl: './parameter-graph.component.html',
  styleUrls: ['./parameter-graph.component.scss']
})
export class ParameterGraphComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  equipmentId: string;
  @Input()
  parameter: string;
  @Input()
  parameterId?: string;

  @Input()
  graphService: BaseGraphParameterService;

  @Input()
  module: Tabs;

  containerId: Guid;
  isLoading = true;
  public permissions = Permissions;

  public options: Highcharts.Options = {};

  constructor(private translate: TranslateService) {
    this.containerId = Guid.create();
  }

  private ngUnsubscribe: Subject<void> = new Subject();

  ngOnInit() {}

  ngAfterViewInit() {
    this.graphService.points$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((graph: GraphParameterModel<Highcharts.Point>) => {
        this.options = {
          time: {
            useUTC: false
          },
          chart: {
            type: 'line',
            backgroundColor: 'transparent',
            zoomType: 'x'
          },
          title: {
            text: null
          },
          credits: {
            enabled: false
          },
          xAxis: {
            visible: true,
            type: 'datetime'
          },
          yAxis: {
            visible: true,
            opposite: true,
            plotLines: graph.threshold
              ? [
                  {
                    value: parseFloat(graph.threshold.veryLow),
                    color: 'red',
                    width: 2,
                    dashStyle: 'Solid'
                  },
                  {
                    value: parseFloat(graph.threshold.low),
                    color: 'orange',
                    width: 2,
                    dashStyle: 'Solid'
                  },
                  {
                    value: parseFloat(graph.threshold.high),
                    color: 'orange',
                    width: 2,
                    dashStyle: 'Solid'
                  },
                  {
                    value: parseFloat(graph.threshold.veryHigh),
                    color: 'red',
                    width: 2,
                    dashStyle: 'Solid'
                  }
                ]
              : []
          },
          legend: {
            enabled: true
          },
          tooltip: {
            enabled: true,
            valueDecimals: 2,
            headerFormat: '',
            pointFormat: 'x: {point.x:%d %b %Y}<br/>y: {point.y}'
          },
          plotOptions: {
            series: {
              turboThreshold: 5000 // larger threshold or set to 0 to disable
            }
          },
          series: [
            {
              showInLegend: false,
              type: 'line',
              data: graph.values,
              marker: {
                enabled: false
              }
            }
          ],
          navigator: {
            enabled: false
          }
        };
        Highcharts.setOptions({
          lang: {
            resetZoom: this.translate.instant('cockpit.common.graph_zoom_out')
          }
        });
        Highcharts.chart(this.containerId.toString(), this.options);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.complete();
    this.ngUnsubscribe.unsubscribe();
  }
}
