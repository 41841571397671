/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./equipment-type-info.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "./equipment-type-info.component";
var styles_EquipmentTypeInfoComponent = [i0.styles];
var RenderType_EquipmentTypeInfoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EquipmentTypeInfoComponent, data: {} });
export { RenderType_EquipmentTypeInfoComponent as RenderType_EquipmentTypeInfoComponent };
export function View_EquipmentTypeInfoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "equipment-type"], ["fxLayout", "column"], ["fxLayoutAlign", "space-around start"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [["class", "class-structure"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "detail"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""]))], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "space-around start"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.equipment.class.name; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.equipment.locationDescription; _ck(_v, 6, 0, currVal_3); }); }
export function View_EquipmentTypeInfoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ahc-equipment-type-info", [], null, null, null, View_EquipmentTypeInfoComponent_0, RenderType_EquipmentTypeInfoComponent)), i1.ɵdid(1, 114688, null, 0, i4.EquipmentTypeInfoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EquipmentTypeInfoComponentNgFactory = i1.ɵccf("ahc-equipment-type-info", i4.EquipmentTypeInfoComponent, View_EquipmentTypeInfoComponent_Host_0, { equipment: "equipment" }, {}, []);
export { EquipmentTypeInfoComponentNgFactory as EquipmentTypeInfoComponentNgFactory };
