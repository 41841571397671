import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';
import { catchError, map } from 'rxjs/operators';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import StorageKeysEnum from '../models/common/enums/storage-keys.enum';
import { SearchEquipmenFilterEnum } from '../models/common/search-equipment-filter.enum';
import { SearchResultModel } from '../models/common/search-result.model';
import { EquipmentModel } from '../models/equipment/equipement.model';
import { SearchCriteria } from '../models/equipment/search-criteria.model';
import { EquipmentViewModel } from '../viewmodels/equipment.viewmodel';
import { PlantViewModel } from '../viewmodels/plant.viewmodel';

import { Logger } from './Logger.provider';
import { StorageService } from './storage.provider';

@Injectable()
export class SearchEquipmentService {
  result: Map<PlantViewModel, EquipmentViewModel[]> = new Map();
  loading$ = new BehaviorSubject(false);
  criteria$: BehaviorSubject<SearchCriteria>;
  filtredResult: BehaviorSubject<
    SearchResultModel<Map<PlantViewModel, EquipmentViewModel[]>>
  > = new BehaviorSubject({ totalSize: 0, items: new Map() });

  /**
   *
   */
  constructor(
    private logger: Logger,
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.blankCriteria = Object.assign(this.criteria);
    this.onFiltredResult().subscribe(() => this.loading$.next(false));

    this.criteria =
      this.storageService.get(StorageKeysEnum.SearchCriteria) ||
      this.blankCriteria;

    this.criteria$ = new BehaviorSubject<SearchCriteria>(this.criteria);
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'no-cache': 'true'
    })
  };

  private blankCriteria: SearchCriteria;
  private criteria: SearchCriteria = {
    classId: undefined,
    clusterId: undefined,
    countryId: undefined,
    expertiseDomainId: undefined,
    locationId: undefined,
    page: 1,
    pageSize: 25,
    plantId: undefined,
    query: undefined,
    unitId: undefined,
    filter: SearchEquipmenFilterEnum.MAIN
  };

  getCriteria(): SearchCriteria {
    return this.criteria;
  }

  onCriteria(): Observable<SearchCriteria> {
    return this.criteria$.asObservable();
  }

  onFiltredResult(): Observable<
    SearchResultModel<Map<PlantViewModel, EquipmentViewModel[]>>
  > {
    return this.filtredResult.asObservable();
  }

  doRequest(
    criteria: SearchCriteria
  ): Observable<SearchResultModel<EquipmentModel[]>> {
    // build request url
    let requestUrl = `${ApiEndpointsEnum.EQUIPMENTS}?page=${
      criteria.page
    }&page_size=${criteria.pageSize}&with_scope=true`;
    if (criteria) {
      if (criteria.query) {
        requestUrl += `&query=${criteria.query}`;
      }
      if (criteria.classId) {
        requestUrl += `&class_structure_id=${criteria.classId}`;
      }
      if (criteria.clusterId) {
        requestUrl += `&cluster_id=${criteria.clusterId}`;
      }
      if (criteria.countryId) {
        requestUrl += `&country_id=${criteria.countryId}`;
      }
      if (criteria.expertiseDomainId) {
        requestUrl += `&expertise_domain=${criteria.expertiseDomainId}`;
      }
      if (criteria.locationId) {
        requestUrl += `&location=${criteria.locationId}`;
      }
      if (criteria.plantId) {
        requestUrl += `&plant_id=${criteria.plantId}`;
      }
      if (criteria.unitId) {
        requestUrl += `&production_unit_id=${criteria.unitId}`;
      }
      if (criteria.assetId) {
        requestUrl += `&asset_num=${criteria.assetId}`;
      }
      switch (criteria.filter) {
        case SearchEquipmenFilterEnum.MAIN: {
          requestUrl += '&main=true';
          break;
        }
        case SearchEquipmenFilterEnum.FAVORITE: {
          requestUrl += '&favorite=true';
          break;
        }
        default: {
        }
      }
    }

    return this.http.get<SearchResultModel<EquipmentModel[]>>(
      requestUrl,
      this.httpOptions
    );
  }

  search(criteria: SearchCriteria) {
    this.loading$.next(true);
    this.criteria = criteria;
    this.criteria$.next(this.criteria);
    this.storageService.set(StorageKeysEnum.SearchCriteria, this.criteria);

    this.doRequest(this.criteria)
      .pipe(
        map((searchResult: SearchResultModel<EquipmentModel[]>) => {
          const equipments = searchResult.items.map((model: EquipmentModel) =>
            EquipmentViewModel.fromModel(model)
          );
          return {
            totalSize: searchResult.totalSize,
            items: equipments
          };
        }),
        catchError(err => {
          this.result = new Map();
          this.filtredResult.next({ totalSize: 0, items: new Map() });
          this.logger.error(
            'error loading equipments list',
            err,
            this.criteria
          );
          return [];
        })
      )
      .subscribe((result: SearchResultModel<EquipmentViewModel[]>) => {
        const equipments = result.items;
        this.result = new Map();

        const plants = _.uniqBy(
          equipments.map((eq: EquipmentViewModel) => eq.plant),
          'id'
        );
        plants.forEach((plant: PlantViewModel) =>
          this.result.set(
            plant,
            equipments.filter(
              (eq: EquipmentViewModel) => eq.plant.id === plant.id
            )
          )
        );
        const searchResult: SearchResultModel<
          Map<PlantViewModel, EquipmentViewModel[]>
        > = { totalSize: result.totalSize, items: this.result };
        this.filtredResult.next(searchResult);
      });
  }

  filter(query: SearchEquipmenFilterEnum) {
    switch (query) {
      case SearchEquipmenFilterEnum.FAVORITE: {
        this.filterByFavorite();
        break;
      }
      case SearchEquipmenFilterEnum.ALL:
      case SearchEquipmenFilterEnum.MAIN:
      default: {
        this.search(this.blankCriteria);
      }
    }
  }
  private filterByFavorite() {
    const currentResult = this.filtredResult.getValue();
    currentResult.items.forEach((v, k) => {
      currentResult.items.set(
        k,
        v.filter((e: EquipmentViewModel) => e.isFavorite)
      );
      if (currentResult.items.get(k).length === 0) {
        currentResult.items.delete(k);
      }
    });
    this.filtredResult.next(currentResult);
  }
}
