import { Component, OnInit } from '@angular/core';

import { EquipmentGlobalInfoModel } from 'libs/shared-module/src/lib/models/cockpit/global-info.model';
import EquipmentStatus from 'libs/shared-module/src/lib/models/common/enums/equipment.status.enum';
import ExpertiseDomain from 'libs/shared-module/src/lib/models/common/enums/expertise-domain.enum';
import { CockpitService } from 'libs/shared-module/src/lib/providers/cockpit.provider';
import { EquipmentGlobalInfoService } from 'libs/shared-module/src/lib/providers/equipment-global-info.provider';

@Component({
  selector: 'ahc-global-info',
  templateUrl: './global-info.component.html',
  styleUrls: ['./global-info.component.scss']
})
export class GlobalInfoComponent implements OnInit {
  info: EquipmentGlobalInfoModel;
  expertiseDomain = ExpertiseDomain;
  currentExpertiseDomain: ExpertiseDomain;
  firstHealthIndicator: EquipmentStatus;
  secondHealthIndicator: EquipmentStatus;
  firstAlarm = null;
  secondAlarm = null;
  firstAnalysis = '';
  secondAnalysis = '';

  constructor(
    private globalInfoService: EquipmentGlobalInfoService,
    private cockpitService: CockpitService
  ) {}

  ngOnInit() {
    this.cockpitService.onEquipment().subscribe(equipment => {
      if (equipment) {
        this.currentExpertiseDomain = equipment.class.expertiseDomain;
        this.globalInfoService.getInfo(equipment).subscribe(info => {
          this.info = info;
          if (this.currentExpertiseDomain === ExpertiseDomain.WATER) {
            this.firstAnalysis = 'water';
            this.secondAnalysis = 'service_report';
            this.firstAlarm = info.alarmKpis;
            this.secondAlarm = info.alarmServiceReport;
            this.firstHealthIndicator = info.healthKpis;
            this.secondHealthIndicator = info.healthServiceReport;
          } else {
            this.firstAnalysis = 'oil';
            this.secondAnalysis = 'vibration';
            this.firstAlarm = info.alarmOil;
            this.secondAlarm = info.alarmVibration;
            this.firstHealthIndicator = info.healthOil;
            this.secondHealthIndicator = info.healthVibration;
          }
        });
      }
    });
  }
}
