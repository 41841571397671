import { enableProdMode } from '@angular/core';
import Amplify from 'aws-amplify';
import { Analytics } from 'aws-amplify';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
Amplify.configure(environment.cognito.amplify.awsConfig.Auth);
Analytics.configure({ disabled: true });
if (environment.production) {
    enableProdMode();
}
__NgCli_bootstrap_2.platformBrowser()
    .bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory)
    .catch(function (err) { return console.error(err); });
