import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

import { ConnectedUserService } from './connected-user.provider';

@Injectable()
export class AhcPaginatorIntl extends MatPaginatorIntl {
  /**
   *
   */
  constructor(
    connectedUser: ConnectedUserService,
    translate: TranslateService
  ) {
    super();

    connectedUser.onUser().subscribe(user => {
      this.itemsPerPageLabel = translate.instant(
        'common.ui.paginator_items_per_page'
      );
    });
  }
}
