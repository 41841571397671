<div class="cockpit" fxLayout="row" fxLayoutAlign="start" fxFlexFill>
  <ahc-side-menu fxFlex="50px"></ahc-side-menu>
  <div
    class="content"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="1px"
  >
    <ahc-cockpit-header
      class="header"
      fxFlexAlign="stretch"
    ></ahc-cockpit-header>
    <mat-progress-bar
      *ngIf="isLoading"
      class="loader loading"
      mode="indeterminate"
    ></mat-progress-bar>
    <ahc-cockpit-tab-selection></ahc-cockpit-tab-selection>
    <div class="panel" *ngIf="!isLoading">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
