import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import SortEnum from 'libs/shared-module/src/lib/models/common/enums/sort.enum';

@Component({
  selector: 'ahc-table-column-filter',
  templateUrl: './table-column-filter.component.html',
  styleUrls: ['./table-column-filter.component.scss']
})
export class TableColumnFilterComponent implements OnInit {
  isOpened = false;
  isUsed = false;

  @Input()
  sortType: SortEnum;

  @Input()
  enableFilter = true;

  @Input()
  enableSort = true;

  @Input()
  placeHolder: '';

  @Output()
  search: EventEmitter<string> = new EventEmitter();

  @Output()
  sort: EventEmitter<SortEnum> = new EventEmitter();

  @Output()
  used: EventEmitter<boolean> = new EventEmitter();

  filterValue = '';

  constructor() {}

  ngOnInit() {
    if (!this.sortType) {
      this.sortType = SortEnum.none;
    }
  }

  doSort() {
    switch (this.sortType) {
      case SortEnum.none: {
        this.sortType = SortEnum.asc;
        break;
      }
      case SortEnum.asc: {
        this.sortType = SortEnum.desc;
        break;
      }
      case SortEnum.desc: {
        this.sortType = SortEnum.none;
        break;
      }
    }

    this.sort.emit(this.sortType);
    this.updateUsedStatus();
  }

  toggleFilter() {
    this.filterValue = '';
    this.isOpened = !this.isOpened;
    if (this.isOpened) {
      this.used.emit(true);
    } else {
      this.search.emit(null);
    }
    this.updateUsedStatus();
  }

  filter() {
    this.search.emit(this.filterValue);
  }

  updateUsedStatus() {
    if (this.isOpened || this.sortType !== SortEnum.none) {
      this.used.emit(true);
    } else {
      this.used.emit(false);
    }
  }
}
