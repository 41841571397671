import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import ApiEndpointsEnum from '../models/common/enums/api_endpoints.enum';
import { SearchResultModel } from '../models/common/search-result.model';
import { EquipmentViewModel } from '../viewmodels/equipment.viewmodel';

@Injectable()
export class FavoriteEquipmentService {
  /**
   *
   */
  constructor(private http: HttpClient) {}

  toggle(equipment: EquipmentViewModel): Observable<Object> {
    return this.http.put(
      `${ApiEndpointsEnum.EQUIPMENTS}/${equipment.pid}/${
        equipment.isFavorite ? 'unfavorite' : 'favorite'
      }`,
      {}
    );
  }

  loadStatus(
    equipments: EquipmentViewModel[]
  ): Observable<EquipmentViewModel[]> {
    return this.http
      .post<SearchResultModel<string[]>>(ApiEndpointsEnum.EQUIPMENTS_FAVORITE, {
        equipment_list: equipments.map(eq => eq.pid)
      })
      .pipe(
        map(pIds => {
          equipments.map(
            eq =>
              (eq.isFavorite =
                pIds.items.find(id => id === eq.pid) !== undefined)
          );
          return equipments;
        })
      );
  }
}
