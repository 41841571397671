<div fxLayout="row" class="feedback">
  <div class="gauge" id="{{ containerId.toString() }}"></div>
  <div fxLayout="column" fxLayoutAlign="stretch" fxLayoutGap="5px">
    <div class="who">
      {{ 'cockpit.feedback.comments_by' | translate }}
      <span class="user-name">
        {{ feedback.comment ? feedback.comment.author : '' }},
        {{ feedback.date | date: 'mediumDate' }}</span
      >
    </div>
    <i *ngIf="compact" class="material-icons eq-status s-{{ feedback.status }}">
      fiber_manual_record
    </i>
    <div class="message">
      {{ feedback.comment ? feedback.comment.comment : '' }}
    </div>
  </div>
</div>
