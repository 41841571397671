import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatSelectModule
} from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';

import { ScopeParameterService } from 'libs/shared-module/src/lib/providers/scope-parameters.provider';
import { UsersService } from 'libs/shared-module/src/lib/providers/users.provider';

import { UiCommonModule } from '../../common/uicommon.module';

import { CreateExternalUserComponent } from './create-external-user/create-external-user.component';
import { CreateNewUserComponent } from './create-new-user/create-new-user.component';
import { InviteUsersComponent } from './invite-users/invite-users.component';
import { UserInformationListComponent } from './user-information-list/user-information-list.component';
import { UserInformationPanelComponent } from './user-information-panel/user-information-panel.component';
import { UserInformationComponent } from './user-information/user-information.component';
import { UsersComponent } from './users.component';

@NgModule({
  declarations: [
    CreateNewUserComponent,
    InviteUsersComponent,
    UsersComponent,
    UserInformationComponent,
    UserInformationListComponent,
    UserInformationPanelComponent,
    CreateExternalUserComponent
  ],
  entryComponents: [CreateNewUserComponent, CreateExternalUserComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatMenuModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatButtonModule,
    FormsModule,
    UiCommonModule,
    MatCardModule,
    MatProgressBarModule,
    NgxPermissionsModule,
    MatDialogModule,
    MatPaginatorModule,
    NgxPermissionsModule
  ],
  providers: [UsersService, ScopeParameterService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class UsersModule {}
