<div
  class="users"
  fxLayout="column"
  fxLayoutAlign="start center"
  fxLayoutGap="30px"
>
  <div class="header" fxFlex="auto">
    <span class="title">{{ 'dashboard.users.title' | translate }}</span>
  </div>
  <ahc-invite-users fxFlexAlign="stretch"></ahc-invite-users>
  <div fxFlexAlign="stretch">
    <ahc-user-information-list></ahc-user-information-list>
  </div>
</div>
