<div class="side-menu" fxLayout="column" fxLayoutAlign="space-between" fxFill>
  <div fxLayout="column" fxLayoutAlign="start" class="top-menu">
    <div
      class="item"
      fxLayout="row"
      fxLayoutAlign="center center"
      [routerLink]="['/main/profile']"
      routerLinkActive="active"
      title="{{ 'dashboard.profile.title' | translate }}"
    >
      <i class="content ahc-icon icon-profil"></i>
    </div>
    <div
      class="item"
      fxLayout="row"
      fxLayoutAlign="center center"
      [routerLink]="['/main/search']"
      routerLinkActive="active"
      title="{{ 'dashboard.search.title' | translate }}"
    >
      <mat-icon class="content" aria-hidden="false">search</mat-icon>
    </div>
    <div
      class="item"
      fxLayout="row"
      fxLayoutAlign="center center"
      [routerLink]="['/main/upload']"
      routerLinkActive="active"
      title="{{ 'dashboard.import.title' | translate }}"
    >
      <mat-icon class="content" aria-hidden="false">cloud_upload</mat-icon>
    </div>
    <div
      *ngxPermissionsExcept="[permissions.EXTERNAL]"
      class="item"
      fxLayout="row"
      fxLayoutAlign="center center"
      [routerLink]="['/main/users']"
      routerLinkActive="active"
      title="{{ 'dashboard.users.title' | translate }}"
    >
      <i class="content ahc-icon icon-group_search"></i>
    </div>
    <div
      *ngxPermissionsOnly="[permissions.ADMIN, permissions.INTERNAL_KEY]"
      class="item"
      fxLayout="row"
      fxLayoutAlign="center center"
      [routerLink]="['/main/admin']"
      routerLinkActive="active"
      title="{{ 'dashboard.admin.title' | translate }}"
    >
      <i class="content ahc-icon icon-settings"></i>
    </div>
    <div class="item logout" fxLayout="row" fxLayoutAlign="center center">
      <i class="content material-icons logout" (click)="signOut()"
        >power_settings_new</i
      >
    </div>
  </div>

  <div class="bottom-menu" fxLayout="column" fxLayoutAlign="end center">
    <div class="item">
      <i class="logo ahc-icon icon-al_logo_side" fxFill></i>
    </div>
    <a class="legal" href="assets/data/legal-notice.pdf">{{
      'common.legal_notice' | translate
    }}</a>
  </div>
</div>
