/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scada.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../../../../../../node_modules/@angular/material/progress-bar/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-bar";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../common/parameters-table/parameters-table.component.ngfactory";
import * as i8 from "../../../../../shared-module/src/lib/providers/scada-graph-parameter.provider";
import * as i9 from "../../../../../shared-module/src/lib/providers/dataServices/scada-data-service.provider";
import * as i10 from "../../common/parameters-table/parameters-table.component";
import * as i11 from "@angular/common";
import * as i12 from "@angular/common/http";
import * as i13 from "../../../../../shared-module/src/lib/providers/dataSource/scada-data-source.provider";
import * as i14 from "./scada.component";
import * as i15 from "../../../../../shared-module/src/lib/providers/cockpit.provider";
var styles_ScadaComponent = [i0.styles];
var RenderType_ScadaComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScadaComponent, data: {} });
export { RenderType_ScadaComponent as RenderType_ScadaComponent };
function View_ScadaComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["fxFlexAlign", "stretch"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultFlexAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.FlexAlignStyleBuilder], i3.MediaMarshaller], { fxFlexAlign: [0, "fxFlexAlign"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "mat-progress-bar", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "loading mat-progress-bar"], ["mode", "indeterminate"], ["role", "progressbar"]], [[1, "aria-valuenow", 0], [1, "mode", 0], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatProgressBar_0, i4.RenderType_MatProgressBar)), i1.ɵdid(3, 4374528, null, 0, i5.MatProgressBar, [i1.ElementRef, i1.NgZone, [2, i6.ANIMATION_MODULE_TYPE], [2, i5.MAT_PROGRESS_BAR_LOCATION]], { mode: [0, "mode"] }, null)], function (_ck, _v) { var currVal_0 = "stretch"; _ck(_v, 1, 0, currVal_0); var currVal_4 = "indeterminate"; _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = (((i1.ɵnov(_v, 3).mode === "indeterminate") || (i1.ɵnov(_v, 3).mode === "query")) ? null : i1.ɵnov(_v, 3).value); var currVal_2 = i1.ɵnov(_v, 3).mode; var currVal_3 = i1.ɵnov(_v, 3)._isNoopAnimation; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
function View_ScadaComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ahc-parameters-table", [], null, null, null, i7.View_ParametersTableComponent_0, i7.RenderType_ParametersTableComponent)), i1.ɵprd(4608, null, i8.ScadaGraphParameterService, i8.ScadaGraphParameterService, [i9.ScadaDataService]), i1.ɵdid(2, 4308992, null, 0, i10.ParametersTableComponent, [], { dataSource: [0, "dataSource"], equipment: [1, "equipment"], module: [2, "module"], graphService: [3, "graphService"], dateFormat: [4, "dateFormat"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; var currVal_1 = _co.equipment; var currVal_2 = _co.activeTab; var currVal_3 = _co.graphService; var currVal_4 = "dd MMM yyyy, hh:mm"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ScadaComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["fxLayout", "column"], ["fxLayoutAlign", "start stretch"]], null, null, null, null, null)), i1.ɵdid(1, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScadaComponent_2)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScadaComponent_3)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "start stretch"; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.isLoading; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.equipment; _ck(_v, 6, 0, currVal_3); }, null); }
export function View_ScadaComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScadaComponent_1)), i1.ɵdid(1, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.equipment; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ScadaComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "ahc-scada", [], null, null, null, View_ScadaComponent_0, RenderType_ScadaComponent)), i1.ɵprd(512, null, i9.ScadaDataService, i9.ScadaDataService, [i12.HttpClient]), i1.ɵprd(131584, null, i13.ScadaDataSourceService, i13.ScadaDataSourceService, [i9.ScadaDataService]), i1.ɵdid(3, 114688, null, 0, i14.ScadaComponent, [i1.ChangeDetectorRef, i8.ScadaGraphParameterService, i15.CockpitService, i13.ScadaDataSourceService], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var ScadaComponentNgFactory = i1.ɵccf("ahc-scada", i14.ScadaComponent, View_ScadaComponent_Host_0, {}, {}, []);
export { ScadaComponentNgFactory as ScadaComponentNgFactory };
